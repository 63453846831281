import { createSerializrSchema } from "pik-react-utils/utils"
import { BaseEntity } from "./BaseEntity"

interface IFullName extends IEntity {
  full_name: string
  visible_name?: string
}

const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "НСИ ID" },
      name: { name: "Наименование" },
      visible_name: { name: "Сокращенное наименование" },
      full_name: { name: "Полное наименование" },
    },
  },
  group_system_info: {
    name: "Системная информация",
    children: {
      created: { name: "Дата создания", _type: "datetime" },
      updated: { name: "Дата последнего изменения", _type: "datetime" },
      deleted: { name: "Дата удаления", _type: "datetime" },
    },
  },
} as const

export class FullNameEntity extends BaseEntity implements IFullName {
  static readonly schema: ISchema = schema

  readonly full_name: string
  readonly visible_name: string | undefined
}

createSerializrSchema(FullNameEntity, schema)
