import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { Result, Spin } from "antd"
import { observer } from "mobx-react"

import { processError } from "pik-react-utils/utils"
import { entitiesStore } from "stores"
import {
  AccessPortalStatus,
  ERROR_DESCRIPTIONS,
  ERROR_NUMBER_CODES,
} from "./constants"
import { Wrapper } from "./components"
import { AccessPortalProps } from "./types"

export const AccessPortal = observer(
  ({
    entityType,
    title,
    description,
    children,
  }: PropsWithChildren<AccessPortalProps>) => {
    const [store] = useState(() => entitiesStore)
    const [status, setStatus] = useState<AccessPortalStatus>(
      AccessPortalStatus.LOADING
    )
    const currentType = useRef<string | null>()

    const checkAccess = useCallback(async () => {
      if (!entityType) {
        return setStatus(AccessPortalStatus.SUCCESS)
      }

      setStatus(AccessPortalStatus.LOADING)

      try {
        await store.getCount(entityType)
        setStatus(AccessPortalStatus.SUCCESS)
      } catch (e) {
        const status = e.response?.status
        setStatus(ERROR_NUMBER_CODES.includes(status) ? status : "error")
        if (status === "error") {
          processError(e)
        }
      }
    }, [entityType, store])

    useEffect(() => {
      if (currentType.current !== entityType || !currentType.current) {
        currentType.current = entityType
        checkAccess()
      }
    }, [checkAccess, entityType, status])

    if (status === "loading") {
      return (
        <Wrapper>
          <Spin spinning />
        </Wrapper>
      )
    }

    if ([...ERROR_NUMBER_CODES, AccessPortalStatus.ERROR].includes(status)) {
      return (
        <Wrapper>
          <Result
            status={status}
            title={
              (status === AccessPortalStatus.FORBIDDEN && title) ||
              ERROR_DESCRIPTIONS[status]?.title
            }
            subTitle={
              (status === AccessPortalStatus.FORBIDDEN && description) ||
              ERROR_DESCRIPTIONS[status]?.description
            }
          />
        </Wrapper>
      )
    }

    return <>{children}</>
  }
)
