import React from "react"
import { Button, message } from "antd"
import { action, computed, makeObservable, observable } from "mobx"
import { observer } from "mobx-react"

import { INVENTORY_ACCESS_DESCRIPTION } from "constants/description"
import { AccessPortal, QueryPanel } from "components"
import { ColumnHeader } from "components/css"
import { AttentionModal, CreateEntityModal } from "modals"
import { AgGridInspector } from "pik-react-utils/components"
import { IReferenceType } from "types"
import {
  CenterColumn,
  ColumnContent,
  LeftColumn,
  RightColumn,
  SubHeader,
} from "./components"
import { TypeList, TypeListRef } from "./TypeList"
import { ReferenceStore } from "./InventoryReferenceStore"
import { ReferenceList } from "./ReferenceList"
import { PrivateFields } from "./types"

export const InventoryReferences = observer(
  class InventoryReferences extends React.Component {
    private isOpenCreatingModal = false
    private isOpenDeletingModal = false

    private readonly refStore = new ReferenceStore()
    private readonly refListRef = React.createRef<ReferenceList>()
    private readonly typeListRef = React.createRef<TypeListRef>()

    constructor(props: Record<string, unknown>) {
      super(props)
      makeObservable<InventoryReferences, PrivateFields>(this, {
        isOpenCreatingModal: observable.struct,
        isOpenDeletingModal: observable.struct,
        selectedReferenceType: computed.struct,
        selectedEntity: computed.struct,
        updateReferenceList: action.bound,
        updateTypeList: action.bound,
        setReferenceType: action.bound,
        setOpenModal: action.bound,
        setOpenDeleteModal: action.bound,
        deleteEntity: action.bound,
      })
    }

    private get selectedReferenceType() {
      return this.refStore.referenceType?.type
    }

    private get selectedEntity() {
      return this.refStore.selectedEntity
    }

    private updateReferenceList() {
      this.refListRef.current?.update()
    }

    private updateTypeList() {
      this.typeListRef.current?.update()
    }

    private setReferenceType(reference: IReferenceType) {
      this.refStore.setReferenceType(reference)
      this.updateReferenceList()
    }

    private setOpenModal(isOpen: boolean) {
      this.isOpenCreatingModal = isOpen
    }

    private setOpenDeleteModal(isOpen: boolean) {
      this.isOpenDeletingModal = isOpen
    }

    private async deleteEntity() {
      const name = this.selectedEntity.name
      await this.refStore.deleteEntity()
      message.success(`Объект "${name}" удалён`)
      this.isOpenDeletingModal = false
      this.updateReferenceList()
    }

    render() {
      return (
        <AccessPortal
          entityType="inventorybuilding"
          title={INVENTORY_ACCESS_DESCRIPTION.title}
          description={INVENTORY_ACCESS_DESCRIPTION.description}
        >
          <ColumnContent>
            <ColumnHeader>
              <QueryPanel
                store={this.refStore}
                entityType={this.selectedReferenceType ?? ""}
                onUpdate={this.updateTypeList}
                input={{ placeholder: "Объект справочника" }}
              />
              <Button
                onClick={() => this.setOpenModal(true)}
                disabled={
                  !this.selectedReferenceType ||
                  this.selectedReferenceType === "mdslivingcomplex"
                }
              >
                Создать
              </Button>
            </ColumnHeader>
            <LeftColumn>
              <TypeList
                ref={this.typeListRef}
                store={this.refStore}
                onOpenModal={() => this.setOpenModal(true)}
                onSelectRow={this.setReferenceType}
              />
            </LeftColumn>
            <CenterColumn>
              <ReferenceList
                ref={this.refListRef}
                store={this.refStore}
                onSelectRow={this.refStore.setSelectedEntity}
              />
            </CenterColumn>
            <SubHeader>
              <Button
                danger
                onClick={() => this.setOpenDeleteModal(true)}
                disabled={!this.selectedEntity}
              >
                Удалить
              </Button>
            </SubHeader>
            <RightColumn>
              <AgGridInspector entityLink={this.selectedEntity} />
            </RightColumn>
          </ColumnContent>
          {this.isOpenCreatingModal && (
            <CreateEntityModal
              open={this.isOpenCreatingModal}
              title={`Создать объект "${this.refStore.referenceName}"`}
              onClose={() => this.setOpenModal(false)}
              entityType={this.selectedReferenceType}
              onSuccess={this.updateReferenceList}
            />
          )}
          {this.refStore.selectedEntity && this.isOpenDeletingModal && (
            <AttentionModal
              open={this.isOpenDeletingModal}
              onOk={this.deleteEntity}
              onCancel={() => this.setOpenDeleteModal(false)}
            >
              Вы действительно хотите удалить&nbsp;
              {this.refStore.selectedEntity.name}?
            </AttentionModal>
          )}
        </AccessPortal>
      )
    }
  }
)
