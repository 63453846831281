import { observable, computed, makeObservable } from "mobx"
import { alias, object, primitive } from "serializr"

import { PersonEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { Organization } from "./organization"

interface IExternalAccount extends IEntity {
  last_name: string | undefined
  middle_name: string | undefined
  organization: Organization
}

export class ExternalAccount extends PersonEntity implements IExternalAccount {
  organization: Organization

  constructor(props: PersonEntity) {
    super(props)
    makeObservable(this, {
      organization: observable,
      breadcrumbFullName: computed,
    })
  }

  get breadcrumbFullName() {
    return this.organization?.name ?? ""
  }
}

createSerializrSchema(ExternalAccount, null, {
  organization: object(Organization),
  first_name: alias("name", primitive()),
})
