import { REALESTATE_KINDID_CHOICES } from "constants/choices"
import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "ЕФС UID" },
      mds_id: { name: "MDS ID" },
      objectid: { name: "ОбъектИДП" },
      code1c: { name: "Код 1c" },
      name: { name: "Наименование" },
      full_name: { name: "Полное наименование" },
    },
  },
  realestatekindid: {
    name: "Вид объекта недвижимости",
    _type: "choices",
    choices: REALESTATE_KINDID_CHOICES,
  },
  totalarea: { name: "Общая площадь (по БТИ)" },
  realestatenumber: { name: "Номер объекта недвижимости" },
  conditionalnumber: { name: "Условный номер (по данным застройщика)" },
  floor: { name: "Номер этажа" },
  roomcount: { name: "Кол-во комнат" },
  decoration: { name: "Отделка" },
  livingfactualarea: { name: "Жилая фактическая площадь" },
  factsummerarea: { name: "Фактическая площадь летних помещений" },
  sectionid: { name: "ИД секции" },
  ownerid: { name: "ИД Собственник объекта недвижимости" },
  addresspost: { name: "Почтовый адрес" },
  addressbuilding: { name: "Строительный адрес" },
  dateofissuecertificateaboutpropertycompany: {
    name: "Дата выдачи свидетельства о собственности компании",
    _type: "date",
  },
  numberrecordsregistrationcertificatesofpropertycompany: {
    name: "Номер записи регистрации свидетельства о собственности компании",
  },
  cadastralnumbercertificatespropertycompany: {
    name: "Кадастровый или условный номер свидетельства о собственности компании",
  },
  startdate: { name: "Дата начала использования", _type: "date" },
  enddate: { name: "Дата окончания использования", _type: "date" },
  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class MDSRealEstate extends BaseEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(MDSRealEstate, schema)
