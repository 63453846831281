import { observable, computed, makeObservable } from "mobx"
import { list, object, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { AsyncSelectOption } from "types"

import { BuildingsGroup } from "./buildingsgroup"
import { FinancialUnit } from "./financialunit"
import { Organization } from "./organization"
import { Territory } from "./territory"
import { UnitType } from "./unittype"

interface IUnit extends IEntity {
  master_names: string[]
  unit_type: UnitType
  functions: FinancialUnit[]
  territories: Territory[]
  leader_names: string[]
  buildings_group: BuildingsGroup[]
}

export class Unit extends BaseEntity implements IUnit {
  master_names: string[]
  unit_type: UnitType
  organization: Organization | null
  functions: FinancialUnit[]
  territories: Territory[]
  leader_names: string[]
  buildings_group: BuildingsGroup[]
  followers: BaseEntity[]
  leader: BaseEntity

  constructor(props: BaseEntity) {
    super(props)
    makeObservable(this, {
      master_names: observable,
      unit_type: observable,
      organization: observable,
      functions: observable,
      territories: observable,
      leader_names: observable,
      buildings_group: observable,
      followers: observable,
      leader: observable,
      activeFollowers: computed,
    })
  }

  get activeFollowers() {
    return this.followers?.filter((item) => !item.is_deleted)
  }

  static initialState(unit?: Partial<Unit> | undefined) {
    return {
      _type: "unit",
      name: unit?.name ?? "",
      leader: unit?.leader
        ? { value: unit.leader, label: unit.leader.name }
        : undefined,
      unit_type: unit?.unit_type?._uid,
    }
  }

  static toUpdate(formData: {
    name: string
    leader: AsyncSelectOption<Unit>
    unit_type: string
  }) {
    return {
      _type: "unit",
      name: formData?.name,
      leader: formData?.leader?.value?.toEntityLink(),
      unit_type: { _type: "unittype", _uid: formData.unit_type },
    }
  }
}

createSerializrSchema(Unit, null, {
  master_names: list(primitive()),
  unit_type: object(UnitType),
  organization: object(Organization),
  functions: list(object(FinancialUnit)),
  territories: list(object(Territory)),
  leader_names: list(primitive()),
  buildings_group: list(object(BuildingsGroup)),
  followers: list(object(BaseEntity)),
  leader: object(BaseEntity),
})
