import React from "react"
import { ICellEditorParams } from "ag-grid-community"
import styled from "styled-components"

export const Text = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  direction: rtl;
  text-align: left;
  padding-right: 6px;
`

export const EntityTextRenderer =
  (field: IEntityField) =>
  ({ value }: ICellEditorParams) => {
    if (!value) return ""
    let name = value.full_name ?? value.name ?? ""

    if ("_entityvisiblename" in field && field._entityvisiblename) {
      name = value[field._entityvisiblename]
    }

    return <Text>{name}</Text>
  }
