import { primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { RosReestrStatement } from "./rosreestrstatement"

const schema: ISchema = {
  group_premise_parameters: {
    name: "Характеристики помещения",
    children: {
      statement_premise: {
        _type: "parent",
        parentEntityLinkTo: RosReestrStatement,
        aliasParentField: "statement",
        fields: [
          "building",
          "number",
          "certificate_number",
          "premise_type",
          "premise_residential_type",
          "common_space",
          "premise_level",
        ],
      },
    },
  },

  group_rights: {
    name: "Зарегистрированные права",
    children: {
      owners_names: { name: "Собственники" },
      statement_rights: {
        _type: "parent",
        parentEntityLinkTo: RosReestrStatement,
        aliasParentField: "statement",
        fields: ["right"],
      },
      share_text: { name: "Доли владения" },
      registration_number: { name: "Номер государственной регистрации права" },
      registration_date: {
        name: "Дата государственной регистрации права",
        _type: "date",
      },
    },
  },

  group_cadastral: {
    name: "Кадастровый учет",
    children: {
      statement_cadastral: {
        _type: "parent",
        parentEntityLinkTo: RosReestrStatement,
        aliasParentField: "statement",
        fields: [
          "cadastral_id",
          "cadastral_date",
          "old_number",
          "cadastral_block_number",
          "cadastral_cost",
        ],
      },
    },
  },

  group_additional_information: {
    name: "Выписка - дополнительная информация",
    children: {
      statement_additional: {
        _type: "parent",
        parentEntityLinkTo: RosReestrStatement,
        aliasParentField: "statement",
        fields: [
          "order_user",
          "status",
          "last_order_date",
          "user_order_date",
          "certificate_date",
        ],
      },
    },
  },

  group_building_parameters: {
    name: "Характеристики здания",
    children: {
      statement_building_parameters: {
        _type: "parent",
        parentEntityLinkTo: RosReestrStatement,
        aliasParentField: "statement",
        fields: [
          "assignation",
          "floors",
          "underground_floors",
          "outer_wall_material",
          "year_built",
          "year_used",
        ],
      },
    },
  },
} as const

export class RosReestrRight extends BaseEntity {
  static readonly schema: ISchema = schema

  statement: RosReestrStatement

  get statement_uid() {
    return this.statement?.statement_uid
  }
}

createSerializrSchema(RosReestrRight, schema, {
  certificate_number: primitive(),
})
