import styled from "styled-components"

export const ColumnContent = styled.section`
  height: 100%;
  display: grid;
  padding: 12px;
  grid-template-areas:
    "header header subheader"
    "columnLeft columnCenter columnRight";
  grid-auto-columns: 1fr;
  grid-template-rows: auto 1fr;
  column-gap: 12px;
  row-gap: 12px;
`

export const SubHeader = styled.header`
  grid-area: "subheader";
`

export const LeftColumn = styled.div`
  grid-area: columnLeft;
`

export const CenterColumn = styled.div`
  grid-area: columnCenter;
`

export const RightColumn = styled.div`
  grid-area: columnRight;
`
