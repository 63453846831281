import { object, primitive } from "serializr"

import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupEditableNameFields } from "utils/fields"

import { Building } from "./building"
import { BuildingPart } from "./buildingpart"
import { PremiseType } from "./premisetype"

const schema: ISchema = {
  ...groupEditableNameFields(),

  group_parameters: {
    name: "Характеристики",
    children: {
      pay_space: { name: "Начисляемая площадь помещений, м²" },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class NonLivingPremise extends FullNameEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(NonLivingPremise, schema, {
  number: primitive(),
  building: object(Building),
  building_part: object(BuildingPart),
  premise_type: object(PremiseType),
})
