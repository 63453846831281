import { AddressComponents } from "./addresscomponents"
import { AdjacentArea } from "./adjacentarea"
import { Building } from "./building"
import { BuildingChange } from "./buildingchange"
import { BuildingChangeAttachment } from "./buildingchangeattachment"
import { BuildingCounter } from "./buildingcounter"
import { BuildingExtraAttr } from "./buildingextraattr"
import { BuildingLocation } from "./buildinglocation"
import { BuildingLocationRule } from "./buildinglocationrule"
import { BuildingPart } from "./buildingpart"
import { BuildingPartExtraAttr } from "./buildingpartextraattr"
import { BuildingPartType } from "./buildingparttype"
import { BuildingsGroup } from "./buildingsgroup"
import { BuildingSnapshot } from "./buildingsnapshot"
import { BuildingType } from "./buildingtype"
import { Competition } from "./competition"
import { CompetitionChange } from "./competitionchange"
import { CompetitionChangeAttachment } from "./competitionchangeattachment"
import { CompetitionSnapshot } from "./competitionsnapshot"
import { Contact } from "./contact"
import { Contractor } from "./contractor"
import { Division } from "./division"
import { Elevator } from "./elevator"
import { ElevatorModel } from "./elevatormodel"
import { Employee } from "./employee"
import { Employment } from "./employment"
import { Entity } from "./entity"
import { Exploitation } from "./exploitation"
import { ExploitationDivision } from "./exploitationdivision"
import { ExploitationSector } from "./exploitationsector"
import { ExternalAccount } from "./externalaccount"
import { FinancialEmployment } from "./financialemployment"
import { FinancialTerritory } from "./financialterritory"
import { FinancialUnit } from "./financialunit"
import { FinancialUnitAssessment } from "./financialunitassessment"
import { FinancialUnitOrganizationRelation } from "./financialunitorganizationrelation"
import { Foundation } from "./foundation"
import { Function } from "./function"
import { InventoryBuilding } from "./inventorybuilding"
import { InventoryBuildingPart } from "./inventorybuildingpart"
import { InventoryBuildingPartType } from "./inventorybuildingparttype"
import { InventoryPremise } from "./inventorypremise"
import { InventoryUtilitySystem } from "./inventoryutilitysystem"
import { InventoryUtilitySystemType } from "./inventoryutilitysystemtype"
import { JuristicUnit } from "./juristicunit"
import { LivingEntrance } from "./livingentrance"
import { LocalGovernment } from "./localgovernment"
import { ManagementCompany } from "./managementcompany"
import { ManagementService } from "./managementservice"
import { ManufacturingPlant } from "./manufacturingplant"
import { Material } from "./material"
import { MDSLivingComplex } from "./mdslivingcomplex"
import { MDSObject } from "./mdsobject"
import { MDSRealEstate } from "./mdsrealestate"
import { MeasureType } from "./measuretype"
import { MeasureUnit } from "./measureunit"
import { Meeting } from "./meeting"
import { MeetingChange } from "./meetingchange"
import { MeetingChangeAttachment } from "./meetingchangeattachment"
import { MeetingRequest } from "./meetingrequest"
import { MeetingSnapshot } from "./meetingsnapshot"
import { NonLivingEntrance } from "./nonlivingentrance"
import { NonLivingPremise } from "./nonlivingpremise"
import { Office } from "./office"
import { Organization } from "./organization"
import { PassportStorage } from "./passportstorage"
import { PermissionGroup } from "./permissiongroup"
import { PermissionRequest } from "./permissionrequest"
import { PlayHub } from "./playhub"
import { Premise } from "./premise"
import { PremiseExtraAttr } from "./premiseextraattr"
import { PremiseType } from "./premisetype"
import { Regulation } from "./regulation"
import { RegulationProducer } from "./regulationproducer"
import { Road } from "./road"
import { Role } from "./role"
import { RolesGroup } from "./rolesgroup"
import { RosReestrOwner } from "./rosreestrowner"
import { RosReestrRight } from "./rosreestrright"
import { RosReestrStatement } from "./rosreestrstatement"
import { Section } from "./section"
import { Sector } from "./sector"
import { Service } from "./service"
import { ServiceGroup } from "./servicegroup"
import { ServiceRegulation } from "./serviceregulation"
import { ServiceType } from "./servicetype"
import { StaffUnit } from "./staffunit"
import { StaffUnitRequest } from "./staffunitrequest"
import { SubSection } from "./subsection"
import { TargetService } from "./targetservice"
import { TariffRecalcAttachment } from "./tariffrecalcattachment"
import { TariffRecalcRequest } from "./tariffrecalcrequest"
import { TariffService } from "./tariffservice"
import { Territory } from "./territory"
import { UndergroundParking } from "./undergroundparking"
import { Unit } from "./unit"
import { UnitType } from "./unittype"
import { User } from "./user"
import { UtilitySystem } from "./utilitysystem"
import { UtilitySystemExtraAttr } from "./utilitysystemextraattr"
import { UtilitySystemType } from "./utilitysystemtype"
import { WheelChairElevator } from "./wheelchairelevator"

export const classByModelName = {
  addresscomponents: AddressComponents,
  adjacentarea: AdjacentArea,
  building: Building,
  buildingchange: BuildingChange,
  buildingchangeattachment: BuildingChangeAttachment,
  buildingextraattr: BuildingExtraAttr,
  buildinglocation: BuildingLocation,
  buildinglocationrule: BuildingLocationRule,
  buildingpart: BuildingPart,
  buildingpartextraattr: BuildingPartExtraAttr,
  buildingparttype: BuildingPartType,
  buildingsgroup: BuildingsGroup,
  buildingsnapshot: BuildingSnapshot,
  buildingtype: BuildingType,
  competition: Competition,
  competitionchange: CompetitionChange,
  competitionchangeattachment: CompetitionChangeAttachment,
  competitionsnapshot: CompetitionSnapshot,
  contact: Contact,
  contractor: Contractor,
  division: Division,
  elevator: Elevator,
  elevatormodel: ElevatorModel,
  employee: Employee,
  employment: Employment,
  exploitation: Exploitation,
  exploitationdivision: ExploitationDivision,
  exploitationsector: ExploitationSector,
  externalaccount: ExternalAccount,
  financialemployment: FinancialEmployment,
  financialterritory: FinancialTerritory,
  financialunit: FinancialUnit,
  financialunitassessment: FinancialUnitAssessment,
  financialunitorganizationrelation: FinancialUnitOrganizationRelation,
  foundation: Foundation,
  function: Function,
  inventorybuilding: InventoryBuilding,
  inventorybuildingpart: InventoryBuildingPart,
  inventorybuildingparttype: InventoryBuildingPartType,
  inventorypremise: InventoryPremise,
  inventoryutilitysystem: InventoryUtilitySystem,
  inventoryutilitysystemtype: InventoryUtilitySystemType,
  juristicunit: JuristicUnit,
  livingentrance: LivingEntrance,
  localgovernment: LocalGovernment,
  managementcompany: ManagementCompany,
  managementservice: ManagementService,
  manufacturingplant: ManufacturingPlant,
  material: Material,
  mdslivingcomplex: MDSLivingComplex,
  mdsobject: MDSObject,
  mdsrealestate: MDSRealEstate,
  measuretype: MeasureType,
  measureunit: MeasureUnit,
  meeting: Meeting,
  meetingchange: MeetingChange,
  meetingchangeattachment: MeetingChangeAttachment,
  meetingrequest: MeetingRequest,
  meetingsnapshot: MeetingSnapshot,
  nonlivingentrance: NonLivingEntrance,
  nonlivingpremise: NonLivingPremise,
  office: Office,
  organization: Organization,
  passportstorage: PassportStorage,
  permissiongroup: PermissionGroup,
  permissionrequest: PermissionRequest,
  playhub: PlayHub,
  premise: Premise,
  premiseextraattr: PremiseExtraAttr,
  premisetype: PremiseType,
  regulation: Regulation,
  regulationproducer: RegulationProducer,
  road: Road,
  role: Role,
  rolesgroup: RolesGroup,
  rosreestrowner: RosReestrOwner,
  rosreestrright: RosReestrRight,
  rosreestrstatement: RosReestrStatement,
  section: Section,
  sector: Sector,
  service: Service,
  servicegroup: ServiceGroup,
  serviceregulation: ServiceRegulation,
  servicetype: ServiceType,
  staffunit: StaffUnit,
  staffunitrequest: StaffUnitRequest,
  subsection: SubSection,
  targetservice: TargetService,
  tariffservice: TariffService,
  tariffrecalcattachment: TariffRecalcAttachment,
  tariffrecalcrequest: TariffRecalcRequest,
  territory: Territory,
  undergroundparking: UndergroundParking,
  unit: Unit,
  unittype: UnitType,
  user: User,
  utilitysystem: UtilitySystem,
  utilitysystemextraattr: UtilitySystemExtraAttr,
  utilitysystemtype: UtilitySystemType,
  wheelchairelevator: WheelChairElevator,
  utilitysystemcount: BuildingCounter,
  buildingpartcount: BuildingCounter,
  premisecount: BuildingCounter,
}

export { AttachmentEntity } from "./attachment"
export {
  AddressComponents,
  AdjacentArea as AdjacentAreaEntity,
  Building as BuildingEntity,
  BuildingChange as BuildingChangeEntity,
  BuildingChangeAttachment as BuildingChangeAttachmentEntity,
  BuildingExtraAttr as BuildingExtraAttrEntity,
  BuildingLocation as BuildingLocationEntity,
  BuildingLocationRule as BuildingLocationRuleEntity,
  BuildingPart as BuildingPartEntity,
  BuildingPartExtraAttr as BuildingPartExtraAttrEntity,
  BuildingPartType as BuildingPartTypeEntity,
  BuildingsGroup as BuildingsGroupEntity,
  BuildingSnapshot as BuildingSnapshotEntity,
  BuildingType as BuildingTypeEntity,
  Competition as CompetitionEntity,
  CompetitionChange as CompetitionChangeEntity,
  CompetitionChangeAttachment as CompetitionChangeAttachmentEntity,
  CompetitionSnapshot as CompetitionSnapshotEntity,
  Contact as ContactEntity,
  Contractor as ContractorEntity,
  Division as DivisionEntity,
  Elevator as ElevatorEntity,
  ElevatorModel as ElevatorModelEntity,
  Employee as EmployeeEntity,
  Employment as EmploymentEntity,
  Entity,
  Exploitation as ExploitationEntity,
  ExploitationDivision as ExploitationDivisionEntity,
  ExploitationSector as ExploitationSectorEntity,
  ExternalAccount as ExternalAccountEntity,
  FinancialEmployment as FinancialEmploymentEntity,
  FinancialTerritory as FinancialTerritoryEntity,
  FinancialUnit as FinancialUnitEntity,
  FinancialUnitAssessment as FinancialUnitAssessmentEntity,
  FinancialUnitOrganizationRelation as FinancialUnitOrganizationRelationEntity,
  Foundation as FoundationEntity,
  Function as FunctionEntity,
  InventoryBuilding as InventoryBuildingEntity,
  InventoryBuildingPart as InventoryBuildingPartEntity,
  InventoryBuildingPartType as InventoryBuildingPartTypeEntity,
  InventoryPremise as InventoryPremiseEntity,
  InventoryUtilitySystem as InventoryUtilitySystemEntity,
  InventoryUtilitySystemType as InventoryUtilitySystemTypeEntity,
  JuristicUnit as JuristicUnitEntity,
  LivingEntrance as LivingEntranceEntity,
  LocalGovernment as LocalGovernmentEntity,
  ManagementCompany as ManagementCompanyEntity,
  ManagementService as ManagementServiceEntity,
  ManufacturingPlant as ManufacturingPlantEntity,
  Material as MaterialEntity,
  MDSLivingComplex as MDSLivingComplexEntity,
  MDSObject as MDSObjectEntity,
  MDSRealEstate as MDSRealEstateEntity,
  MeasureType as MeasureTypeEntity,
  MeasureUnit as MeasureUnitEntity,
  Meeting as MeetingEntity,
  MeetingChange as MeetingChangeEntity,
  MeetingChangeAttachment as MeetingChangeAttachmentEntity,
  MeetingRequest as MeetingRequestEntity,
  MeetingSnapshot as MeetingSnapshotEntity,
  NonLivingEntrance as NonLivingEntranceEntity,
  NonLivingPremise as NonLivingPremiseEntity,
  Office as OfficeEntity,
  Organization as OrganizationEntity,
  PassportStorage as PassportStorageEntity,
  PermissionGroup as PermissionGroupEntity,
  PermissionRequest as PermissionRequestEntity,
  PlayHub as PlayHubEntity,
  Premise as PremiseEntity,
  PremiseExtraAttr as PremiseExtraAttrEntity,
  PremiseType as PremiseTypeEntity,
  Regulation as RegulationEntity,
  RegulationProducer as RegulationProducerEntity,
  Road as RoadEntity,
  Role as RoleEntity,
  RolesGroup as RolesGroupEntity,
  RosReestrOwner as RosReestrOwnerEntity,
  RosReestrRight as RosReestrRightEntity,
  RosReestrStatement as RosReestrStatementEntity,
  Section as SectionEntity,
  Sector as SectorEntity,
  Service as ServiceEntity,
  ServiceGroup as ServiceGroupEntity,
  ServiceRegulation as ServiceRegulationEntity,
  ServiceType as ServiceTypeEntity,
  StaffUnit as StaffUnitEntity,
  StaffUnitRequest as StaffUnitRequestEntity,
  SubSection as SubSectionEntity,
  TargetService as TargetServiceEntity,
  TariffService as TariffServiceEntity,
  TariffRecalcAttachment as TariffRecalcAttachmentEntity,
  TariffRecalcRequest as TariffRecalcRequestEntity,
  Territory as TerritoryEntity,
  UndergroundParking as UndergroundParkingEntity,
  Unit as UnitEntity,
  UnitType as UnitTypeEntity,
  User as UserEntity,
  UtilitySystem as UtilitySystemEntity,
  UtilitySystemExtraAttr as UtilitySystemExtraAttrEntity,
  UtilitySystemType as UtilitySystemTypeEntity,
  WheelChairElevator as WheelChairElevatorEntity,
}
