import { makeObservable } from "mobx"
import { primitive } from "serializr"

import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupNameFields } from "utils/fields"

import { BaseEntity } from "pik-react-utils/entities"
import { addFieldsInClass, generateObservableFieldsBySchema } from "utils/model"

const schema: ISchema = {
  ...groupNameFields(),

  group_premise_erp: {
    name: "Помещения собственников данные инициируются в ERP",
    children: {
      parking_place_count: { name: "Машиноместа (кол-во)", _type: "integer" },
      parking_place_space: {
        name: "Машиноместа (площадь, м2)",
        _type: "decimal",
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
}

export class UndergroundParking extends BaseEntity {
  static readonly schema: ISchema = schema

  constructor(props: BaseEntity) {
    super(props)
    addFieldsInClass.call(this, schema)
    makeObservable(this, generateObservableFieldsBySchema(schema))
  }
}

createSerializrSchema(UndergroundParking, schema, {
  parking_place_count: primitive(),
  parking_place_space: primitive(),
})
