import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from "ag-grid-community"

import { PAGE_SIZE_DEFAULT } from "constants/requests"
import { UnitEntity, OrganizationEntity } from "models"
import { entitiesStore, IEntitiesStore } from "stores"
import { DataItem } from "./types"

export class Datasource implements IServerSideDatasource {
  private search = ""
  private store: IEntitiesStore = entitiesStore

  constructor(params: { search?: string }) {
    this.search = params.search ?? ""
  }

  private mapUnits(entities: UnitEntity[]): DataItem[] {
    return entities.map((unit: UnitEntity) => {
      const { name, _uid, activeFollowers = [], unit_type, isDeleted } = unit

      return {
        name,
        uid: _uid,
        children: activeFollowers,
        isGroup: activeFollowers?.length > 0,
        type: unit_type?.name,
        entityType: "unit",
        groupKey: _uid,
        isDeleted,
      }
    })
  }

  private mapOrganizations(entities: OrganizationEntity[]): DataItem[] {
    return entities.map(({ name, _uid }: OrganizationEntity) => ({
      name,
      uid: _uid,
      isGroup: true,
      type: "Организация",
      entityType: "organization",
      groupKey: _uid,
    }))
  }

  private buildUidString(links: IEntityLink[]): string {
    return links.reduce((acc, link) => {
      acc += `${link._uid},`
      return acc
    }, "")
  }

  private async loadGroupRows(params: IServerSideGetRowsParams): Promise<void> {
    const { parentNode, request, successCallback } = params
    if (parentNode.data.entityType === "organization") {
      this.loadUnitsFromOrganization(params)
    } else {
      const uidString = this.buildUidString(parentNode.data.children)
      const response = await this.store.getList<UnitEntity>("unit", {
        uid__in: uidString,
        page_size: PAGE_SIZE_DEFAULT,
        page: Math.floor(request.endRow / PAGE_SIZE_DEFAULT),
        is_deleted: false,
      })

      if (response?.entities) {
        successCallback(this.mapUnits(response.entities), response.meta.count)
      }
    }
  }

  private async loadUnitsFromOrganization(params: IServerSideGetRowsParams) {
    const { request, successCallback, parentNode } = params
    const response = await this.store.getList<UnitEntity>("unit", {
      page_size: PAGE_SIZE_DEFAULT,
      page: Math.floor(request.endRow / PAGE_SIZE_DEFAULT),
      organization__uid: parentNode.data.uid,
      ordering: "name",
      leader__uid__isnull:
        parentNode.data.entityType === "organization" ? true : undefined,
      is_deleted: false,
    })

    if (response?.entities) {
      successCallback(this.mapUnits(response.entities), response.meta.count)
    }
  }

  private async loadScrollRows(
    params: IServerSideGetRowsParams
  ): Promise<void> {
    const { request, successCallback, failCallback } = params
    try {
      const response = await this.store.getList<OrganizationEntity>(
        "organization",
        {
          page_size: PAGE_SIZE_DEFAULT,
          page: Math.floor(request.endRow / PAGE_SIZE_DEFAULT),
          search: this.search,
          has_units: true,
          ordering: "name",
          is_deleted: false,
        }
      )

      if (response?.entities) {
        successCallback(
          this.mapOrganizations(response.entities),
          response.meta?.count
        )
      }
    } catch (e) {
      failCallback()
    }
  }

  getRows(params: IServerSideGetRowsParams) {
    const { request } = params
    if (request.groupKeys.length > 0) {
      this.loadGroupRows(params)
    } else {
      this.loadScrollRows(params)
    }
  }
}
