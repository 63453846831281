import styled from "styled-components"
import { COLOR_BUTTON_HOVER } from "constants/ui-colors"

export const Header = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  margin-bottom: 16px;
  background-color: ${COLOR_BUTTON_HOVER};
  z-index: 1;
  h2 {
    font-size: 20px;
    margin: 0;
  }
`

export const FormContent = styled.div`
  padding: 8px 24px;
`
