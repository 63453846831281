import { Button, message } from "antd"
import React, { useCallback, useState } from "react"

import { ColumnHeader, ColumnWithHeader } from "components/css"
import { DeleteOutlined, EditOutlined } from "components/antd"
import { AttentionModal } from "modals"
import { UnitEntity } from "models"
import { entitiesStore } from "stores"
import { ContentWrap, DivisionType, DrawerTitle, Content } from "./components"
import { DivisionViewerProps } from "./types"
import { UnitTabs } from "./Tabs"

export const DivisionViewer = ({
  unit,
  onChange,
  onClose,
  onAdd,
}: DivisionViewerProps) => {
  const [store] = useState(() => entitiesStore)
  const [openPopUp, setOpenPopUp] = useState(false)

  const onSubmit = useCallback(async () => {
    if (!unit) return
    try {
      await store.deleteEntity({ _uid: unit._uid, _type: "unit" })
      onClose({ refresh: true })
      message.success(`Подразделение ${unit.name} было удалено`)
    } finally {
      setOpenPopUp(false)
    }
  }, [store, unit, onClose])

  return (
    <>
      <ColumnWithHeader>
        <ColumnHeader>
          <Button type="primary" onClick={onAdd}>
            Добавить подразделение
          </Button>
          {unit instanceof UnitEntity && (
            <>
              <Button icon={<EditOutlined />} onClick={onChange}>
                Редактировать
              </Button>
              <Button
                danger
                onClick={() => setOpenPopUp(true)}
                icon={<DeleteOutlined />}
                style={{ marginLeft: "auto" }}
              >
                Удалить
              </Button>
            </>
          )}
        </ColumnHeader>
        {unit ? (
          <Content>
            <ContentWrap>
              <DrawerTitle>{unit.name}</DrawerTitle>
              <DivisionType>
                Тип:&nbsp;
                {unit instanceof UnitEntity
                  ? unit.unit_type?.name
                  : "Организация"}
              </DivisionType>
            </ContentWrap>

            {unit instanceof UnitEntity && <UnitTabs unit={unit} />}
          </Content>
        ) : null}
      </ColumnWithHeader>
      <AttentionModal
        open={openPopUp}
        onOk={() => onSubmit()}
        onCancel={() => setOpenPopUp(false)}
      >
        Удаление подразделения повлечет за собой удаление всех дочерних
        подразделений и связанных рабочих мест.
      </AttentionModal>
    </>
  )
}
