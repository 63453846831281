import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupEditableNameFields } from "utils/fields"

import { Division } from "./division"

const schema: ISchema = {
  ...groupEditableNameFields(),

  group_management: {
    name: "Структура управления",
    children: {
      division: {
        name: "Регион управления",
        _type: "entitylink",
        entityLinkTo: Division,
        editable: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class Sector extends FullNameEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(Sector, schema)
