import { observer } from "mobx-react"
import React from "react"
import { action, makeObservable, observable } from "mobx"
import { Button } from "antd"
import { ICellEditorParams } from "ag-grid-community"

import { DeleteOutlined, UploadOutlined } from "components/antd"
import { AttachmentEntity } from "models"
import { PikUpload } from "pik-react-utils/components"
import { CellContainer, NameEllipsis } from "../components"
import "./editors.css"

export const AttachmentEditor = observer(
  class AttachmentEditor extends React.Component<ICellEditorParams> {
    private value: AttachmentEntity | null = this.props.value

    constructor(props: ICellEditorParams) {
      super(props)
      makeObservable<AttachmentEditor, "value" | "onChange" | "onClear">(this, {
        value: observable,
        onChange: action.bound,
        onClear: action.bound,
      })
    }

    public getValue() {
      return this.value ?? null
    }

    private onChange(response: AttachmentEntity) {
      this.value = response
      this.props.stopEditing()
    }

    private onClear() {
      this.value = null
      this.props.stopEditing()
    }

    render() {
      const { formatValue, data } = this.props

      return (
        <CellContainer>
          {this.value ? (
            <>
              <NameEllipsis>{formatValue(this.value)}</NameEllipsis>
              <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
                onClick={this.onClear}
              />
            </>
          ) : (
            <PikUpload<AttachmentEntity>
              name="file"
              entityType={data._entitylinkto}
              onChange={this.onChange}
            >
              <Button
                size="small"
                style={{ marginLeft: "auto" }}
                icon={<UploadOutlined />}
              >
                Добавить
              </Button>
            </PikUpload>
          )}
        </CellContainer>
      )
    }
  }
)
