import { COLOR_ACCENT, COLOR_DARK_LINES } from "constants/ui-colors"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { MEDIA } from "constants/media"
import { iconLogoPath } from "components/icons"

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  padding: 12px 24px 0 24px;
  border-bottom: 1px solid ${COLOR_DARK_LINES};
  background-color: #fff;
  box-sizing: border-box;
  z-index: 100;
  @media ${MEDIA.Desktop_S} {
    padding-left: 16px;
  }
`

export const Logo = styled(NavLink)`
  display: flex;
  align-items: center;
  margin-right: 16px;
`

export const LogoIcon = styled.div`
  width: 64px;
  height: 64px;
  background-image: url(${iconLogoPath});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
`

export const Title = styled.div`
  padding: 0 16px;
  color: ${COLOR_ACCENT};
  font-size: 1.15rem;
  font-weight: 500;
`
