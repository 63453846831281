import { AxiosResponse } from "axios"

import { sentry } from "pik-react-utils/sentry"

const ERROR_CODES_NOT_SEND_TO_SENTRY = [400, 401, 403]
const VERSION_REG_EXP = /^v[1-9][0-9]*$/
const POSTFIX_REG_EXP = /^[a-z]*(-?)[a-z]*$/

export const getEndpoint = (
  { _type, _uid }: { _type: string; _uid?: string },
  config: { apiVer?: string; suffix?: string } = {}
) => {
  let { apiVer = "v1", suffix } = config
  if (!_type) {
    sentry.setError("_type is required", { _type })
    throw new Error("_type is required")
  }

  if (!VERSION_REG_EXP.test(apiVer)) {
    sentry.setError("Invalid endpoint version", { _type, _uid, apiVer })
    apiVer = "v1"
  }

  if (suffix && !POSTFIX_REG_EXP.test(suffix)) {
    sentry.setError("Added no valid postfix for the endpoint", {
      _type,
      _uid,
      ...config,
    })

    suffix = ""
  }

  let list = `/api/${apiVer}/${_type}-list/`
  if (suffix) {
    list = `${list}${suffix}/`
  }
  return _uid ? `${list}${_uid}/` : list
}

export const makeRequestHash = (
  method: string,
  endpoint: string,
  data: object
): string => [method, endpoint, JSON.stringify(data)].join("-")

export const sendToSentry = (response: AxiosResponse | undefined) => {
  if (!response) {
    return
  }

  const {
    status,
    config: { method, params, data, url },
    request: { responseURL },
  } = response

  if (ERROR_CODES_NOT_SEND_TO_SENTRY.includes(status)) return

  const title = `Method: ${method}, httpCode: ${status} url: ${url}`
  sentry.setError(title, {
    httpCode: status,
    url: responseURL,
    method,
    data,
    params,
    responseURL,
  })
}
