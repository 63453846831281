import React, { useCallback, useEffect, useState } from "react"
import { Button, Card, Col, Row, Statistic, Typography } from "antd"
import dayjs from "dayjs"

import { ArrowRightOutlined } from "components/antd"
import { entitiesStore } from "stores"
import { PullButton, Wrapper } from "./components"

type WidgetProps = {
  entityType: string
  title: string
  onShowCreated?: (date: string) => void
  onShowUpdated?: (date: string) => void
}

const aDayAgo = dayjs().startOf("date").subtract(1, "day").toISOString()

export const Widget = ({
  title,
  onShowCreated,
  onShowUpdated,
  entityType,
}: WidgetProps) => {
  const [store] = useState(() => entitiesStore)
  const [isVisible, setVisible] = useState<boolean>(false)
  const [count, setCount] = useState<{
    created: number
    updated: number
  }>()

  const retrieveCounts = useCallback(async () => {
    const promise = (query: Record<string, string>) =>
      store.getCount(entityType, query)
    const [created, updated] = await Promise.all([
      promise({ created__gte: aDayAgo }),
      promise({ updated__gte: aDayAgo }),
    ])
    setCount(() => ({ created, updated: updated ? updated - created : 0 }))
    setVisible(true)
  }, [entityType, store])

  useEffect(() => {
    if (!count) retrieveCounts()
    let timer: NodeJS.Timeout
    if (isVisible) {
      timer = setTimeout(() => setVisible(false), 15000)
    }
    return () => clearTimeout(timer)
  }, [count, retrieveCounts, isVisible])

  const onCreated = () => {
    onShowCreated?.(aDayAgo)
    setVisible(false)
  }

  const onUpdated = () => {
    onShowUpdated?.(aDayAgo)
    setVisible(false)
  }

  return (
    <Wrapper isVisible={isVisible}>
      <Typography.Title level={4}>Измененных {title} за сутки</Typography.Title>
      <Row gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic
              title="Новых"
              value={count?.created ?? 0}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
          {onShowCreated && (
            <Button
              type="link"
              onClick={() => onCreated()}
              disabled={!count?.created}
            >
              Показать
            </Button>
          )}
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Обновленных"
              value={count?.updated ?? 0}
              valueStyle={{ color: "#cf1322" }}
            />
          </Card>
          {onShowUpdated && (
            <Button
              type="link"
              onClick={() => onUpdated()}
              disabled={!count?.updated}
            >
              Показать
            </Button>
          )}
        </Col>
      </Row>
      <PullButton
        type="dashed"
        icon={<ArrowRightOutlined rotate={isVisible ? 180 : 0} />}
        onClick={() => setVisible(!isVisible)}
      />
    </Wrapper>
  )
}
