import React, { useCallback, useState } from "react"
import { Button } from "antd"

import { DeleteOutlined, EditOutlined } from "components/antd"
import { AttentionModal } from "modals"
import { StaffUnitEntity } from "models"
import { entitiesStore } from "stores"
import { ButtonGroup, Organization, Role, Wrapper } from "./components"

type WorkplaceProps = {
  staffUnit: StaffUnitEntity
  onClose: (refresh?: boolean) => void
  onEdit: (staffUnit: StaffUnitEntity) => void
}

export const Workplace = ({ staffUnit, onClose, onEdit }: WorkplaceProps) => {
  const [store] = useState(() => entitiesStore)
  const [openPopUp, setOpenPopUp] = useState(false)
  const { external_account, employment, role } = staffUnit

  const employeeName =
    external_account?.full_name ?? employment?.employee?.full_name ?? "-"

  const onDelete = useCallback(async () => {
    await store.deleteEntity(staffUnit)
    setOpenPopUp(false)
    onClose(true)
  }, [onClose, store, staffUnit])

  return (
    <>
      <Wrapper>
        <div>
          <strong>{employeeName}</strong>
          <Role>{role?.breadcrumbFullName}</Role>
          <Organization>{staffUnit.breadcrumbFullName}</Organization>
        </div>
        <ButtonGroup>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => onEdit(staffUnit)}
          />
          <Button
            danger
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => setOpenPopUp(true)}
          />
        </ButtonGroup>
      </Wrapper>
      <AttentionModal
        open={openPopUp}
        onCancel={() => setOpenPopUp(false)}
        onOk={onDelete}
      >
        <p>Подтвердите удаление рабочего места:</p>
        <strong>{employeeName}</strong>
        <Role>{role?.breadcrumbFullName}</Role>
        <Organization>{staffUnit.breadcrumbFullName}</Organization>
      </AttentionModal>
    </>
  )
}
