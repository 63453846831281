import {
  API_URL,
  APP_RELEASE_VERSION,
  AUTH_URL,
  CLIENT_ID,
  NODE_ENV,
  SENTRY_KEY,
} from "constants/env"

export const AUTH_CONFIG = {
  clientId: CLIENT_ID ?? "",
  authUrl: AUTH_URL ?? "",
}

export const API_CONFIG = {
  withCredentials: false,
  baseURL: API_URL,
}

export const SENTRY_CONFIG = SENTRY_KEY
  ? {
      dsn: SENTRY_KEY,
      release: APP_RELEASE_VERSION,
      environment: NODE_ENV,
    }
  : undefined
