import { primitive, raw } from "serializr"

import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"
import { AddressComponents } from "./addresscomponents"
import { BuildingType } from "./buildingtype"
import { Division } from "./division"
import { ManagementCompany } from "./managementcompany"
import { ManagementService } from "./managementservice"
import { Sector } from "./sector"

export const schema: Readonly<ISchema> = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "НСИ ID" },
      full_name: { name: "Полное наименование" },
      normalized_address: { name: "Почтовый адрес" },
      building_type: {
        name: "Тип",
        _type: "entitylink",
        entityLinkTo: BuildingType,
      },
    },
  },

  affiliation: {
    name: "Принадлежность",
    children: {
      management_company: {
        name: "Управляющая компания",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
      },
      division: {
        name: "Регион управления",
        _type: "entitylink",
        entityLinkTo: Division,
      },
      sector: {
        name: "Филиал управления",
        _type: "entitylink",
        entityLinkTo: Sector,
      },
      management_service: {
        name: "Территориальное отделение управления",
        _type: "entitylink",
        entityLinkTo: ManagementService,
      },
    },
  },

  common_data: {
    name: "Общие данные",
    children: {
      built_year: { name: "Год постройки" },
      occupancy_start_date: { name: "Дата начала заселения", _type: "date" },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class Building extends FullNameEntity {
  static readonly schema: ISchema = schema

  address: string
  juristic_address: string
  normalized_address: string
  building_type: BuildingType | null
  address_components: AddressComponents
  management_company: ManagementCompany
}

createSerializrSchema(Building, schema, {
  extra_attrs: raw(),
  address: primitive(),
})
