import { action, makeObservable } from "mobx"
import { BuildingChangeEntity } from "models"
import { ORDERING_FILTER } from "constants/filters"
import { BaseQueryStore } from "stores"

type InventoryMDSStoreType = { ordering: string }

export class InventoryMDSStore extends BaseQueryStore<InventoryMDSStoreType> {
  readonly entityType = "mdsobject"

  constructor() {
    super({ filterFields: [ORDERING_FILTER] })
    makeObservable(this, { onSuccess: action.bound })
  }

  onSuccess(entity?: BuildingChangeEntity) {
    this.entitiesStore.flushList([
      "building",
      "inventorybuilding",
      "buildingchange",
      "buildingsnapshot",
    ])
    this.updateSearch(entity?.building?._uid)
  }
}
