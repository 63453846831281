import React from "react"

export const Requests = () => (
  <ul>
    <li>
      После официального трудоустройства логин и временный пароль для нового
      сотрудника приходит на почту <b>непосредственному руководителю.</b>
      <br />*
      <i>
        Если руководитель не получил логин/пароль, то необходимо связаться со
        службой технической поддержки
      </i>
      &nbsp;-&nbsp;(7777@pik&#8209;comfort.ru).
    </li>
    <li>
      Сотрудник должен зайти на корпоративный портал с браузера Google Chrome по
      адресу <b>home.pik&#8209;comfort.ru</b>, использовав логин и временный
      пароль.
    </li>
    <li>
      Сменить на портале временный пароль на постоянный на портале.
      <br />
      <i>
        *Минимальная длина пароля 10 символов. Необходимо использовать большие и
        маленькие буквы и цифры.
      </i>
    </li>
    <li>
      <b>Руководитель</b> должен определить какие ещё доступы требуются новому
      сотруднику для работы и сделать заявки на их предоставление.
    </li>
    <li>
      Сотрудник должен установить на мобильный телефон корпоративное приложение
      Кабинет техника.
    </li>
    <li>Авторизоваться в приложении, использовав логин и постоянный пароль.</li>
  </ul>
)
