import dayjs from "dayjs"
import { Building } from "models/building"
import { Elevator } from "models/elevator"
import { InventoryBuilding } from "models/inventorybuilding"
import { FileService } from "pik-react-utils/services"

export const createRequestTemplate = (
  elevator: Elevator,
  building: InventoryBuilding | Building
) => {
  const {
    name,
    building_part,
    manufacturing_plant,
    declaration_number: declarationNumber,
    declaration_date: declarationDate,
    declaration_applicant_info: declarationOrgInfo,
    declaration_applicant_ogrn: declarationOgrn,
    manufacture_year: manufactureYear,
    commissioning_date,
    elevator_model,
  } = elevator
  const { management_company, address_components } = building
  const commissioningDate = commissioning_date
    ? dayjs(commissioning_date)
    : null

  FileService.downloadDocXFromTemplate("/elevator_request.docx", {
    name,
    region: address_components?.region_name ?? "",
    city: address_components?.city,
    street: address_components?.street,
    house: address_components?.house,
    block: address_components?.block ?? "-",
    entrance: building_part?.name,
    manufacturingPlant: manufacturing_plant?.full_name,
    elevatorModel: elevator_model?.full_name,
    declarationNumber,
    declarationDate,
    declarationOrgInfo,
    declarationOgrn,
    manufactureYear,
    company: management_company?.full_name,
    companyAddress: management_company?.organization?.juristic_address,
    inn: management_company?.inn,
    ogrn: management_company?.ogrn,
    commissioningDay: commissioningDate?.format("DD") ?? "",
    commissioningMonth: commissioningDate?.format("MMMM") ?? "",
    commissioningYear: commissioningDate?.format("YYYY") ?? "",
    isInExploitation: !!commissioning_date,
  })
}
