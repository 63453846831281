export const groupNameFields = (additionalChildren?: {
  [slug: string]: IChildSchema
}): ISchema =>
  ({
    group_identification: {
      name: "Идентификация",
      children: {
        _uid: { name: "НСИ ID" },
        name: { name: "Наименование" },
        visible_name: { name: "Сокращенное наименование" },
        full_name: { name: "Полное наименование" },
        ...additionalChildren,
      },
    },
  } as const)

export const groupEditableNameFields = (additionalChildren?: {
  [slug: string]: IChildSchema
}): ISchema =>
  ({
    group_identification: {
      name: "Идентификация",
      children: {
        _uid: { name: "НСИ ID" },
        name: { name: "Наименование", editable: true },
        visible_name: { name: "Сокращенное наименование" },
        full_name: { name: "Полное наименование" },
        ...additionalChildren,
      },
    },
  } as const)

export const groupNumberFields = (additionalChildren?: {
  [slug: string]: IChildSchema
}): ISchema =>
  ({
    group_identification: {
      name: "Идентификация",
      children: {
        _uid: { name: "НСИ ID" },
        number: { name: "Наименование" },
        visible_name: { name: "Сокращенное наименование" },
        full_name: { name: "Полное наименование" },
        ...additionalChildren,
      },
    },
  } as const)

export const GROUP_SYSTEM_DATE_FIELDS: ISchema = {
  group_system_info: {
    name: "Системная информация",
    children: {
      created: { name: "Дата создания", _type: "datetime" },
      updated: { name: "Дата последнего изменения", _type: "datetime" },
      deleted: { name: "Дата удаления", _type: "datetime" },
    },
  },
} as const

export const groupDocumentFields = (additionalChildren?: {
  [slug: string]: IChildSchema
}): ISchema =>
  ({
    document: {
      name: "Документ",
      children: {
        _uid: { name: "UID документа" },
        document_date: {
          name: "Дата применения",
          _type: "date",
          editable: true,
        },
        document_name: { name: "Название документа", editable: true },
        document_fields: {
          name: "Поля заданные документами",
          _type: "string[]",
        },
        document_is_draft: {
          name: "Черновик",
          _type: "boolean",
          editable: true,
          _isNull: true,
        },
        ...additionalChildren,
      },
    },
  } as const)

export const DEFAULT_EDITABLE_FIELDS = {
  ...groupEditableNameFields(),
  ...GROUP_SYSTEM_DATE_FIELDS,
} as const
