import React from "react"

export const Corporate = () => (
  <ol>
    <li>
      Необходимо перейти по ссылке&nbsp;
      <a href="https://drive.google.com/drive/u/0/my-drive" rel="noreferrer">
        https://drive.google.com/drive/u/0/my-drive
      </a>
      &nbsp;и авторизоваться под под вашим логином
      (вашлогин@pik&#8209;comfort.ru) и постоянным паролем.
    </li>
    <li>
      Для работы с корпоративными ресурсами компании необходимо использовать
      программу Cisco AnyConnect, найти ее можно в меню Пуск:
      <br />
      <img src={require("assets/img/win.png")} alt="" />
      <br />
      Если данная программа отсутствует, необходимо обращение в техническую
      поддержку с корпоративного почтового ящика с письмом на адрес&nbsp;
      <a href="mailto:7777@pik-comfort.ru">7777@pik&#8209;comfort.ru</a>, или с
      помощью Портала, перейдя по ссылке:&nbsp;
      <a href="https://home.pik-comfort.ru/request-system/" rel="noreferrer">
        https://home.pik-comfort.ru/request&#8209;system/
      </a>
      <br />
      <br />
      Запустите Cisco AnyConnect. В открывшемся окне будет предложено ввести имя
      сервера. Если Вы являетесь сотрудником Контакт-центра, нужно ввести
      следующий адрес: vpncc.pik-comfort.ru Если Вы сотрудник Москвы и МО, нужно
      ввести следующий адрес: vpn1.pik-comfort.ru В любом другом случае,
      необходимо использовать следующий адрес: vpn.pik-comfort.ru
      <br />
      <img src={require("assets/img/cisco.png")} alt="" />
      <br />
      После ввода адреса нужно нажать кнопку Connect, на что программа предложит
      ввести Ваш логин и пароль от учетной записи:
      <br />
      <img src={require("assets/img/login.png")} alt="" />
      <br />
      Нажимаем ОК. Если соединение установлено, выйдет сообщение об успешном
      подключении:
      <br />
      <img src={require("assets/img/connected.png")} alt="" />
      <br />
      Если у Вас выходит ошибка или соединение не устанавливается, проверьте ваш
      логин и пароль, если ошибка не пропала, сделайте заявку на адрес&nbsp;
      <a href="mailto:7777@pik-comfort.ru">7777@pik&#8209;comfort.ru</a> со
      своего корпоративного ящика или оставьте обращение на портале по
      ссылке:&nbsp;
      <a href="https://home.pik-comfort.ru/request-system/" rel="noreferrer">
        https://home.pik&#8209;comfort.ru/request&#8209;system/
      </a>
      .
    </li>
    <li>
      После подключения к Cisco, для работы с корпоративными ресурсами
      необходимо использовать Терминал. Его иконка должна находиться на рабочем
      столе, выглядит она следующим образом (название может быть отлично):
      <br />
      <img src={require("assets/img/terminal.png")} alt="" />
      <br />
      Если ярлык на рабочем столе отсутствует, Вы можете загрузить его
      самостоятельно:
      <br />
      Ярлык для подключения к терминалу “Терминальный сервер.rdp” можно скачать
      по ссылке:
      <br />
      <a
        rel="noreferrer"
        href="https://drive.google.com/file/d/1L4J7jwVaieKwD2N-RZkj_tHa6-oiTE-b/view?usp=sharing"
      >
        https://drive.google.com/file/d/1L4J7jwVaieKwD2N&#8209;RZkj_tHa6&#8209;oiTE-b/view?usp=sharing
      </a>
      <br />
      <br />
      Если вы сотрудник, относящийся к КЦ (Деп. клиентского сервиса), то вам
      необходимо скачать ярлык для подключения к терминалам КЦ по ссылке:
      <br />
      <a
        rel="noreferrer"
        href="https://drive.google.com/file/d/1cFw1l3D-Cj-nMRF_dKXCyCfnYJnoRQnl/view?usp=sharing"
      >
        https://drive.google.com/file/d/1cFw1l3D&#8209;Cj&#8209;nMRF_dKXCyCfnYJnoRQnl/view?usp=sharing
      </a>
      <br />
      <br />
      После загрузки ярлыка, размещаем его на рабочем столе и используем при
      необходимости работы с корпоративными ресурсами. Необходимость работы на
      терминале уточняйте у руководителя. При запуске ярлыка, поставьте галочку
      «Больше не выводить запрос о подключениях к этому компьютеру» и нажмите
      «Подключить».
      <br />
      <img src={require("assets/img/start.png")} alt="" />
      <br />
      В открывшемся окошке введите логин в формате comf\логин и пароль.
      Поставьте галочку на «запомнить меня» и нажмите «ОК»
      <br />
      <img src={require("assets/img/pass.png")} alt="" />
      <br />
    </li>
    <li>
      При запущеном и подключенном Cisco AnyConnect и успешном входе на
      Терминал, сотрудник готов к удаленной работе с личного ПК. Если необходимо
      запросить дополнительные доступы, сделать это можно здесь же на Портале по
      ссылке:&nbsp;
      <a href="https://home.pik-comfort.ru/request-system/" rel="noreferrer">
        https://home.pik&#8209;comfort.ru/request&#8209;system/
      </a>
      &nbsp;или обратиться с корпоративного ящика по адресу&nbsp;
      <a href="mailto:7777@pik-comfort.ru" rel="noreferrer">
        7777@pik&#8209;comfort.ru
      </a>
    </li>
  </ol>
)
