import styled from "styled-components"
import { COLOR_EXTRA_TEXT, COLOR_ICON_DEFAULT } from "constants/ui-colors"

export const Wrapper = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`

export const Header = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  padding: 8px;
  background-color: inherit;
`

export const Content = styled.div`
  padding: 8px;
  height: 100%;
  overflow-y: auto;
`

export const BuildingInfo = styled.div`
  padding: 6px 4px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;

  & + & {
    border-top: 1px solid ${COLOR_ICON_DEFAULT};
  }
`

export const Type = styled.span`
  flex-shrink: 0;
  color: ${COLOR_EXTRA_TEXT};
`
