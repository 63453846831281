import { COLOR_EXTRA_TEXT, COLOR_ICON_DEFAULT } from "constants/ui-colors"
import styled from "styled-components"

export const EmployeeList = styled.div`
  overflow-y: auto;
`

export const SearchWrap = styled.div`
  display: flex;
  flex-direction: row;
`

export const EmployeesWrap = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 12px;
  padding: 12px;
`

export const Employee = styled.div`
  font-size: 12px;
  padding: 8px 0 12px;
  & + & {
    border-top: 1px solid ${COLOR_ICON_DEFAULT};
  }
  &:first-child {
    padding-top: 0;
  }
`

export const Organization = styled.p`
  color: #ac41f4;
  margin: 0;
`
export const Role = styled.p`
  margin: 0;
  color: ${COLOR_EXTRA_TEXT};
`
