import React from "react"
import { RequestContent } from "../content"

export const OtherRequest = () => (
  <RequestContent>
    <div style={{ margin: "25px auto" }}>
      <div>
        <h2 style={{ textAlign: "center" }}>Переход на портал ГК ПИК</h2>
        <strong>Уважаемые коллеги!</strong>
        <br />
        <br />
        <p>
          Теперь заявки на доступ к информационным системам необходимо оставлять
          на портале&nbsp;
          <b>
            <a href="https://home.pik.ru/" target="_blank" rel="noreferrer">
              ГК ПИК
            </a>
          </b>
          .
        </p>

        <p>
          Все привычные шаблоны доступа уже перенесены на портал, а инструкцию
          по созданию заявки на доступ можно найти&nbsp;
          <a
            href="https://info.pik.ru/spaces/common/pages/10768"
            target="_blank"
            rel="noreferrer"
          >
            <b>здесь</b>
          </a>
          .
        </p>

        <p>
          Для авторизации на портале&nbsp;
          <b>
            <a href="https://home.pik.ru/" target="_blank" rel="noreferrer">
              ГК ПИК
            </a>
          </b>
          &nbsp;необходимо использовать данные своей корпоративной почты.
          <br />
          Если по техническим причинам вам не удается оставить заявку на доступ,
          просим направить ее письмом на&nbsp;
          <b>
            <a href="mailto:7777@pik-comfort.ru">7777@pik&#8209;comfort.ru</a>
          </b>
          .
        </p>

        <p>
          В письме укажите куда необходим доступ, с какой ролью, и укажите
          обоснование для предоставления доступа.
          <br />
          Обязательно укажите с какой проблемой вы столкнулись при подаче заявки
          на портале&nbsp;
          <b>
            <a href="https://home.pik.ru/" target="_blank" rel="noreferrer">
              ГК ПИК
            </a>
          </b>
          , специалисты технической поддержки помогут решить вопрос.
        </p>
        <br />
        <br />
        <p>
          С Уважением,
          <br />
          Дирекция автоматизации эксплуатирующего блока ПИК Digital.
        </p>
      </div>
    </div>
  </RequestContent>
)
