import { primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

export class TargetService extends BaseEntity {
  url: string

  get full_name() {
    return this.url ? `${this.name} (${this.url})` : this.name
  }
}

createSerializrSchema(TargetService, null, { url: primitive() })
