import { object } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { RolesGroup } from "./rolesgroup"

interface IRole extends IEntity {
  roles_group: RolesGroup
}

export class Role extends BaseEntity implements IRole {
  roles_group: RolesGroup

  get roleGroupName() {
    return this.roles_group?.name
  }

  get breadcrumbFullName() {
    return [this.name, this.roleGroupName].join(" / ")
  }
}

createSerializrSchema(Role, null, {
  roles_group: object(RolesGroup),
})
