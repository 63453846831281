import { object } from "serializr"

import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupEditableNameFields } from "utils/fields"

import { LocalGovernment } from "./localgovernment"
import { Sector } from "./sector"

const schema: ISchema = {
  ...groupEditableNameFields(),
  group_parameters: {
    name: "Характеристики",
    children: {
      local_government: {
        name: "Орган местного самоуправления",
        _type: "entitylink",
        entityLinkTo: LocalGovernment,
        editable: true,
      },
    },
  },
  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class ManagementService extends FullNameEntity {
  static readonly schema: ISchema = schema

  sector: Sector
}

createSerializrSchema(ManagementService, schema, {
  sector: object(Sector),
})
