import { makeObservable, observable } from "mobx"

export class List implements NormalizedList {
  meta: ListMetaType
  results: IEntityLink[]

  constructor(list: NormalizedList) {
    Object.assign(this, list)
    makeObservable(this, { meta: observable, results: observable })
  }

  static fromServer(response: IListResponse<IRawEntity>) {
    const { results, ...meta } = response
    const normalizedResults = results.map(({ _uid, _type }) => ({
      _uid,
      _type,
    }))

    return new List({ meta, results: normalizedResults })
  }
}
