import { object } from "serializr"

import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupEditableNameFields } from "utils/fields"

import { Contractor } from "./contractor"
import { Organization } from "./organization"

const schema: ISchema = {
  ...groupEditableNameFields(),

  group: {
    name: "Реквизиты",
    children: {
      inn: { name: "ИНН" },
      kpp: { name: "КПП" },
      ogrn: { name: "ОГРН" },
      organization: {
        name: "Организация ГК Комфорт",
        _type: "entitylink",
        entityLinkTo: Organization,
        editable: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class ManagementCompany extends FullNameEntity {
  static readonly schema: ISchema = schema

  inn: string
  ogrn: string
  organization: Organization
}

createSerializrSchema(ManagementCompany, schema, {
  contractor: object(Contractor),
})
