import { Button } from "antd"
import styled from "styled-components"

export const Content = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 12px;
  row-gap: 12px;
`

export const Header = styled.header`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(200px, 300px) 1fr;
  justify-content: flex-start;
  column-gap: 12px;
`

export const ExcelBtn = styled(Button).attrs({ type: "primary" })`
  --excel-color: #008000;
  background-color: var(--excel-color);
  border-color: var(--excel-color);
  &:hover,
  &:focus,
  &:active {
    background-color: var(--excel-color);
    border-color: var(--excel-color);
  }
`
