import { ValueFormatterParams } from "ag-grid-community"
import { getChoiceLabel } from "../utils"

export const generateChoiceFormatter =
  (field: IEntityField) =>
  ({ value }: ValueFormatterParams) => {
    if (field._type === "choices") {
      return getChoiceLabel(field.choices, value)
    }
    return ""
  }
