import { ValueFormatterParams } from "ag-grid-community"
import { booleanFormatter } from "./BooleanFormatter"
import { generateChoiceFormatter } from "./ChoiceFormatter"
import { dateFormatter } from "./DateFormatter"
import { dateTimeFormatter } from "./DateTimeFormatter"
import { generateEntityLinkFormatter } from "./EntityLinkFormatter"
import { fileLinkFormatter } from "./FileLinkFormatter"
import { monthFormatter } from "./MonthFormatter"
import { numberFormatter } from "./NumberFormatter"
import { pointFormatter } from "./PointFormatter"
import { requestLinkFormatter } from "./RequestLinkFormatter"
import { stringArrayFormatter } from "./StringArrayFormatter"

export function fieldFormatter(field: IEntityField) {
  if (!field) return
  switch (field?._type) {
    case "boolean":
      return booleanFormatter
    case "number":
      return numberFormatter
    case "datetime":
      return dateTimeFormatter
    case "date":
      return dateFormatter
    case "entitylink":
      return generateEntityLinkFormatter(field)
    case "choices":
      return generateChoiceFormatter(field)
    case "month":
      return monthFormatter
    case "string[]":
      return stringArrayFormatter
    case "attachment":
      return fileLinkFormatter
    case "request_url":
      return requestLinkFormatter
    case "point":
      return pointFormatter
    default:
      return (params: ValueFormatterParams) => {
        return params.value ?? ""
      }
  }
}
