import React, { PropsWithChildren } from "react"
import { Modal as DefaultModal, ModalProps } from "antd"
import { CloseCircleOutlined } from "components/antd"

export const Modal = (props: PropsWithChildren<ModalProps>) => (
  <DefaultModal
    centered
    closable
    destroyOnClose
    closeIcon={<CloseCircleOutlined />}
    cancelText="Отмена"
    {...props}
  />
)
