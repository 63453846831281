import { list, object, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { TargetService } from "./targetservice"

export class PermissionGroup extends BaseEntity {
  slug: string
  target_service: TargetService
  responsibles_login_list: string[]

  get full_name() {
    return this.name
  }
}

createSerializrSchema(PermissionGroup, null, {
  slug: primitive(),
  target_service: object(TargetService),
  responsibles_login_list: list(primitive()),
})
