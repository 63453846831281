import styled from "styled-components"
import {
  COLOR_EXTRA_TEXT,
  STATIC_SHADOW,
  COLOR_WHITE,
  COLOR_DARK_LINES,
  COLOR_BUTTON_HOVER,
  COLOR_BLACK,
} from "constants/ui-colors"
import { iconUserPickPath } from "components/icons"

export const ImgCircleUser = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px;
  margin-left: 16px;
  margin-top: -8px;
  background-image: url(${iconUserPickPath});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const UserDropDownContainer = styled.div`
  position: absolute;
  width: max-content;
  top: calc(100% + 8px);
  right: 0;
  box-shadow: ${STATIC_SHADOW};
  background-color: ${COLOR_WHITE};
  border: 1px solid ${COLOR_DARK_LINES};
  display: none;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 4px 0;

  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -1;
  }
`

export const DropDownItem = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-grow: 1;
  height: 50%;
  align-items: center;
  padding: 8px 24px 8px 16px;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  font-size: 16px;
  color: ${COLOR_BLACK};

  > *:first-child {
    margin-right: 16px;
  }

  &:hover {
    background-color: ${COLOR_BUTTON_HOVER};
  }
`

export const UserLogin = styled.div`
  display: flex;
  align-items: center;
  color: ${COLOR_EXTRA_TEXT};
  position: relative;
  margin-left: auto;
  padding-left: 24px;

  &:hover ${UserDropDownContainer} {
    display: block;
  }
`
