/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from "mobx-react"
import React from "react"
import { ICellEditorParams } from "ag-grid-community"
import { action, makeObservable, observable } from "mobx"
import dayjs, { Dayjs } from "dayjs"
import { Button, DatePicker } from "antd"

export const DateTimeEditor = observer(
  class DateTimeEditor extends React.Component<ICellEditorParams> {
    private isOpen = false
    private value: Dayjs | null
    private readonly selectRef: React.RefObject<any> = React.createRef()

    constructor(props: ICellEditorParams) {
      super(props)
      makeObservable<
        DateTimeEditor,
        "isOpen" | "value" | "selectRef" | "onChange" | "onOk" | "onClear"
      >(this, {
        isOpen: observable.struct,
        value: observable,
        selectRef: observable.ref,
        afterGuiAttached: action.bound,
        onChange: action.bound,
        onOk: action.bound,
        onClear: action.bound,
      })
    }

    public getValue() {
      return this.value?.toISOString() || null
    }

    public afterGuiAttached() {
      const value = this.props.value
      this.value = value ? dayjs.utc(value).local() : dayjs()
      this.isOpen = true
      // @rulapin Dirty Hack to allow close Datepicker by Escape
      setTimeout((): void => this.selectRef.current?.focus(), 300)
    }

    private onChange(date: Dayjs) {
      this.value = date
    }

    private onOk() {
      this.isOpen = false
      // Workaround for missing DatePicker animation finish call back
      setTimeout(() => this.props.stopEditing(), 300)
    }

    private onClear() {
      this.value = null
      this.onOk()
    }

    render() {
      return (
        <DatePicker
          ref={this.selectRef}
          allowClear
          size="small"
          value={this.value}
          open={this.isOpen}
          onOk={this.onOk}
          onChange={this.onChange}
          renderExtraFooter={() => (
            <Button type="primary" size="small" onClick={this.onClear}>
              Очистить
            </Button>
          )}
          format="DD.MM.YYYY HH:mm"
          showTime
        />
      )
    }
  }
)
