export const REQUEST_TYPES = [
  {
    value: "poor_service",
    title: "Некачественное предоставление услуг",
  },
  {
    value: "incomplete_service",
    title: "Недопоставка услуг",
  },
  {
    value: "service_outage",
    title: "Временное неоказание услуги",
  },
  {
    value: "point_shutdown",
    title: "Точечное отключение",
  },
  {
    value: "control_reading_recalc",
    title: "Перерасчет по контрольному снятию",
  },
  {
    value: "level_of_supply_change",
    title: "Изменение объемов по начислениям",
  },
]

export const APARTMENTS_NUMBERS_REG_EX = RegExp(
  // 1, 2, 3, 4, 10, 10-20
  "^(((?!0)[0-9]+)|((?!0)[0-9]+-(?!0)[0-9]+)|(, ))*$"
)

export const NON_LIVING_PREMISES_REG_EX = RegExp("^(([A-zА-яЁё\\d_-]+)|(, ))+$")

export enum AttrNames {
  APARTMENT_NUMBERS = "apartments_numbers",
  NON_LIVING_PREMISE = "non_living_premise_list",
  TYPE = "type",
  COMMENT = "comment",
  RECALC_TARIFF = "recalc_tariff",
  BUILDING = "building",
  SERVICE = "service",
  RECALC_PERIOD = "recalc_period",
  IS_ALL_APARTMENTS = "all_apartments",
  IS_ALL_NON_LIVING_PREMISES = "is_all_non_living_premises",
  MEASURE_UNIT_NAME = "measure_unit_name",
  APPROVERS = "approvers",
}

export const NON_MODIFIED_FIELDS = [
  AttrNames.APARTMENT_NUMBERS,
  AttrNames.NON_LIVING_PREMISE,
  AttrNames.TYPE,
  AttrNames.COMMENT,
  AttrNames.RECALC_TARIFF,
  AttrNames.IS_ALL_APARTMENTS,
  AttrNames.IS_ALL_NON_LIVING_PREMISES,
]

export const RANGE_DATA_FIELDS = [AttrNames.RECALC_PERIOD]
