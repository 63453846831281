import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupEditableNameFields } from "utils/fields"

import { ExploitationDivision } from "./exploitationdivision"

const schema: ISchema = {
  ...groupEditableNameFields(),
  group_exploitation_structure: {
    name: "Структура эксплуатации (ГП №1)",
    children: {
      exploitation_division: {
        name: "Дивизион эксплуатации",
        _type: "entitylink",
        entityLinkTo: ExploitationDivision,
        editable: true,
      },
    },
  },
  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class ExploitationSector extends FullNameEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(ExploitationSector, schema)
