import {
  ICellEditorParams,
  ICellRendererParams,
  ITooltipParams,
  ValueFormatterParams,
} from "ag-grid-community"

import {
  COLOR_AGGRID_BG,
  COLOR_AGGRID_GROUP_BG,
  COLOR_EXTRA_TEXT,
  COLOR_BLACK,
} from "constants/ui-colors"
import {
  cellEditorSelector,
  fieldFormatter,
} from "pik-react-utils/utils/agGrid"

export const getNameStyle = (params: ValueFormatterParams) => {
  if (!params.data) {
    return {}
  }

  if (params.data.isGroup) {
    return {
      backgroundColor: COLOR_AGGRID_GROUP_BG,
      color: COLOR_BLACK,
      fontWeight: "bold",
    }
  }

  return {
    backgroundColor: COLOR_AGGRID_BG,
    color: COLOR_EXTRA_TEXT,
    fontWeight: "bold",
  }
}

export const getDocumentFields = (type: string, fields: string[]) =>
  Array.from(new Set(fields)).filter(
    (f) => !f.includes("document_") && !f.includes("attachment") && f !== type
  )

export const getTooltipValue = (params: ITooltipParams) => {
  const slug = params?.data?.slug ?? ""
  if (!slug) return
  return params?.data?.errors?.[slug] || params.valueFormatted || ""
}

export const cellRendererSelector = (
  params: ICellRendererParams,
  field: IEntityField = params.data
) => {
  switch (field?._type) {
    case "attachment":
      return { params, component: "AttachmentRender" }
    case "point":
      return { params, component: "PointRenderer" }
    case "rosreestrstatement":
      return { params, component: "RosReestrInspectorRenderer" }
    case "request_url":
      return { params, component: "RequestURLRender" }
    default:
      return { params, component: undefined }
  }
}

export const inspectorFieldFormatter = (params: ValueFormatterParams) => {
  const formatter = fieldFormatter(params.data)
  return formatter?.(params) ?? ""
}

export const inspectorCellEditor = (params: ICellEditorParams) => {
  const editorSelector = cellEditorSelector(params.data)
  return editorSelector?.(params) ?? ""
}
