import { makeObservable, observable } from "mobx"
import { primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

interface IOffice extends IEntity {
  address: string
}

export class Office extends BaseEntity implements IOffice {
  address: string

  constructor(props: BaseEntity) {
    super(props)
    makeObservable(this, { address: observable })
  }
}

createSerializrSchema(Office, null, { address: primitive() })
