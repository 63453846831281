import { object } from "serializr"

import { GROUP_SYSTEM_DATE_FIELDS, groupEditableNameFields } from "utils/fields"
import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { Building } from "./building"
import { BuildingPartType } from "./buildingparttype"

const schema: ISchema = {
  ...groupEditableNameFields(),

  group_placement: {
    name: "Расположение",
    children: {
      building: {
        name: "Здание",
        _type: "entitylink",
        entityLinkTo: Building,
        editable: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class BuildingPart extends FullNameEntity {
  static readonly schema: ISchema = schema

  building: Building
  building_part_type: BuildingPartType
}

createSerializrSchema(BuildingPart, schema, {
  building_part_type: object(BuildingPartType),
})
