import React from "react"
import * as Scroll from "react-infinite-scroller"
import { Button, Empty, Spin } from "antd"
import { action, computed, makeObservable, observable } from "mobx"
import { observer } from "mobx-react"

import { DEFAULT_QUERY } from "constants/requests"
import { entitiesStore } from "stores"
import { Center, Wrapper } from "./components"
import { PrivateFields } from "./types"

type InfiniteScrollProps = {
  query: Record<string, unknown>
  entityType: string
  itemEl: (entity: IEntity) => JSX.Element | null
}

const Loader = () => (
  <Wrapper>
    <Spin />
  </Wrapper>
)

export class InfiniteScrollRef extends React.Component<InfiniteScrollProps> {
  private requests: Map<
    string,
    { entities: null | IEntity[]; page: number | null }
  > = new Map()
  private loading = false
  private hasError = false
  private store = entitiesStore

  constructor(props: InfiniteScrollProps) {
    super(props)
    makeObservable<InfiniteScrollRef, PrivateFields>(this, {
      requests: observable,
      loading: observable.struct,
      hasError: observable.struct,
      currentHash: computed.struct,
      entities: computed,
      nextPage: computed.struct,
      clear: action.bound,
      refresh: action.bound,
      loadMore: action.bound,
      refreshOnError: action.bound,
    })
  }

  componentDidMount(): void {
    this.refresh()
  }

  componentDidUpdate(prevProps: InfiniteScrollProps): void {
    const { query } = this.props
    for (const key in query) {
      if (query[key] !== prevProps.query[key]) {
        return this.refresh()
      }
    }
  }

  get currentHash() {
    const { entityType, query } = this.props
    return JSON.stringify({ type: entityType, ...query })
  }

  private get entities() {
    return this.requests?.get(this.currentHash)?.entities
  }

  private get nextPage() {
    return this.requests?.get(this.currentHash)?.page
  }

  private clear() {
    this.requests.clear()
    this.requests.set(this.currentHash, { entities: null, page: 1 })
  }

  refresh() {
    this.clear()
    this.loading = false
    this.loadMore()
  }

  private async loadMore() {
    this.hasError = false
    const { entityType, query } = this.props
    if (!this.nextPage || this.loading) return
    this.loading = true
    const hashQuery = this.currentHash
    try {
      const response = await this.store.getList(
        entityType,
        {
          ...DEFAULT_QUERY,
          page: this.nextPage,
          ...query,
        },
        { handleError: false }
      )
      if (hashQuery === this.currentHash) {
        const entities = this.requests.get(this.currentHash)?.entities ?? []
        this.requests.set(this.currentHash, {
          entities: entities.concat(response?.entities ?? []),
          page: response?.meta?.page_next || null,
        })
      } else {
        this.requests.delete(hashQuery)
      }
    } catch {
      this.clear()
      this.hasError = true
    } finally {
      this.loading = false
    }
  }

  private refreshOnError() {
    this.store.flushList(this.props.entityType)
    this.refresh()
  }

  render() {
    const { itemEl } = this.props

    if (this.hasError) {
      return (
        <Wrapper>
          <Center>
            <p>Произошла ошибка, попробуйте повторить запрос</p>
            <Button type="default" onClick={this.refreshOnError}>
              Обновить
            </Button>
          </Center>
        </Wrapper>
      )
    }

    if (!this.entities) {
      return <Loader />
    }

    if (!this.entities.length) {
      return (
        <Wrapper>
          <Empty />
        </Wrapper>
      )
    }

    const loadingNextPage = this.nextPage && this.nextPage > 1 && this.loading

    return (
      <Scroll
        key={this.currentHash}
        pageStart={1}
        loadMore={this.loadMore}
        hasMore={Boolean(this.nextPage)}
        useWindow={false}
        loader={loadingNextPage ? <Loader /> : undefined}
      >
        {this.entities?.map((entity: IEntity) => (
          <React.Fragment key={entity._uid}>{itemEl(entity)}</React.Fragment>
        ))}
      </Scroll>
    )
  }
}

export const InfiniteScroll = React.forwardRef(function (
  props: InfiniteScrollProps,
  ref: React.LegacyRef<InfiniteScrollRef>
) {
  const InfiniteScroll = observer(InfiniteScrollRef)
  return <InfiniteScroll {...props} ref={ref} />
})
