import { PAY_SERVICE_CHOICES } from "constants/choices"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"
import { InventoryBuilding } from "../inventorybuilding"

export const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "НСИ ID" },
      name: { name: "Наименование" },
      oidp_codes: { name: "Коды ОИДП", _type: "string[]" },
      oidp_name: { name: "Наименование ОИДП" },
      visible_name: { name: "Сокращенное наименование" },
      full_name: { name: "Полное наименование" },
      linked_object: {
        name: "Связанный объект",
        _type: "entitylink",
        entityLinkTo: InventoryBuilding,
        editable: true,
      },
      cadastral_id: { name: "Кадастровый номер", editable: true },
      land_cadastral_id: {
        name: "Кадастровый номер связанного участка",
        editable: true,
      },
    },
  },

  group_parameters: {
    name: "Характеристики",
    children: {
      pay_space: {
        name: "Обслуживаемая площадь, м2",
        _type: "number",
        editable: true,
      },
      pay_service: {
        _type: "choices",
        choices: PAY_SERVICE_CHOICES,
        name: "Бюджет обслуживания",
        editable: true,
      },
    },
  },

  group_common_parameters: {
    name: "Общие характеристики",
    children: {
      mechanized_cleaning_area: {
        name: "Площадь механизированной уборки, м²",
        _type: "decimal",
        editable: true,
      },
      manual_cleaning_area_sidewalks: {
        name: "Площадь ручной уборки (тротуары, площадки для отдыха), м²",
        _type: "decimal",
        editable: true,
      },
      manual_cleaning_area_playground: {
        name: "Площадь ручной уборки (детская площадка, спортивная площадка), м²",
        _type: "decimal",
        editable: true,
      },
      manual_cleaning_area_parking_pockets: {
        name: "Площадь ручной уборки (парковочные карманы), м²",
        _type: "decimal",
        editable: true,
      },
      manual_cleaning_area_walking_pets: {
        name: "Площадь ручной уборки площадок для выгула домашних животных, м²",
        _type: "decimal",
        editable: true,
      },
      covering_of_geogrid_area: {
        name: "Покрытие из пластиковой георешетки с заполнением плодородным грунтом,'' м²",
        _type: "decimal",
        editable: true,
      },
      coverings_of_decorative_bark_area: {
        name: "Покрытия из декоративной коры, м²",
        _type: "decimal",
        editable: true,
      },
      lawn_coverage_area: {
        name: "Площадь покрытия газонов, м²",
        _type: "decimal",
        editable: true,
      },
      green_coverage_area: {
        name: "Площадь покрытия цветников, деревьев и кустарников, м²",
        _type: "decimal",
        editable: true,
      },
      total_manual_cleaning_area: {
        name: "Общая площадь ручной уборки, м²",
        _type: "decimal",
      },
      total_cleaning_area: {
        name: "Общая уборочная площадь, м²",
        _type: "decimal",
      },
    },
  },

  group_internal_transit: {
    name: "Проезды внутридворовые и межквартальные",
    children: {
      driveways_covering_of_asphalt_concrete_area: {
        name: "Покрытие из асфальтобетона, м²",
        _type: "decimal",
        editable: true,
      },
      driveways_covering_of_concrete_tiles_more_load: {
        name: "Покрытие из бетонной плитки/брусчатки с нагрузкой более 6 тонн, м²",
        _type: "decimal",
        editable: true,
      },
      driveways_covering_of_ground_area: {
        name: "Покрытие из грунта (неусовершенствованное), м²",
        _type: "decimal",
        editable: true,
      },
      driveways_covering_of_concrete_tiles_less_load: {
        name: "Покрытие из бетонной плитки/брусчатки с нагрузкой менее 6 тонн, м²",
        _type: "decimal",
        editable: true,
      },
      driveways_covering_of_concrete_eco_tile: {
        name: "Покрытие из бетонной эко-плитки, м²",
        _type: "decimal",
        editable: true,
      },
      driveways_covering_of_lawn_grid_with_gravel: {
        name: "Покрытие из пластиковой газонной решетки с заполнением гравием, м²",
        _type: "decimal",
        editable: true,
      },
      driveways_covering_of_geogrid_area: {
        name: "Покрытие из пластиковой газонной решетки с заполнением гравием, м²",
        _type: "decimal",
        editable: true,
      },
      driveways_total_cleaning_area: {
        name: "Общая уборочная площадь проездов, м²",
      },
    },
  },

  group_parking_covering: {
    name: "Открытые гостевые автопарковки/экопарковки",
    children: {
      parking_covering_of_asphalt_concrete_area: {
        name: "Покрытие из асфальтобетона, м²",
        _type: "decimal",
        editable: true,
      },
      parking_covering_of_concrete_tiles_load_more_than_6_tons: {
        name: "Покрытие из бетонной плитки/брусчатки с нагрузкой более 6 тонн, м²",
        _type: "decimal",
        editable: true,
      },
      parking_covering_of_granite_gravel_area: {
        name: "Покрытие из гранитной высевки, м²",
        _type: "decimal",
        editable: true,
      },
      parking_covering_of_concrete_eco_tile_area: {
        name: "Покрытие из бетонной эко-плитки, м²",
        _type: "decimal",
        editable: true,
      },
      parking_covering_of_lawn_grate_with_gravel: {
        name: "Покрытие из пластиковой газонной решетки с заполнением гравием, м²",
        _type: "decimal",
        editable: true,
      },
      parking_covering_of_geogrid_with_fertile_soil: {
        name: "Покрытие из пластиковой георешетки с заполнением плодородным грунтом, м²",
        _type: "decimal",
        editable: true,
      },
      parking_total_cleaning_area: {
        name: "Общая уборочная площадь гостевых парковок, м²",
      },
    },
  },

  group_recreation_area: {
    name: "Дорожно-тропиночная сеть. Площадки для отдыха",
    children: {
      recreation_number_of_recreation_areas: {
        name: "Кол-во площадок для отдыха, шт",
        _type: "integer",
        editable: true,
      },
      recreation_covering_of_asphalt_concrete_area: {
        name: "Покрытие из асфальтобетона, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_covering_of_concrete_tiles_more_load: {
        name: "Покрытие из бетонной плитки/брусчатки с нагрузкой более 6 тонн, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_covering_of_concrete_tiles_less_load: {
        name: "Покрытие из бетонной плитки/брусчатки с нагрузкой менее 6 тонн, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_pebble_covering_area: {
        name: "Покрытие из гальки, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_covering_of_granite_gravel_area: {
        name: "Покрытие из гранитной высевки, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_sand_covering_area: {
        name: "Покрытие из песка, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_artificial_grass_covering_area: {
        name: "Покрытие из искусственной травы, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_rubber_crumbs_covering_area: {
        name: "Покрытие из резиновой (каучуковой) крошки, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_wooden_decking_covering_area: {
        name: "Покрытие из деревянного настила, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_covering_of_lawn_grate_with_gravel: {
        name: "Покрытие из пластиковой газонной решетки с заполнением гравием, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_covering_of_geogrid_with_fertile_soil: {
        name: "Покрытие из пластиковой георешетки с заполнением плодородным грунтом, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_decorative_bark_covering_area: {
        name: "Площадь покрытия из декоративной коры, м²",
        _type: "decimal",
        editable: true,
      },
      recreation_total_cleaning_area: {
        name: "Общая уборочная площадь площадок для отдыха, м²",
      },
    },
  },

  group_playground: {
    name: "Спортивная площадка для игровых видов спорта",
    children: {
      playground_number_of_playgrounds: {
        name: "Кол-во детских площадок, шт",
        _type: "integer",
        editable: true,
      },
      playground_number_of_sports_fields: {
        name: "Кол-во спортивных площадок, шт",
        _type: "integer",
        editable: true,
      },
      playground_covering_of_asphalt_concrete_area: {
        name: "Покрытие из асфальтобетона, м²",
        _type: "decimal",
        editable: true,
      },
      playground_covering_of_concrete_tiles_more_load: {
        name: "Покрытие из бетонной плитки/брусчатки с нагрузкой более 6 тонн, м²",
        _type: "decimal",
        editable: true,
      },
      playground_covering_of_concrete_tiles_less_load: {
        name: "Покрытие из бетонной плитки/брусчатки с нагрузкой менее 6 тонн, м²",
        _type: "decimal",
        editable: true,
      },
      playground_pebble_covering_area: {
        name: "Покрытие из гальки, м²",
        _type: "decimal",
        editable: true,
      },
      playground_covering_of_granite_gravel_area: {
        name: "Покрытие из гранитной высевки, м²",
        _type: "decimal",
        editable: true,
      },
      playground_sand_covering_area: {
        name: "Покрытие из песка, м²",
        _type: "decimal",
        editable: true,
      },
      playground_artificial_grass_covering_area: {
        name: "Покрытие из искусственной травы, м²",
        _type: "decimal",
        editable: true,
      },
      playground_rubber_crumbs_covering_area: {
        name: "Покрытие из резиновой (каучуковой) крошки, м²",
        _type: "decimal",
        editable: true,
      },
      playground_wooden_decking_covering_area: {
        name: "Покрытие из деревянного настила, м²",
        _type: "decimal",
        editable: true,
      },
      playground_decorative_bark_covering_area: {
        name: "Площадь покрытия из декоративной коры, м²",
        _type: "decimal",
        editable: true,
      },
      playground_total_cleaning_area: {
        name: "Общая уборочная площадь спортивных площадок, м²",
      },
    },
  },

  group_rink: {
    name: "Каток с искусственным льдом",
    children: {
      rink_rubber_crumbs_covering_area: {
        name: "Покрытие из резиновой (каучуковой) крошки, м²",
        _type: "decimal",
        editable: true,
      },
      rink_plastic_covering_area: {
        name: "Покрытие из пластика, м²",
        _type: "decimal",
        editable: true,
      },
      rink_total_cleaning_area: { name: "Общая уборочная площадь катков, м²" },
    },
  },

  pet_walking: {
    name: "Площадка для выгула домашних животных",
    children: {
      pet_walking_number_of_pet_walking_areas: {
        name: "Кол-во площадок для выгула домашних животных, шт",
        _type: "integer",
        editable: true,
      },
      pet_walking_covering_of_granite_gravel_area: {
        name: "Покрытие из гранитной высевки, м²",
        _type: "decimal",
        editable: true,
      },
      pet_walking_sand_covering_area: {
        name: "Покрытие из песка, м²",
        _type: "decimal",
        editable: true,
      },
      pet_walking_loan_area: {
        name: "Газон на площадках для выгула собак, м²",
        _type: "decimal",
        editable: true,
      },
      pet_walking_total_cleaning_area: {
        name: "Общая уборочная площадь площадок для выгула домашних животных, м²",
      },
    },
  },

  group_borders: {
    name: "Борта, ограждения и элементы сопряжения",
    children: {
      borders_concrete_granite_stone_curb_100_30_15: {
        name: "Бетонный/гранитный борт камень 100*30*15, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_concrete_granite_stone_curb_100_20_8: {
        name: "Бетонный/гранитный борт камень 100*20*8, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_concrete_granite_stone_curb_100_10_6: {
        name: "Бетонный/гранитный борт камень 100*10*6, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_steel_side: {
        name: "Стальной борт, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_aluminum_side: {
        name: "Алюминиевый борт, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_plastic_side: {
        name: "Пластиковый борт, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_number_of_antiparking_bollards: {
        name: "Антипарковочный столбик/устройство, шт",
        _type: "integer",
        editable: true,
      },
      borders_number_of_antiparking_hemispheres: {
        name: "Антипарковочная полусфера, шт",
        _type: "integer",
        editable: true,
      },
      borders_fence_between_buildings: {
        name: "Ограждение между корпусами, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_noise_barrier_fence: {
        name: "Ограждение из шумозащитного экрана, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_bunker_fence: {
        name: "Ограждение бункерной площадки, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_container_yard_fence: {
        name: "Ограждение контейнерной площадки, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_playground_fence: {
        name: "Ограждение детской площадки, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_dog_walking_area_fence: {
        name: "Ограждение площадки для выгула собак, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_quite_rest_area_fence: {
        name: "Ограждение площадки для тихого отдыха, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_sports_field_fence: {
        name: "Ограждение спортивной площадки, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_decorative_fence: {
        name: "Ограждение декоративное, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_lawn_fence: {
        name: "Ограждение газонное, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_rope_fence: {
        name: "Ограждение канатное, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_metal_handrails: {
        name: "Перила (поручни) металлические, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_wooden_handrails: {
        name: "Перила (поручни) деревянные, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_ramp: {
        name: "Пандус, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_street_stairs: {
        name: "Уличные лестницы, пг.м",
        _type: "decimal",
        editable: true,
      },
      borders_retaining_walls: {
        name: "Подпорные стены, пг.м",
        _type: "decimal",
        editable: true,
      },
    },
  },

  group_functional: {
    name: "Системы функционального обеспечения",
    children: {
      functional_outdoor_lighting_poles_number: {
        name: "Опоры наружного освещения, шт",
        _type: "integer",
        editable: true,
      },
      functional_drainage_gutter_without_grid: {
        name: "Лоток водоотводный, ливневой сток (без решетки), пг.м",
        _type: "decimal",
        editable: true,
      },
      functional_drainage_gutter_with_grid: {
        name: "Лоток водоотводный, ливневой сток (с решеткой), пг.м",
        _type: "decimal",
        editable: true,
      },
      functional_drainage_grates_number: {
        name: "Водоприемная решетка, шт",
        _type: "integer",
        editable: true,
      },
      functional_manhole_underground_utilities_number: {
        name: "Люк подземных коммуникаций, шт",
        _type: "integer",
        editable: true,
      },
      functional_ventilation_chamber_shafts_number: {
        name: "Шахта вентиляционной камеры, шт",
        _type: "integer",
        editable: true,
      },
      functional_root_ring_around_trees: {
        name: "Приствольное кольцо вокруг деревьев, м²",
        _type: "decimal",
        editable: true,
      },
    },
  },

  group_architectural_forms: {
    name: "Малые архитектурные формы",
    children: {
      architectural_articles_from_arch_concrete: {
        name: "Изделия из арх. бетона, шт",
        _type: "integer",
        editable: true,
      },
      architectural_benches: {
        name: "Скамейки, шт",
        _type: "integer",
        editable: true,
      },
      architectural_chairs: {
        name: "Стулья, шт",
        _type: "integer",
        editable: true,
      },
      architectural_tables: {
        name: "Столы, шт",
        _type: "integer",
        editable: true,
      },
      architectural_garden_sofa: {
        name: "Садовый диван, шт",
        _type: "integer",
        editable: true,
      },
      architectural_gazebo: {
        name: "Беседка, шт",
        _type: "integer",
        editable: true,
      },
      architectural_canopy: {
        name: "Навес, шт",
        _type: "integer",
        editable: true,
      },
      architectural_veranda: {
        name: "Веранда, шт",
        _type: "integer",
        editable: true,
      },
      architectural_pergolas: {
        name: "Перголы, шт",
        _type: "integer",
        editable: true,
      },
      architectural_decorative_wooden_post: {
        name: "Столбик декоративный деревянный, шт",
        _type: "integer",
        editable: true,
      },
      architectural_bicycle_parking: {
        name: "Велопарковка, шт",
        _type: "integer",
        editable: true,
      },
      architectural_urn_pik: {
        name: "Урна (ПИК стандарт), шт",
        _type: "integer",
        editable: true,
      },
      architectural_urn_economy: {
        name: "Урна (Эконом), шт",
        _type: "integer",
        editable: true,
      },
      architectural_fountain: {
        name: "Фонтан, шт",
        _type: "integer",
        editable: true,
      },
      architectural_creek: {
        name: "Ручей, шт",
        _type: "integer",
        editable: true,
      },
      architectural_pool: {
        name: "Бассейн, шт",
        _type: "integer",
        editable: true,
      },
      architectural_outdoor_toilet: {
        name: "Уличный туалет, шт",
        _type: "integer",
        editable: true,
      },
      architectural_playground_complex: {
        name: "Игровой комплекс, шт",
        _type: "integer",
        editable: true,
      },
      architectural_swing: {
        name: "Качели, шт",
        _type: "integer",
        editable: true,
      },
      architectural_swing_balancer: {
        name: "Качалка-балансир, шт",
        _type: "integer",
        editable: true,
      },
      architectural_cabin_shelter: {
        name: "Домик-беседка, шт",
        _type: "integer",
        editable: true,
      },
      architectural_spring_swing: {
        name: "Качалка на пружине, шт",
        _type: "integer",
        editable: true,
      },
      architectural_sandbox: {
        name: "Песочница, шт",
        _type: "integer",
        editable: true,
      },
      architectural_table_tennis: {
        name: "Стол для настольного тенниса, шт",
        _type: "integer",
        editable: true,
      },
      architectural_basketball_rack: {
        name: "Баскетбольная стойка, шт",
        _type: "integer",
        editable: true,
      },
      architectural_work_out_complex: {
        name: "Work out (спортивный комплекс), шт",
        _type: "integer",
        editable: true,
      },
      architectural_outdoor_fitness_equipment: {
        name: "Уличные тренажеры, шт",
        _type: "integer",
        editable: true,
      },
      architectural_football_goal: {
        name: "Ворота футбольные, шт",
        _type: "integer",
        editable: true,
      },
      architectural_tribune: {
        name: "Трибун амфитеатр, шт",
        _type: "integer",
        editable: true,
      },
    },
  },

  group_lawn_area: {
    name: "Газоны",
    children: {
      lawn_common_area: {
        name: "Обыкновенный газон, м²",
        _type: "decimal",
        editable: true,
      },
      lawn_herbaceous_area: {
        name: "Разнотравный газон, м²",
        _type: "decimal",
        editable: true,
      },
      lawn_rolled_area: {
        name: "Рулонный газон, м²",
        _type: "decimal",
        editable: true,
      },
      lawn_parterre_area: {
        name: "Партерный газон, м²",
        _type: "decimal",
        editable: true,
      },
      lawn_on_cellular_base_area: {
        name: "На ячеистом основании, м²",
        _type: "decimal",
        editable: true,
      },
      lawn_on_slope_area: {
        name: "На откосе/холмах, м²",
        _type: "decimal",
        editable: true,
      },
      lawn_in_geogrids_area: {
        name: "Газон в георешетках, м²",
        _type: "decimal",
        editable: true,
      },
      lawn_total_cleaning_area: { name: "Общая уборочная площадь газонов, м²" },
    },
  },

  group_green_area: {
    name: "Зеленые насаждения",
    children: {
      trees: { name: "Деревья, шт", _type: "integer", editable: true },
      shrubs: {
        name: "Кустарники единичные, шт",
        _type: "integer",
        editable: true,
      },
      hedges: {
        name: "Живая изгородь (кустарник в массивах), шт",
        _type: "integer",
        editable: true,
      },
    },
  },

  group_flowers_area: {
    name: "Цветники",
    children: {
      flowerbeds_annuals: {
        name: "Цветники однолетники, м²",
        _type: "decimal",
        editable: true,
      },
      flowerbeds_perennials: {
        name: "Цветники многолетники, м²",
        _type: "decimal",
        editable: true,
      },
      flowerbeds_total_cleaning_area: {
        name: "Общая уборочная площадь цветников, м²",
      },
    },
  },

  group_cover_area: {
    name: "Мульчирование под деревьями, кустарниками, цветниками",
    children: {
      bark_cover_area_under_trees_and_shrubs: {
        name: "Площадь покрытия из коры под деревьями и кустарниками, м²",
        _type: "decimal",
        editable: true,
      },
      mulch_cover_area_under_trees_and_shrubs: {
        name: "Площадь покрытия из коры под деревьями и кустарниками, м²",
        _type: "decimal",
        editable: true,
      },
      chip_cover_area_under_trees_and_shrubs: {
        name: "Площадь покрытия из щепы под деревьями и кустарниками, м²",
        _type: "decimal",
        editable: true,
      },
    },
  },

  group_podd: {
    name: "Проект организации дорожного движения (ПОДД)",
    children: {
      parking_places_count: {
        name: "Кол-во парковочных мест (машино-место), шт",
        _type: "integer",
        editable: true,
      },
      markings_area: {
        name: "Разметка (сплошные, места стоянок пожарной техники, остановочные пункты, пешеходные переходы), м²",
        _type: "decimal",
        editable: true,
      },
      tactile_tiles_count: {
        name: "Тактильная плитка, шт",
        _type: "integer",
        editable: true,
      },
      road_signs_count: {
        name: "Дорожные знаки, шт",
        _type: "integer",
        editable: true,
      },
    },
  },

  group_waste_area: {
    name: "Места сборов отходов",
    children: {
      waste_collection_bins_count: {
        name: "Кол-во баков на контейнерной площадки, шт",
        _type: "integer",
        editable: true,
      },
      waste_collection_bunker_count: {
        name: "Кол-во бункеров, шт",
        _type: "integer",
        editable: true,
      },
      waste_collection_containers_area: {
        name: "Площадь контейнерной площадки, м²",
        _type: "decimal",
        editable: true,
      },
      waste_collection_bunker_area: {
        name: "Площадь бункерной площадки, м²",
        _type: "decimal",
        editable: true,
      },
      waste_collection_total_cleaning_area: {
        name: "Общая уборочная площадь места сборов отходов, м²",
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
}
