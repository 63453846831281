import React from "react"
import { Tabs } from "antd"
import dayjs from "dayjs"
import { observer } from "mobx-react"
import { action, computed, makeObservable, observable, reaction } from "mobx"
import { CellClickedEvent, CellDoubleClickedEvent } from "ag-grid-community"

import { CreateEntityModal } from "modals"
import { DocumentList } from "components"
import { AgGridInspector } from "pik-react-utils/components"
import { CompetitionEntity } from "models"
import { SUBSCRIBABLE_ENTITY_TO_DATA } from "./constants"
import { TabContent } from "./components"
import { UirtaStore } from "../UirtaStore"
import { PrivateFields } from "./types"

type InspectorProps = {
  store: UirtaStore
}

export const UirtaInspector = observer(
  class UirtaInspector extends React.Component<InspectorProps> {
    private openDocumentsByField = false
    private isOpenCreatingModal = false
    private selectedFilters: Record<string, string>
    private inspectorCurrentSlug: string

    private reactionDisposer = reaction(
      () => this.uirtaStore.isAbleDocuments,
      () => (this.openDocumentsByField = false)
    )

    constructor(props: InspectorProps) {
      super(props)
      makeObservable<UirtaInspector, PrivateFields>(this, {
        openDocumentsByField: observable.struct,
        isOpenCreatingModal: observable.struct,
        selectedFilters: observable,
        inspectorCurrentSlug: observable,
        uirtaStore: computed,
        status: computed,
        openDocumentList: action.bound,
        changeSelectedSlug: action.bound,
        setSelectedFilters: action.bound,
        openCreatingModal: action.bound,
        closeCreatingModal: action.bound,
        onInspectorSave: action.bound,
      })
    }

    componentWillUnmount(): void {
      this.reactionDisposer()
    }

    private get uirtaStore() {
      return this.props.store
    }

    private get status() {
      const entity = this.uirtaStore.currentEntity

      if (!entity) return
      if (entity instanceof CompetitionEntity && entity?.status) {
        return entity.status
      }

      return "decision_to_participate_competition"
    }

    private openDocumentList(event: CellDoubleClickedEvent) {
      if (this.uirtaStore.isAbleDocuments) return
      this.inspectorCurrentSlug = event.data.slug
      this.openDocumentsByField = !this.openDocumentsByField
      this.setSelectedFilters(event.data)
    }

    private changeSelectedSlug(event: CellClickedEvent) {
      if (!this.openDocumentsByField) return
      this.inspectorCurrentSlug = event.data.slug
    }

    private setSelectedFilters(data: IEntityField) {
      const slug = data?.slug

      if (slug === "meeting_begin_date") {
        this.selectedFilters = { status: "absentee_start" }
        return
      }

      if (slug === "meeting_end_date") {
        this.selectedFilters = { status: "absentee_end" }
        return
      }

      if (slug === "license_date") {
        this.selectedFilters = { status: "license" }
        return
      }

      if (slug === "request_date") {
        this.selectedFilters = { status: "request" }
        return
      }

      if (slug === "notification_mailing_date") {
        this.selectedFilters = { status: "notification_mailing" }
        return
      }

      if (slug === "forms_acceptance_date") {
        this.selectedFilters = { status: "forms_acceptance" }
        return
      }

      if (slug === "protocol_date") {
        this.selectedFilters = {
          status__in: "protocol,meeting_did_not_take_place",
        }
        return
      }

      if (slug === "storage_date") {
        this.selectedFilters = { status: "storage" }
        return
      }

      this.selectedFilters = { document_fields__overlap: slug }
    }

    private openCreatingModal() {
      this.isOpenCreatingModal = true
    }

    private closeCreatingModal() {
      this.isOpenCreatingModal = false
    }

    private onInspectorSave(isClosing: boolean) {
      if (!isClosing) return
      this.uirtaStore.flushLists(["competition", "meeting"])
    }

    render() {
      const entity = this.uirtaStore.currentEntity

      return (
        <>
          <Tabs
            size="small"
            defaultActiveKey="inspector"
            animated={false}
            destroyInactiveTabPane
          >
            <Tabs.TabPane key="inspector" tab="Характеристики">
              <TabContent>
                <AgGridInspector
                  subscribableData={
                    SUBSCRIBABLE_ENTITY_TO_DATA[entity?._type ?? ""]
                  }
                  entityLink={entity}
                  onCellDoubleClicked={this.openDocumentList}
                  onCellClicked={this.changeSelectedSlug}
                />
                {entity?._uid && this.openDocumentsByField && (
                  <DocumentList
                    entityType={entity._type}
                    onCreate={this.openCreatingModal}
                    onSuccess={this.onInspectorSave}
                    slug={this.inspectorCurrentSlug}
                    query={{
                      [`${entity._type}__uid`]: entity._uid,
                      ...this.selectedFilters,
                    }}
                  />
                )}
              </TabContent>
            </Tabs.TabPane>
            {this.uirtaStore.isAbleDocuments && (
              <Tabs.TabPane key="documents" tab="Документы">
                {entity && (
                  <DocumentList
                    entityType={entity._type}
                    onCreate={this.openCreatingModal}
                    onSuccess={this.onInspectorSave}
                    query={{
                      [`${entity._type}__uid`]: entity._uid,
                    }}
                  />
                )}
              </Tabs.TabPane>
            )}
          </Tabs>
          {entity && (
            <CreateEntityModal
              isDocument
              entityType={`${entity._type}change`}
              title="Новый документ изменений в проекте"
              open={this.isOpenCreatingModal}
              onClose={this.closeCreatingModal}
              referenceLink={entity}
              defaultValues={{
                status: this.status,
                [entity._type]: entity,
                document_date: dayjs().toISOString(),
                document_is_draft: false,
              }}
              onSuccess={() => this.onInspectorSave(true)}
            />
          )}
        </>
      )
    }
  }
)
