import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from "ag-grid-community"

import { PAGE_SIZE_DEFAULT } from "constants/requests"
import { FinancialUnitEntity } from "models"
import { entitiesStore } from "stores"
import { QueryArgs } from "types"

export class Datasource implements IServerSideDatasource {
  private query: QueryArgs
  private store = entitiesStore

  constructor(params?: { query: QueryArgs }) {
    this.query = params?.query ?? {}
  }

  private mapFinancialUnits(entities: FinancialUnitEntity[]) {
    return entities.map((financialunit: FinancialUnitEntity) => {
      const {
        name,
        _uid,
        follower_count,
        is_financial_responsibility_center,
        noBudgetOwner,
        isDeleted,
      } = financialunit

      return {
        name,
        _uid,
        isGroup: follower_count > 0,
        isFinancialResponsibilityCenter: is_financial_responsibility_center,
        _type: "financialunit",
        groupKey: _uid,
        noBudgetOwner,
        isDeleted,
      }
    })
  }

  async getRows(params: IServerSideGetRowsParams) {
    const { parentNode, request, successCallback } = params

    const leaderQuery = parentNode.data
      ? { leader__uid: parentNode.data.groupKey }
      : { leader__uid__isnull: true }

    const response = await this.store.getList<FinancialUnitEntity>(
      "financialunit",
      {
        page_size: PAGE_SIZE_DEFAULT,
        page: Math.floor(request.endRow / PAGE_SIZE_DEFAULT),
        is_deleted: false,
        ...leaderQuery,
        ...this.query,
      }
    )

    if (response?.entities) {
      successCallback(
        this.mapFinancialUnits(response.entities),
        response.meta.count
      )
    }
  }
}
