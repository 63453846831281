import React, { PropsWithChildren } from "react"

import { Modal } from "../modal"
import { AttentionModalProps } from "./types"

export const AttentionModal = ({
  children,
  ...props
}: PropsWithChildren<AttentionModalProps>) => (
  <Modal title="Внимание" okText="Подтвердить" {...props}>
    <div>{children}</div>
  </Modal>
)
