import React, { useCallback, useState } from "react"
import { observer } from "mobx-react"

import { CREATING_ORGANIZATION_FIELDS } from "constants/fields"
import { SelectList } from "components"
import { Modal } from "modals"
import { FinancialUnitOrganizationRelationEntity as RelationEntity } from "models"
import { entitiesStore } from "stores"
import { AsyncSelectOption, QueryArgs } from "types"
import { ModalProps } from "antd"

interface IAddOrganizationModalProps extends ModalProps {
  defaultValues: { [slug: string]: IEntityLink }
  onCancel: () => void
  onSave: () => void
}

const defaultFields = CREATING_ORGANIZATION_FIELDS.reduce(
  (acc, { type }) => ({ ...acc, [type]: null }),
  {}
)

const getFilters = (type: string): QueryArgs | undefined => {
  if (type === "contractor") {
    return { organization__is_deleted: "False" }
  }
  return
}

export const AddOrganizationModal = observer(
  ({ open, defaultValues, ...props }: IAddOrganizationModalProps) => {
    const [store] = useState(() => entitiesStore)
    const [fields, setFields] = useState({ ...defaultFields })

    const onChange = useCallback(
      (type: string, option?: AsyncSelectOption) => {
        setFields({ ...fields, [type]: option })
      },
      [fields]
    )

    const onSave = useCallback(async () => {
      try {
        const dummy = store.createDummy("financialunitorganizationrelation")
        if (!dummy) return

        const changes = { ...defaultValues }

        CREATING_ORGANIZATION_FIELDS.forEach(({ type, slug }) => {
          const filterId = fields[type]?.value?._uid

          if (filterId) {
            changes[slug] = { _uid: filterId, _type: type }
          }
        })

        await store.createEntity<RelationEntity>(
          { ...dummy, ...changes },
          { handleError: false }
        )
      } finally {
        setFields({ ...defaultFields })
        props.onSave()
      }
    }, [store, defaultValues, props, fields])

    const onCancel = useCallback(() => {
      setFields({ ...defaultFields })
      props.onCancel()
    }, [props])

    return (
      <Modal
        open={open}
        title="Добавить организацию"
        okText="Добавить"
        onOk={onSave}
        onCancel={onCancel}
      >
        <SelectList
          options={CREATING_ORGANIZATION_FIELDS}
          filters={getFilters}
          onChange={onChange}
          fields={fields}
        />
      </Modal>
    )
  }
)
