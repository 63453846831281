import styled from "styled-components"

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

export const Center = styled.div`
  text-align: center;
`
