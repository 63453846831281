import { makeObservable, observable } from "mobx"

import { BaseEntity } from "pik-react-utils/entities"
import { SubSection } from "./subsection"
import { createSerializrSchema } from "pik-react-utils/utils"
import { object, primitive } from "serializr"

interface IMaterial extends IEntity {
  number: string
  cover: string
  url: string
  subsection: SubSection
}

export class Material extends BaseEntity implements IMaterial {
  number: string
  cover: string
  url: string
  subsection: SubSection

  constructor(props: BaseEntity) {
    super(props)
    makeObservable(this, {
      number: observable,
      cover: observable,
      url: observable,
      subsection: observable,
    })
  }
}

createSerializrSchema(Material, null, {
  number: primitive(),
  cover: primitive(),
  url: primitive(),
  subsection: object(SubSection),
})
