import { STORAGE_CHOICES } from "constants/choices"
import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupNameFields } from "utils/fields"

const schema: ISchema = {
  ...groupNameFields(),

  group_parameters: {
    name: "Характеристики",
    children: {
      storage_type: {
        name: "Тип хранилища",
        _type: "choices",
        choices: STORAGE_CHOICES,
        editable: true,
      },
      address: { name: "Адрес", editable: true },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class PassportStorage extends FullNameEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(PassportStorage, schema)
