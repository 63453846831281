import dayjs from "dayjs"
import { Decimal } from "decimal.js"

import { MDSObjectEntity } from "models"

const getIfEqual = (array: (string | number)[]) => {
  const set = new Set(array)
  return set.size === 1 ? set.values().next().value : null
}

export const defaultMDSObject = (rows: MDSObjectEntity[]) => ({
  building_type: null,
  document_name: "Принятие к учету",
  document_date: dayjs().toISOString(),
  juristic_address: getIfEqual(rows.map((data) => data.addresspost)),
  building_address: getIfEqual(rows.map((data) => data.name)),
  permission_to_enter_date: getIfEqual([
    ...rows.map((data) => data.parkingreceiptdatervoe),
    ...rows.map((data) => data.residentialreceiptdatervoe),
    ...rows.map((data) => data.nonresidentialreceiptdatervoe),
  ]),
  document_is_draft: false,
  min_floor_count: Math.min(
    ...rows.map(({ min_floor_count }) => min_floor_count)
  ),
  max_floor_count: Math.max(
    ...rows.map(({ max_floor_count }) => max_floor_count)
  ),
  apartment_count: rows.reduce((a, { apartment_count: b }) => a + b, 0),
  apartment_space: rows.reduce(
    (a, { apartment_space: b }) => a.add(b),
    new Decimal(0)
  ),
  storage_room_count: rows.reduce((a, { storage_room_count: b }) => a + b, 0),
  storage_room_space: rows.reduce(
    (a, { storage_room_space: b }) => b.add(a),
    new Decimal(0)
  ),
  garage_parking_place_count: rows.reduce(
    (a, { parking_place_count: b }) => a + b,
    0
  ),
  parking_place_space: rows.reduce(
    (a, { parking_place_space: b }) => a.add(b),
    new Decimal(0)
  ),
  non_living_premise_count: rows.reduce(
    (a, { non_residential_premise_count: b }) => a + b,
    0
  ),
  non_living_premise_space: rows.reduce(
    (a, { non_residential_premise_space: b }) => a.add(b),
    new Decimal(0)
  ),
  residential_premise_count: rows.reduce(
    (a, { residential_premise_count: b }) => a + b,
    0
  ),
  residential_premise_space: rows.reduce(
    (a, { residential_premise_space: b }) => a.add(b),
    new Decimal(0)
  ),
  oidp_codes: rows?.map(({ code1c }) => code1c),
})
