import { computed, makeObservable, observable } from "mobx"
import { object } from "serializr"

import { TERRITORIAL_UNIT_TYPE_CHOICES } from "constants/choices"
import { FullNameEntity, isBaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupEditableNameFields } from "utils/fields"

import { FinancialEmployment } from "./financialemployment"

const schema: ISchema = {
  ...groupEditableNameFields(),

  group_parameters: {
    name: "Характеристики",
    children: {
      is_financial_responsibility_center: {
        name: "ЦФО",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      owner: {
        name: "Владелец бюджета",
        _type: "entitylink",
        entityLinkTo: FinancialEmployment,
        _isNull: true,
        editable: true,
      },
      leader: {
        name: "Узел финансовой структуры",
        _type: "entitylink",
        entityLinkTo: FullNameEntity,
        aliasEntityType: "financialunit",
        editable: true,
      },
      follower_count: { name: "Кол-во вложенных узлов", _type: "number" },
      description: { name: "Описание", editable: true },
      territorial_unit_type: {
        name: "Тип территориального подразделения",
        _type: "choices",
        choices: TERRITORIAL_UNIT_TYPE_CHOICES,
        _isNull: true,
        editable: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
}
export class FinancialUnit extends FullNameEntity {
  static readonly schema: ISchema = schema

  is_financial_responsibility_center: boolean
  follower_count: number
  owner: FinancialEmployment | null

  constructor(props: FullNameEntity) {
    super(props)
    makeObservable(this, {
      is_financial_responsibility_center: observable,
      owner: observable,
      noBudgetOwner: computed,
    })
  }

  get noBudgetOwner() {
    if (!this.is_financial_responsibility_center) return false

    return !isBaseEntity(this.owner)
  }
}

createSerializrSchema(FinancialUnit, FinancialUnit.schema, {
  leader: object(FullNameEntity),
  owner: object(FinancialEmployment),
})
