import { CellClickedEvent } from "ag-grid-community"
import { action, computed, makeObservable, observable, override } from "mobx"

import { ENTITIES_FILTERS } from "constants/filters"
import { EXCLUDED_PIVOT_FIELDS } from "constants/pivot"
import { BaseQueryStore } from "stores"
import { IColumnGroupState, QueryArgs } from "pik-react-utils/types"
import { CREATABLE_ENTITIES } from "./constants"
import { IStateByType, IPivotStoreParams } from "./types"

export class PivotStore extends BaseQueryStore {
  private _currentEntityType = ""
  private _selectedEntity: IEntity | undefined
  private _stateByType: IStateByType = {}

  constructor(params: IPivotStoreParams) {
    super({ ...params, filterFields: ENTITIES_FILTERS[params.type] })
    const { type, visible_fields, editable_fields } = params
    this._currentEntityType = type ?? this._currentEntityType
    this.setFieldByType(visible_fields?.split(","), editable_fields?.split(","))
    makeObservable<
      PivotStore,
      "_currentEntityType" | "_selectedEntity" | "_stateByType"
    >(this, {
      _currentEntityType: observable.struct,
      currentEntityType: computed,
      _selectedEntity: observable,
      selectedEntity: computed,
      _stateByType: observable.deep,
      stateByCurrentType: computed,
      canCreateEntity: computed,
      visibleFields: computed,
      visibleAllFields: computed,
      editableFields: computed,
      searchParamsForURL: override,
      columnOpenGroupState: computed,
      update: action.bound,
      updateQuery: override,
      setVisibleFields: action.bound,
      setEditableFields: action.bound,
      setColumnGroupState: action.bound,
      flushList: action.bound,
      setSelect: action.bound,
    })
  }

  update(type: string) {
    this.onSaveQueryByType()
    this._currentEntityType = type
    this.setFields(ENTITIES_FILTERS[this.currentEntityType] ?? [])
    const { query = { search: "" } } = this.stateByCurrentType ?? {}
    this.resetQuery(query, query?.search)
    this.setFieldByType()
  }

  updateQuery(params: IPivotStoreParams): void {
    const { filters = {}, search, visible_fields, editable_fields } = params
    this.resetQuery(filters, search)
    this.setFieldByType(visible_fields?.split(","), editable_fields?.split(","))
  }

  get currentEntityType() {
    return this._currentEntityType
  }

  get stateByCurrentType() {
    return this._stateByType[this._currentEntityType]
  }

  get canCreateEntity() {
    return CREATABLE_ENTITIES.includes(this.currentEntityType)
  }

  get visibleFields() {
    return this.stateByCurrentType?.visibleFields ?? []
  }

  get visibleAllFields() {
    return this.stateByCurrentType?.totalCount === this.visibleFields?.length
  }

  get editableFields() {
    return this.stateByCurrentType?.editableFields ?? []
  }

  get selectedEntity() {
    return this._selectedEntity
  }

  get columnOpenGroupState() {
    return this.stateByCurrentType?.columnOpenGroupState ?? []
  }

  get searchParamsForURL() {
    const { search, ...filters } = this.query
    const params: {
      search?: string
      filters?: QueryArgs
      visible_fields?: string
      editable_fields?: string
    } = {}
    if (search) {
      params.search = search
    }

    Object.entries(filters).map(([key, value]) => {
      if (value == null) return

      if (!params.filters) params.filters = {}
      params.filters[key] = value
    })

    if (this.visibleFields && !this.visibleAllFields) {
      params.visible_fields = this.visibleFields.toString()
    }

    if (this.editableFields?.length) {
      params.editable_fields = this.editableFields.toString()
    }

    return params
  }

  async setSelect(event: CellClickedEvent) {
    this._selectedEntity = event.data
  }

  private onSaveQueryByType() {
    if (this.stateByCurrentType) {
      this.stateByCurrentType.query = this.query
    }
  }

  private setFieldByType(visibleFields?: string[], editableFields?: string[]) {
    if (this.stateByCurrentType) {
      if (visibleFields?.length) this.setVisibleFields(visibleFields)
      if (editableFields?.length) this.setEditableFields(editableFields)
      return
    }

    const fieldsByType = this.entitiesStore.getAgGridFieldsByType(
      this.currentEntityType,
      EXCLUDED_PIVOT_FIELDS[this.currentEntityType]
    )

    const fields = fieldsByType.reduce((acc, field) => {
      if ("children" in field) {
        return [...acc, ...field.children.map(({ slug }) => slug)]
      }
      return [...acc, field.slug]
    }, [])

    this._stateByType[this._currentEntityType] = {
      query: this.query,
      totalCount: fields.length,
      visibleFields: visibleFields || fields,
      editableFields: editableFields ?? [],
      columnOpenGroupState: [],
    }
  }

  setVisibleFields(fields?: string[]) {
    if (this.stateByCurrentType && fields?.length) {
      this.stateByCurrentType.visibleFields = fields
    } else {
      this.setFieldByType(fields)
    }
  }

  setEditableFields(fields: string[]) {
    if (this.stateByCurrentType) {
      this.stateByCurrentType.editableFields = fields
    } else {
      this.setFieldByType(undefined, fields)
    }
  }

  setColumnGroupState(type: string, items: IColumnGroupState[]) {
    if (type && this._stateByType[type]) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._stateByType[type]!.columnOpenGroupState = items
    }
  }

  flushList() {
    this.entitiesStore.flushList(this._currentEntityType)
  }
}
