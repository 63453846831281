import { list, primitive } from "serializr"

import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

interface IOrganization extends IEntity {
  is_actual: boolean
  juristic_units: string[]
  is_external: boolean
  inn: string
}

export class Organization extends FullNameEntity implements IOrganization {
  juristic_address: string
  ogrn: string

  juristic_units: string[]
  is_actual: boolean
  is_external: boolean
  inn: string
}

createSerializrSchema(Organization, null, {
  juristic_address: primitive(),
  ogrn: primitive(),
  juristic_units: list(primitive()),
  is_actual: primitive(),
  is_external: primitive(),
  inn: primitive(),
})
