/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProcessCellForExportParams } from "ag-grid-community"

function processCellForClipboard(params: ProcessCellForExportParams) {
  const colDef = params.column.getColDef()
  const valueFormatter = colDef?.valueFormatter

  if (typeof valueFormatter === "function") {
    return valueFormatter(params as any)
  }

  return valueFormatter ?? ""
}

export { processCellForClipboard }
