import { object } from "serializr"

import { BaseEntity, FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { schema } from "./schema"

export class BuildingLocationRule extends BaseEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(BuildingLocationRule, schema, {
  financial_unit: object(FullNameEntity),
  management_service: object(FullNameEntity),
})
