import {
  OAUTH_FORBID_REDIRECT_PATHNAME,
  OAUTH_AUTHORIZE_REDIRECT_PATHNAME,
} from "pik-react-utils/constants"

export const oauthStatusUri = (
  authUri: string,
  redirectUri: string,
  clientId: string
): string => {
  return (
    `${authUri ?? ""}/openid/authorize/?response_type=id_token token` +
    `&scope=openid profile email roles&nonce=1&client_id=${clientId}` +
    `&redirect_uri=${redirectUri ?? ""}`
  )
}

export const oauthForbidUri = (
  authUri: string,
  redirectUri: string,
  idToken: string | undefined,
  state: number | undefined
) =>
  `${authUri || ``}/openid/end-session/?id_token_hint=${idToken || ``}` +
  `&state=${state || ``}` +
  `&post_logout_redirect_uri=${redirectUri || ``}`

export const oauthLoginUri = (nextUri: string, authUri: string): string =>
  `${authUri || ``}/openid/authorize/${new URL(nextUri).search}`

export const oauthAthorizeRedirectUri = (basePath: string) =>
  `${basePath || ``}${OAUTH_AUTHORIZE_REDIRECT_PATHNAME}`

export const oauthForbidRedirectUri = (basePath: string) =>
  `${basePath || ``}${OAUTH_FORBID_REDIRECT_PATHNAME}`

export const oauthUserInfoUri = (authUri: string, accessToken: string) =>
  `${authUri || ``}/openid/userinfo/?access_token=${accessToken}`
