import React from "react"

export const LinkCell = ({
  title,
  link,
  onClick,
}: {
  title: string
  link?: string
  onClick?: () => void
}) => (
  <a key={link} href={link} onClick={onClick}>
    {title}
  </a>
)
