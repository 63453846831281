import React from "react"
import { Button } from "antd"
import { ICellRendererParams } from "ag-grid-community"
import { ButtonType } from "antd/lib/button"

interface ButtonRendererProps extends ICellRendererParams {
  type: ButtonType
  title: string
  onClick: (params: ICellRendererParams) => void
  danger?: boolean
  icon?: JSX.Element
}

export const ButtonRenderer = (props: ButtonRendererProps) => (
  <Button
    size="small"
    type={props.type}
    title={props.title}
    onClick={() => props.onClick(props)}
    danger={props.danger}
    icon={props.icon ?? null}
  >
    {props.title}
  </Button>
)
