import {
  KIND_CHOICES,
  MEETING_RESULT_CHOICES,
  MEETING_STATUS_CHOICES,
} from "constants/choices"
import { groupDocumentFields, GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { Building } from "../building"
import { FinancialEmployment } from "../financialemployment"
import { ManagementCompany } from "../managementcompany"
import { MeetingChangeAttachment } from "../meetingchangeattachment"

export const schema: ISchema = {
  ...groupDocumentFields({
    attachment: {
      name: "Прикрепленный файл",
      _type: "attachment",
      entityLinkTo: MeetingChangeAttachment,
      editable: true,
    },
  }),

  group_parameters: {
    name: "Свойства собрания",
    children: {
      name: { name: "Название", editable: true },
      building: {
        name: "Здание",
        _type: "entitylink",
        entityLinkTo: Building,
        editable: true,
      },
      curator: {
        name: "Руководитель проекта",
        _type: "entitylink",
        entityLinkTo: FinancialEmployment,
        editable: true,
      },
      leading_specialist: {
        name: "Ведущий специалист",
        _type: "entitylink",
        entityLinkTo: FinancialEmployment,
        editable: true,
      },
      quorum: { name: "Кворум (в %)", _type: "number", editable: true },
      kind: {
        name: "Вид",
        _type: "choices",
        choices: KIND_CHOICES,
        editable: true,
      },
      status: {
        name: "Статус",
        _type: "choices",
        choices: MEETING_STATUS_CHOICES,
        editable: true,
      },
      result: {
        name: "Итог ОСС",
        _type: "choices",
        choices: MEETING_RESULT_CHOICES,
        editable: true,
      },
      current_management_company: {
        name: "Действующая УК",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
        editable: true,
      },
      target_management_company: {
        name: "Будущая УК",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
        editable: true,
      },
      service_desk_request_url: {
        name: "Заявка на проведение собрания",
        _type: "request_url",
        editable: true,
      },
      e_oss: {
        name: "Е-ОСС (Электронное голосование)",
        _type: "boolean",
        editable: true,
        _isNull: true
      },
      comment: { name: "Комментарий" },
    },
  },

  group_complaint: {
    name: "Жалобы собственников",
    children: {
      is_owners_complaint_exploitation: {
        name: "Из-за эксплуатации",
        _type: "boolean",
        editable: true,
        _isNull: true
      },
      is_owners_complaint_cleaning: {
        name: "Из-за уборки",
        _type: "boolean",
        editable: true,
        _isNull: true
      },
      is_owners_complaint_landscaping: {
        name: "Из-за благоустройства",
        _type: "boolean",
        editable: true,
        _isNull: true
      },
      is_owners_complaint_billing: {
        name: "Из-за начислений",
        _type: "boolean",
        editable: true,
        _isNull: true
      },
      is_owners_complaint_complaints: {
        name: "Из-за заявок",
        _type: "boolean",
        editable: true,
        _isNull: true
      },
      is_owners_complaint_office_accessibility: {
        name: "Из-за доступности офиса",
        _type: "boolean",
        editable: true,
        _isNull: true
      },
      is_owners_complaint_elevators: {
        name: "Из-за лифтов",
        _type: "boolean",
        editable: true,
        _isNull: true
      },
      is_owners_complaint_tariff: {
        name: "Из-за тарифов",
        _type: "boolean",
        editable: true,
        _isNull: true
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const
