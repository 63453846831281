import React from "react"

import {
  INVENTORY_WIKI_LINK,
  InventoryStructurePageTabs,
  RequestPageTabs,
} from "constants/links"
import { ExternalLink } from "components"
import {
  iconEmployeesPath,
  iconFinStructurePath,
  iconInstructionsPath,
  iconInventoryPath,
  iconPivotPath,
  iconRequestsPath,
} from "components/icons"
import { routerService as router } from "services"
import { NavMenuType } from "types"

export const MENU_ITEMS: NavMenuType[] = [
  {
    link: router.INVENTORY_STRUCTURE_PATH,
    label: "Объекты учета",
    description:
      "Здания и сооружения, объекты, на которых мы собираем доходы и расходы",
    icon: iconInventoryPath,
    children: [
      {
        link: router.linkToInventoryTab(InventoryStructurePageTabs.BUILDINGS),
        label: "Список зданий",
      },
      {
        link: router.linkToInventoryTab(InventoryStructurePageTabs.REFERENCES),
        label: "Справочники",
      },
      {
        link: router.linkToInventoryTab(InventoryStructurePageTabs.MDS_OBJECTS),
        label: "Объекты ИДП",
      },
      {
        key: router.INVENTORY_STRUCTURE_PATH + "wiki_buildings",
        link: <ExternalLink to={INVENTORY_WIKI_LINK} label="База знаний ОУ" />,
      },
    ],
  },
  {
    key: router.PIVOT_PATH,
    link: router.linkToPivotBuildings,
    label: "Сводная таблица",
    description: "Удобный интерфейс просмотра и редактирования объектов",
    icon: iconPivotPath,
  },
  {
    link: router.FINANCIAL_STRUCTURE_PATH,
    label: "Фин. структура холдинга",
    description:
      "Центры финансовой ответственности холдинга с сотрудниками и объектами учета",
    icon: iconFinStructurePath,
  },
  {
    link: router.EMPLOYEE_LIST_PATH,
    label: "Сотрудники",
    description:
      "Контакты сотрудников, информация по должностям и трудоустройствам",
    icon: iconEmployeesPath,
  },
  {
    link: router.INFO_PATH,
    label: "Обучение",
    description:
      "Инструкции по настройке рабочего места и работе с информационными системами",
    icon: iconInstructionsPath,
  },
  {
    link: router.REQUEST_PATH,
    label: "Заявки",
    description: "Сервис для создания заявок на перерасчет, проведения ОСС",
    icon: iconRequestsPath,
    children: [
      {
        link: router.linkToRequestTab(RequestPageTabs.TARIFF_RECALC),
        label: "Заявка на перерасчет",
      },
      {
        link: router.linkToRequestTab(RequestPageTabs.MEETING),
        label: "Заявка на проведение ОСС",
      },
      {
        link: router.linkToRequestTab(RequestPageTabs.PERMISSION),
        label: "Заявка на права доступа",
      },
      {
        link: router.linkToRequestTab(RequestPageTabs.OTHER),
        label: "Другие заявки",
      },
    ],
  },
]
