import React from "react"
import { Button, Checkbox } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { TableButtonPanel } from "./components"

type ConfirmationPanelProps = {
  isVisible: boolean
  loading?: boolean
  onCancel?: () => void
  onSave?: () => void
  canCreateMore?: boolean
  isCreateMore?: boolean
  onCreateMoreChange?: (value: boolean) => void
  isNew: boolean
}

export const ConfirmationPanel = ({
  isVisible,
  loading,
  onCancel,
  onSave,
  canCreateMore,
  onCreateMoreChange,
  isCreateMore,
  isNew,
}: ConfirmationPanelProps) => {
  const onChange = (e: CheckboxChangeEvent) => {
    onCreateMoreChange?.(e.target.checked)
  }

  if (!isVisible) return null

  return (
    <TableButtonPanel>
      {canCreateMore && (
        <Checkbox checked={isCreateMore} onChange={onChange}>
          Создать ещё
        </Checkbox>
      )}
      <Button onClick={onCancel}>Отмена</Button>
      <Button type="primary" loading={loading} onClick={onSave}>
        {isNew ? "Создать" : "Сохранить"}
      </Button>
    </TableButtonPanel>
  )
}
