import styled from "styled-components"

export const Wrapper = styled.div`
  width: 70%;
  margin: 24px auto;
`

export const Heading = styled.h2`
  text-align: center;
  margin: 24px auto;
`

export const Footer = styled.footer`
  margin-top: 36px;
  text-align: center;
`
