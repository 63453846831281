import { createGlobalStyle } from "styled-components"
const sanitize = require("sanitize.css")

import { COLOR_MAIN_TEXT, COLOR_BASIC } from "constants/ui-colors"
import { SIZES } from "constants/media"

export const GlobalStyle = createGlobalStyle`
  ${sanitize}
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    color: ${COLOR_MAIN_TEXT};
    font-family: ProbaPro, Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 1.69;
    overflow-y: hidden;
  }

  body {
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: hidden;
  }

  #root{
    height: 100vh;
    min-width: ${SIZES.Desktop_S};
    overflow: hidden;
    overflow-x: auto;
  }

  a,
  button,
  input {
    font-weight: normal;
    cursor: pointer;
  }

  button, input {
    color: ${COLOR_MAIN_TEXT};
  }

  a:visited, a:link {
    text-decoration: none;
    cursor: pointer;
  }

  a:hover {
    color: ${COLOR_BASIC};
  }

  #root {
    position: relative;
  }
`
