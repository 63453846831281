import React from "react"
import { Select, DatePicker } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { observer } from "mobx-react"
import styled from "styled-components"

import { AsyncSelect } from "components"
import { AsyncSelectOption, FilterType, QueryArgs } from "types"
import { FilterValue } from "./types"

const DateSelect = styled(DatePicker)`
  width: 100%;
`

interface IProps {
  field: FilterType
  filtersByEntity: QueryArgs | undefined
  value?: FilterValue
  onChange?: (value: FilterValue) => void
}

export const FilterField = observer(
  ({ value, field, filtersByEntity, onChange }: IProps) => {
    if (field._type === "tags") {
      return (
        <Select
          mode="tags"
          showSearch={false}
          placeholder="Выберите теги"
          onChange={onChange}
          value={value}
          options={field.choices}
          size="large"
          allowClear
        />
      )
    }

    if (field._type === "choices") {
      return (
        <Select
          value={value}
          placeholder="Выберите"
          onChange={onChange}
          size="large"
          options={field.choices}
          allowClear
        />
      )
    }

    if (field._type === "entitylink") {
      return (
        <AsyncSelect
          entityType={field.entityLinkTo}
          filters={{ ...field.staticFilters, ...filtersByEntity }}
          isClearable
          placeholder={field.name}
          value={value as AsyncSelectOption}
          onChange={onChange}
        />
      )
    }

    if (field._type === "date") {
      return (
        <DateSelect
          allowClear
          size="large"
          placeholder="Выберите дату"
          value={value ? dayjs(value as string) : null}
          format={field.format ?? "DD.MM.YYYY"}
          onChange={(val: Dayjs) => onChange?.(val?.startOf("date"))}
          showToday={false}
        />
      )
    }

    return null
  }
)
