import { EmploymentEntity, ExternalAccountEntity } from "models"
import { AsyncSelectOption } from "types"

export const employmentOption = (
  entity: ExternalAccountEntity | EmploymentEntity
) => {
  if (!entity) return
  const elements = [entity.breadcrumbFullName]

  if (entity instanceof EmploymentEntity && entity.employee.full_name) {
    elements.push(entity.employee.full_name)
  } else if (entity instanceof ExternalAccountEntity && entity.full_name) {
    elements.push(entity.full_name)
  }

  return { value: entity, label: elements.join(" / ") }
}

export const employmentOptions = (
  entities: (ExternalAccountEntity | EmploymentEntity)[] | undefined
) => {
  const response: AsyncSelectOption<
    ExternalAccountEntity | EmploymentEntity
  >[] = []

  entities?.forEach((item) => {
    const result = employmentOption(item)
    if (result) response.push(result)
  })

  return response
}
