import { cloneDeep } from "lodash"

export function unionEntities(entity: IRawEntity, cachedEntity?: IRawEntity) {
  if (!cachedEntity) {
    return cloneDeep(entity)
  }

  if ("_version" in cachedEntity && cachedEntity._version > entity._version) {
    return cloneDeep(cachedEntity)
  }

  if ("_version" in cachedEntity && cachedEntity._version === entity._version) {
    return cloneDeep({ ...cachedEntity, ...entity })
  }

  return cloneDeep(entity)
}
