import React, { useCallback, useEffect, useState } from "react"
import { Button } from "antd"
import { observer } from "mobx-react"

import { DEFAULT_QUERY } from "constants/requests"
import { BuildingLocationRuleEntity } from "models"
import { RulesModal } from "modals"
import { Wrapper } from "pages/FinancialStructure/components"
import { entitiesStore } from "stores"
import { usePrevious } from "utils/hooks"
import { Rule } from "./Rule"
import { OrderedList } from "./components"

interface IRulesProps {
  financialUnitId: string
}

export const RuleList = observer(({ financialUnitId }: IRulesProps) => {
  const [store] = useState(() => entitiesStore)
  const [isOpenModal, setModalOpen] = useState(false)
  const [buildingLocationRules, setBuildingLocationRules] = useState<
    BuildingLocationRuleEntity[] | null
  >(null)
  const prevFinancialUnitId = usePrevious<string>(financialUnitId)

  const retrieveBuildings = useCallback(async () => {
    const response = await store.getList<BuildingLocationRuleEntity>(
      "buildinglocationrule",
      { ...DEFAULT_QUERY, financial_unit__uid: financialUnitId }
    )

    setBuildingLocationRules(response?.entities || [])
  }, [financialUnitId, store])

  const onUpdate = useCallback(() => {
    setBuildingLocationRules(null)
    setModalOpen(false)
    store.flushList("buildinglocation")
  }, [store])

  useEffect(() => {
    if (buildingLocationRules === null) {
      retrieveBuildings()
    }
  }, [buildingLocationRules, retrieveBuildings])

  useEffect(() => {
    if (prevFinancialUnitId !== financialUnitId) {
      setBuildingLocationRules(null)
    }
  }, [financialUnitId, prevFinancialUnitId])

  return (
    <Wrapper>
      <Button onClick={() => setModalOpen(true)}>Создать правило</Button>
      <OrderedList>
        {buildingLocationRules?.map((el) => (
          <Rule key={el._uid} rule={el} onDelete={onUpdate} />
        ))}
      </OrderedList>
      <RulesModal
        open={isOpenModal}
        onCancel={() => setModalOpen(false)}
        onSave={onUpdate}
        defaultValues={{
          financial_unit: { _uid: financialUnitId, _type: "financialunit" },
        }}
      />
    </Wrapper>
  )
})
