import React from "react"
import { Button, Col, Form, FormInstance, Row, Switch } from "antd"
import { action, computed, makeObservable, observable, reaction } from "mobx"
import { observer } from "mobx-react"

import { FormLabel } from "components/css"
import { FilterType, QueryArgs } from "types"
import { FilterField } from "./FilterField"
import { FilterModalProps, FilterValue, PrivateFields } from "./types"
import { FilterStore } from "./store"
import { Modal } from "../modal"

export const FilterModal = observer(
  class FilterModal extends React.Component<FilterModalProps> {
    private isDeletedSelect = false
    private readonly filterStore = new FilterStore()
    private readonly formRef: React.RefObject<
      FormInstance<Record<string, FilterValue>>
    > = React.createRef()

    private reactionDisposer = reaction(
      () => this.filterStore.filters,
      (filters) => this.formRef.current?.setFieldsValue(filters)
    )

    constructor(props: FilterModalProps) {
      super(props)
      makeObservable<FilterModal, PrivateFields>(this, {
        isDeletedSelect: observable.struct,
        formattedFilters: computed,
        filtersByEntityFields: computed,
        customFooter: computed,
        onFieldsChange: action.bound,
        onCheckboxChange: action.bound,
        onClearFilters: action.bound,
        onSuccess: action.bound,
      })
    }

    componentDidUpdate(prevProps: FilterModalProps) {
      const { open, ...rest } = this.props

      if (!prevProps.open && open) {
        this.filterStore.initStore(rest) // action on open modal
      } else if (!open) {
        this.filterStore.destroy()
      }
    }

    componentWillUnmount(): void {
      this.reactionDisposer()
    }

    private get formattedFilters() {
      return this.filterStore.formattedFilters
    }

    private get filtersByEntityFields() {
      const filters: Record<string, QueryArgs> = {}

      this.props.fields
        .filter(({ _type }) => _type === "entitylink")
        .forEach((field) => {
          const { slug, dependencies = [] } = field
          const newFilter = { is_deleted: this.isDeletedSelect ? null : false }

          dependencies?.map((dependencyField) => {
            newFilter[dependencyField] = this.formattedFilters[dependencyField]
          })
          filters[slug] = newFilter
        })

      return filters
    }

    private onFieldsChange() {
      const filters = this.formRef.current?.getFieldsValue()
      if (!filters) return
      this.filterStore.updateFilters(filters)
    }

    private onCheckboxChange(isChecked: boolean) {
      this.isDeletedSelect = isChecked
    }

    private onClearFilters() {
      this.isDeletedSelect = false
      this.filterStore.resetFilters()
    }

    private onSuccess() {
      this.props.onSuccess(this.formattedFilters)
    }

    private get customFooter() {
      const { onCancel, entityType } = this.props
      return (
        <Row align="middle" justify="space-between">
          {entityType ? (
            <Col>
              {this.filterStore.count === null
                ? "Загрузка..."
                : `Найдено: ${this.filterStore.count}`}
            </Col>
          ) : null}
          <Col>
            <Button onClick={onCancel}>Отмена</Button>
            <Button type="primary" onClick={this.onSuccess}>
              Применить
            </Button>
          </Col>
        </Row>
      )
    }

    render() {
      const { open, fields, onCancel } = this.props
      if (!open) return null

      return (
        <Modal
          open={open}
          title="Задать фильтры"
          footer={this.customFooter}
          onCancel={onCancel}
        >
          <Form
            layout="vertical"
            ref={this.formRef}
            onFieldsChange={this.onFieldsChange}
          >
            {fields.map((field: FilterType) => (
              <Form.Item
                key={field.slug}
                name={field.slug}
                label={<FormLabel>{field.name}</FormLabel>}
                dependencies={field.dependencies}
              >
                <FilterField
                  field={field}
                  filtersByEntity={this.filtersByEntityFields[field.slug]}
                />
              </Form.Item>
            ))}
            {this.filterStore.hasSelect && (
              <Form.Item className="ant-form-item-inline">
                <Switch
                  onChange={this.onCheckboxChange}
                  checked={this.isDeletedSelect}
                />
                <FormLabel>
                  Показывать в выпадающих списках удаленные объекты
                </FormLabel>
              </Form.Item>
            )}
          </Form>
          {this.filterStore.canClearable && (
            <Row justify="end">
              <Button type="link" onClick={this.onClearFilters}>
                Сбросить фильтры
              </Button>
            </Row>
          )}
        </Modal>
      )
    }
  }
)
