import { makeObservable, observable } from "mobx"
import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

const schema: ISchema = {
  file: { name: "Ссылка на файл" },
} as const

export class AttachmentEntity extends BaseEntity {
  static readonly schema: ISchema = schema

  file: string

  constructor(props: BaseEntity) {
    super(props)
    makeObservable(this, { file: observable })
  }
}

createSerializrSchema(AttachmentEntity, schema)
