import dayjs, { Dayjs } from "dayjs"
import { makeAutoObservable } from "mobx"
import { ValueFormatterParams } from "ag-grid-community"

import { PIVOT_ENTITIES, EXCLUDED_PIVOT_FIELDS } from "constants/pivot"
import { entitiesStore } from "stores"
import { DEFAULT_QUERY } from "pik-react-utils/constants"
import { FileService } from "pik-react-utils/services"
import { fieldFormatter } from "pik-react-utils/utils/agGrid"
import { fieldsFromURL, fieldsToQuery } from "utils/url"
import { ExportStatus } from "./constants"
import { ExcelSearchQueryType, RawExcelSearchQueryType } from "./types"

export class ExcelStore {
  private _totalCount = 0
  private _currentCount = 0
  private _status: ExportStatus = ExportStatus.INIT
  private startDate: Dayjs = dayjs()
  private endDate: Dayjs
  private searchQuery: ExcelSearchQueryType
  private readonly store = entitiesStore
  private entityType: string

  constructor({
    type,
    searchParams,
  }: {
    type: string | undefined
    searchParams: RawExcelSearchQueryType
  }) {
    this.entityType = type ?? ""
    const { filters, search = "", visible_fields } = searchParams

    this.searchQuery = {
      filters,
      search,
      visible_fields: fieldsFromURL(visible_fields),
    }
    this.loadAllEntities()
    makeAutoObservable(this)
  }

  get status() {
    return this._status
  }

  get currentCount() {
    return this._currentCount
  }

  get totalCount() {
    return this._totalCount
  }

  get checkedFields() {
    const fields = this.searchQuery.visible_fields
    if (!fields.length || fields.length === this.allFields.length) {
      return this.allFields
    }
    return this.allFields.filter((field) => fields.includes(field.slug))
  }

  get entityName() {
    const pivot = PIVOT_ENTITIES.find((item) => item.value === this.entityType)
    return pivot?.label ?? "Объекты учета"
  }

  private get allFields() {
    const fields = this.store.getAgGridFieldsByType(
      this.entityType,
      EXCLUDED_PIVOT_FIELDS[this.entityType]
    )

    return fields.reduce((acc, field) => {
      if ("children" in field) {
        return [...acc, ...field.children]
      }
      return [...acc, field]
    }, [])
  }

  private get queryForRequest() {
    const preparedQuery = {
      ...DEFAULT_QUERY,
      search: this.searchQuery.search,
      ...this.searchQuery.filters,
    }
    const fields = this.searchQuery.visible_fields
    const maxFieldsInQuery = Math.floor(this.allFields.length / 3)
    if (!fields.length || fields.length >= maxFieldsInQuery) {
      return preparedQuery
    }

    return { ...preparedQuery, query: fieldsToQuery(this.checkedFields) }
  }

  get progressValue() {
    if (!this.currentCount || !this.totalCount) {
      return 0
    }

    if (this.currentCount === this.totalCount) {
      return 100
    }

    return Math.round((this.currentCount / this.totalCount) * 100)
  }

  private setStatus(status: ExportStatus) {
    this._status = status
  }

  getRows(entities: IEntity[]) {
    return entities.map((entity) =>
      this.checkedFields.reduce((acc, field) => {
        const formatter = fieldFormatter(field)
        const { slug, parent } = field
        const params = {
          value: parent ? entity?.[parent]?.[slug] : entity?.[slug],
        } as ValueFormatterParams
        return { ...acc, [slug]: formatter?.(params) }
      }, {})
    )
  }

  private setEndDate() {
    this.endDate = dayjs()
  }

  async loadAllEntities() {
    let result: IEntity[] = []
    let nextPage: number | null = 1

    try {
      this.setStatus(ExportStatus.LOADING)
      while (nextPage) {
        const request = this.store.getList(this.entityType, {
          ...this.queryForRequest,
          page: nextPage,
        }) as Promise<IList<IEntity>>

        const response = await request

        result = result.concat(response.entities ?? [])
        nextPage = response.meta.page_next
        this._currentCount = result.length
        this._totalCount = response.meta.count
      }
      await FileService.downloadExcel({
        name: this.entityName,
        rows: this.getRows(result),
        fields: this.checkedFields,
      })
      this.setEndDate()
      this.setStatus(ExportStatus.DONE)
    } catch (e) {
      this.setStatus(ExportStatus.ERROR)
    }
  }

  get downloadTime() {
    if (!this.startDate || !this.endDate) return
    return dayjs.duration(this.endDate.diff(this.startDate))
  }

  get waitingTime() {
    if (!this.totalCount) return

    const progress = this.currentCount / this.totalCount
    const multiplier = (1 - progress) / progress
    return dayjs.duration(dayjs().diff(this.startDate) * multiplier)
  }
}
