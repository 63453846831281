import React from "react"
import { observable, action, computed, makeObservable } from "mobx"
import { observer } from "mobx-react"
import { Button } from "antd"

import { StaffUnitEntity } from "models"
import { PlusCircleOutlined } from "components/antd"
import { InfiniteScroll, InfiniteScrollRef } from "components"
import { Wrapper, Content } from "./components"
import { WorkplaceEditor } from "./WorkplaceEditor"
import { Workplace } from "./Workplace"
import { WorkplaceEditorState } from "./constants"
import { Footer } from "../../../components"
import { PrivateFields } from "./types"

export const Workplaces = observer(
  class Workplaces extends React.Component<{ unitUid: string }> {
    private staffUnit: StaffUnitEntity | null = null
    private editorState: WorkplaceEditorState | null = null
    private listRef = React.createRef<InfiniteScrollRef>()

    constructor(props: { unitUid: string }) {
      super(props)
      makeObservable<Workplaces, PrivateFields>(this, {
        staffUnit: observable,
        editorState: observable,
        query: computed,
        resetList: action.bound,
        onCreate: action.bound,
        onEdit: action.bound,
        onClose: action.bound,
      })
    }

    componentDidUpdate({ unitUid }: { unitUid: string }): void {
      if (this.props.unitUid !== unitUid) {
        this.resetList()
      }
    }

    private get query() {
      return {
        unit__uid: this.props.unitUid,
        is_main: false,
        is_actual: true,
      }
    }

    private resetList() {
      this.staffUnit = null
      this.editorState = null
    }

    private onCreate() {
      this.editorState = WorkplaceEditorState.CREATING
    }

    private onEdit(staffUnit: StaffUnitEntity | null) {
      this.editorState = WorkplaceEditorState.EDITING
      this.staffUnit = staffUnit
    }

    private onClose(refresh?: boolean) {
      this.resetList()
      if (refresh) {
        this.listRef.current?.refresh()
      }
    }

    render() {
      if (
        this.editorState === WorkplaceEditorState.CREATING ||
        (this.editorState === WorkplaceEditorState.EDITING && this.staffUnit)
      ) {
        return (
          <WorkplaceEditor
            state={this.editorState}
            unitUid={this.props.unitUid}
            onClose={this.onClose}
            staffUnit={this.staffUnit}
          />
        )
      }

      return (
        <Content>
          <Wrapper>
            <InfiniteScroll
              ref={this.listRef}
              entityType="staffunit"
              query={this.query}
              itemEl={(staffUnit: StaffUnitEntity) => (
                <Workplace
                  staffUnit={staffUnit}
                  onClose={this.onClose}
                  onEdit={this.onEdit}
                />
              )}
            />
          </Wrapper>
          <Footer>
            <Button
              type="link"
              icon={<PlusCircleOutlined />}
              onClick={this.onCreate}
            >
              Добавить рабочее место
            </Button>
          </Footer>
        </Content>
      )
    }
  }
)
