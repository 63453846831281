import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

import { schema } from "./schema"

export class AdjacentArea extends FullNameEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(AdjacentArea, schema)
