export enum BuildingType {
  NEW = "new",
  CHANGING = "changing",
}

export const AVAILABLE_THIRD_TYPENAME = ["utility_system", "premise"] as const

export const AVAILABLE_SECOND_TYPENAME = [
  "building_part",
  "utility_system",
  "premise",
] as const
