import React, { Suspense, useCallback, useRef, useState } from "react"
import { Input, Spin } from "antd"
import { observer } from "mobx-react"

import {
  AgGridServerSide,
  AgGridServerSideRef,
} from "pik-react-utils/components"
import { ColumnHeader, ColumnWithHeader, TwoColumnLayout } from "components/css"
import { OrganizationEntity, UnitEntity } from "models"
import { entitiesStore } from "stores"
import { Datasource } from "./Datasource"
import { Division } from "./Division"
import { DataItem } from "./types"

export const ManagementStructure = observer(() => {
  const [store] = useState(() => entitiesStore)
  const [unit, setUnit] = useState<UnitEntity | OrganizationEntity>()
  const agGridRef = useRef<AgGridServerSideRef>()

  const update = (refresh?: boolean) => {
    if (refresh) agGridRef.current?.refresh()
    agGridRef.current?.focus()
  }

  const updateData = useCallback((search?: string) => {
    const datasource = new Datasource({ search })
    agGridRef.current?.setServerSideDatasource(datasource)
  }, [])

  const setUnitData = useCallback(
    async (data: DataItem) => {
      const response = data
        ? await store.getEntity<UnitEntity | OrganizationEntity>(
            { _type: data.entityType, _uid: data.uid },
            { fromStorage: true }
          )
        : undefined
      setUnit(response)
    },
    [store]
  )

  const onSearch = (search: string) => updateData(search)

  return (
    <Suspense fallback={<Spin />}>
      <TwoColumnLayout>
        <ColumnWithHeader>
          <ColumnHeader>
            <Input.Search
              placeholder="Объект Целевой оргструктуры"
              enterButton="Найти"
              onSearch={onSearch}
              allowClear
            />
          </ColumnHeader>
          <AgGridServerSide
            ref={agGridRef as React.Ref<AgGridServerSideRef>}
            columnDefs={[
              { field: "name", hide: true, resizable: true },
              { field: "type", headerName: "Тип подразделения", width: 70 },
            ]}
            autoGroupColumnDef={{
              headerName: "Подразделение",
              field: "name",
            }}
            onGridReady={updateData}
            onRowClicked={setUnitData}
          />
        </ColumnWithHeader>
        <Division onUpdate={update} unit={unit} />
      </TwoColumnLayout>
    </Suspense>
  )
})
