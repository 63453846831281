import React from "react"
import { Button } from "antd"
import { ICellRendererParams } from "ag-grid-community"

interface ActionRendererProps extends ICellRendererParams {
  loading: boolean
  onSubmit: (params: ICellRendererParams) => void
  onCancel: (params: ICellRendererParams) => void
  changes: {
    [_uid: string]: Record<string, string | number | IEntityLink>
  }
}

export const ButtonsInRowRenderer = ({
  changes,
  ...props
}: ActionRendererProps) => {
  if (!props.data || !changes || !changes[props.data._uid]) {
    return null
  }

  return (
    <>
      <Button
        size="small"
        type="primary"
        title="Cохранить"
        onClick={() => props.onSubmit(props)}
        loading={props.loading}
      >
        Сохранить
      </Button>
      &nbsp;
      <Button
        size="small"
        type="primary"
        title="Отмена"
        danger
        onClick={() => props.onCancel(props)}
        disabled={props.loading}
      >
        Отмена
      </Button>
    </>
  )
}
