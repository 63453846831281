import styled from "styled-components"
import { COLOR_MENU_BACKGROUND, COLOR_WHITE } from "constants/ui-colors"

export const Content = styled.div`
  position: relative;
  height: calc(100vh - 110px);
`
export const Hero = styled.div`
  height: 400px;
  background: ${COLOR_MENU_BACKGROUND},
    url(${require("assets/img/hero-bg.jpg")});
  background-size: cover;
  background-position: top right;

  h1 {
    padding: 110px 10%;
    color: ${COLOR_WHITE};
    font-family: ProbaProMedium;
    font-size: 48px;
    text-align: left;
    white-space: pre-line;
    line-height: 0.75;
    margin: 0;
  }
`

export const MenuWrap = styled.div`
  margin: -40px 8% 40px;
  padding-bottom: 80px;
  z-index: 1;
`
