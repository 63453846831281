import React from "react"
import { ICellRendererParams } from "ag-grid-community"
import { Button } from "antd"

interface Props extends ICellRendererParams {
  href: string
  target?: React.HTMLAttributeAnchorTarget
  onClick?: (params: ICellRendererParams) => void
}

export const LinkRenderer = (props: Props) => (
  <Button
    type="link"
    size="small"
    target={props.target}
    href={props.href}
    onClick={() => props.onClick?.(props)}
  >
    {props.valueFormatted}
  </Button>
)
