import styled from "styled-components"

export const RuleItem = styled.li`
  &:not(:first-of-type) {
    padding-top: 15px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Text = styled.div`
  white-space: normal;
`

export const OrderedList = styled.ol`
  margin: 12px 0;
`
