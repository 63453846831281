import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { groupNameFields, GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

const schema: ISchema = {
  ...groupNameFields(),

  group: {
    name: "Реквизиты",
    children: {
      inn: { name: "ИНН" },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class Contractor extends FullNameEntity {
  static readonly schema: ISchema = schema

  inn: string
}

createSerializrSchema(Contractor, schema)
