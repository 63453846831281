import React from "react"
import {
  Corporate,
  CorporateForDirector,
  OfficeWork,
  Personal,
  PersonalForDirector,
  Requests,
  Telephony,
} from "./sections"

const HeadDepartmentInstructions = [
  {
    key: "office-work",
    header: "Сотрудник работает в офисе",
    component: <OfficeWork />,
  },
  {
    key: "personal-for-director",
    header: "Сотрудник работает дома с личным ПК",
    component: <PersonalForDirector />,
  },
  {
    key: "corporate-for-director",
    header: "Сотрудник работает дома с корпоративным ПК",
    component: <CorporateForDirector />,
  },
  {
    key: "requests",
    header: "Сотрудник выполняет заявки в ПИК-Диспетчерской",
    component: <Requests />,
  },
]

const EmployeeInstructions = [
  {
    key: "corporate",
    header: "Работаю удаленно с корпоративного компьютера",
    component: <Corporate />,
  },
  {
    key: "personal",
    header: "Работаю удаленно с личного компьютера",
    component: <Personal />,
  },
  {
    key: "telephony",
    header: "Настройка телефонии на компьютере",
    component: <Telephony />,
  },
  {
    key: "apm",
    header: "Выполняю заявки в ПИК-Диспетчерской",
    component: (
      <p>
        Если Вы являетесь сотрудником ПИК-Комфорт или подрядной организации для
        выполнения заявок в программе ПИК-Диспетчерская, Вам необходимо
        загрузить на смартфон приложение “Кабинет техника”. Для этого необходимо
        открыть магазин приложений Google Play (если смартфон работает на ОС
        Android) или App Store (если это iPhone), в поиске найти приложение
        “Кабинет техника”, загрузить его, запустить и войти под своими учетными
        данными.
      </p>
    ),
  },
]

export const instructionBlocks = [
  {
    title: "Инструкции для руководителя",
    items: HeadDepartmentInstructions,
  },
  {
    title: "Инструкции для нового сотрудника",
    items: EmployeeInstructions,
  },
]
