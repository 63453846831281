import { NavLink as Link } from "react-router-dom"
import { Menu } from "antd"
import styled from "styled-components"

import { COLOR_BASIC_DARK, COLOR_MAIN_TEXT } from "constants/ui-colors"
import { MEDIA } from "constants/media"

export const RouterLink = styled(Link)`
  color: ${COLOR_MAIN_TEXT};
  text-decoration: none;
  white-space: nowrap;
  &.active {
    pointer-events: none;
    color: ${COLOR_BASIC_DARK} !important;
    font-weight: 500;
  }
`

export const MenuList = styled(Menu)`
  flex-grow: 1;
  line-height: 76px;
  border-bottom: none;

  @media ${MEDIA.Desktop_M} {
    max-width: 780px;
  }
`

export const DropdownWrapper = styled.section`
  margin-right: 16px;
  order: -1;
`
