import { Row, Form as _Form } from "antd"
import styled from "styled-components"

export const Content = styled(Row)`
  position: relative;
  border: 1px solid #bdc3c7;
  border-radius: 2px;
  overflow: hidden;
  overflow-y: auto;
`

export const Footer = styled.footer`
  position: sticky;
  left: 0;
  bottom: 0;
  background-color: #fff;
  margin-top: auto;
  padding: 12px 24px;
  margin-top: auto;
  z-index: 1;
`

export const Form = styled(_Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`
