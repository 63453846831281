import {
  ACCESSION_STATUS_CHOICES,
  COMFORT_CLASS_CHOICES,
  OUTFLOW_STATUS_CHOICES,
  TARIFFICATION_CLASS_CHOICES,
} from "constants/choices"
import {
  BILLING_GROUP_UID,
  CONTRACT_GROUP_UID,
  EXPLOITATION_DATA_GROUP_UID,
  EXPLOITATION_GROUP_UID,
  IDENTIFICATION_GROUP_UID,
  LIFECICLE_GROUP_UID,
  MANAGEMENT_STRUCTURE_GROUP_UID,
  NSI_SERVICE_UID,
  PREMISE_TYPE_GROUP_UID,
} from "constants/subscribe-groups"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { AddressComponents } from "../addresscomponents"
import { BuildingsGroup } from "../buildingsgroup"
import { BuildingType } from "../buildingtype"
import { Contractor } from "../contractor"
import { Division } from "../division"
import { Exploitation } from "../exploitation"
import { ExploitationDivision } from "../exploitationdivision"
import { ExploitationSector } from "../exploitationsector"
import { ManagementService } from "../managementservice"
import { ManagementCompany } from "../managementcompany"
import { MDSLivingComplex } from "../mdslivingcomplex"
import { Sector } from "../sector"

export const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    subscribeData: {
      target_service: NSI_SERVICE_UID,
      permission_group: IDENTIFICATION_GROUP_UID,
    },
    children: {
      _uid: { name: "НСИ ID" },
      house_fias_id: { name: "Код ФИАС" },
      oidp_codes: { name: "Коды ОИДП", _type: "string[]" },
      cadastral_id: { name: "Кадастровый номер здания" },
      land_cadastral_id: {
        name: "Кадастровый номер замельного участка",
      },
      certificate_number: {
        name: "Выписка из ЕГРН",
        _type: "rosreestrstatement",
        computed: ["_uid"],
      },
      building_type: {
        name: "Тип",
        _type: "entitylink",
        entityLinkTo: BuildingType,
      },
      juristic_address: { name: "Наименование" },
      point: { name: "Координаты", _type: "point" },
      normalized_address: { name: "Почтовый адрес" },
      comfort_class: {
        name: "Класс фонда",
        _type: "choices",
        choices: COMFORT_CLASS_CHOICES,
      },
      empowered_developer: {
        name: "Застройщик",
        _type: "entitylink",
        entityLinkTo: Contractor,
      },
      building_address: { name: "Строительный адрес" },
      living_complex: {
        name: "Жилищный комплекс",
        _type: "entitylink",
        entityLinkTo: MDSLivingComplex,
      },
      special_fund: { name: "Спецфонд", _type: "boolean" },
      product_fund: { name: "Фонд продукт", _type: "boolean" },
      renovation_fund: { name: "Реновация", _type: "boolean" },
      tariffication_class: {
        name: "Тарификация",
        _type: "choices",
        choices: TARIFFICATION_CLASS_CHOICES,
        _isNull: true,
      },
    },
  },

  group_agreements: {
    name: "Договоры",
    subscribeData: {
      target_service: NSI_SERVICE_UID,
      permission_group: CONTRACT_GROUP_UID,
    },
    children: {
      has_management_tag: { name: "Управление (УК)", _type: "boolean" },
      has_exploitation_tag: { name: "Эксплуатация (УК)", _type: "boolean" },
      has_receipt_tag: { name: "Приемка", _type: "boolean" },
      has_funds_collecting_tag: {
        name: "Сбор средств через ЛК Клиента",
        _type: "boolean",
      },
      has_customer_service_tag: {
        name: "Услуга обращения в ЛК",
        _type: "boolean",
      },
      has_contact_center_service_tag: {
        name: "Контактный центр",
        _type: "boolean",
      },
      has_dvz_tag: {
        name: "ДВЗ",
        _type: "boolean",
      },
    },
  },

  group_management_structure: {
    name: "Структура управления",
    subscribeData: {
      target_service: NSI_SERVICE_UID,
      permission_group: MANAGEMENT_STRUCTURE_GROUP_UID,
    },
    children: {
      management_company: {
        name: "Управляющая компания",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
      },
      division: {
        name: "Регион управления",
        _type: "entitylink",
        entityLinkTo: Division,
      },
      sector: {
        name: "Филиал управления",
        _type: "entitylink",
        entityLinkTo: Sector,
      },
      management_service: {
        name: "Территориальное отделение управления",
        _type: "entitylink",
        entityLinkTo: ManagementService,
      },
    },
  },

  group_exploitation_structure: {
    name: "Структура эксплуатации (ГП №1)",
    subscribeData: {
      target_service: NSI_SERVICE_UID,
      permission_group: EXPLOITATION_GROUP_UID,
    },
    children: {
      exploitation_division: {
        name: "Дивизион эксплуатации",
        _type: "entitylink",
        entityLinkTo: ExploitationDivision,
      },
      exploitation_sector: {
        name: "Участок эксплуатации",
        _type: "entitylink",
        entityLinkTo: ExploitationSector,
      },
      exploitation: {
        name: "Служба эксплуатации",
        _type: "entitylink",
        entityLinkTo: Exploitation,
      },
      buildings_group: {
        name: "Группа зданий",
        _type: "entitylink",
        entityLinkTo: BuildingsGroup,
      },
    },
  },

  group_billing: {
    name: "Биллинг",
    subscribeData: {
      target_service: NSI_SERVICE_UID,
      permission_group: BILLING_GROUP_UID,
    },
    children: {
      residential_premise_billing: {
        name: "Билинг жилых помещений",
        _type: "entitylink",
        entityLinkTo: Contractor,
      },
      non_living_premise_billing: {
        name: "Билинг нежилых помещений",
        _type: "entitylink",
        entityLinkTo: Contractor,
      },
    },
  },

  group_premise_type: {
    name: "Тип помещений",
    subscribeData: {
      target_service: NSI_SERVICE_UID,
      permission_group: PREMISE_TYPE_GROUP_UID,
    },
    children: {
      residential_premise_space: { name: "Квартиры, м2", _type: "number" },
      residential_premise_count: { name: "Квартиры, шт.", _type: "number" },
      apartment_space: { name: "Апартаменты, м2", _type: "number" },
      apartment_count: { name: "Апартаменты, шт.", _type: "number" },
      parking_place_space: { name: "Машиноместа, м2", _type: "number" },
      parking_place_count: { name: "Машиноместа, шт.", _type: "number" },
      storage_room_space: { name: "Кладовые, м2", _type: "number" },
      storage_room_count: { name: "Кладовые, шт.", _type: "number" },
      non_living_premise_space: {
        name: "Нежилые помещения (БКФН), м2",
        _type: "number",
      },
      non_living_premise_count: {
        name: "Нежилые помещения (БКФН), шт.",
        _type: "number",
      },
      pay_space_incl_parking: {
        name: "Начисляемая площадь (общая), м2",
        _type: "number",
      },
    },
  },

  group_expoatation_chars: {
    name: "Эксплуатационные характеристики",
    subscribeData: {
      target_service: NSI_SERVICE_UID,
      permission_group: EXPLOITATION_DATA_GROUP_UID,
    },
    children: {
      living_entrance_count: { name: "Жилые подъезды, шт.", _type: "number" },
      elevators_count: { name: "Лифты, шт.", _type: "number" },
      max_floor_count: {
        name: "Этажность (1 этаж и выше), max",
        _type: "number",
      },
      min_floor_count: {
        name: "Этажность (1 этаж и выше), min",
        _type: "number",
      },
      common_areas_space_first_floor: {
        name: "МОП (1 этаж), м2",
        _type: "number",
      },
      common_areas_space_other_floors: {
        name: "МОП (2 этаж и выше), м2",
        _type: "number",
      },
      common_areas_space_garage: { name: "МОП (гараж), м2", _type: "number" },
      garbage_room_space: { name: "МК, м2", _type: "number" },
      adjacent_territory_space: {
        name: "ПТ (убираемая УК), м2",
        _type: "number",
      },
      typical_floors_count: { name: "Типовые этажи, шт.", _type: "number" },
      concierge_count: { name: "Консьержи (по ОСС), шт.", _type: "number" },
    },
  },

  group_lifecycle: {
    name: "Жизненный цикл",
    subscribeData: {
      target_service: NSI_SERVICE_UID,
      permission_group: LIFECICLE_GROUP_UID,
    },
    children: {
      accession_status: {
        _type: "choices",
        choices: ACCESSION_STATUS_CHOICES,
        name: "Основание прихода в периметр",
      },
      outflow_status: {
        _type: "choices",
        choices: OUTFLOW_STATUS_CHOICES,
        name: "Основание выхода из периметра",
      },
      outflow_company: {
        name: "Выбытие в управляющую компанию",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
      },
      built_year: { name: "Год постройки" },
      permission_to_enter_date: { name: "РВОЭ", _type: "date" },
      occupancy_start_date: { name: "Заселение (начало)", _type: "date" },
    },
  },

  group_location: {
    name: "Локация",
    children: {
      address_components: {
        _type: "parent",
        parentEntityLinkTo: AddressComponents,
        fields: [
          "country",
          "region_name",
          "area_name",
          "city_name",
          "city_area",
          "city_district_name",
          "settlement_name",
          "street_name",
          "house_name",
          "block",
          "postal_code",
        ],
      },
      local_government: {
        _type: "parent",
        parentEntityLinkTo: ManagementService,
        fields: ["local_government"],
        aliasParentField: "management_service",
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const
