import React, { useEffect, useRef, useState } from "react"
import { Button, Empty } from "antd"
import { observer } from "mobx-react"

import { ColumnHeader, ColumnWithHeader } from "components/css"
import { OrganizationEntity, UnitEntity } from "models"
import { DivisionState } from "./constants"
import { DivisionEditor } from "./DivisionEditor"
import { DivisionViewer } from "./DivisionViewer"
import { Content } from "./components"
import { CloseParams } from "./types"

type DivisionProps = {
  unit: UnitEntity | OrganizationEntity | undefined
  onUpdate: (refresh?: boolean) => void
}

export const Division = observer(({ unit, onUpdate }: DivisionProps) => {
  const [state, setState] = useState<DivisionState>(DivisionState.VIEWING)
  const prevUnit = useRef(unit)

  const onChangeUnit = () => {
    setState(DivisionState.EDITING)
  }

  const onAddUnit = () => {
    setState(DivisionState.CREATING)
  }

  const onClose = (params?: CloseParams) => {
    setState(params?.nextState ?? DivisionState.VIEWING)
    onUpdate(params?.refresh)
  }

  useEffect(() => {
    if (unit !== prevUnit.current) {
      prevUnit.current = unit
      setState(DivisionState.VIEWING)
    }
  }, [state, prevUnit, unit])

  if (state === DivisionState.CREATING) {
    return (
      <DivisionEditor
        state={state}
        unit={unit instanceof UnitEntity ? unit : undefined}
        onClose={onClose}
      />
    )
  }

  if (!unit) {
    return (
      <ColumnWithHeader>
        <ColumnHeader>
          <Button type="primary" onClick={onAddUnit}>
            Добавить подразделение
          </Button>
        </ColumnHeader>
        <Content align="middle" justify="center">
          <Empty description="Выберите подразделение" />
        </Content>
      </ColumnWithHeader>
    )
  }

  if (state === DivisionState.EDITING && unit instanceof UnitEntity) {
    return <DivisionEditor state={state} unit={unit} onClose={onClose} />
  }

  return (
    <DivisionViewer
      unit={unit}
      onClose={onClose}
      onChange={onChangeUnit}
      onAdd={onAddUnit}
    />
  )
})
