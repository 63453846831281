import React from "react"
import { action, computed, makeObservable, observable } from "mobx"
import {
  GridReadyEvent,
  GridApi,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ColumnApi,
  ICellEditorParams,
  ValueGetterParams,
  Column,
  ValueSetterParams,
} from "ag-grid-community"
import { observer } from "mobx-react"
import { isEqual } from "lodash"

import { PAGE_SIZE_DEFAULT } from "pik-react-utils/constants"
import { cellEditorSelector } from "pik-react-utils/utils/agGrid"
import { processCellForClipboard } from "pik-react-utils/utils/clipboard"
import { IAgGridViewportDatasource, ViewportDatasource } from "./Datasource"
import { IViewportStore, ViewportStore } from "./store"
import { IAgGridViewportProps } from "./types"
import {
  actionButtonsColumn,
  cellRendererSelectorByField,
  getRowStyle,
  getTooltipValue,
  getValueStyle,
  rowNumberColumn,
  valueFormatterByField,
  valueGetterByField,
} from "./utils"
import { AgGridComponent } from "../GridComponent"

export class AgGridViewportRef extends React.Component<IAgGridViewportProps> {
  private store: IViewportStore
  private columnApi: ColumnApi
  private gridApi: GridApi
  private datasource: IAgGridViewportDatasource | undefined

  constructor(props: IAgGridViewportProps) {
    super(props)
    const { entityType, excludedFields } = props
    this.store = new ViewportStore({ entityType, excludedFields })
    makeObservable<
      AgGridViewportRef,
      | "store"
      | "columnApi"
      | "gridApi"
      | "datasource"
      | "columnDefs"
      | "openedGroups"
      | "updateEntity"
      | "onCancelUpdate"
      | "valueSetter"
      | "generateColumn"
      | "generateGroup"
      | "updateDataSource"
      | "onGridReady"
      | "initialFieldState"
      | "onColumnVisible"
      | "onToolPanelVisibleChanged"
    >(this, {
      store: observable,
      columnApi: observable,
      gridApi: observable,
      datasource: observable,
      columnDefs: computed,
      openedGroups: action.bound,
      updateEntity: action.bound,
      onCancelUpdate: action.bound,
      valueSetter: action.bound,
      generateColumn: action.bound,
      generateGroup: action.bound,
      updateDataSource: action.bound,
      onGridReady: action.bound,
      initialFieldState: action.bound,
      onColumnVisible: action.bound,
      onToolPanelVisibleChanged: action.bound,
      refresh: action.bound,
    })
  }

  componentDidUpdate(prevProps: Readonly<IAgGridViewportProps>): void {
    const { query, entityType, onChangeColumnGroupState } = this.props
    if (!isEqual(prevProps.query, query)) {
      this.updateDataSource()
    }

    if (prevProps.entityType && prevProps.entityType !== entityType) {
      onChangeColumnGroupState?.(prevProps.entityType, this.openedGroups())
    }
  }

  componentWillUnmount(): void {
    this.store.reactionDisposer?.()
  }

  private get columnDefs() {
    const { editableFields, getColumnDefs, autoColumnGroups } = this.props
    const currentColumns: (ColDef | ColGroupDef)[] = []
    this.store.fields.forEach((field: IEntityField | IEntityGroup) => {
      if (!("children" in field)) {
        currentColumns.push(this.generateColumn(field))
      } else if (autoColumnGroups) {
        currentColumns.push(this.generateGroup(field))
      } else {
        currentColumns.push(
          ...field.children.map((field) => this.generateColumn(field))
        )
      }
    })

    const columns = [rowNumberColumn, ...currentColumns]

    if (editableFields?.length && Object.keys(this.store.changes).length) {
      columns.push({
        ...actionButtonsColumn,
        cellRendererParams: {
          onSubmit: this.updateEntity,
          onCancel: this.onCancelUpdate,
          changes: this.store.changes,
        },
      } as ColDef)
    }

    return getColumnDefs?.(columns) ?? columns
  }

  private openedGroups() {
    return this.columnApi?.getColumnGroupState().filter(({ open }) => open)
  }

  private async updateEntity(params: ICellRendererParams) {
    try {
      this.gridApi?.stopEditing()
      this.gridApi?.showLoadingOverlay()
      const rowIndex = params.rowIndex
      const newEntity = await this.store.updateEntity(params.data)
      if (newEntity) {
        this.datasource?.updateRow(rowIndex, newEntity)
      }
    } finally {
      this.gridApi?.hideOverlay()
      this.gridApi.redrawRows({ rowNodes: [params.node] })
    }
  }

  private onCancelUpdate(params: ICellRendererParams) {
    this.store.clearChanges(params)
    this.gridApi?.redrawRows({ rowNodes: [params.node] })
  }

  private valueSetter(params: ValueSetterParams) {
    this.store.setPropertyValue(params)
    params.api?.redrawRows({ rowNodes: [params.node] })
    return true
  }

  private generateColumn(
    field: IEntityField,
    columnGroupShow: string | null = null
  ): ColDef {
    const { editableFields } = this.props
    const { errors, changes } = this.store

    return {
      headerName: field.name,
      colId: field.slug,
      field: field.slug,
      editable: editableFields?.includes(field.slug),
      cellEditorSelector: cellEditorSelector(field),
      cellStyle: getValueStyle({ errors, changes }),
      valueGetter: ({ data }: ValueGetterParams) =>
        valueGetterByField(field, data, changes?.[data?._uid]),
      valueFormatter: valueFormatterByField(field),
      tooltipValueGetter: getTooltipValue,
      valueSetter: this.valueSetter,
      cellRendererSelector: cellRendererSelectorByField(field),
      resizable: true,
      minWidth: 150,
      menuTabs: [],
      columnGroupShow,
      cellEditorParams: ({ data }: ICellEditorParams) => {
        if (!field.dependencies?.length) return {}
        return field.dependencies.reduce(
          (acc, key) => ({
            ...acc,
            [key]: valueGetterByField(
              { slug: key },
              data,
              changes?.[data?._uid]
            ),
          }),
          {}
        )
      },
    } as ColDef
  }

  private generateGroup(field: IEntityGroup) {
    const currentColumnGroup = this.openedGroups()?.find(
      (item) => item.groupId === field.slug
    )

    const leftColumn = this.columnApi
      ?.getColumnGroup(field.slug)
      ?.getChildren()
      ?.find((item: Column) => item.isVisible())

    return {
      headerName: field.name,
      marryChildren: true,
      groupId: field.slug,
      openByDefault: currentColumnGroup?.open ?? false,
      children: field.children.map((item, index) => {
        const isGroupColumn = leftColumn
          ? leftColumn?.getUniqueId() === item.slug
          : index === 0
        return this.generateColumn(item, isGroupColumn ? null : "open")
      }),
    } as ColGroupDef
  }

  private updateDataSource() {
    this.store.clearChanges()
    this.datasource?.update({
      entityType: this.store.entityType,
      query: this.props.query,
    })
  }

  private onGridReady(event: GridReadyEvent) {
    const { query, onGridReady, columnOpenGroupState = [] } = this.props

    this.gridApi = event.api
    this.columnApi = event.columnApi
    onGridReady?.(event)
    this.datasource = new ViewportDatasource({
      entityType: this.store.entityType,
      query,
    })
    this.gridApi?.setViewportDatasource(this.datasource)
    if (columnOpenGroupState.length) {
      this.columnApi?.setColumnGroupState(columnOpenGroupState ?? [])
    }
    this.initialFieldState()
  }

  private initialFieldState() {
    const { visibleFields } = this.props
    const columns = this.columnApi.getAllColumns()
    const nonVisibleColumns = columns
      ?.map((item) => item.getColDef().field)
      ?.filter(
        (slug: string) =>
          slug !== "rowNumber" &&
          slug !== "actions" &&
          !visibleFields.includes(slug)
      ) as string[]
    if (nonVisibleColumns?.length) {
      this.columnApi?.setColumnsVisible(nonVisibleColumns, false)
    }
  }

  private onColumnVisible() {
    const { onChangeVisibleFields } = this.props
    if (!onChangeVisibleFields) return

    const columns = this.columnApi.getAllColumns()
    const visibleColumns = columns
      ?.filter((item) => item.isVisible())
      .map((item) => item.getColDef().field)
      .filter((slug) => slug !== "rowNumber" && slug !== "actions") as string[]

    onChangeVisibleFields(visibleColumns)
    this.gridApi?.sizeColumnsToFit()
  }

  private onToolPanelVisibleChanged() {
    this.gridApi?.sizeColumnsToFit()
    const isShowing = this.gridApi?.isToolPanelShowing()
    if (typeof isShowing === "boolean") {
      this.props.sideBarProps?.onShowToolPanel?.(isShowing)
    }
  }

  refresh() {
    this.updateDataSource()
  }

  render() {
    const { sideBarProps, onCellClicked } = this.props

    return (
      <AgGridComponent
        rowModelType="viewport"
        columnDefs={this.columnDefs}
        viewportRowModelPageSize={PAGE_SIZE_DEFAULT}
        viewportRowModelBufferSize={PAGE_SIZE_DEFAULT}
        onCellContextMenu={onCellClicked}
        getRowStyle={getRowStyle}
        onGridReady={this.onGridReady}
        maxConcurrentDatasourceRequests={3}
        cacheOverflowSize={40}
        processCellForClipboard={processCellForClipboard}
        onColumnVisible={this.onColumnVisible}
        onToolPanelVisibleChanged={this.onToolPanelVisibleChanged}
        sideBar={
          sideBarProps
            ? {
                toolPanels: [
                  {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                      suppressRowGroups: true,
                      suppressValues: true,
                      suppressPivotMode: true,
                      suppressColumnMove: true,
                      suppressSyncLayoutWithGrid: true,
                    },
                  },
                ],
                defaultToolPanel: sideBarProps.isDefaultShowing
                  ? "columns"
                  : undefined,
              }
            : false
        }
        {...this.props}
      />
    )
  }
}

export const AgGridViewport = React.forwardRef(function (
  props: IAgGridViewportProps,
  ref: React.LegacyRef<AgGridViewportRef>
) {
  const AgGridViewport = observer(AgGridViewportRef)
  return <AgGridViewport {...props} ref={ref} />
})
