import { observer } from "mobx-react"
import React from "react"
import { action, makeObservable, observable, reaction } from "mobx"
import AsyncPaginate from "react-select-async-paginate"

import { PAGE_SIZE_DEFAULT } from "constants/requests"
import { entitiesStore } from "pik-react-utils/stores"
import { AsyncSelectOption, QueryArgs } from "types"

interface AsyncSelectProps {
  entityType: string
  isClearable?: boolean
  value?: AsyncSelectOption<IEntityLink> | null
  placeholder?: string
  filters?: QueryArgs
  onChange?: (option?: AsyncSelectOption | null) => void
  field?: {
    label?: string | string[]
    search?: string
  }
  defaultValue?: AsyncSelectOption<IEntityLink> | undefined
  openMenuOnFocus?: boolean
}

const MAX_COUNT = 5

export class AsyncSelectRef extends React.Component<AsyncSelectProps> {
  private option: AsyncSelectOption | null | undefined
  private requestCount = 0
  private readonly selectRef: React.RefObject<{
    select: HTMLSelectElement
  }> = React.createRef()
  private store = entitiesStore

  private reactionDisposer = reaction(
    () => this.props.value,
    (option) => {
      if (option?.value !== this.option?.value) {
        this.onChange(option)
      }
    }
  )

  constructor(props: AsyncSelectProps) {
    super(props)
    makeObservable<
      AsyncSelectRef,
      "option" | "requestCount" | "loadOptions" | "onChange"
    >(this, {
      option: observable,
      requestCount: observable,
      loadOptions: action.bound,
      onFocus: action.bound,
      onChange: action.bound,
    })
  }

  componentWillUnmount(): void {
    this.reactionDisposer?.()
  }

  private label<T extends IEntity>(entity: T): string {
    const { field } = this.props
    if (!field?.label) return entity["full_name"] ?? entity.name
    if (Array.isArray(field?.label)) {
      return field.label.map((item) => entity[item]).join(" / ")
    }
    return entity[field?.label]
  }

  public onFocus() {
    this.selectRef.current?.select?.focus()
  }

  private async loadOptions(
    search: string,
    _: unknown,
    additional: { page: number }
  ) {
    if (this.requestCount >= MAX_COUNT) {
      return { options: [], hasMore: false }
    }

    try {
      const { filters = {}, field, entityType } = this.props
      const page = additional.page
      if (field?.search) {
        filters[field.search] = search
      } else {
        filters.search = search
      }
      const response = await this.store.getList(
        entityType,
        { page, page_size: PAGE_SIZE_DEFAULT, ordering: "name", ...filters },
        { handleError: false }
      )

      const options =
        response?.entities?.map((entity) => ({
          value: entity,
          label: this.label(entity),
        })) ?? []

      if (!search && options.length === 1) {
        this.onChange(options[0])
      }

      return {
        options: [
          {
            label: "Не выбрано",
            value: null,
          },
          ...options,
        ],
        hasMore: Boolean(response?.meta.page_next),
        additional: { page: page + 1 },
      }
    } catch (e) {
      this.requestCount++
      return { options: [], hasMore: false, additional: { page: 0 } }
    }
  }

  private onChange(option?: AsyncSelectOption | null) {
    this.option = option
    this.props.onChange?.(option)
  }

  render() {
    const { placeholder, isClearable, defaultValue, filters } = this.props

    return (
      <AsyncPaginate
        key={JSON.stringify(filters)}
        selectRef={this.selectRef}
        value={this.option}
        placeholder={placeholder}
        onChange={this.onChange}
        isClearable={isClearable}
        defaultOptions
        openMenuOnFocus
        loadOptions={this.loadOptions}
        additional={{ page: 1 }}
        debounceTimeout={300}
        defaultValue={defaultValue}
        loadOptionsOnMenuOpen
      />
    )
  }
}

export const AsyncSelect = React.forwardRef(function (
  props: AsyncSelectProps,
  ref: React.LegacyRef<AsyncSelectRef>
) {
  const AsyncSelect = observer(AsyncSelectRef)
  return <AsyncSelect {...props} ref={ref} />
})
