import { object, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

interface IService extends IEntity {
  slug: string
  type: IEntityLink
  group: IEntityLink
}

export class Service extends BaseEntity implements IService {
  slug: string
  type: IEntityLink
  group: IEntityLink
  measure_type: { _uid: string; name: string }
}

createSerializrSchema(Service, null, {
  slug: primitive(),
  type: object(BaseEntity),
  group: object(BaseEntity),
  measure_type: object(BaseEntity),
})
