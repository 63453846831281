import { object, raw } from "serializr"

import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { Building } from "./building"
import { BuildingPart } from "./buildingpart"
import { ElevatorModel } from "./elevatormodel"
import { ManufacturingPlant } from "./manufacturingplant"
import { UtilitySystemType } from "./utilitysystemtype"

const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "НСИ ID" },
      name: { name: "Заводской номер", editable: true },
      visible_name: { name: "Сокращенное наименование" },
      full_name: { name: "Полное наименование" },
    },
  },

  group_placement: {
    name: "Расположение",
    children: {
      building: {
        name: "Здание",
        _type: "entitylink",
        entityLinkTo: Building,
        editable: true,
      },
      building_part: {
        name: "Подъезд",
        _type: "entitylink",
        entityLinkTo: BuildingPart,
        editable: true,
      },
    },
  },

  group_parameters: {
    name: "Характеристики",
    children: {
      manufacturing_plant: {
        name: "Завод изготовитель",
        _type: "entitylink",
        entityLinkTo: ManufacturingPlant,
        editable: true,
      },
      elevator_model: {
        name: "Модель лифта",
        _type: "entitylink",
        entityLinkTo: ElevatorModel,
        editable: true,
      },
      stop_count: { name: "Кол-во остановок", editable: true },
      speed: { name: "Скорость (м/с)", editable: true },
      lifting_capacity: { name: "Грузоподъемность (кг)", editable: true },
      inspection_date: {
        name: "Дата освидетельствования",
        _type: "date",
        editable: true,
      },
      manufacture_year: {
        name: "Год выпуска",
        _type: "number",
        editable: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class WheelChairElevator extends FullNameEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(WheelChairElevator, schema, {
  utility_system_type: object(UtilitySystemType),
  extra_attrs: raw(),
})
