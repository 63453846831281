import { object, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { TariffRecalcRequest } from "./tariffrecalcrequest"

export class TariffRecalcAttachment extends BaseEntity {
  attachment: string
  tariff_recalc_request: IEntityLink
  file_name: string
}

createSerializrSchema(TariffRecalcAttachment, null, {
  attachment: primitive(),
  tariff_recalc_request: object(TariffRecalcRequest),
  file_name: primitive(),
})
