import React from "react"
import { observer } from "mobx-react"
import { Button, Input, Switch } from "antd"
import { action, computed, makeObservable, observable } from "mobx"

import { Modal } from "modals"
import { SettingOutlined } from "components/antd"
import { QueryArgs } from "types"
import { EmploymentList } from "./EmploymentList"
import { EmployeesWrap, SearchWrap } from "./components"
import { ISwitchProps, PrivateFields } from "./types"

interface IEmploymentList {
  financialUnitId: string
  query: QueryArgs | undefined
}

export const Employees = observer(
  class Employees extends React.Component<IEmploymentList> {
    private search: string
    private isOpenSettingsModal: boolean
    private settings = {
      isLoadChildren: false,
      isShowFinancialEmployment: true,
      isShowJuristicEmployment: false,
    }

    constructor(props: IEmploymentList) {
      super(props)
      makeObservable<Employees, PrivateFields>(this, {
        search: observable.struct,
        isOpenSettingsModal: observable.struct,
        settings: observable,
        isSettingsChanged: computed.struct,
        settingOptions: computed.struct,
        changeIsLoadChildren: action.bound,
        changeIsShowFinancialEmployment: action.bound,
        changeIsShowJuristicEmployment: action.bound,
        openSettingsModal: action.bound,
        closeSettingsModal: action.bound,
        onSearch: action.bound,
      })
    }

    private get isSettingsChanged() {
      const {
        isLoadChildren,
        isShowFinancialEmployment,
        isShowJuristicEmployment,
      } = this.settings
      return (
        isLoadChildren || !isShowFinancialEmployment || isShowJuristicEmployment
      )
    }

    private get settingOptions(): ISwitchProps[] {
      const {
        isLoadChildren,
        isShowFinancialEmployment,
        isShowJuristicEmployment,
      } = this.settings
      return [
        {
          key: "loadChildren",
          label: "Показывать сотрудников дочерних подразделений",
          checked: isLoadChildren,
          onChange: this.changeIsLoadChildren,
        },
        {
          key: "financialEmployment",
          label: "Показывать финансовое трудоустройство",
          checked: isShowFinancialEmployment,
          onChange: this.changeIsShowFinancialEmployment,
        },
        {
          key: "juristicEmployment",
          label: "Показывать юридическое трудоустройство",
          checked: isShowJuristicEmployment,
          onChange: this.changeIsShowJuristicEmployment,
        },
      ]
    }

    private changeIsLoadChildren(value: boolean) {
      this.settings.isLoadChildren = value
    }

    private changeIsShowFinancialEmployment(value: boolean) {
      this.settings.isShowFinancialEmployment = value
    }

    private changeIsShowJuristicEmployment(value: boolean) {
      this.settings.isShowJuristicEmployment = value
    }

    private openSettingsModal() {
      this.isOpenSettingsModal = true
    }

    private closeSettingsModal() {
      this.isOpenSettingsModal = false
    }

    private onSearch(search: string) {
      this.search = search
    }

    render() {
      const organization =
        this.props.query?.organization_or_followers_organization__uid

      return (
        <>
          <EmployeesWrap>
            <SearchWrap>
              <Input.Search
                placeholder="Поиск"
                onSearch={this.onSearch}
                enterButton="Найти"
                allowClear
              />
              <Button
                type="primary"
                danger={this.isSettingsChanged}
                shape="round"
                icon={<SettingOutlined />}
                style={{ lineHeight: 0, marginLeft: "12px" }}
                onClick={this.openSettingsModal}
              />
            </SearchWrap>
            <EmploymentList
              organization={organization as string}
              search={this.search}
              financialUnitId={this.props.financialUnitId}
              settings={this.settings}
            />
          </EmployeesWrap>
          <Modal
            title="Настройки списка сотрудников"
            open={this.isOpenSettingsModal}
            footer={<Button onClick={this.closeSettingsModal}>Закрыть</Button>}
            onCancel={this.closeSettingsModal}
          >
            {this.settingOptions.map(({ key, onChange, checked, label }) => (
              <p key={key}>
                <Switch
                  size="small"
                  id={key}
                  onChange={onChange}
                  checked={checked}
                />
                &nbsp;
                <label key={key} htmlFor={key}>
                  {label}
                </label>
              </p>
            ))}
          </Modal>
        </>
      )
    }
  }
)
