import { isEqual } from "lodash"
import { isBaseEntity } from "pik-react-utils/entities"

export function shouldUpdateEntity(
  loaded: IRawEntity,
  cached?: IRawEntity
): boolean {
  if (!cached || !isBaseEntity(cached)) {
    return true
  }

  if (loaded._uid !== cached._uid) {
    return false
  }

  if (loaded._version > cached._version) {
    return true
  }

  if (Object.keys(loaded).length > Object.keys(cached).length) {
    return true
  }

  if (loaded._version === cached._version) {
    for (const key in loaded) {
      if (["_version", "_uid", "_type"].includes(key)) continue
      if (isEqual(loaded[key], cached[key])) continue

      return true
    }
  }

  return false
}
