import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

import { Building } from "../building"
import { schema } from "./schema"

export class Meeting extends BaseEntity {
  static readonly schema: ISchema = schema

  building: Building
}

createSerializrSchema(Meeting, schema)
