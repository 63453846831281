import { makeObservable } from "mobx"
import { object } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { addFieldsInClass, generateObservableFieldsBySchema } from "utils/model"

import { schema } from "./schema"
import { BuildingChangeAttachment } from "../buildingchangeattachment"
import { BuildingSnapshot } from "../buildingsnapshot"

export class BuildingChange extends BaseEntity {
  static readonly schema: ISchema = schema

  document_date: string
  document_name: string
  document_link: string
  readonly document_fields: string[]
  document_is_draft: boolean

  attachment: BuildingChangeAttachment
  readonly snapshot: BuildingSnapshot
  building: BaseEntity | null

  constructor(props: BaseEntity) {
    super(props)
    addFieldsInClass.call(this, schema)
    makeObservable(this, generateObservableFieldsBySchema(schema))
  }
}

createSerializrSchema(BuildingChange, schema, {
  attachment: object(BuildingChangeAttachment),
  snapshot: object(BuildingSnapshot),
  building: object(BaseEntity),
})
