import { ObjWithType } from "pik-react-utils/types"

export const isBaseEntity = <T extends IEntityLink = IEntityLink>(
  arg: unknown
): arg is T =>
  Boolean(arg && "object" === typeof arg && "_type" in arg && "_uid" in arg)

export const objWithType = (arg: unknown): arg is ObjWithType =>
  Boolean(arg && "object" === typeof arg && "_type" in arg)

export const validateEntity = <T extends IEntityLink = IEntityLink>(
  arg: unknown
): T | undefined => (isBaseEntity<T>(arg) ? arg : undefined)

export const getLink = (entity: IEntityLink | unknown) => {
  if (!isBaseEntity(entity)) {
    throw new Error(
      "Can not extract entityLink from entity without _uid or _type"
    )
  }
  return { _type: entity._type, _uid: entity._uid }
}
