import * as Sentry from "@sentry/browser"
import { SentryConfig } from "./types"

type IUser = {
  nickname: string | undefined
}

class SentryErrorHandler {
  private enabled = false

  get isEnabled(): boolean {
    return this.enabled
  }

  // Init error handling
  init({ dsn, release, environment }: SentryConfig): void {
    if (!dsn) return

    Sentry.init({
      dsn,
      release,
      environment,
      integrations: [
        new Sentry.Integrations.GlobalHandlers({
          onerror: true,
          onunhandledrejection: false,
        }),
      ],
      tracesSampleRate: 1.0,
    })
    this.enabled = true
  }

  // Set current user info
  setUser(user: IUser): void {
    if (!this.enabled) return
    Sentry.configureScope((scope) => scope.setUser(user))
  }

  // Unset current user info
  clearSentry(): void {
    if (!this.enabled) return
    Sentry.configureScope((scope) => scope.clear())
  }

  // Send error immidiatly
  setError(message: string, data: Record<string, unknown> | null): void {
    if (!this.enabled) return

    Sentry.configureScope((scope) => {
      scope.setContext("error", data)
      Sentry.captureMessage(message)
    })
  }
}

export const sentry = new SentryErrorHandler()
