import { makeObservable, observable } from "mobx"
import { primitive } from "serializr"

import { PersonEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

interface IUser extends IEntity {
  username: string
}

export class User extends PersonEntity implements IUser {
  username: string

  constructor(props: PersonEntity) {
    super(props)
    makeObservable(this, { username: observable })
  }
}

createSerializrSchema(User, User.schema, { username: primitive() })
