import React, { useState } from "react"
import { Button, Empty, Tabs } from "antd"
import { observer } from "mobx-react"

import { DeleteOutlined } from "components/antd"
import { AgGridInspector } from "pik-react-utils/components"
import { AttentionModal } from "modals"
import { FinancialUnitEntity } from "models"
import { entitiesStore } from "stores"
import { QueryArgs } from "types"
import { Employees, Rules, Organizations } from "./Tabs"

type FinUnitInspectorProp = {
  financialUnit?: FinancialUnitEntity
  refresh: () => void
  query: QueryArgs | undefined
}

export const FinUnitInspector = observer(
  ({ financialUnit, refresh, query }: FinUnitInspectorProp) => {
    const [store] = useState(() => entitiesStore)
    const [isOpenModal, setOpenModal] = useState(false)

    const deleteEntity = async () => {
      if (!financialUnit) return
      await store.deleteEntity(financialUnit)
      setOpenModal(false)
      refresh()
    }

    const rightTabContent = () => (
      <Button
        danger
        icon={<DeleteOutlined />}
        onClick={() => setOpenModal(true)}
      >
        Удалить
      </Button>
    )

    if (!financialUnit) {
      return <Empty description="Выберите подразделение" />
    }

    return (
      <>
        <Tabs
          size="small"
          defaultActiveKey="reference"
          tabBarExtraContent={{ right: rightTabContent() }}
          style={{ height: "100%" }}
          items={[
            {
              key: "reference",
              label: "Характеристики",
              children: <AgGridInspector entityLink={financialUnit} />,
            },
            {
              key: "employee",
              label: "Сотрудники МВЗ",
              children: (
                <Employees financialUnitId={financialUnit._uid} query={query} />
              ),
            },
            {
              key: "inventory",
              label: "Объекты учёта",
              children: <Rules financialUnit={financialUnit} />,
            },
            {
              key: "organization",
              label: "Организации",
              children: <Organizations financialUnitId={financialUnit._uid} />,
            },
          ]}
        />
        <AttentionModal
          open={isOpenModal}
          onOk={deleteEntity}
          onCancel={() => setOpenModal(false)}
        >
          Вы действительно хотите удалить узел: `{financialUnit.name}`?
        </AttentionModal>
      </>
    )
  }
)
