import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

import { Building } from "../building"
import { BuildingPart } from "../buildingpart"
import { ElevatorModel } from "../elevatormodel"
import { ManufacturingPlant } from "../manufacturingplant"
import { schema } from "./schema"

export class Elevator extends FullNameEntity {
  static readonly schema: ISchema = schema

  building: Building
  building_part: BuildingPart
  commissioning_date: string
  declaration_number: string
  declaration_date: string
  manufacture_year: number
  declaration_applicant_info: string
  declaration_applicant_ogrn: string
  elevator_model: ElevatorModel
  manufacturing_plant: ManufacturingPlant
}

createSerializrSchema(Elevator, schema)
