export const PIVOT_ENTITIES = [
  { label: "Здания", value: "inventorybuilding" },
  { label: "Подъезды", value: "livingentrance" },
  { label: "Лифты", value: "elevator" },
  { label: "Подъемники для инвалидов", value: "wheelchairelevator" },
  { label: "Помещения", value: "premise" },
  { label: "Дороги", value: "road" },
  { label: "Плейхабы", value: "playhub" },
  { label: "Прилегающие территории", value: "adjacentarea" },
  { label: "Заявки ОСС", value: "meetingrequest" },
  { label: "Проекты-Собрания", value: "meeting" },
  { label: "Росреестр Собственники помещений", value: "rosreestrright" },
  { label: "Росреестр Выписки", value: "rosreestrstatement" },
  { label: "Оценки руководителей ТО", value: "financialunitassessment" },
]

export const EXCLUDED_PIVOT_FIELDS = {
  inventorybuilding: ["certificate_number"],
}
