import { object, primitive } from "serializr"

import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupNameFields } from "utils/fields"

import { Building } from "./building"
import { BuildingPart } from "./buildingpart"
import { PremiseType } from "./premisetype"

const schema: ISchema = {
  ...groupNameFields(),

  group_placement: {
    name: "Расположение",
    children: {
      conditional_number: { name: "Условный номер (по данным застройщика)" },
      floor: { name: "Номер этажа" },
    },
  },

  group_parameters: {
    name: "Характеристики",
    children: {
      room_count: { name: "Кол-во комнат" },
      common_space: { name: "Общая площадь" },
      living_space: { name: "Площадь жилых помещений" },
      nonliving_space: { name: "Площадь нежилых помещений" },
      summer_space: { name: "Площадь летних помещений, м²" },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class InventoryPremise extends FullNameEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(InventoryPremise, schema, {
  building: object(Building),
  building_part: object(BuildingPart),
  premise_type: object(PremiseType),
  pay_space: primitive(),
})
