import React from "react"

export const CorporateForDirector = () => (
  <ul>
    <li>
      После официального трудоустройства логин и временный пароль для нового
      сотрудника приходит на почту <b>непосредственному руководителю.</b>
      <br />*
      <i>
        Если руководитель не получил логин/пароль, то необходимо связаться со
        службой технической поддержки
      </i>
      &nbsp;-&nbsp;(7777@pik&#8209;comfort.ru).
    </li>
    <li>
      <b>Руководитель</b> должен определить какие ещё доступы требуются новому
      сотруднику для работы и сделать заявки на их предоставление.
    </li>
    <li>
      <b>Руководитель</b> должен о сделать заявку на удаленный доступ к
      корпоративным ресурсам для нового сотрудника.
    </li>
    <li>
      <b>Руководитель</b> должен связаться со специалистом по учету
      товарно-материальных ценностей (контактное лицо - Алексей Новиков
      8&#8209;925&#8209;390&#8209;10&#8209;34) для уточнения даты и места
      получения корпоративного ПК
    </li>
    <li>
      Сотрудник должен войти в корпоративный ПК под своим логином и временным
      паролем и сменить его. <br />
      <i>
        *Требования к новому паролю: минимальная длина пароля 10 символов,
        необходимо использовать большие и маленькие буквы и цифры.
      </i>
    </li>
    <li>
      Сотрудник должен подключить корпоративный ПК к домашней сети интернет.
      Зайти на корпоративный портал home.pik-comfort.ru под своим логином и
      паролем и ознакомиться с инструкциями по настройке удаленного
      доступа:&nbsp;
      <a
        href="https://home.pik-comfort.ru/instructions/corporate/"
        target="_blank"
        rel="noreferrer"
      >
        https://home.pik&#8209;comfort.ru/instructions/corporate/
      </a>
    </li>
    <li>
      Настроить софтфон для корпоративной связи согласно инструкции:&nbsp;
      <a
        href="https://home.pik-comfort.ru/instructions/telephony/"
        rel="noreferrer"
      >
        https://home.pik&#8209;comfort.ru/instructions/telephony/
      </a>
    </li>
    <li>
      Зайти в свою корпоративную почту под логином
      (вашлогин@pik&#8209;comfort.ru) и постоянным паролем здесь:&nbsp;
      <b>mail.google.com</b>
    </li>
  </ul>
)
