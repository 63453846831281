import {
  OBJECT_CATEGORYID_CHOICES,
  OBJECT_KINDID_CHOICES,
  OBJECT_TYPEID_CHOICES,
} from "constants/choices"
import { NSI_SERVICE_UID, MDS_OBJECT_RVOE } from "constants/subscribe-groups"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

export const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "ЕФС UID" },
      mds_id: { name: "Идентификатор MDS" },
      name: { name: "Наименование" },
      commercialname: { name: "Коммерческое наименование" },
      shortname: { name: "Краткое наименование" },
      comment: { name: "Комментарий" },
      code1c: { name: "Код" },
    },
  },

  group_class: {
    name: "Классификация",
    children: {
      objectcategoryid: {
        name: "Категория объекта ИДП",
        _type: "choices",
        choices: OBJECT_CATEGORYID_CHOICES,
      },
      objectkindid: {
        name: "Вид объекта ИДП",
        _type: "choices",
        choices: OBJECT_KINDID_CHOICES,
      },
      objecttypeid: {
        name: "Тип объекта ИДП",
        _type: "choices",
        choices: OBJECT_TYPEID_CHOICES,
      },
    },
  },

  group_other: {
    name: "Прочее",
    children: {
      corps: { name: "Корпус" },
      receiptplanneddatervoe: {
        name: "Плановая дата получения РВОЭ",
        _type: "date",
      },
      objectseriesid: { name: "Серия объекта ИДП" },
      active: { name: "Признак активности" },
      districtid: { name: "Район" },
      realobjectid: { name: "Реальный объект ИДП" },
      directionid: { name: "Направление (Москва, МО, Регионы)" },
      countryid: { name: "Страна мира" },
      regionid: { name: "Регион" },
      cityid: { name: "Город" },
      projectid: { name: "Площадка (проект)" },
      livingcomplexid: { name: "Площадка (комплекс)" },
      requirementofbimpdid: { name: "Версия BIM из проектной документации" },
      requirementofbimwdid: { name: "Версия BIM из рабочей документации" },
      queue: { name: "Очередь" },
      roomtype: { name: "Тип помещения" },
      objectencumbrance: { name: "Это Объект обременения", _type: "boolean" },
      containsbuiltinskb: { name: "Содержит встроенный СКБ", _type: "boolean" },
      objectprojecttypeid: { name: "Тип проекта" },
      houseid: { name: "Дом" },
      datezos: { name: "Дата ЗОС", _type: "date" },
      participantdeadlineddu: { name: "Срок передачи участнику ДДУ" },
      dateresidentialzos: { name: "Дата получения ЗОС жилое", _type: "date" },
      datenonresidentialzos: {
        name: "Дата получения ЗОС нежилое",
        _type: "date",
      },
      dateparkingzos: { name: "Дата получения ЗОС паркинг", _type: "date" },
      addresspost: { name: "Почтовый адрес" },
      addresspostfull: { name: "Полный адрес почтовый" },
      addressbuilding: { name: "Строительный адрес из документации" },
      startdate: { name: "Дата начала использования", _type: "date" },
      enddate: { name: "Дата окончания использования", _type: "date" },
      escrowmovement: {
        name: "Наличие поступление на счет эскроу",
        _type: "boolean",
      },
      isrenovation: { name: "Реновация", _type: "boolean" },
      contractid: { name: "Ссылка на договор эскроу по объекту" },
      isescrow: { name: "Эскроу", _type: "boolean" },
      projectfinancing: { name: "Проектное финансирование", _type: "boolean" },
      escrowaccountsalesplanned: {
        name: "Планируются продажи по счетам эскроу",
        _type: "boolean",
      },
    },
  },

  group_rvoe: {
    name: "Фактическая дата РВОЭ",
    subscribeData: {
      target_service: NSI_SERVICE_UID,
      permission_group: MDS_OBJECT_RVOE,
    },
    children: {
      parkingreceiptdatervoe: { name: "РВОЭ паркинг", _type: "date" },
      residentialreceiptdatervoe: { name: "РВОЭ жилое", _type: "date" },
      nonresidentialreceiptdatervoe: { name: "РВОЭ нежилое", _type: "date" },
    },
  },

  group_stats: {
    name: "Статистика",
    children: {
      min_floor_count: { name: "Минимальная этажность", _type: "number" },
      max_floor_count: { name: "Максимальная этажность", _type: "number" },
      premise_count: { name: "Помещения (кол-во)", _type: "number" },
      premise_space: { name: "Помещения (площадь)", _type: "decimal" },
      residential_premise_count: { name: "Квартиры (кол-во)", _type: "number" },
      residential_premise_space: {
        name: "Квартиры (площадь)",
        _type: "decimal",
      },
      non_residential_premise_count: {
        name: "Нежилые (кол-во)",
        _type: "number",
      },
      non_residential_premise_space: {
        name: "Нежилые (площадь)",
        _type: "decimal",
      },
      parking_place_count: { name: "Машиноместа (кол-во)", _type: "number" },
      parking_place_space: { name: "Машиноместа (площадь)", _type: "decimal" },
      storage_room_count: { name: "Кладовые (кол-во)", _type: "number" },
      storage_room_space: { name: "Кладовые (площадь)", _type: "decimal" },
      apartment_count: { name: "Апартаменты (кол-во)", _type: "number" },
      apartment_space: { name: "Апартаменты (площадь)", _type: "decimal" },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const
