import { COLOR_LINK_HOVER, COLOR_MAIN_TEXT } from "constants/ui-colors"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const TwoColumnLayout = styled.section`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 12px;
  column-gap: 12px;
`

export const ColumnWithHeader = styled.div`
  display: grid;
  grid-template-areas:
    "header"
    "content";
  grid-template-rows: auto 1fr;
  row-gap: 12px;
  overflow: hidden;
`

export const ColumnHeader = styled.header`
  grid-area: header;
  display: flex;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 12px;
  }
`

export const FormLabel = styled.label`
  color: rgba(51, 51, 51, 0.56);
  font-size: 13px;
  font-family: ProbaProMedium;
  letter-spacing: 0.8px;
`

export const RouterLink = styled(Link)`
  color: ${COLOR_MAIN_TEXT};

  &:hover {
    color: ${COLOR_LINK_HOVER};
  }
`
