export function fieldsFromURL(search = "") {
  if (!search) return []

  const prepareSearch = search
    .replaceAll(/(\w+){/g, "")
    .replaceAll(/,(\w+){/g, ",")
    .replace("}", "")

  return prepareSearch.split(",")
}
