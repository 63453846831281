export interface Media {
  Desktop_XL: string
  Desktop_L: string
  Desktop_M: string
  Desktop_S: string
}

export const MEDIA = {} as Media

export const SIZES = {
  Desktop_XL: 1920,
  Desktop_L: 1440,
  Desktop_M: 1280,
  Desktop_S: 1024,
}

Object.entries(SIZES).forEach(([key, size]) => {
  const query = `screen and (max-width: ${size}px)`
  MEDIA[key] = query
})
