import React from "react"
import { observer } from "mobx-react"
import { Button } from "antd"
import { action, computed, makeObservable, observable } from "mobx"
import {
  CellClickedEvent,
  CellDoubleClickedEvent,
  ColDef,
  ColGroupDef,
  SelectionChangedEvent,
} from "ag-grid-community"

import { INVENTORY_ACCESS_DESCRIPTION } from "constants/description"
import {
  NSI_SERVICE_UID,
  MDS_OBJECT_ALL_ATTRS_GROUP,
} from "constants/subscribe-groups"
import { AccessPortal, QueryPanel } from "components"
import { ColumnHeader } from "components/css"
import { CreateEntityModal } from "modals"
import { BuildingChangeEntity, MDSObjectEntity } from "models"
import { AgGridViewport, AgGridInspector } from "pik-react-utils/components"
import { ColumnContent } from "./css"
import { InventoryMDSStore } from "./InventoryMDSStore"
import { defaultMDSObject } from "./utils"
import { PrivateFields } from "./types"

const SUBSCRIBABLE_ENTITY = {
  mdsobject: {
    target_service: NSI_SERVICE_UID,
    permission_group: MDS_OBJECT_ALL_ATTRS_GROUP,
  },
}
export const InventoryMDSObjects = observer(
  class InventoryMDSObjects extends React.Component {
    private entity: IEntityLink
    private selectionRows: MDSObjectEntity[] = []
    private isOpen: boolean
    private mdsstore = new InventoryMDSStore()

    constructor(props: Record<string, unknown>) {
      super(props)
      makeObservable<InventoryMDSObjects, PrivateFields>(this, {
        entity: observable,
        selectionRows: observable,
        isOpen: observable.struct,
        query: computed,
        defaultValues: computed,
        onCellClicked: action.bound,
        openModal: action.bound,
        closeModal: action.bound,
        onCreate: action.bound,
        onCellDoubleClicked: action.bound,
        onSelectionChanged: action.bound,
      })
    }

    private get query() {
      return this.mdsstore.query
    }

    private get defaultValues() {
      return this.selectionRows.length && this.isOpen
        ? defaultMDSObject(this.selectionRows)
        : {}
    }

    private getColumnDefs(coldef: (ColDef | ColGroupDef)[]) {
      if (coldef[0]) {
        coldef[0] = { ...coldef[0], checkboxSelection: true, width: 100 }
      }
      return coldef
    }

    private onCellClicked(event: CellClickedEvent) {
      const node = event.api.getDisplayedRowAtIndex(event.rowIndex)
      if (this.entity?._uid !== node.data?._uid) {
        this.entity = node.data
      }
    }

    private openModal() {
      this.isOpen = true
    }

    private closeModal(isCreated?: boolean) {
      if (isCreated) {
        this.onCreate()
      } else {
        this.isOpen = false
      }
    }

    private onCreate(entity?: BuildingChangeEntity) {
      this.isOpen = false
      this.mdsstore.onSuccess(entity)
    }

    onSelectionChanged(event: SelectionChangedEvent) {
      this.selectionRows = event.api.getSelectedRows()
    }

    onCellDoubleClicked(event: CellDoubleClickedEvent) {
      event.node.setSelected(!event.node.isSelected())
    }

    render() {
      return (
        <AccessPortal
          entityType={this.mdsstore.entityType}
          title={INVENTORY_ACCESS_DESCRIPTION.title}
          description={INVENTORY_ACCESS_DESCRIPTION.description}
        >
          <ColumnContent>
            <ColumnHeader>
              <QueryPanel
                entityType={this.mdsstore.entityType}
                store={this.mdsstore}
                input={{ placeholder: "Объект ИДП" }}
              />
              <Button
                onClick={this.openModal}
                disabled={!this.selectionRows.length}
              >
                Создать
              </Button>
            </ColumnHeader>
            <div />
            <AgGridViewport
              entityType={this.mdsstore.entityType}
              query={this.query}
              rowSelection="multiple"
              getColumnDefs={this.getColumnDefs}
              onSelectionChanged={this.onSelectionChanged}
              onCellClicked={this.onCellClicked}
              onCellDoubleClicked={this.onCellDoubleClicked}
              suppressRowClickSelection
              autoColumnGroups
              visibleFields={[
                "objectkindid",
                "name",
                "premise_count",
                "premise_space",
                "parkingreceiptdatervoe",
                "residentialreceiptdatervoe",
                "nonresidentialreceiptdatervoe",
                "min_floor_count",
                "max_floor_count",
              ]}
            />

            <AgGridInspector
              subscribableData={SUBSCRIBABLE_ENTITY[this.entity?._type]}
              entityLink={this.entity}
            />
          </ColumnContent>
          <CreateEntityModal
            isDocument
            entityType="buildingchange"
            title="Новый объект учета"
            open={this.isOpen}
            onClose={this.closeModal}
            defaultValues={this.defaultValues}
            onSuccess={this.onCreate}
          />
        </AccessPortal>
      )
    }
  }
)
