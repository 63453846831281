import React, { PropsWithChildren } from "react"
import * as Sentry from "@sentry/react"
import { Button, ConfigProvider } from "antd"
import { Locale } from "antd/lib/locale"

import { Info } from "./components"

const ErrorBoundary = ({ error }: { error: Error }) => (
  <Info>
    <div>
      <h1>Что-то пошло не так.</h1>
      <p>Наша команда работает над этим.</p>
      <p>{error.message}</p>
      <br />
      <Button type="primary" onClick={() => window.location.reload()}>
        Обновить
      </Button>
    </div>
  </Info>
)

export const PikContainer = ({
  children,
  locale,
}: PropsWithChildren<{ locale: Locale }>) => (
  <ConfigProvider locale={locale}>
    <Sentry.ErrorBoundary
      fallback={({ error }: { error: Error }) => (
        <ErrorBoundary error={error} />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  </ConfigProvider>
)
