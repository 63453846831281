import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupEditableNameFields } from "utils/fields"

import { Building } from "./building"

const schema: ISchema = {
  ...groupEditableNameFields(),

  group_placement: {
    name: "Расположение",
    children: {
      building: {
        name: "Здание",
        _type: "entitylink",
        entityLinkTo: Building,
        editable: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
}

export class LivingEntrance extends FullNameEntity {
  static readonly schema: ISchema = schema

  building: Building
}

createSerializrSchema(LivingEntrance, schema)
