import {
  IServerSideGetRowsParams,
  IServerSideDatasource,
} from "ag-grid-community"

import {
  InventoryBuildingEntity,
  InventoryBuildingPartEntity,
  BuildingEntity,
} from "models"
import { agGridPageQuery } from "pik-react-utils/utils/agGrid"
import { entitiesStore } from "stores"
import { QueryArgs } from "types"
import {
  AVAILABLE_SECOND_TYPENAME,
  AVAILABLE_THIRD_TYPENAME,
} from "./constants"
import { GroupDataItem, SecondTypeName, ThirdTypeName } from "./types"
import { QueryByType, entityName, entityTypeName } from "./utils"

export class Datasource implements IServerSideDatasource {
  private query: Record<string, unknown>
  private store = entitiesStore

  constructor({ query }: { query: QueryArgs }) {
    this.query = query
  }

  private async fetchBuildingList(params: IServerSideGetRowsParams) {
    const response = await this.store.getList<InventoryBuildingEntity>(
      "inventorybuilding",
      {
        ...agGridPageQuery(params.request.endRow),
        ...this.query,
        query: QueryByType.building,
      }
    )

    if (!response?.entities) return
    params.successCallback(
      response.entities.map((entity) =>
        Object.assign(entity, {
          groupKey: entity._uid,
          isGroup: entity.totalGroupCount,
        })
      ),
      response.meta.count
    )
  }

  private fetchBuildingEntityTypeNameList(params: IServerSideGetRowsParams) {
    const rows: GroupDataItem[] = []
    const building = params.parentNode.data as BuildingEntity

    const groups = [
      { name: "Части ОЭ", groupKey: "building_part" },
      { name: "Инженерные системы", groupKey: "utility_system" },
      { name: "Помещения", groupKey: "premise" },
    ]

    groups.forEach((item) => {
      if (!building[`${item.groupKey}_count`].amount) {
        return
      }
      rows.push({
        ...item,
        count: building[`${item.groupKey}_count`].amount,
        isGroup: true,
        isDeleted: building.isDeleted,
      })
    })

    params.successCallback(rows, rows.length)
  }

  private fetchThirdTypeNameList(params: IServerSideGetRowsParams) {
    const rows: GroupDataItem[] = []

    const buildingPart = params.parentNode.data as InventoryBuildingPartEntity
    const groups = [
      { name: "Инженерные системы", groupKey: "utility_system" },
      { name: "Помещения", groupKey: "premise" },
    ]

    groups.forEach((item) => {
      if (!buildingPart[`${item.groupKey}_count`].amount) {
        return
      }
      rows.push({
        ...item,
        count: buildingPart[`${item.groupKey}_count`].amount,
        isGroup: true,
        isDeleted: buildingPart.isDeleted,
      })
    })

    params.successCallback(rows, rows.length)
  }

  private async fetchSecondTypeList(
    type: SecondTypeName,
    params: IServerSideGetRowsParams
  ) {
    if (!params.parentNode.parent) {
      return
    }
    const queryFromType = {
      utility_system: { link_type__in: "all,building" },
      premise: { link_type__in: "all,building" },
    }

    const building = params.parentNode.parent.data as BuildingEntity
    const query = {
      ...agGridPageQuery(params.request.endRow),
      ordering: "name",
      uid__in: building[`${type}_count`].ids.join(","),
      is_deleted: false,
      ...queryFromType[type],
    }

    const response = await this.store.getList(entityTypeName(type), query)

    if (!response?.entities) return
    params.successCallback(
      response.entities.map((entity) =>
        Object.assign(entity, {
          count: building[`${type}_count`][entity._uid],
          isGroup: true,
          groupKey: entity._uid,
        })
      ),
      response.meta.count
    )
  }

  private async fetchThirdTypeList(
    type: ThirdTypeName,
    params: IServerSideGetRowsParams
  ) {
    if (!params.parentNode.parent) {
      return
    }
    const buildingPart = params.parentNode.parent
      .data as InventoryBuildingPartEntity
    const query = {
      ...agGridPageQuery(params.request.endRow),
      ordering: "name",
      link_type__in: "all,buildingpart",
      uid__in: buildingPart[`${type}_count`].ids.join(","),
      is_deleted: false,
    }

    const response = await this.store.getList(entityTypeName(type), query)

    if (!response?.entities) return
    params.successCallback(
      response.entities.map((entity) =>
        Object.assign(entity, {
          count: buildingPart[`${type}_count`][entity._uid],
          isGroup: true,
          groupKey: entity._uid,
        })
      ),
      response.meta.count
    )
  }

  private async fetchSecondList(
    {
      type,
      buildingId,
      entityTypeId,
    }: { buildingId: string; entityTypeId: string; type: SecondTypeName },
    params: IServerSideGetRowsParams
  ) {
    const queryFromType = {
      building_part: {
        building_part_type__uid: entityTypeId,
        query: QueryByType.building_part,
      },
      utility_system: {
        utility_system_type__uid: entityTypeId,
        building_part__uid__isnull: true,
        query: QueryByType.utility_system,
      },
      premise: {
        premise_type__uid: entityTypeId,
        building_part__uid__isnull: true,
        ordering: "number_index",
        query: QueryByType.premise,
      },
    }

    const query = {
      ...agGridPageQuery(params.request.endRow),
      building__uid: buildingId,
      is_deleted: false,
      ordering: "name",
      ...queryFromType[type],
    }

    const response = await this.store.getList<InventoryBuildingPartEntity>(
      entityName(type),
      query
    )

    const building = await this.store.getEntity<InventoryBuildingEntity>({
      _type: "inventorybuilding",
      _uid: buildingId,
    })

    if (!response?.entities) return
    params.successCallback(
      response.entities.map((entity) =>
        Object.assign(entity, {
          building,
          groupKey: entity._uid,
          isGroup: entity.totalGroupCount,
        })
      ),
      response.meta.count
    )
  }

  private async fetchThirdList(
    {
      type,
      buildingId,
      buildingPartId,
      entityTypeId,
    }: {
      type: ThirdTypeName
      buildingId: string
      buildingPartId: string
      entityTypeId: string
    },
    params: IServerSideGetRowsParams
  ) {
    const queryFromType = {
      utility_system: {
        utility_system_type__uid: entityTypeId,
        query: QueryByType.utility_system,
      },
      premise: {
        premise_type__uid: entityTypeId,
        ordering: "number_index",
        query: QueryByType.premise,
      },
    }

    const query = {
      ...agGridPageQuery(params.request.endRow),
      building_part__uid: buildingPartId,
      ordering: "name",
      is_deleted: false,
      ...queryFromType[type],
    }

    const response = await this.store.getList(entityName(type), query)
    const building = await this.store.getEntity<InventoryBuildingEntity>({
      _type: "inventorybuilding",
      _uid: buildingId,
    })

    if (!response?.entities) return
    params.successCallback(
      response.entities.map((entity) => Object.assign(entity, { building })),
      response.meta.count
    )
  }

  getRows(params: IServerSideGetRowsParams): void {
    const [
      buildingId,
      secondTypeName,
      secondTypeId,
      secondId,
      thirdTypeName,
      thirdTypeId,
      thirdId,
    ] = params.request.groupKeys

    if (!buildingId) {
      this.fetchBuildingList(params)
      return
    }

    if (!secondTypeName) {
      this.fetchBuildingEntityTypeNameList(params)
      return
    }

    if (!AVAILABLE_SECOND_TYPENAME.includes(secondTypeName)) {
      return
    }

    if (!secondTypeId) {
      this.fetchSecondTypeList(secondTypeName, params)
      return
    }

    if (!secondId) {
      this.fetchSecondList(
        { type: secondTypeName, buildingId, entityTypeId: secondTypeId },
        params
      )
      return
    }

    if (!thirdTypeName) {
      this.fetchThirdTypeNameList(params)
      return
    }

    if (!AVAILABLE_THIRD_TYPENAME.includes(thirdTypeName)) {
      return
    }

    if (!thirdTypeId) {
      this.fetchThirdTypeList(thirdTypeName, params)
      return
    }

    if (!thirdId) {
      this.fetchThirdList(
        {
          type: thirdTypeName,
          buildingId,
          buildingPartId: secondId,
          entityTypeId: thirdTypeId,
        },
        params
      )
      return
    }

    params.failCallback()
  }
}
