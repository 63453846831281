import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

import { Service } from "./service"
import { Regulation } from "./regulation"

const schema: ISchema = {
  name_by_invoice: { name: "Название" },
  service: {
    name: "Услуга",
    _type: "entitylink",
    entityLinkTo: Service,
  },
  regulation: {
    name: "Постановление",
    _type: "entitylink",
    entityLinkTo: Regulation,
  },
  value: { name: "Значение" },
} as const

export class ServiceRegulation extends BaseEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(ServiceRegulation, schema)
