import { observable, computed, makeObservable } from "mobx"
import { list, object, primitive } from "serializr"

import { BaseEntity, PersonEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { JuristicUnit } from "./juristicunit"
import { Organization } from "./organization"

type PropsEmployment = {
  employee: PersonEntity
  is_actual: boolean
  is_deleted: boolean
  employment_date: string
  dismissal_date: string | undefined
  juristic_unit: JuristicUnit
  juristic_units_list: string[]
  position_name: string
  organization: Organization
}

export class Employment extends BaseEntity implements PropsEmployment {
  is_actual: boolean
  dismissal_date: string
  employment_date: string
  juristic_units_list: string[]
  position_name: string
  organization: Organization
  employee: PersonEntity
  juristic_unit: JuristicUnit
  kind: string

  constructor(props: BaseEntity) {
    super(props)
    makeObservable(this, {
      is_actual: observable,
      dismissal_date: observable,
      employment_date: observable,
      juristic_units_list: observable,
      position_name: observable,
      organization: observable,
      employee: observable,
      juristic_unit: observable,
      kind: observable,
      breadcrumbFullName: computed,
    })
  }

  get breadcrumbFullName() {
    const { juristic_units_list, organization } = this
    const elements = [...juristic_units_list]
    if (organization?.name) elements.unshift(organization.name)
    return elements.join(" / ")
  }
}

createSerializrSchema(Employment, null, {
  is_actual: primitive(),
  dismissal_date: primitive(),
  employment_date: primitive(),
  juristic_units_list: list(primitive()),
  position_name: primitive(),
  organization: object(Organization),
  juristic_unit: object(JuristicUnit),
  employee: object(PersonEntity),
  kind: primitive(),
})
