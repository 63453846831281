import { primitive } from "serializr"

import {
  PREMISE_TYPE_CHOICES,
  ROSREEST_STATUS_CHOICES,
  ROSREEST_TYPE_CHOICES,
} from "constants/choices"
import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { Building } from "./building"
import { User } from "./user"

const schema: ISchema = {
  group_premise_parameters: {
    name: "Характеристики помещения",
    children: {
      building: {
        name: "Наименование ОУ",
        _type: "entitylink",
        entityLinkTo: Building,
      },
      number: { name: "Номер помещения" },
      certificate_number: {
        name: "Выписка из ЕГРН",
        _type: "rosreestrstatement",
        computed: ["certificate_number", "_uid"],
      },
      premise_type: { name: "Тип помещения" },
      premise_residential_type: {
        name: "Тип жилого помещения",
        _type: "choices",
        choices: PREMISE_TYPE_CHOICES,
      },
      common_space: { name: "Общая площадь помещения, м²" },
      premise_level: { name: "Этаж помещения" },
    },
  },

  group_rights: {
    name: "Зарегистрированные права",
    children: {
      glued_holder_names: { name: "Собственники" },
      right: { name: "Вид права" },
      holders_ownerships: { name: "Доли владения" },
      holders_certificate_numbers: {
        name: "Номера государственной регистрации права",
      },
      holders_certificate_dates: {
        name: "Даты государственной регистрации права",
      },
    },
  },

  group_cadastral: {
    name: "Кадастровый учет",
    children: {
      cadastral_id: { name: "Кадастровый номер" },
      cadastral_date: {
        name: "Дата присвоения кадастрового номера",
        _type: "date",
      },
      old_number: { name: "Ранее присвоенный государственный учетный номер" },
      cadastral_block_number: { name: "Номер кадастрового квартала" },
      cadastral_cost: { name: "Кадастровая стоимость" },
    },
  },

  group_additional_information: {
    name: "Выписка - дополнительная информация",
    children: {
      order_user: { name: "Заказчик", _type: "entitylink", entityLinkTo: User },
      status: {
        name: "Статус",
        _type: "choices",
        choices: ROSREEST_STATUS_CHOICES,
      },
      last_order_date: { name: "Заказано на rosreestr.gov.ru", _type: "date" },
      user_order_date: { name: "Заказанно пользователем", _type: "date" },
      registration_date: { name: "Дата выполнения заказа", _type: "date" },
      registration_number: { name: "Регистрационный номер выписки" },
      type: {
        name: "Тип выписки",
        _type: "choices",
        choices: ROSREEST_TYPE_CHOICES,
      },
    },
  },

  group_building_parameters: {
    name: "Характеристики здания",
    children: {
      area: { name: "Площадь, м²" },
      assignation: { name: "Назначение" },
      floors: { name: "Кол-во этажей, в том числе подземных этажей" },
      underground_floors: { name: "Кол-во подземных этажей" },
      outer_wall_material: { name: "Материал наружных стен" },
      year_built: {
        name: "Год ввода в эксплуатацию по завершении строительства",
      },
      year_used: { name: "Год завершения строительства" },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class RosReestrStatement extends BaseEntity {
  static readonly schema: ISchema = schema
  certificate_number: string
  status: string

  get statement_uid() {
    if (this.status !== "parsed") return
    return this._uid
  }
}

createSerializrSchema(RosReestrStatement, schema, {
  certificate_number: primitive(),
})
