import React from "react"
import { observer } from "mobx-react"
import { action, computed, makeObservable, observable } from "mobx"
import { ICellEditorParams } from "ag-grid-community"

import { AsyncSelect, AsyncSelectRef } from "components"
import { AsyncSelectOption } from "types"

interface IEntityLinkEditorProps extends ICellEditorParams {
  entityType: string
  isNull: boolean
  value: IEntityLink & { full_name: string }
  dependencies: { [field: string]: string | number | IEntity }
}

export const EntityLinkEditor = observer(
  class EntityLinkEditor extends React.Component<IEntityLinkEditorProps> {
    private option: AsyncSelectOption | null
    private width: number
    private ref: React.RefObject<AsyncSelectRef> = React.createRef()

    constructor(props: IEntityLinkEditorProps) {
      super(props)
      makeObservable<
        EntityLinkEditor,
        "option" | "width" | "typeSpecificQuery" | "onChange"
      >(this, {
        option: observable,
        width: observable.struct,
        typeSpecificQuery: computed,
        onChange: action.bound,
      })
    }

    private get typeSpecificQuery() {
      const { entityType, data, dependencies } = this.props
      if (entityType === "financialemployment") {
        return {
          is_actual: true,
          ordering:
            "employee__last_name,employee__first_name,employee__middle_name",
        }
      }

      if (entityType === "contractor") {
        return { ordering: "name", is_active: true }
      }

      if (
        entityType === "buildingpart" &&
        (data?._type === "elevator" || data?.slug === "building_part")
      ) {
        return {
          ordering: "name",
          building__uid: dependencies?.building?.["_uid"],
        }
      }

      return { ordering: "name" }
    }

    public getValue() {
      return this.option?.value ?? null
    }

    public isPopup() {
      return true
    }

    public afterGuiAttached() {
      const { value, column } = this.props
      this.option = value ? { value, label: value.full_name } : null
      this.width = column.getActualWidth()
      this.ref.current?.onFocus()
    }

    private onChange(option: AsyncSelectOption | null) {
      this.option = option
    }

    render() {
      const { isNull, entityType } = this.props

      return (
        <div style={{ width: this.width }}>
          <AsyncSelect
            ref={this.ref}
            placeholder="Выбрать"
            entityType={entityType}
            filters={this.typeSpecificQuery}
            onChange={this.onChange}
            isClearable={isNull}
            openMenuOnFocus
          />
        </div>
      )
    }
  }
)
