import { Typography } from "antd"
import styled from "styled-components"

export const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 8px 12px;
`

export const Title = styled(Typography.Title).attrs({ level: 5 })`
  margin-right: auto;
  margin-bottom: 0 !important;
`
