import { Button } from "antd"
import styled from "styled-components"

export const Wrapper = styled.aside`
  position: fixed;
  width: fit-content;
  bottom: 0;
  right: 0;
  z-index: 100;
  padding: 12px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(
    ${({ isVisible }: { isVisible: boolean }) => (isVisible ? 0 : "100%")}
  );
  transition: all 1.5s ease;
`

export const PullButton = styled(Button)`
  line-height: 10px;
  position: absolute;
  bottom: 0;
  right: 100%;
`
