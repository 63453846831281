import React from "react"

export const PersonalForDirector = () => (
  <ul>
    <li>
      После официального трудоустройства логин и временный пароль для нового
      сотрудника приходит на почту <b>непосредственному руководителю.</b>
      <br />*
      <i>
        Если руководитель не получил логин/пароль, то необходимо связаться со
        службой технической поддержки
      </i>
      &nbsp;-&nbsp;(7777@pik&#8209;comfort.ru).
    </li>
    <li>
      Сотрудник должен зайти на корпоративный портал с браузера Google Chrome по
      адресу <b>home.pik&#8209;comfort.ru</b>, использовав логин и временный
      пароль.
    </li>
    <li>
      Сменить на портале временный пароль на постоянный на портале. <br />
      <i>
        *Минимальная длина пароля 10 символов. Необходимо использовать большие и
        маленькие буквы и цифры.
      </i>
    </li>
    <li>
      <b>Руководитель</b> должен определить какие ещё доступы требуются новому
      сотруднику для работы и сделать заявки на их предоставление.
    </li>
    <li>
      Сотрудник должен зайти на корпоративный портал и ознакомиться с
      инструкциями по настройке удаленного доступа:&nbsp;
      <a
        href="https://home.pik-comfort.ru/instructions/personal/"
        target="_blank"
        rel="noreferrer"
      >
        https://home.pik&#8209;comfort.ru/instructions/personal/
      </a>
    </li>
    <li>
      Настроить софтфон для корпоративной связи согласно инструкции:&nbsp;
      <a
        href="https://home.pik-comfort.ru/instructions/telephony/"
        target="_blank"
        rel="noreferrer"
      >
        https://home.pik&#8209;comfort.ru/instructions/telephony/
      </a>
    </li>
    <li>
      Зайти в свою корпоративную почту под логином
      (вашлогин@pik&#8209;comfort.ru) и постоянным паролем здесь:&nbsp;
      <b>mail.google.com</b>
    </li>
  </ul>
)
