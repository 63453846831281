type ICreatingFields = {
  label: string
  type: string
  slug: string
}

export const CREATING_RULE_FIELDS: ICreatingFields[] = [
  {
    label: "Тип здания",
    type: "buildingtype",
    slug: "building_type",
  },
  {
    label: "Управляющая компания",
    type: "managementcompany",
    slug: "management_company",
  },
  {
    label: "Регион управления",
    type: "division",
    slug: "division",
  },
  {
    label: "Дивизион эксплуатации",
    type: "exploitationdivision",
    slug: "exploitation_division",
  },
  {
    label: "Участок эксплуатации",
    type: "exploitationsector",
    slug: "exploitation_sector",
  },
  {
    label: "Территориальное отделение управления",
    type: "managementservice",
    slug: "management_service",
  },
  {
    label: "Служба эксплуатации",
    type: "exploitation",
    slug: "exploitation",
  },
  {
    label: "Группа домов",
    type: "buildingsgroup",
    slug: "buildings_group",
  },
  {
    label: "Здание",
    type: "building",
    slug: "building",
  },
]

export const CREATING_ORGANIZATION_FIELDS: ICreatingFields[] = [
  {
    label: "Организация",
    type: "contractor",
    slug: "contractor",
  },
]
