import { PAY_SERVICE_CHOICES } from "constants/choices"
import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { MDSLivingComplex } from "./mdslivingcomplex"

const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "НСИ ID" },
      name: { name: "Наименование", editable: true },
      visible_name: { name: "Сокращенное наименование" },
      full_name: { name: "Полное наименование" },
      oidp_codes: { name: "Коды ОИДП", _type: "string[]" },
      oidp_name: { name: "Наименование ОИДП" },
      living_complex: {
        name: "Жилищный комплекс",
        _type: "entitylink",
        entityLinkTo: MDSLivingComplex,
        editable: true,
      },
      cadastral_id: { name: "Кадастровый номер", editable: true },
      land_cadastral_id: {
        name: "Кадастровый номер связанного участка",
        editable: true,
      },
    },
  },

  group_exploitation_parameters: {
    name: "Характеристики",
    children: {
      pay_space: {
        name: "Обслуживаемая площадь, м2",
        _type: "number",
        editable: true,
      },
      pay_service: {
        _type: "choices",
        choices: PAY_SERVICE_CHOICES,
        name: "Бюджет обслуживания",
        editable: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class Road extends FullNameEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(Road, schema)
