import styled from "styled-components"

export const Content = styled.div`
  margin: 36px 8%;
`

export const Heading = styled.h1`
  margin-bottom: 48px;
  text-align: center;
`
