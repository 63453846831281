import { INVENTORY_ACCESS_DESCRIPTION } from "constants/description"

export const CREATABLE_ENTITIES = [
  "livingentrance",
  "elevator",
  "wheelchairelevator",
  "road",
  "playhub",
  "adjacentarea",
  "meeting",
  "financialunitassessment",
]

export const PIVOT_ENTITIES_DESCRIPTION: Record<
  string,
  { title: string; description?: string }
> = {
  inventorybuilding: INVENTORY_ACCESS_DESCRIPTION,
  livingentrance: INVENTORY_ACCESS_DESCRIPTION,
  elevator: INVENTORY_ACCESS_DESCRIPTION,
  wheelchairelevator: INVENTORY_ACCESS_DESCRIPTION,
  premise: INVENTORY_ACCESS_DESCRIPTION,
  road: INVENTORY_ACCESS_DESCRIPTION,
  playhub: INVENTORY_ACCESS_DESCRIPTION,
  adjacentarea: INVENTORY_ACCESS_DESCRIPTION,
  meetingrequest: {
    title: "У вас недостаточно прав для просмотра Заявок ОСС!",
  },
  meeting: {
    title: "У вас недостаточно прав для просмотра Проектов и Собраний!",
  },
  rosreestrright: {
    title: "У вас недостаточно прав для просмотра Собственников помещений!",
  },
  rosreestrstatement: {
    title: "У вас недостаточно прав для просмотра Выписок Росреестра!",
  },
  financialunitassessment: {
    title: "У вас недостаточно прав для просмотра Оценки руководителей ТО!",
  },
}
