import { list, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

interface IContact extends IEntity {
  emails: string[]
  phones: string[]
  order_index: number
}

export class Contact extends BaseEntity implements IContact {
  emails: string[]
  phones: string[]
  order_index: number
}

createSerializrSchema(Contact, null, {
  emails: list(primitive()),
  phones: list(primitive()),
  order_index: primitive(),
})
