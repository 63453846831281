import { BaseEntity, FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

const schema: ISchema = {
  financial_unit: {
    name: "Територия",
    _type: "entitylink",
    entityLinkTo: FullNameEntity,
    editable: false,
  },
} as const

export class FinancialTerritory extends BaseEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(FinancialTerritory, schema)
