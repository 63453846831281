import React from "react"
import { action, computed, makeObservable } from "mobx"
import { ColDef, MenuItemDef } from "ag-grid-community"
import { observer } from "mobx-react"

import { IReferenceType } from "types"
import {
  AgGridServerSide,
  AgGridServerSideRef,
} from "pik-react-utils/components"
import { ELEVATORS_TYPES } from "./constants"
import { Datasource } from "./Datasource"
import { ReferenceStore } from "../InventoryReferenceStore"

const getCellStyle = ({ data }: { data: IReferenceType }) => {
  return !data?.type
    ? { backgroundColor: "silver" }
    : { backgroundColor: "inherit" }
}

const colDefs: ColDef[] = [
  {
    headerName: "Название",
    field: "label",
    cellStyle: getCellStyle,
  },
  {
    headerName: "Кол-во",
    field: "count",
    width: 90,
    suppressSizeToFit: true,
    cellStyle: getCellStyle,
  },
]

type TypeListProps = {
  store: ReferenceStore
  onOpenModal: () => void
  onSelectRow: (data: IReferenceType) => void
}

export class TypeListRef extends React.Component<TypeListProps> {
  private readonly agGridRef = React.createRef<AgGridServerSideRef>()

  constructor(props: TypeListProps) {
    super(props)
    makeObservable<TypeListRef, "refStore" | "getContextMenuItems">(this, {
      refStore: computed,
      getContextMenuItems: action.bound,
      update: action.bound,
    })
  }

  componentDidMount(): void {
    this.update()
  }

  private get refStore() {
    return this.props.store
  }

  private getContextMenuItems(defaultItems: string[]) {
    const type = this.refStore.referenceType?.type

    const items: (MenuItemDef | string)[] = defaultItems
    if (!type) return items

    if (ELEVATORS_TYPES.includes(type)) {
      items.push({
        name: "Создать",
        action: () => this.props.onOpenModal(),
      })

      return items
    }

    return items
  }

  update() {
    this.agGridRef.current?.setServerSideDatasource(
      new Datasource({ query: this.refStore.query })
    )
  }

  render() {
    return (
      <AgGridServerSide
        ref={this.agGridRef}
        columnDefs={colDefs}
        onRowClicked={this.props.onSelectRow}
        getContextMenuItems={this.getContextMenuItems}
      />
    )
  }
}

export const TypeList = React.forwardRef(function (
  props: TypeListProps,
  ref: React.LegacyRef<TypeListRef>
) {
  const TypeList = observer(TypeListRef)
  return <TypeList {...props} ref={ref} />
})
