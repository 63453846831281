import { message } from "antd"
import { ResponseError, ErrorDetail } from "pik-react-utils/types"

function getFieldsErrors(error: ResponseError): { [field: string]: string } {
  if (error.response?.status === 400) {
    const res = error.response.data?.detail
    const errors =
      res &&
      Object.keys(res).reduce((acc, item) => {
        acc[item] = res[item].reduce((text, i) => `${text}${i.message} `, "")
        return acc
      }, {})

    return errors || {}
  }

  return {}
}

function getNonFieldsErrors(error: ResponseError): ErrorDetail[] {
  if (error.response?.status === 400) {
    const res = error.response.data?.detail
    return res?.non_field_errors || []
  }
  return []
}

function getErrorCode(error: ResponseError) {
  const errorDetail = error.response?.data?.detail
  if (errorDetail && errorDetail[0]) {
    return errorDetail[0][0]?.code
  }
  return null
}

function processError(error: ResponseError) {
  if (error.message === "Network Error") {
    message.error("Сетевая ошибка, повторите попытку")
    return
  }
  const { status, data, request } = error.response || {}
  const errorMessage = typeof data === "string" ? data : data?.message ?? ""

  if (status === 403) {
    message.error(
      `Недостаточно прав для выполнения запроса ${request.responseURL}`
    )
    return error
  }

  if (status === 400) {
    if (getErrorCode(error) === "duplicate") {
      message.warning("Такой объект уже существует")
    } else {
      message.warning(`Введены некорректные данные. ${errorMessage}`)
    }
    return error
  }

  if (status === 500) {
    message.error(
      "Произошла внутренняя ошибка сервера, разработчики оповещены и " +
        "уже работают над ее устранением"
    )
    return error
  }

  if (errorMessage?.length) {
    message.error(errorMessage)
  }

  return error
}

export { ResponseError, getFieldsErrors, getNonFieldsErrors, processError }
