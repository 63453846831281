import { object, raw } from "serializr"
import { computed, makeObservable, observable } from "mobx"

import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { DEFAULT_EDITABLE_FIELDS } from "utils/fields"
import { BuildingCounter } from "./buildingcounter"
import { InventoryBuilding } from "./inventorybuilding"

export class InventoryBuildingPart extends FullNameEntity {
  static readonly schema: ISchema = DEFAULT_EDITABLE_FIELDS

  building: InventoryBuilding
  utility_system_count: BuildingCounter
  premise_count: BuildingCounter

  constructor(props: FullNameEntity) {
    super(props)
    makeObservable(this, {
      utility_system_count: observable,
      premise_count: observable,
      totalGroupCount: computed,
    })
  }

  get totalGroupCount() {
    return this.utility_system_count.amount + this.premise_count.amount
  }
}

createSerializrSchema(InventoryBuildingPart, InventoryBuildingPart.schema, {
  utility_system_count: object(BuildingCounter),
  premise_count: object(BuildingCounter),
  extra_attrs: raw(),
})
