import { makeObservable, observable } from "mobx"
import { list, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

export class ExtraAttr extends BaseEntity {
  slug: string
  choices: string[] | number[]
  field_type: string
  decimal_min: number | null
  decimal_max: number | null
  decimal_decimal_places: number | null
  max_length: number | null
  help_text: string
  group: string

  constructor(props: BaseEntity) {
    super(props)
    makeObservable(this, {
      slug: observable,
      choices: observable,
      field_type: observable,
      decimal_min: observable,
      decimal_max: observable,
      decimal_decimal_places: observable,
      max_length: observable,
      help_text: observable,
      group: observable,
    })
  }
}

createSerializrSchema(ExtraAttr, null, {
  slug: primitive(),
  choices: list(primitive()),
  field_type: primitive(),
  decimal_min: primitive(),
  decimal_max: primitive(),
  decimal_decimal_places: primitive(),
  max_length: primitive(),
  help_text: primitive(),
  group: primitive(),
})
