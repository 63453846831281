import { observer } from "mobx-react"
import React from "react"
import { action, makeObservable, observable } from "mobx"
import { Button, InputNumber } from "antd"
import { ICellEditorParams } from "ag-grid-community"

import { CloseOutlined } from "components/antd"
import { CellContainer } from "../components"
import "./editors.css"

const NUMBER_PRECISION = {
  integer: 0,
  decimal: 2,
  number: undefined,
} as const

interface NumberEditorProps extends ICellEditorParams {
  type: "integer" | "decimal" | "number"
}

export const NumberEditor = observer(
  class NumberEditor extends React.Component<NumberEditorProps> {
    private value: number | undefined
    private readonly selectRef: React.RefObject<HTMLInputElement> =
      React.createRef()

    constructor(props: NumberEditorProps) {
      super(props)
      makeObservable<NumberEditor, "value" | "onChange" | "onClear">(this, {
        value: observable.struct,
        afterGuiAttached: action.bound,
        onChange: action.bound,
        onClear: action.bound,
      })
    }

    public getValue() {
      return this.value
    }

    public afterGuiAttached() {
      this.onChange(this.props.value)
      this.selectRef.current?.focus()
    }

    private onChange(value: number) {
      const { type } = this.props
      this.value =
        type === "decimal" && value ? Number(value.toFixed(2)) : value
    }

    private onClear() {
      this.value = undefined
      this.props.stopEditing()
    }

    render() {
      return (
        <CellContainer>
          <InputNumber
            ref={this.selectRef}
            value={this.value}
            className="pik_ag-grid_number-cell-editor"
            decimalSeparator="."
            precision={NUMBER_PRECISION[this.props.type]}
            onChange={this.onChange}
            size="small"
            autoFocus
          />
          <Button
            type="text"
            size="small"
            onClick={this.onClear}
            className="pik_ag-grid_number-cell-editor_icon"
            icon={<CloseOutlined />}
          />
        </CellContainer>
      )
    }
  }
)
