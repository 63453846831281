import { FilterType } from "types"
import { BooleanCellRenderer, TagCellRenderer } from "./cells"

export const COLUMNS = [
  { field: "name", hide: true },
  {
    field: "noBudgetOwner",
    headerName: "Владелец бюджета",
    width: 160,
    suppressSizeToFit: true,
    cellRendererFramework: TagCellRenderer,
  },
  {
    headerName: "ЦФО",
    field: "isFinancialResponsibilityCenter",
    width: 90,
    suppressSizeToFit: true,
    cellRendererFramework: BooleanCellRenderer,
  },
]

export const FILTER_LIST: FilterType[] = [
  {
    name: "Организация",
    slug: "",
    _type: "entitylink",
    entityLinkTo: "organization",
    staticFilters: {
      is_actual: true,
      is_external: false,
    },
  },
]
