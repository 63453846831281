import { ExportStatus } from "./constants"

export const getAttention = () => {
  window.focus()
  let i = 0
  const show = ["************", window.document.title]

  const focusTimer = setInterval(() => {
    if (window.closed) {
      clearInterval(focusTimer)
      return
    }
    window.document.title = show[i % 2]
    i = i + 1
  }, 1000)

  window.document.onmousemove = function () {
    clearInterval(focusTimer)
    window.document.title = show[1]
    window.document.onmousemove = null
  }
}

export const progressStatus = (status: ExportStatus) => {
  switch (status) {
    case ExportStatus.DONE:
    case ExportStatus.ERROR:
      return status
    case ExportStatus.LOADING:
      return "active"
    default:
      return "normal"
  }
}
