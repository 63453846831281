import { observer } from "mobx-react"
import React from "react"
import { action, makeObservable, observable } from "mobx"
import { Select } from "antd"
import { ICellEditorParams } from "ag-grid-community"

import "./editors.css"
import { BaseSelectRef } from "components/antd"

interface IStringArrayEditorProps extends ICellEditorParams {
  type: string
}

export const StringArrayEditor = observer(
  class StringArrayEditor extends React.Component<IStringArrayEditorProps> {
    private value: string[] | null = this.props.value
    private readonly selectRef: React.RefObject<BaseSelectRef> =
      React.createRef()

    constructor(props: IStringArrayEditorProps) {
      super(props)
      makeObservable<StringArrayEditor, "value" | "onChange">(this, {
        value: observable,
        afterGuiAttached: action.bound,
        onChange: action.bound,
      })
    }

    public getValue() {
      return this.value
    }

    public afterGuiAttached() {
      this.selectRef.current?.focus()
    }

    private onChange(value: string[]) {
      this.value = value.length === 0 ? null : value
      this.props.stopEditing()
    }

    render() {
      return (
        <Select
          ref={this.selectRef}
          size="small"
          mode="tags"
          tokenSeparators={[","]}
          value={this.value}
          onChange={this.onChange}
          style={{ width: "100%" }}
          allowClear
        />
      )
    }
  }
)
