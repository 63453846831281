import { AxiosError, AxiosRequestConfig } from "axios"

export type RequestConfig = {
  handleError?: boolean
  apiVer?: `v${number}`
  suffix?: string
}

export type IInitConfig = {
  apiConfig: AxiosRequestConfig
  authConfig: IAuthConfig
}

export type IAuthConfig = {
  clientId: string
  authUrl: string
}

export type APIConfig = RequestConfig & {
  fromStorage?: boolean
}

export enum MethodType {
  GET = "get",
  POST = "post",
  DELETE = "delete",
  PATCH = "patch",
}

export interface ErrorDetail {
  code: string
  message: string
}

type ErrorData = {
  code?: string
  detail?: {
    [key: string]: ErrorDetail[]
  }
  message?: string
}

export interface ResponseError extends AxiosError<ErrorData> {}

export type ObjWithType = {
  _type: string
  [key: string]: unknown
}

export type QueryArgs = Record<string, string | null | undefined | boolean>

export interface IColumnGroupState {
  groupId: string
  open: boolean
}
