import React from "react"
import { AgGridReact, AgGridReactProps } from "ag-grid-react"
import { AgGridEvent } from "ag-grid-community"
import { observer } from "mobx-react"
import styled from "styled-components"

import { agGridRu } from "pik-react-utils/constants"
import { Editors, Renderers } from "pik-react-utils/utils/agGrid"

const TableWrap = styled.div`
  height: 100%;
  width: 100%;
  outline: none;
`

export const AgGridComponent = observer((props: AgGridReactProps) => {
  const onResize = (event: AgGridEvent) => event.api?.sizeColumnsToFit()

  return (
    <TableWrap className="ag-theme-balham">
      <AgGridReact
        localeText={agGridRu}
        rowSelection="single"
        frameworkComponents={{ ...Editors, ...Renderers }}
        onGridSizeChanged={onResize}
        onRowDataChanged={onResize}
        onColumnEverythingChanged={onResize}
        suppressCopyRowsToClipboard
        animateRows
        loadingOverlayComponent="LoadingOverlayRenderer"
        onDisplayedColumnsChanged={onResize}
        defaultColDef={{ resizable: true }}
        {...props}
      />
    </TableWrap>
  )
})
