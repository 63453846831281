import { IReferenceType } from "types/references"

export const ELEVATORS_TYPES = ["manufacturingplant", "elevatormodel"]

export const INVENTORY_REFERENCE_LIST: IReferenceType[] = [
  {
    label: "Здания",
    type: null,
  },
  {
    label: "Управляющие компании",
    singleLabel: "Управляющая компания",
    type: "managementcompany",
  },
  {
    label: "Группы зданий",
    singleLabel: "Группа зданий",
    type: "buildingsgroup",
  },
  {
    label: "Регионы управления",
    singleLabel: "Регион управления",
    type: "division",
  },
  {
    label: "Участки управления",
    singleLabel: "Участок управления",
    type: "sector",
  },
  {
    label: "Территориальные отделения управления",
    singleLabel: "Территориальное отделение управления",
    type: "managementservice",
  },
  {
    label: "Дивизионы эксплуатации",
    singleLabel: "Дивизион эксплуатации",
    type: "exploitationdivision",
  },
  {
    label: "Участки эксплуатации",
    singleLabel: "Участок эксплуатации",
    type: "exploitationsector",
  },
  {
    label: "Службы эксплуатации",
    singleLabel: "Служба эксплуатации",
    type: "exploitation",
  },
  {
    label: "Жилищные комплексы",
    singleLabel: "Жилищный комплекс",
    type: "mdslivingcomplex",
  },
  {
    label: "Архивы документов",
    singleLabel: "Архив документов",
    type: "passportstorage",
  },
  {
    label: "Органы местного самоуправления",
    singleLabel: "Орган местного самоуправления",
    type: "localgovernment",
  },
  {
    label: "Лифты",
    type: null,
  },
  {
    label: "Заводы изготовители лифтов",
    singleLabel: "Завод изготовитель лифтов",
    type: "manufacturingplant",
  },
  {
    label: "Модели лифтов",
    singleLabel: "Модель лифта",
    type: "elevatormodel",
  },
]
