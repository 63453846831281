import { object } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

import { Building } from "./building"
import { BuildingsGroup } from "./buildingsgroup"
import { BuildingType } from "./buildingtype"
import { Division } from "./division"
import { Exploitation } from "./exploitation"
import { ExploitationDivision } from "./exploitationdivision"
import { ExploitationSector } from "./exploitationsector"
import { FinancialTerritory } from "./financialterritory"
import { ManagementCompany } from "./managementcompany"
import { ManagementService } from "./managementservice"

const schema: ISchema = {
  group_parameters: {
    name: "Характеристики",
    children: {
      building: {
        name: "Здание",
        _type: "entitylink",
        entityLinkTo: Building,
      },
      financial_territory: {
        name: "Територия",
        _type: "entitylink",
        entityLinkTo: FinancialTerritory,
      },
    },
  },
} as const

export class BuildingLocation extends BaseEntity {
  static readonly schema: ISchema = schema

  building: Building | null
}

createSerializrSchema(BuildingLocation, schema, {
  building: object(Building),
  financial_territory: object(FinancialTerritory),
  building_type: object(BuildingType),
  management_company: object(ManagementCompany),
  division: object(Division),
  exploitation_division: object(ExploitationDivision),
  exploitation_sector: object(ExploitationSector),
  management_sector: object(ManagementService),
  exploitation: object(Exploitation),
  buildings_group: object(BuildingsGroup),
})
