import { object } from "serializr"

import { BaseEntity, FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

import { MeetingChangeAttachment } from "../meetingchangeattachment"
import { MeetingSnapshot } from "../meetingsnapshot"
import { schema } from "./schema"

export class MeetingChange extends BaseEntity {
  static schema: ISchema = schema

  document_date: string
  document_name: string
  document_link: string
  readonly document_fields: string[]
  document_is_draft: boolean

  readonly snapshot: MeetingSnapshot
}

createSerializrSchema(MeetingChange, schema, {
  meeting: object(FullNameEntity),
  attachment: object(MeetingChangeAttachment),
  snapshot: object(MeetingSnapshot),
})
