import { Button, Checkbox, Col, message, Row } from "antd"
import React, { useCallback, useState } from "react"

import { AsyncSelect } from "components"
import { FormLabel } from "components/css"
import { StaffUnitEntity } from "models"
import { entitiesStore } from "stores"
import { Footer, Form } from "pages/ManagementStructure/Division/components"
import { EmploymentSelect } from "./EmploymentSelect"
import { employmentOption } from "./EmploymentSelect/utils"
import { dataToServer } from "./utils"
import { WorkplaceEditorState } from "../constants"
import { Wrapper } from "../components"

const init = (staffUnit: StaffUnitEntity | null | undefined) => {
  if (!staffUnit) return
  const {
    external_account,
    employment: employmentEntity,
    role: roleEntity,
  } = staffUnit
  const employment = external_account ?? employmentEntity
  const role = roleEntity
    ? { label: roleEntity.breadcrumbFullName, value: roleEntity }
    : undefined
  return {
    role,
    employment: employment ? employmentOption(employment) : undefined,
  }
}

interface WorkplaceEditorProps {
  state: WorkplaceEditorState
  staffUnit?: StaffUnitEntity | null
  unitUid: string
  onClose: (refresh?: boolean) => void
}

export const WorkplaceEditor = ({
  state,
  staffUnit,
  unitUid,
  onClose,
}: WorkplaceEditorProps) => {
  const [store] = useState(() => entitiesStore)
  const [initialValues] = useState(() => init(staffUnit))
  const [count, setCount] = useState<number>(0)
  const [form] = Form.useForm()

  const onSave = useCallback(async () => {
    await form.validateFields()
    const formField = form.getFieldsValue()

    const data = dataToServer(unitUid, formField)

    state === WorkplaceEditorState.CREATING
      ? await store.createEntity(data)
      : staffUnit
      ? await store.updateEntity({ _uid: staffUnit._uid, ...data })
      : null

    message.success(
      `Рабочее место успешно ${staffUnit ? "обновлено" : "создано"}`
    )
    if (formField?.isCreateMore) {
      setCount((prevCount) => prevCount + 1)
      form.resetFields(["employment", "role"])
    } else {
      onClose(true)
    }
  }, [form, onClose, staffUnit, state, store, unitUid])

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Wrapper>
        <Form.Item
          name="employment"
          label={<FormLabel>Трудоустройство</FormLabel>}
          validateTrigger="submit"
          rules={[
            {
              required: true,
              message: "Выберите трудоустройство",
            },
          ]}
        >
          <EmploymentSelect />
        </Form.Item>
        <Form.Item
          name="role"
          label={<FormLabel>Целевая должность</FormLabel>}
          validateTrigger="submit"
          rules={[
            {
              required: true,
              message: "Выберите должность",
            },
          ]}
        >
          <AsyncSelect
            entityType="role"
            filters={{ "name__contains!": "(!)" }}
            field={{ label: "breadcrumbFullName" }}
            isClearable
            defaultValue={initialValues?.role}
          />
        </Form.Item>
        <Form.Item name="office" label={<FormLabel>Офис</FormLabel>} required>
          <AsyncSelect
            entityType="office"
            field={{ label: "address" }}
            isClearable
          />
        </Form.Item>
      </Wrapper>
      <Footer>
        {state === WorkplaceEditorState.CREATING && (
          <Form.Item
            name="isCreateMore"
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox>Создать ещё</Checkbox>
          </Form.Item>
        )}
        <Row gutter={12}>
          <Col>
            <Button type="primary" onClick={onSave}>
              {state === WorkplaceEditorState.CREATING
                ? "Создать"
                : "Сохранить"}
            </Button>
          </Col>
          <Col>
            <Button onClick={() => onClose(!!count)}>Отмена</Button>
          </Col>
        </Row>
      </Footer>
    </Form>
  )
}
