import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { Building } from "../building"
import { BuildingsGroup } from "../buildingsgroup"
import { BuildingType } from "../buildingtype"
import { Division } from "../division"
import { Exploitation } from "../exploitation"
import { ExploitationDivision } from "../exploitationdivision"
import { ExploitationSector } from "../exploitationsector"
import { FinancialUnit } from "../financialunit"
import { ManagementCompany } from "../managementcompany"
import { ManagementService } from "../managementservice"
import { Sector } from "../sector"

export const schema: ISchema = {
  financial_unit: {
    name: "Подразделение финансовой структуры",
    _type: "entitylink",
    entityLinkTo: FinancialUnit,
  },
  division: { name: "Дивизион", _type: "entitylink", entityLinkTo: Division },
  sector: { name: "Участок", _type: "entitylink", entityLinkTo: Sector },
  exploitation_sector: {
    name: "Участок эксплуатации",
    _type: "entitylink",
    entityLinkTo: ExploitationSector,
  },
  exploitation: {
    name: "Служба эксплуатации",
    _type: "entitylink",
    entityLinkTo: Exploitation,
  },
  management_company: {
    name: "Управляющая компания",
    _type: "entitylink",
    entityLinkTo: ManagementCompany,
  },
  buildings_group: {
    name: "Группа зданий",
    _type: "entitylink",
    entityLinkTo: BuildingsGroup,
  },
  building_type: {
    name: "Тип здания",
    _type: "entitylink",
    entityLinkTo: BuildingType,
  },
  building: {
    name: "Здание",
    _type: "entitylink",
    entityLinkTo: Building,
  },
  exploitation_division: {
    name: "Дивизион эксплуатации",
    _type: "entitylink",
    entityLinkTo: ExploitationDivision,
  },
  management_service: {
    name: "Территориальное отделение управления",
    _type: "entitylink",
    entityLinkTo: ManagementService,
  },
  ...GROUP_SYSTEM_DATE_FIELDS,
} as const
