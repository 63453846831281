import { observable, computed, makeObservable } from "mobx"
import dayjs from "dayjs"
import { list, object, primitive } from "serializr"

import { PersonEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { Employment } from "./employment"

interface IEmployee extends IEntity {
  first_name: string | undefined
  middle_name: string | undefined
  last_name: string | undefined
  birth_date?: string
  employments: Employment[]
  functions: string[]
  units: string[]
}

export class Employee extends PersonEntity implements IEmployee {
  birth_date: string
  internal_phones: string[]
  external_phones: string[]
  emails: string[]
  functions: string[]
  units: string[]
  employments: Employment[]

  constructor(props: PersonEntity) {
    super(props)
    makeObservable(this, {
      birth_date: observable,
      internal_phones: observable,
      external_phones: observable,
      emails: observable,
      functions: observable,
      units: observable,
      employments: observable,
      birthDate: computed,
      actualEmploymentEntities: computed,
    })
  }

  get birthDate() {
    return dayjs(this.birth_date).format("DD.MM")
  }

  get actualEmploymentEntities() {
    return this.employments?.filter(
      ({ is_deleted, is_actual, organization }) =>
        !is_deleted && is_actual && organization?.is_actual
    )
  }

  static tableFields() {
    return new Map([
      ["last_name", { title: "Фамилия", sorter: true }],
      ["first_name", { title: "Имя", sorter: true }],
      ["middle_name", { title: "Отчество", sorter: true }],
      [
        "birth_date",
        { title: "ДР", sorter: true, columnKey: "_birth_date_index" },
      ],
      ["employments", { title: "Занимаемые должности" }],
      ["internal_phones", { title: "Внутренние телефоны" }],
      ["external_phones", { title: "Внешние телефоны" }],
      ["emails", { title: "Электронная почта" }],
    ])
  }
}

createSerializrSchema(Employee, null, {
  birth_date: primitive(),
  employments: list(object(Employment)),
  internal_phones: list(primitive()),
  external_phones: list(primitive()),
  emails: list(primitive()),
})
