import { object } from "serializr"

import { createSerializrSchema } from "pik-react-utils/utils"
import { BuildingType } from "./buildingtype"
import { ExtraAttr } from "./extraattr"

export class BuildingExtraAttr extends ExtraAttr {
  building_type: BuildingType
}

createSerializrSchema(BuildingExtraAttr, null, {
  building_type: object(BuildingType),
})
