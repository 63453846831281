import React, { useCallback, useEffect, useState } from "react"
import { Input, Table, TablePaginationConfig } from "antd"
import { SorterResult } from "antd/lib/table/interface"
import { observer } from "mobx-react"

import { PAGE_SIZE_DEFAULT } from "constants/requests"
import { EmployeeEntity } from "models"
import { entitiesStore } from "stores"
import { ContainerSearch, Wrapper } from "./components"
import { EmploymentsCell, LinkCell } from "./cell"

const DEFAULT_PAGE = 1

const rowRenderer = (
  {
    _uid,
    last_name,
    first_name,
    middle_name,
    birthDate,
    internal_phones,
    external_phones,
    emails,
    actualEmploymentEntities: employments,
  }: EmployeeEntity,
  onClick: (value: string) => void
) => ({
  key: _uid,
  last_name: last_name,
  first_name: first_name,
  birth_date: birthDate,
  middle_name,
  internal_phones: internal_phones?.map((phone: string) => (
    <LinkCell key={phone} link={`tel:${phone}`} title={phone} />
  )),
  employments: (
    <EmploymentsCell employments={employments ?? []} onClick={onClick} />
  ),
  external_phones: external_phones?.map((phone: string) => (
    <LinkCell key={phone} link={`tel:${phone}`} title={phone} />
  )),
  emails: emails?.map((email: string) => (
    <LinkCell key={email} link={`email:${email}`} title={email} />
  )),
})

export const Employees = observer(() => {
  const [store] = useState(() => entitiesStore)
  const [isLoading, setLoading] = useState(true)
  const [query, setQuery] = useState({
    page_size: PAGE_SIZE_DEFAULT,
    page: DEFAULT_PAGE,
    ordering: "",
    search: "",
    organization_is_actual: true,
    is_hired: true,
  })
  const [list, setList] = useState<EmployeeEntity[]>([])
  const [total, setTotal] = useState<number>()

  const getList = useCallback(async () => {
    try {
      setList([])
      const response = await store.getList<EmployeeEntity>("employee", query)
      setList(response?.entities ?? [])
      setTotal(response?.meta?.count)
    } finally {
      setLoading(false)
    }
  }, [query, store])

  useEffect(() => {
    if (!isLoading) return
    getList()
  }, [getList, isLoading])

  const onTableChange = (
    { current, pageSize }: TablePaginationConfig,
    _: unknown,
    { order, columnKey }: SorterResult<unknown>
  ) => {
    setQuery((prev) => ({
      ...prev,
      page: current ?? 1,
      page_size: pageSize ?? PAGE_SIZE_DEFAULT,
      ...(order && {
        ordering: order === "descend" ? `-${columnKey}` : String(columnKey),
      }),
    }))
    setLoading(true)
  }

  const onSearch = () => {
    setQuery((prev) => ({ ...prev, page: 1 }))
    setLoading(true)
  }

  const onChange = (value: string) => {
    setQuery((prev) => ({ ...prev, search: value }))
  }

  const onEmploymentClick = (value: string) => {
    onChange(value)
    onSearch()
  }

  const tableData = list.map((item) => rowRenderer(item, onEmploymentClick))

  return (
    <Wrapper>
      <ContainerSearch>
        <Input.Search
          placeholder="Начать поиск"
          enterButton="Найти"
          onSearch={onSearch}
          allowClear
          disabled={isLoading}
          value={query.search}
          onChange={(e) => onChange(e.target.value)}
        />
        <span>Найдено: {total}</span>
      </ContainerSearch>
      <Table
        loading={isLoading}
        pagination={{
          position: ["bottomRight"],
          defaultCurrent: DEFAULT_PAGE,
          defaultPageSize: PAGE_SIZE_DEFAULT,
          total,
          hideOnSinglePage: true,
          showSizeChanger: true,
        }}
        dataSource={tableData}
        onChange={onTableChange}
      >
        {Array.from(EmployeeEntity.tableFields()).map(([key, item]) => (
          <React.Fragment key={key}>
            <Table.Column
              key={item.columnKey || key}
              title={item.title}
              dataIndex={key}
              sorter={item.sorter}
            />
          </React.Fragment>
        ))}
      </Table>
    </Wrapper>
  )
})
