import { Decimal } from "decimal.js"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

import { schema } from "./schema"

export class MDSObject extends BaseEntity {
  static readonly schema: ISchema = schema

  code1c: string
  parkingreceiptdatervoe: string
  residentialreceiptdatervoe: string
  nonresidentialreceiptdatervoe: string
  addresspost: string

  min_floor_count: number
  max_floor_count: number

  premise_count: number
  premise_space: Decimal

  residential_premise_count: number
  residential_premise_space: Decimal

  parking_place_count: number
  parking_place_space: Decimal

  storage_room_count: number
  storage_room_space: Decimal

  apartment_count: number
  apartment_space: Decimal

  non_residential_premise_count: number
  non_residential_premise_space: Decimal
}

createSerializrSchema(MDSObject, schema)
