class LocalStorage {
  readonly keys = {
    pivotToolPanel: "pik-pivot_tool_panel",
  }

  getItem(key: string) {
    return localStorage.getItem(key)
  }

  setItem(key: string, value: string) {
    return localStorage.setItem(key, value)
  }
}

export const storageService = new LocalStorage()
