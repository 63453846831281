import { queryFormat } from "utils/url"
import { SecondTypeName } from "./types"

export const entityName = (type: SecondTypeName) =>
  `inventory${type.replace("_", "")}`
export const entityTypeName = (type: SecondTypeName) =>
  `${type === "premise" ? type : entityName(type)}type`

export const QueryByType = {
  building: queryFormat(
    new Set([
      "juristic_address",
      "full_name",
      "building_type",
      "building_part_count",
      "utility_system_count",
      "premise_count",
    ])
  ),
  building_part: queryFormat(
    new Set([
      "name",
      "visible_name",
      "building_part_type",
      "utility_system_count",
      "premise_count",
      "_real_type",
    ])
  ),
  utility_system: queryFormat(
    new Set(["name", "full_name", "utility_system_type", "_real_type"])
  ),
  premise: queryFormat(
    new Set(["name", "full_name", "premise_type", "_real_type"])
  ),
} as const
