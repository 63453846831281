import {
  ColDef,
  ICellRendererParams,
  ITooltipParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community"
import { cloneDeep } from "lodash"

import {
  COLOR_ACCENT,
  COLOR_AGGRID_BG_CHANGED_FIELD,
  COLOR_AGGRID_ERROR_BOXSHADOW,
} from "constants/ui-colors"
import { fieldFormatter } from "pik-react-utils/utils/agGrid"
import { FieldChangesByRow, FieldErrorByRow } from "./types"

export const getTooltipValue = (params: ITooltipParams): string => {
  const slug = params.colDef?.field ?? ""
  return params.data?.errors?.[slug] ?? params.valueFormatted ?? "НЕТ"
}

export const getRowStyle = (params: ValueFormatterParams) => {
  if (!params.data?.isDeleted) return
  return {
    color: COLOR_ACCENT,
    fontWeight: "600",
  }
}

export const getValueStyle =
  ({
    errors,
    changes,
  }: {
    errors: FieldErrorByRow
    changes: FieldChangesByRow
  }) =>
  (params: ValueFormatterParams) => {
    if (!params.data) {
      return {}
    }

    const styles: IStyles = {
      backgroundColor: "inherit",
      boxShadow: "inherit",
    }

    const { _uid, _type } = params.data
    const slug = params.colDef.field ?? ""
    if (_type === "text") {
      styles.height = "100%"
      styles.whiteSpace = "normal"
    }

    if (errors[_uid] && errors[_uid][slug]) {
      styles.boxShadow = COLOR_AGGRID_ERROR_BOXSHADOW
    }

    if (_type === "number" || _type === "integer") {
      styles.textAlign = "right"
    }

    const isFieldChanged = changes[_uid]?.[slug]

    if (isFieldChanged) {
      styles.backgroundColor = COLOR_AGGRID_BG_CHANGED_FIELD
    }

    return styles
  }

export const rowNumberColumn = {
  headerName: "#",
  maxWidth: 100,
  valueGetter: (params: ValueGetterParams) => params.node?.rowIndex + 1,
  field: "rowNumber",
  width: 60,
  resizable: false,
  suppressSizeToFit: true,
  pinned: "left",
  suppressColumnsToolPanel: true,
  type: "rightAligned",
} as ColDef

export const actionButtonsColumn = {
  field: "actions",
  headerName: "",
  cellRenderer: "ButtonsInRowRenderer",
  resizable: false,
  suppressSizeToFit: true,
  pinned: "right",
  width: 180,
  suppressColumnsToolPanel: true,
} as ColDef

export const cellRendererSelectorByField =
  (field: IEntityField) => (params: ICellRendererParams) => {
    switch (field?._type) {
      case "entitylink":
        return { params, component: "EntityLinkRenderer" }
      case "attachment":
        return { params, component: "AttachmentRender" }
      case "point":
        return { params, component: "PointRenderer" }
      case "request_url":
        return { params, component: "RequestURLRender" }
      case "rosreestrstatement":
        return { params, component: "RosReestrViewportRenderer" }
      default:
        return { params, component: undefined }
    }
  }

export const valueFormatterByField =
  (field: IEntityField) => (params: ValueFormatterParams) => {
    const formatter = fieldFormatter(field)
    return formatter?.(params) ?? ""
  }

export const valueGetterByField = (
  { parent, slug }: Pick<IEntityField, "slug" | "parent">,
  data: IEntity,
  changes: { [field: string]: string | number | IEntityLink }
) => {
  if (!data) return
  const newData = Object.assign(cloneDeep(data), changes)
  return parent ? newData?.[parent]?.[slug] : newData?.[slug]
}
