import { IStorageService } from "./types"

class StorageService implements IStorageService {
  private storage: Storage | undefined = window.localStorage && localStorage
  private fakeStorage: Map<string, string> = new Map<string, string>()

  getItem(key: string): string | null {
    let item: string | null = null

    if (this.storage) {
      item = this.storage.getItem(key)
    } else {
      const storedItem = this.fakeStorage.get(key)
      item = storedItem ? storedItem : null
    }

    return item
  }

  setItem(key: string, value: string): void {
    if (this.storage) {
      this.storage.setItem(key, value)
    } else {
      this.fakeStorage.set(key, value)
    }
  }

  removeItem(key: string): void {
    if (this.storage) {
      this.storage.removeItem(key)
    } else {
      this.fakeStorage.delete(key)
    }
  }
}

export const storageService = new StorageService()
