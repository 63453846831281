import axios from "axios"
import pizzip from "pizzip"
import docxtemplater from "docxtemplater"
import dayjs from "dayjs"
import * as ExcelJs from "exceljs"
import { saveAs } from "file-saver"

import { sessionManager } from "pik-react-utils/auth"

export abstract class FileService {
  static async download(fileLink: string, fileName: string) {
    const response = await axios({
      url: fileLink,
      method: "GET",
      responseType: "blob",
      headers: {
        authorization: sessionManager.currentToken,
      },
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
  }

  static async downloadDocXFromTemplate(
    templateURL: string,
    data: {
      [key: string]: string | number | boolean | null | undefined
    }
  ) {
    const response = await fetch(templateURL)
    const arrayBuffer = await response.arrayBuffer()
    const template = new pizzip(arrayBuffer)
    const doc = new docxtemplater().loadZip(template)

    doc.setData(data)

    try {
      doc.render()
    } catch (error) {
      throw Error(error)
    }
    const out = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    })
    const link = window.URL.createObjectURL(out)
    window.location.href = link
    window.URL.revokeObjectURL(link)
  }

  static async downloadExcel({
    name,
    fields,
    rows,
  }: {
    name: string
    fields: IEntityField[]
    rows: unknown[]
  }) {
    const workbook = new ExcelJs.Workbook()
    const sheet = workbook.addWorksheet(name)

    sheet.columns = fields.map((field) => ({
      header: field.name,
      key: field.slug,
      width: 46,
    }))

    sheet.addRows(rows)

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
    const dateTime = dayjs().format("DD.MM.YYYY_HH:mm")
    const fileName = `${name}_${dateTime}.xlsx`
    saveAs(blob, fileName)
  }
}
