import { observer } from "mobx-react"
import React from "react"
import { CellClickedEvent, ColDef, ColGroupDef } from "ag-grid-community"
import { action, computed, makeObservable, observable } from "mobx"

import { AgGridViewport, AgGridInspector, AgGridViewportRef } from "pik-react-utils/components"
import { entitiesStore } from "stores"
import { Modal } from "./Modal"
import { DocumentEntity, IDocumentListProps, PrivateFields } from "./types"
import { actionColDef } from "./utils"
import { Drawer } from "../Drawer"

const defaultFields = ["document_date", "document_name"]

export class DocumentListRef extends React.Component<IDocumentListProps> {
  private documentEntity: DocumentEntity | undefined
  private isOpenDrawer = false
  private isOpenRemovingModal = false
  private snapshot: IEntity | undefined
  private viewportRef = React.createRef<AgGridViewportRef>()
  private store = entitiesStore

  constructor(props: IDocumentListProps) {
    super(props)
    makeObservable<DocumentListRef, PrivateFields>(this, {
      documentEntity: observable,
      isOpenDrawer: observable,
      isOpenRemovingModal: observable,
      snapshot: observable,
      entityDocumentType: computed,
      contextMenuItems: action.bound,
      getColumnDefs: action.bound,
      onCellClick: action.bound,
      onOpenDrawer: action.bound,
      closeDrawer: action.bound,
      refresh: action.bound,
      update: action.bound,
      onSuccess: action.bound,
      openModal: action.bound,
      closeModal: action.bound,
    })
  }

  private get entityDocumentType() {
    return `${this.props.entityType}change`
  }

  private contextMenuItems() {
    return [{ name: "Создать документ", action: this.props.onCreate }]
  }

  private getColumnDefs(coldef: (ColDef | ColGroupDef)[]) {
    if (this.props.slug) return coldef
    return [...coldef, actionColDef(this.openModal)]
  }

  private onCellClick(event: CellClickedEvent) {
    this.documentEntity = event.data
  }

  private async onOpenDrawer({ data }: CellClickedEvent) {
    const currentEntityLink = { _type: data._type, _uid: data._uid }

    this.isOpenDrawer = true
    this.documentEntity = await this.store.getEntity<DocumentEntity>(
      currentEntityLink
    )

    this.snapshot = this.documentEntity?.snapshot
  }

  private closeDrawer() {
    this.isOpenDrawer = false
  }

  private update() {
    this.closeModal()
    this.props.onUpdate?.()
    this.refresh()
  }

  private onSuccess(isClosing?: boolean) {
    this.refresh()
    this.props.onSuccess(isClosing)
  }

  private openModal() {
    if (!this.documentEntity) return
    this.isOpenRemovingModal = true
  }

  private closeModal() {
    this.isOpenRemovingModal = false
    this.snapshot = undefined
  }

  public refresh() {
    this.store.flushList(this.entityDocumentType)
    this.viewportRef.current?.refresh()
  }

  render() {
    const { entityType, slug, query } = this.props
    if (!entityType) return null

    return (
      <>
        <AgGridViewport
          ref={this.viewportRef}
          entityType={this.entityDocumentType}
          query={{ ordering: "document_date", ...query }}
          visibleFields={slug ? [...defaultFields, slug] : defaultFields}
          getContextMenuItems={this.contextMenuItems}
          onCellClicked={this.onCellClick}
          onCellDoubleClicked={this.onOpenDrawer}
          getColumnDefs={this.getColumnDefs}
        />
        <Drawer
          title="Просмотр документа"
          open={this.isOpenDrawer}
          onClose={this.closeDrawer}
        >
          <AgGridInspector
            entityLink={this.documentEntity}
            onSave={this.onSuccess}
            onCancel={this.closeDrawer}
            referenceLink={this.snapshot}
            isDocument
          />
        </Drawer>
        {this.documentEntity && (
          <Modal
            open={this.isOpenRemovingModal}
            entity={this.documentEntity}
            onOk={this.update}
            onCancel={this.closeModal}
          />
        )}
      </>
    )
  }
}

export const DocumentList = React.forwardRef(function (
  props: IDocumentListProps,
  ref: React.LegacyRef<DocumentListRef>
) {
  const DocumentList = observer(DocumentListRef)
  return <DocumentList {...props} ref={ref} />
})
