import { BIT_FINANCE_WIKI_LINK, TABLEAU_WIKI_LINK } from "constants/links"
import { routerService } from "services"

export const INFO_LIST = [
  {
    key: "start",
    link: routerService.linkToInstruction(),
    label: "Начало работы",
    description: "Настройка рабочего места для нового сотрудника",
  },
  {
    key: "bit-finance-1",
    link: "/БИТ_ФИНАНС_РУКОВОДСТВО_ПОЛЬЗОВАТЕЛЯ.pdf",
    label: "БИТ.ФИНАНС",
    description: "Руководство пользователя. Часть 1.",
  },
  {
    key: "bit-finance-12",
    link: "/БИТ_ФИНАНС_РУКОВОДСТВО_ПОЛЬЗОВАТЕЛЯ_ДОПОЛНЕНИЕ_УПРАВЛЕНЧЕСКИЙ_УЧЕТ.pdf",
    label: "БИТ.ФИНАНС",
    description: "Руководство пользователя. Часть 2. Управленческий учет.",
  },
  {
    key: "bit-finance-new",
    link: BIT_FINANCE_WIKI_LINK,
    label: "БИТ.ФИНАНС",
    description: "Новые релизы на home.pik.ru",
  },
  {
    key: "tableau",
    link: TABLEAU_WIKI_LINK,
    label: "Tableau",
    description: "Отчеты ПИК-Комфорт",
  },
]
