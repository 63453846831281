import styled from "styled-components"

export const TableButtonPanel = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 0;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;
`
