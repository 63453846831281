import { primitive } from "serializr"

import { createSerializrSchema } from "pik-react-utils/utils"
import { BaseEntity } from "./BaseEntity"

interface IPerson extends IEntity {
  last_name?: string
  first_name?: string
  middle_name?: string
}

const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "НСИ ID" },
      full_name: {
        name: "Полное имя",
        computed: ["last_name", "first_name", "middle_name"],
      },
    },
  },
  group_system_info: {
    name: "Системная информация",
    children: {
      created: { name: "Дата создания", _type: "datetime" },
      updated: { name: "Дата последнего изменения", _type: "datetime" },
      deleted: { name: "Дата удаления", _type: "datetime" },
    },
  },
} as const

export class PersonEntity extends BaseEntity implements IPerson {
  static readonly schema: ISchema = schema

  first_name: string | undefined
  middle_name: string | undefined
  last_name: string | undefined

  get full_name() {
    return [this.last_name, this.first_name, this.middle_name].join(" ")
  }
}

createSerializrSchema(PersonEntity, schema, {
  last_name: primitive(),
  first_name: primitive(),
  middle_name: primitive(),
})
