import { makeAutoObservable } from "mobx"

import { createSerializrSchema } from "pik-react-utils/utils"
import { addFieldsInClass, generateObservableFieldsBySchema } from "utils/model"

const schema: ISchema = {
  country: { name: "Страна" },
  region_name: { name: "Регион", computed: ["region", "region_type"] },
  area_name: { name: "Район в регионе", computed: ["area", "area_type"] },
  city_name: { name: "Город", computed: ["city", "city_type"] },
  city_area: { name: "Административный округ" },
  city_district_name: {
    name: "Район города",
    computed: ["city_district", "city_district_type"],
  },
  settlement_name: {
    name: "Населенный пункт",
    computed: ["settlement", "settlement_type"],
  },
  street_name: { name: "Улица", computed: ["street", "street_type"] },
  house_name: { name: "Дом", computed: ["house", "house_type"] },
  block: { name: "Корпус (строение)" },
  postal_code: { name: "Почтовый индекс" },
} as const

export class AddressComponents {
  static readonly schema: ISchema = schema

  street_type: string
  street: string
  city_type: string
  city: string
  settlement_type: string
  settlement: string
  region_type: string
  region: string
  area_type: string
  area: string
  city_district_type: string
  city_district: string
  house_type: string
  house: string
  block: string

  constructor() {
    addFieldsInClass.call(this, schema)
    makeAutoObservable(this, generateObservableFieldsBySchema(schema))
  }

  get street_name() {
    return [this.street_type, this.street].join(" ").trim()
  }

  get city_name() {
    return [this.city_type, this.city].join(" ").trim()
  }

  get settlement_name() {
    return [this.settlement_type, this.settlement].join(" ").trim()
  }

  get region_name() {
    return [this.region_type, this.region].join(" ").trim()
  }

  get area_name() {
    return [this.area_type, this.area].join(" ").trim()
  }

  get city_district_name() {
    return [this.city_district_type, this.city_district].join(" ").trim()
  }

  get house_name() {
    return [this.house_type, this.house].join(" ").trim()
  }
}

createSerializrSchema(AddressComponents, schema)
