import { COLOR_BUTTON_HOVER, COLOR_SUB_TEXT } from "constants/ui-colors"
import styled from "styled-components"
import { Content as _Content } from "../components"

export const DivisionType = styled.div`
  margin-top: 4px;
  font-size: 13px;
  color: ${COLOR_SUB_TEXT};
`

export const ContentWrap = styled.div`
  padding: 12px;
  background-color: ${COLOR_BUTTON_HOVER};
`
export const DrawerTitle = styled.div`
  margin-bottom: 4px;
  .ant-breadcrumb {
    font-size: 16px;
  }
`

export const Content = styled(_Content)`
  display: grid;
  grid-template-rows: auto 1fr;
`
