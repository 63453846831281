import styled from "styled-components"
import { SIZES } from "constants/media"

export const Main = styled.main`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 88px 1fr;
  overflow: hidden;
  min-width: ${SIZES.Desktop_S}px;
`

export const Content = styled.div`
  position: relative;
  overflow-y: auto;
`
