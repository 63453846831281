export enum InventoryStructurePageTabs {
  BUILDINGS = "buildings",
  REFERENCES = "references",
  MDS_OBJECTS = "mdsobjects",
}

export enum RequestPageTabs {
  TARIFF_RECALC = "tariff-recalc-create",
  BUILDING_PROBLEM = "building-problem-request-create",
  MEETING = "meeting-request-create",
  PERMISSION = "permissions-request",
  OTHER = "other-request",
}

export const CHANGE_PWD_LINK = "https://auth.pik-software.ru/password/change/"
export const TUTOR_LINK = "https://pik-comfort.ispringonline.ru/"
export const ROSREESTR_PATH =
  "https://rosreestr.pik-software.ru/rosreestr/rosreestr-statements/xml"
export const YANDEX_MAP_LINK = "https://yandex.ru/maps"

const WIKI_LINK = "https://info.pik.ru/spaces/PIK-COMFORT/pages"
export const INVENTORY_WIKI_LINK = `${WIKI_LINK}/11119`
export const BIT_FINANCE_WIKI_LINK = `${WIKI_LINK}/12363`
export const TABLEAU_WIKI_LINK = `${WIKI_LINK}/12549`
