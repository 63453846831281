/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, makeObservable, observable, ObservableMap } from "mobx"
import {
  ClazzOrModelSchema,
  deserialize,
  getDefaultModelSchema,
} from "serializr"

import { isBaseEntity, objWithType } from "pik-react-utils/entities"
import { EntityModelService } from "pik-react-utils/services"
import { getPreparedSchema, shouldUpdateEntity } from "pik-react-utils/utils"
import { normalizer } from "./normalizer"
import { unionEntities } from "./utils"

export class EntitiesCache {
  private entities: ObservableMap<string, ObservableMap<string, IRawEntity>>
  private modelsService: EntityModelService

  constructor(modelsService: EntityModelService) {
    makeObservable<EntitiesCache, "saveEntity">(this, {
      saveEntity: action.bound,
    })
    this.entities = observable.map(new Map(), { deep: false })
    this.modelsService = modelsService
  }

  private getClassByType<T>(type: string) {
    return this.modelsService.getClassByType<T>(type)
  }

  private hasInCache(entity: IRawEntity | unknown, useSchemaFields?: boolean) {
    if (!isBaseEntity(entity)) return
    const { _uid, _type } = entity
    if (!useSchemaFields) {
      return this.entities.get(_type)?.has(_uid)
    }
    const cachedEntity = this.entities.get(_type)?.get(_uid)
    if (!cachedEntity) return false

    const EntityClass = this.getClassByType(entity._type)
    const schema = getPreparedSchema(EntityClass.schema)
    const requiredFields = new Set([...Object.keys(schema), "_uid"])

    // it's not required field, some models don't have this fields (it's OK)
    requiredFields.delete("deleted")
    requiredFields.delete("visible_name")

    return [...requiredFields].every((slug) => slug in cachedEntity)
  }

  private cachedEntity(entity: IRawEntity | unknown) {
    if (!isBaseEntity(entity)) return
    const { _uid, _type } = entity
    return this.entities.get(_type)?.get(_uid)
  }

  private clearEntity(entity: IEntityLink) {
    if (!isBaseEntity(entity)) return
    const { _type, _uid } = entity
    this.entities.get(_type)?.delete(_uid)
  }

  clear(entity?: IEntityLink): void {
    if (entity) {
      this.clearEntity(entity)
    } else {
      this.entities.clear()
    }
  }

  getEntityFromCache<T extends IEntity>(
    entity: IEntityLink,
    useSchemaFields?: boolean
  ): T | undefined {
    const hasInCache = this.hasInCache(entity, useSchemaFields)
    if (!hasInCache) return
    const EntityClass = this.getClassByType<T>(entity._type)
    return this.deserializer<T>(EntityClass, entity)
  }

  private saveEntity(newEntity: IRawEntity) {
    const cachedEntity = this.cachedEntity(newEntity)

    if (!shouldUpdateEntity(newEntity, cachedEntity)) {
      return
    }

    const rawEntity = unionEntities(newEntity, cachedEntity)
    const { _type, _uid } = rawEntity

    if (!this.entities.has(_type)) {
      this.entities.set(_type, observable.map(new Map(), { deep: false }))
    }

    this.entities.get(_type)?.set(_uid, rawEntity)
  }

  updateCache(source: IRawEntity[] | IRawEntity) {
    const entities = normalizer(source)
    entities.forEach(this.saveEntity)
  }

  private deserializer<T extends Record<string, unknown> | IEntity>(
    EntityClass: ClazzOrModelSchema<T> | undefined,
    item: unknown
  ) {
    if (!objWithType(item) || !EntityClass) return item as T

    const rawEntity = isBaseEntity(item) ? this.cachedEntity(item) : item
    const entity = deserialize<T>(EntityClass, rawEntity)
    return this.denormalizer(entity)
  }

  private denormalizer<T extends Record<string, any>>(entity: T) {
    const deserializeItem = (value: unknown) => {
      const modelSchema = getDefaultModelSchema<Record<string, unknown>>(value)
      if (!modelSchema) return value
      return this.deserializer(modelSchema, value)
    }

    for (const key in entity) {
      const currentValue = entity[key]
      if (Array.isArray(currentValue)) {
        const data = (entity[key] as unknown[]).map(deserializeItem)
        Object.assign(entity, { [key]: data })
      } else if (objWithType(currentValue)) {
        const data = deserializeItem(currentValue)
        Object.assign(entity, { [key]: data })
      }
    }

    return entity
  }
}
