export const {
  REACT_APP_API: API_URL,
  REACT_APP_SENTRY_KEY: SENTRY_KEY,
  REACT_APP_AUTH_API: AUTH_URL,
  REACT_APP_CLIENT_ID: CLIENT_ID,
  REACT_APP_AG_GRID_LICENCE_KEY: AG_GRID_LICENSE_KEY,
  APP_RELEASE_VERSION,
  NODE_ENV,
} = process.env

export const isProduction = NODE_ENV === "production"
