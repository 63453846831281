import { AxiosError, AxiosResponse } from "axios"

import {
  OAUTH_AUTHORIZE_REDIRECT_PATHNAME,
  OAUTH_FORBID_REDIRECT_PATHNAME,
} from "pik-react-utils/constants"
import { IAuthConfig, MethodType } from "pik-react-utils/types"
import { getSearchQueryParam, matchPathName } from "./helpers"

import {
  IAuthService,
  IFetchRequestData,
  FetchResponse,
  IUserInfo,
  SessionData,
} from "./types"
import { sessionManager } from "./sessionManager"
import { tokenManager } from "./tokenManager"

import {
  oauthStatusUri,
  oauthLoginUri,
  oauthForbidUri,
  oauthAthorizeRedirectUri,
  oauthForbidRedirectUri,
  oauthUserInfoUri,
} from "./utils"

export class PikAuthService implements IAuthService {
  private clientId: string
  private authUrl: string

  constructor({ clientId, authUrl }: IAuthConfig) {
    this.clientId = clientId
    this.authUrl = authUrl
    this.initService()
  }

  authorize() {
    if (!this.authUrl || !this.clientId) {
      throw new Error("Need authUrl or clienId")
    }

    sessionManager.saveSessionUri()
    const redirectUri = oauthAthorizeRedirectUri(window.location.origin)
    const authStatusUri = oauthStatusUri(
      this.authUrl,
      redirectUri,
      this.clientId
    )
    window.location.replace(oauthLoginUri(authStatusUri, this.authUrl))
  }

  forbid() {
    sessionManager.saveForbidState()

    const redirectUri = oauthForbidRedirectUri(window.location.origin)
    const { idToken } = sessionManager.sessionData
    const STATE = sessionManager.getForbidState()

    window.location.href = oauthForbidUri(
      this.authUrl,
      redirectUri,
      idToken,
      STATE
    )
  }

  handleAuthError(e: AxiosError): void {
    if (e.response && this.authError(e.response)) {
      this.authorize()
    }
  }

  private initService(): void {
    if (this.isForbidden()) {
      sessionManager.removeSession()
      window.location.href = "/"
    } else {
      this.checkToken()
      this.setUserInfo()
    }
  }

  private isForbidden(): boolean {
    if (!matchPathName(OAUTH_FORBID_REDIRECT_PATHNAME)) {
      return false
    }

    const stateFromQuery = getSearchQueryParam("state")
    const stateFromStorage = sessionManager.getForbidState()

    return (
      stateFromQuery !== null && Number(stateFromQuery) === stateFromStorage
    )
  }

  private checkToken(): void {
    const { accessToken, idToken } = tokenManager.getTokensFromUri()

    if (
      matchPathName(OAUTH_AUTHORIZE_REDIRECT_PATHNAME) &&
      accessToken &&
      idToken
    ) {
      sessionManager.setSession({ accessToken, idToken })
      window.location.href = sessionManager.getPrevSessionUri()
      sessionManager.removePrevSessionUri()
    } else if (!sessionManager.isAuthenticated) {
      this.authorize()
    }
  }

  private async setUserInfo() {
    const { idToken, accessToken } = sessionManager.sessionData

    if (!idToken || !accessToken) {
      return
    }

    const userInfo = await this.fetchUserInfo(accessToken, this.authUrl)

    sessionManager.setUserInfo(userInfo)
  }

  private fetchUserInfo(
    accessToken: string,
    authUrl: string
  ): Promise<IUserInfo> {
    const userInfoUri = oauthUserInfoUri(authUrl, accessToken)
    const requestData: IFetchRequestData = {
      method: MethodType.GET,
      uri: userInfoUri,
    }

    return this.request(requestData)
      .then((response: FetchResponse) => response.json())
      .then((data) => data)
  }

  private authError(response: AxiosResponse) {
    const { status, data } = response
    const { message } = data

    return (
      (status === 403 || status === 401) &&
      (message === "invalidToken" || message === "not_authenticated")
    )
  }

  private request = ({
    uri,
    method,
    redirect,
    headers,
  }: IFetchRequestData): Promise<SessionData | object> => {
    return fetch(uri, { method, redirect, headers })
      .then((resp) => {
        if (resp.status === 401) {
          this.authorize()
        } else if (resp.status === 0 && !this.clientId) {
          throw new Error("Auth no clientId")
        } else if (resp.status === 0) {
          console.warn("Auth request status = 0")
        }

        return resp
      })
      .catch((e) => {
        this.forbid()
        return new Error(`Get error: ${e}`)
      })
  }
}
