import { BUILDING_ALL_ATTRS_GROUP_UID, ELEVATOR_ALL_ATTRS_GROUP_UID, NSI_SERVICE_UID } from "constants/subscribe-groups";

export const  SUBSCRIBABLE_ENTITY_TO_DATA = {
  inventorybuilding: {
    target_service: NSI_SERVICE_UID,
    permission_group: BUILDING_ALL_ATTRS_GROUP_UID,
  },
  inventoryutilitysystem: {
    target_service: NSI_SERVICE_UID,
    permission_group: ELEVATOR_ALL_ATTRS_GROUP_UID,
  },
}
