import React from "react"
import { observer } from "mobx-react"
import { action, observable, computed, reaction, makeObservable } from "mobx"
import { CellClickedEvent } from "ag-grid-community"

import { DocumentList, DocumentListRef } from "components"
import { AgGridInspector, AgGridInspectorRef } from "pik-react-utils/components"
import { SUBSCRIBABLE_ENTITY_TO_DATA } from "./constants"
import { Content } from "./components"
import { InventoryBuildingStore } from "../InventoryBuildingStore"
import { PrivateFields } from "./types"

type InspectorProps = {
  store: InventoryBuildingStore
  onCreate: () => void
  onUpdate: () => void
}

export class InspectorRef extends React.Component<InspectorProps> {
  private openDocumentsByField: boolean
  private readonly inspectorRef = React.createRef<AgGridInspectorRef>()
  private readonly documentTabRef = React.createRef<DocumentListRef>()
  private reactionDisposer = reaction(
    () => this.inventoryStore.isAbleDocuments,
    () => {
      this.openDocumentsByField = false
    }
  )

  constructor(props: InspectorProps) {
    super(props)
    makeObservable<InspectorRef, PrivateFields>(this, {
      openDocumentsByField: observable.struct,
      inspectorRef: observable.ref,
      documentTabRef: observable.ref,
      inventoryStore: computed,
      selectedEntity: computed,
      openDocumentList: action.bound,
      changeSelectedSlug: action.bound,
      refresh: action.bound,
    })
  }

  componentWillUnmount(): void {
    this.reactionDisposer()
  }

  private get inventoryStore() {
    return this.props.store
  }

  private get selectedEntity() {
    return this.inventoryStore.selectedEntity
  }

  private openDocumentList(event: CellClickedEvent) {
    if (!this.inventoryStore.isAbleDocuments) return
    this.inventoryStore.setAttribute(event.data)
    this.openDocumentsByField = !this.openDocumentsByField
  }

  private changeSelectedSlug(event: CellClickedEvent) {
    if (!this.openDocumentsByField) return
    this.inventoryStore.setAttribute(event.data)
  }

  refresh() {
    this.inspectorRef.current?.refresh()
    this.documentTabRef.current?.refresh()
  }

  render() {
    if (!this.selectedEntity) return null

    const { _real_type, _type, _uid } = this.selectedEntity
    const entityLink = { _type: _real_type ?? _type, _uid }
    const { onCreate, onUpdate } = this.props

    return (
      <Content>
        <AgGridInspector
          ref={this.inspectorRef}
          subscribableData={SUBSCRIBABLE_ENTITY_TO_DATA[_type]}
          entityLink={entityLink}
          onCellDoubleClicked={this.openDocumentList}
          onCellClicked={this.changeSelectedSlug}
          onSave={onUpdate}
        />
        {this.openDocumentsByField && (
          <DocumentList
            ref={this.documentTabRef}
            entityType="building"
            query={this.inventoryStore.documentQuery}
            onCreate={onCreate}
            onSuccess={onUpdate}
            onUpdate={onUpdate}
            slug={this.inventoryStore.attribute?.slug}
          />
        )}
      </Content>
    )
  }
}

export const Inspector = React.forwardRef(function (
  props: InspectorProps,
  ref: React.LegacyRef<InspectorRef>
) {
  const Inspector = observer(InspectorRef)
  return <Inspector {...props} ref={ref} />
})
