import { getLink, isBaseEntity } from "pik-react-utils/entities"
import { shouldUpdateEntity } from "pik-react-utils/utils"
import { unionEntities } from "./utils"

export function normalizeValue(entity: IRawEntity) {
  const newEntity = Object.assign({}, entity)
  for (const key in entity) {
    if (isBaseEntity(entity[key])) {
      newEntity[key] = getLink(entity[key])
    } else if (Array.isArray(entity[key])) {
      newEntity[key] = (entity[key] as (IRawEntity | unknown)[]).map((item) =>
        isBaseEntity(item) ? getLink(item) : item
      )
    }
  }

  return newEntity
}

function getKey({ _uid, _type }: IRawEntity) {
  return `${_type}:${_uid}`
}

class NormalizeContext {
  private entities: Map<string, IRawEntity> = new Map()

  constructor(rawData: IRawEntity[] | IRawEntity) {
    this.normalize(rawData)
  }

  private normalizeSingle(rawData: IRawEntity | unknown) {
    if (!isBaseEntity(rawData)) return
    const entity = normalizeValue(rawData as IRawEntity)
    const entityKey = getKey(entity)
    const cachedEntity = this.entities.get(entityKey)
    if (shouldUpdateEntity(entity, cachedEntity)) {
      const newEntity = unionEntities(entity, cachedEntity)
      this.entities.set(entityKey, newEntity)
    }

    for (const key in rawData) {
      if (typeof rawData[key] === "object") {
        this.normalize(rawData[key] as IRawEntity | IRawEntity[])
      }
    }
  }

  private normalize(rawData: IRawEntity[] | IRawEntity) {
    if (Array.isArray(rawData)) {
      for (const entity of rawData) {
        this.normalizeSingle(entity)
      }
    } else if (isBaseEntity(rawData)) {
      this.normalizeSingle(rawData)
    }
  }

  getEntities() {
    return Array.from(this.entities).map(([_, value]) => value)
  }
}

export function normalizer(rawData: IRawEntity[] | IRawEntity) {
  const context = new NormalizeContext(rawData)
  return context.getEntities()
}
