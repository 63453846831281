import { object, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { Contractor } from "./contractor"
import { InventoryBuilding } from "./inventorybuilding"

const schema: ISchema = {
  document_fields: { name: "Поля заданные документами", _type: "string[]" },
  empowered_developer: {
    name: "Застройщик",
    _type: "entitylink",
    entityLinkTo: Contractor,
  },
  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class BuildingSnapshot extends InventoryBuilding {
  static readonly schema: ISchema = schema

  history_date: string
  readonly document_fields: string[]
  building: BaseEntity
}

createSerializrSchema(BuildingSnapshot, schema, {
  history_date: primitive(),
  building: object(BaseEntity),
})
