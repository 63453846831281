import React from "react"
import { Button, Tabs } from "antd"
import { observer } from "mobx-react"
import { action, computed, makeObservable, observable } from "mobx"
import dayjs from "dayjs"

import { INVENTORY_ACCESS_DESCRIPTION } from "constants/description"
import {
  AccessPortal,
  DocumentList,
  DocumentListRef,
  QueryPanel,
  Widget,
} from "components"
import { ColumnHeader, ColumnWithHeader, TwoColumnLayout } from "components/css"
import { CreateEntityModal } from "modals"
import { routerService } from "services"
import { BuildingType } from "./constants"
import { BuildingTree, BuildingTreeRef } from "./BuildingTree"
import { Datasource } from "./Datasource"
import { Inspector, InspectorRef } from "./Inspector"
import { InventoryBuildingStore } from "./InventoryBuildingStore"
import { InventoryQueryProps, PrivateFields } from "./types"

const Buildings = observer(
  class Buildings extends React.Component {
    private buildingModalType: BuildingType | null = null
    private readonly inspectorRef = React.createRef<InspectorRef>()
    private readonly buildingTreeRef = React.createRef<BuildingTreeRef>()
    private readonly documentTabRef = React.createRef<DocumentListRef>()
    private readonly router = routerService
    private readonly inventoryStore

    constructor(props: Record<string, unknown>) {
      super(props)
      makeObservable<Buildings, PrivateFields>(this, {
        buildingModalType: observable,
        inspectorRef: observable.ref,
        isCreatingNewBuilding: computed.struct,
        closeModal: action.bound,
        openModal: action.bound,
        updateSource: action.bound,
        onCreateRefresh: action.bound,
        buildingTreeRefresh: action.bound,
        updateQuery: action.bound,
        onChangeTab: action.bound,
      })
      this.inventoryStore = new InventoryBuildingStore(
        this.router.getSearchParams()
      )
    }

    async componentDidMount() {
      await this.inventoryStore.initDefaultFilters()
      this.updateSource()
    }

    private get isCreatingNewBuilding() {
      return this.buildingModalType === BuildingType.NEW
    }

    private closeModal() {
      this.buildingModalType = null
    }

    private openModal(type: BuildingType) {
      this.buildingModalType = type
    }

    private updateSource() {
      const data = new Datasource({ query: this.inventoryStore.query })
      this.buildingTreeRef.current?.updateSource(data)
    }

    private onCreateRefresh() {
      this.inventoryStore.flushLists("inventorybuilding")
      if (!this.isCreatingNewBuilding) {
        this.documentTabRef.current?.refresh()
      }
      if (this.inventoryStore.attribute) {
        this.inspectorRef.current?.refresh()
      }
      this.buildingTreeRefresh()
    }

    private buildingTreeRefresh() {
      this.buildingTreeRef.current?.refresh()
    }

    private updateQuery(filters: Partial<InventoryQueryProps>) {
      this.inventoryStore.updateFilters(filters)
    }

    private onChangeTab() {
      this.inventoryStore.setAttribute(null)
    }

    render() {
      return (
        <>
          <TwoColumnLayout>
            <ColumnWithHeader>
              <ColumnHeader>
                <QueryPanel
                  store={this.inventoryStore}
                  entityType="building"
                  onUpdate={this.updateSource}
                  input={{ placeholder: "Объект эксплуатации" }}
                />
                <Button onClick={() => this.openModal(BuildingType.NEW)}>
                  Создать
                </Button>
              </ColumnHeader>
              <BuildingTree
                ref={this.buildingTreeRef}
                store={this.inventoryStore}
              />
            </ColumnWithHeader>
            <Tabs
              animated={false}
              size="small"
              onChange={this.onChangeTab}
              defaultActiveKey="regular"
              destroyInactiveTabPane
              items={[
                {
                  key: "regular",
                  label: "Характеристики",
                  children: (
                    <Inspector
                      ref={this.inspectorRef}
                      store={this.inventoryStore}
                      onCreate={() => this.openModal(BuildingType.CHANGING)}
                      onUpdate={this.buildingTreeRefresh}
                    />
                  ),
                },
                ...(this.inventoryStore.isAbleDocuments
                  ? [
                      {
                        key: "docs",
                        label: "Документы",
                        children: (
                          <DocumentList
                            ref={this.documentTabRef}
                            entityType="building"
                            query={this.inventoryStore.documentQuery}
                            onCreate={() =>
                              this.openModal(BuildingType.CHANGING)
                            }
                            onSuccess={this.buildingTreeRefresh}
                            onUpdate={this.buildingTreeRefresh}
                          />
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          </TwoColumnLayout>
          <CreateEntityModal
            isDocument
            entityType="buildingchange"
            title={
              this.isCreatingNewBuilding ? "Создать здание" : "Новый документ"
            }
            open={!!this.buildingModalType}
            onClose={this.closeModal}
            onSuccess={this.onCreateRefresh}
            defaultValues={{
              document_date: dayjs().toISOString(),
              document_is_draft: false,
              ...(!this.isCreatingNewBuilding && {
                building: {
                  _type: "building",
                  _uid: this.inventoryStore.selectedEntity?._uid,
                },
              }),
            }}
            referenceLink={
              this.isCreatingNewBuilding
                ? undefined
                : this.inventoryStore.selectedEntity
            }
          />
          <Widget
            entityType="inventorybuilding"
            title="зданий"
            onShowCreated={(date) => this.updateQuery({ created__gte: date })}
            onShowUpdated={(date) => this.updateQuery({ updated__gte: date })}
          />
        </>
      )
    }
  }
)

export const InventoryBuildings = observer(() => (
  <AccessPortal
    entityType="inventorybuilding"
    title={INVENTORY_ACCESS_DESCRIPTION.title}
    description={INVENTORY_ACCESS_DESCRIPTION.description}
  >
    <Buildings />
  </AccessPortal>
))
