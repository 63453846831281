import { ACCESS_TOKEN_NAME, ID_TOKEN_NAME } from "pik-react-utils/constants"
import { storageService } from "./storageService"
import { getHashQueryParam } from "./helpers"
import { ITokenManager, SessionData } from "./types"

class TokenManager implements ITokenManager {
  accessToken: string | undefined = undefined
  idToken: string | undefined = undefined

  constructor() {
    const storedAccessToken: string | undefined =
      this.getToken(ACCESS_TOKEN_NAME)

    const storedIdToken: string | undefined = this.getToken(ID_TOKEN_NAME)

    if (storedAccessToken && storedIdToken) {
      this.setAccessToken(storedAccessToken, ACCESS_TOKEN_NAME)
      this.setIdToken(storedIdToken, ID_TOKEN_NAME)
    }
  }

  setAccessToken(accessToken: string, name: string): void {
    this.accessToken = accessToken
    storageService.setItem(name, accessToken)
  }

  setIdToken(idToken: string, name: string): void {
    this.idToken = idToken
    storageService.setItem(name, idToken)
  }

  getToken(tokenName: string): string | undefined {
    const token = storageService.getItem(tokenName)
    return token !== null ? token : undefined
  }

  removeTokens(): void {
    this.accessToken = undefined
    this.idToken = undefined
    storageService.removeItem(ACCESS_TOKEN_NAME)
    storageService.removeItem(ID_TOKEN_NAME)
  }

  getTokensFromUri(): SessionData {
    const a = getHashQueryParam("#access_token")
    const i = getHashQueryParam("id_token")

    return {
      accessToken: a !== null ? a : undefined,
      idToken: i != null ? i : undefined,
    }
  }
}

export const tokenManager = new TokenManager()
