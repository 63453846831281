import React from "react"
import { ColDef, ICellRendererParams } from "ag-grid-community"
import { DeleteOutlined, UndoOutlined } from "components/antd"

export const actionColDef = (onClick: () => void): ColDef => ({
  field: "deleted",
  headerName: "",
  cellRendererParams: (params: ICellRendererParams) => {
    const isDeleted = !!params.data?.deleted
    return {
      ...params,
      type: "link",
      danger: !isDeleted,
      icon: isDeleted ? <UndoOutlined /> : <DeleteOutlined />,
      onClick,
    }
  },
  cellRenderer: "ButtonRenderer",
  tooltipValueGetter: ({ data }) =>
    data?.deleted ? "Восстановить" : "Удалить",
  suppressSizeToFit: true,
  width: 50,
})
