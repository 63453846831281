import { primitive } from "serializr"

import { FOUNDATION_TYPE_CHOICES } from "constants/choices"
import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupNameFields } from "utils/fields"
import { Building } from "./building"

const schema: ISchema = {
  ...groupNameFields(),
  type: {
    name: "Тип основания",
    _type: "choices",
    choices: FOUNDATION_TYPE_CHOICES,
  },
  building: {
    name: "Здание",
    _type: "entitylink",
    entityLinkTo: Building,
  },
  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class Foundation extends BaseEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(Foundation, schema, {
  detailed_name: primitive(),
  comments: primitive(),
  actualization_date: primitive(),
})
