import React from "react"
import styled from "styled-components"

import { COLOR_BLACK, COLOR_WHITE } from "constants/ui-colors"
import { MENU_ITEM_SHADOW, MENU_ITEM_SHADOW_HOVER } from "constants/ui-shadows"
import { NavMenuType } from "types"

export const Wrapper = styled.div`
  display: grid;
  position: relative;
  grid-gap: 24px;
  justify-content: center;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(350px, 384px));
  grid-auto-rows: 1fr;
`

export const Item = styled.a`
  display: flex;
  padding: 32px;
  background: ${COLOR_WHITE};
  box-shadow: ${MENU_ITEM_SHADOW};
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: ${MENU_ITEM_SHADOW_HOVER};
  }
`

export const Icon = styled.span`
  background-image: url(${(props: { icon: string }) => props.icon});
  background-position: top left;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 24px;
`

export const Description = styled.div`
  color: ${COLOR_BLACK};

  h2 {
    font-size: 1.25rem;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 0;
    color: rgba(51, 51, 51, 0.8);
  }
`

export const NavCardList = ({ items }: { items: NavMenuType[] }) => (
  <Wrapper>
    {items.map(({ key, link, description, label, icon }) => (
      <Item href={link} key={key ?? link}>
        {icon && <Icon icon={icon} />}
        <Description>
          <h2>{label}</h2>
          <p>{description}</p>
        </Description>
      </Item>
    ))}
  </Wrapper>
)
