import React, { ReactElement } from "react"

export const LogoutIcon = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" fill="none">
      <path
        fill="#000"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth=".471"
        d="M1.245 1.282a1.57 1.57 0 0 0-.28.926v10.584c0 .376.114.703.28.926.166.224.364.325.548.325h5.524c.184 0 .382-.1.548-.325.166-.223.28-.55.28-.926 0-.2.163-.361.364-.361.202 0 .365.162.365.36 0 .515-.154.994-.42 1.354-.266.359-.664.62-1.137.62H1.793c-.473 0-.871-.261-1.137-.62a2.283 2.283 0 0 1-.42-1.353V2.208c0-.514.154-.993.42-1.353.266-.359.664-.62 1.137-.62h5.5c.473 0 .87.261 1.137.62.266.36.42.84.42 1.353 0 .2-.163.361-.365.361a.363.363 0 0 1-.365-.36 1.57 1.57 0 0 0-.28-.927c-.165-.224-.364-.325-.547-.325h-5.5c-.184 0-.382.1-.548.325z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth=".471"
        d="M4.324 7.5c0-.2.163-.36.365-.36h10.099c.201 0 .365.16.365.36 0 .2-.164.361-.365.361H4.689a.363.363 0 0 1-.365-.36z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth=".471"
        d="M10.952 3.54a.368.368 0 0 1 .517 0l3.747 3.705c.143.14.143.37 0 .51a.368.368 0 0 1-.516 0l-3.748-3.704a.358.358 0 0 1 0-.51z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth=".471"
        d="M15.216 7.245a.357.357 0 0 1 0 .51L11.47 11.46a.368.368 0 0 1-.517 0 .358.358 0 0 1 0-.51L14.7 7.244a.368.368 0 0 1 .516 0z"
        clipRule="evenodd"
      />
    </svg>
  )
}
