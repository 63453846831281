import { action, computed, makeObservable, observable } from "mobx"
import { entitiesStore } from "pik-react-utils/stores"
import { BaseQueryParams, FilterType, QueryArgs } from "types"

export class BaseQueryStore<T extends QueryArgs = QueryArgs> {
  private _filters: T = Object.create({})
  private _search
  private _filterFields: FilterType[]
  protected entitiesStore = entitiesStore

  constructor(params: BaseQueryParams<T>) {
    makeObservable<BaseQueryStore, "_filters" | "_filterFields" | "_search">(
      this,
      {
        _filters: observable,
        _filterFields: observable,
        _search: observable,
        defaultFilters: computed,
        filterEditingFields: computed,
        hasFilterEditingFields: computed,
        query: computed,
        activeFiltersCount: computed,
        searchParamsForURL: computed,
        updateSearch: action.bound,
        updateFilters: action.bound,
        resetQuery: action.bound,
        updateQuery: action.bound,
        setFields: action.bound,
      }
    )
    this._filterFields = params.filterFields || []
    this._search = params.search ?? ""

    this._filters = Object.assign(
      this._filters,
      this.defaultFilters,
      params.filters
    )
  }

  get defaultFilters() {
    return this._filterFields.reduce(
      (acc, { slug, defaultValue }) => ({ ...acc, [slug]: defaultValue }),
      {}
    )
  }

  get filterEditingFields() {
    return this._filterFields.filter((item) => item._type)
  }

  get hasFilterEditingFields() {
    return !!this.filterEditingFields.length
  }

  get query() {
    return { ...this._filters, search: this._search }
  }

  get activeFiltersCount() {
    return Object.entries(this._filters).reduce<number>((acc, [key, value]) => {
      const isEditableField = this.filterEditingFields.some(
        (item) => item.slug === key
      )
      if (!isEditableField) return acc
      if (key === "is_deleted") {
        return value === false ? acc : acc + 1
      }
      return value ? acc + 1 : acc
    }, 0)
  }

  get searchParamsForURL() {
    const params: { search?: string; filters?: QueryArgs } = {}
    if (this._search) {
      params.search = this._search
    }

    Object.entries(this._filters).map(([key, value]) => {
      if (value == null) return

      if (!params.filters) params.filters = {}
      params.filters[key] = value
    })

    return params
  }

  updateSearch(search = "") {
    this._search = search
  }

  updateFilters(filters: Partial<T> | null) {
    this._filters = { ...this._filters, ...filters }
  }

  resetQuery(filters: T, search = "") {
    this._filters = Object.assign(this.defaultFilters, filters)
    this._search = search
  }

  setFields(fields: FilterType[]) {
    this._filterFields = fields
  }

  updateQuery({ filters, search }: BaseQueryParams<T>) {
    this.resetQuery(Object.assign(Object.create({}), filters), search)
  }
}
