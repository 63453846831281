import { message } from "antd"
import { cloneDeep } from "lodash"
import { ValueSetterParams } from "ag-grid-community"
import { action, makeAutoObservable, remove } from "mobx"

import { entitiesStore } from "pik-react-utils/stores"
import {
  checkIsSame,
  getFieldsErrors,
  ResponseError,
} from "pik-react-utils/utils"
import {BaseEntity, getLink } from "pik-react-utils/entities"
import { APIConfig } from "pik-react-utils/types"
import { getDocumentFields } from "./utils"

interface InspectorEntity extends BaseEntity {
  readonly document_fields: string[]
  full_name?: string
  document_name?: string
}

interface IStore {
  changes: Record<string, unknown>
  errors: Record<string, unknown>
  changedFields: string[]
  saveChanges: (
    isDocument?: boolean,
    requestFormatter?: (
      changes: Record<string, unknown>
    ) => Record<string, unknown>
  ) => Promise<void>
  retrieveEntity: (entityLink: IEntityLink | undefined) => void
  setVisibleFields: (fields?: string[]) => void
  isNew: boolean
  clear: (defaultValues?: Record<string, unknown> | null) => void
  loading: boolean
  setReferenceValues: (params?: IEntity) => Promise<void>
  isCreateMore: boolean
  onCreateMoreChange: (value: boolean) => void
  documentFields: string[]
}

export class InspectorStore implements IStore {
  loading = false
  isCreateMore: boolean
  entity: InspectorEntity | undefined
  visibleFields: string[]
  changes: Record<string, unknown> = {}
  private referenceValues: IEntity | undefined
  errors: Record<string, unknown> = {}
  private store = entitiesStore

  constructor() {
    makeAutoObservable<InspectorStore, "onCreateMoreChange">(this, {
      onCreateMoreChange: action.bound,
    })
  }

  private get mainType() {
    return this.entity?._type.replace("change", "") ?? ""
  }

  private entityTitle(entity: InspectorEntity | undefined) {
    return entity?.name || entity?.full_name || entity?.document_name || ""
  }

  async setReferenceValues(
    entityLink: IEntity | undefined,
    config?: APIConfig
  ) {
    this.referenceValues = entityLink
      ? await this.store.getEntity(entityLink, {
          ...config,
          useSchemaFields: true,
        })
      : undefined
  }

  clear(defaultValues: Record<string, unknown> | null = null) {
    this.clearChanges(defaultValues)
    this.clearErrors()
    this.loading = false
  }

  clearChanges(defaultValues: Record<string, unknown> | null = null) {
    this.changes = defaultValues ? cloneDeep(defaultValues) : {}
  }

  setChanges(slug: string, newValue: unknown) {
    this.changes[slug] = newValue
  }

  removeChanges(slug: string) {
    if (this.changes[slug]) {
      remove(this.changes, slug)
    }
  }

  clearErrors() {
    this.errors = {}
  }

  async refresh(
    props: {
      referenceLink?: IEntity
      defaultValues?: Record<string, unknown> | null
      entityLink?: IEntityLink
    },
    config?: APIConfig
  ) {
    this.clearChanges(props.defaultValues)
    await this.retrieveEntity(props.entityLink, config)
    await this.setReferenceValues(props.referenceLink, config)
  }

  async saveChanges(
    isDocument: boolean | undefined,
    requestFormatter?: (
      changes: Record<string, unknown>
    ) => Record<string, unknown>
  ) {
    try {
      this.clearErrors()
      this.loading = true

      if (this.isNew) {
        await this.createEntity(isDocument, requestFormatter)
      } else {
        await this.updateEntity(isDocument)
      }
    } catch (error) {
      this.handleError(error)
      throw error
    } finally {
      this.loading = false
    }
  }

  setPropertyValue(
    { data, oldValue, newValue }: ValueSetterParams,
    defaultValues: Record<string, unknown> | null = null
  ) {
    const slug = data.slug

    if (checkIsSame(oldValue, newValue)) {
      this.removeChanges(slug)
    } else {
      this.setChanges(slug, newValue)
    }

    if (
      checkIsSame(
        this.changes[slug],
        defaultValues?.[slug] ?? this.referenceValues?.[slug]
      )
    ) {
      this.removeChanges(slug)
    }

    this.clearErrors()
    return true
  }

  async createEntity(
    isDocument: boolean | undefined,
    requestFormatter?: (
      changes: Record<string, unknown>
    ) => Record<string, unknown>
  ) {
    if (!this.entity) return
    const changes = requestFormatter?.(this.changes) ?? this.changes

    if (isDocument) {
      changes.document_fields = getDocumentFields(
        this.mainType,
        Object.keys(changes)
      )
    }

    const entity = await this.store.createEntity<InspectorEntity>(
      { ...this.entity, ...changes },
      { handleError: false }
    )
    const title = this.entityTitle(entity)
    this.entity = this.store.createDummy(this.entity._type)
    if (!this.isCreateMore) {
      this.clearChanges()
    }

    message.success(
      title
        ? `Объект "${title}" был успешно создан`
        : "Объект был успешно создан"
    )
  }

  async updateEntity(isDocument?: boolean) {
    const changes: {
      _type: string
      _uid: string
      [key: string]: unknown
    } = Object.assign({}, getLink(this.entity), this.changes)

    if (isDocument) {
      changes.document_fields = getDocumentFields(this.mainType, [
        ...(this.entity?.document_fields ?? []),
        ...Object.keys(this.changes),
      ])
    }

    this.entity = await this.store.updateEntity<InspectorEntity>(changes, {
      handleError: false,
    })
    this.clearChanges()
    const title = this.entityTitle(this.entity)
    message.success(`Объект "${title}" был успешно сохранен`)
  }

  private handleError(error: ResponseError) {
    this.errors = getFieldsErrors(error)
  }

  async retrieveEntity(
    entityLink: IEntityLink | undefined,
    config?: APIConfig
  ) {
    if (!entityLink) {
      this.entity = undefined
      return
    }
    const { _type, _uid } = entityLink

    this.entity = _uid
      ? await this.store.getEntity<InspectorEntity>(entityLink, {
          ...config,
          useSchemaFields: true,
        })
      : this.store.createDummy<InspectorEntity>(_type)
  }

  setVisibleFields(fields: string[] = []) {
    this.visibleFields = fields
  }

  onCreateMoreChange(value: boolean) {
    this.isCreateMore = value
  }

  get isNew() {
    return this.entity ? !this.entity?._uid : false
  }

  get documentFields() {
    return this.entity?.document_fields ?? []
  }

  get fields(): IEntityField[] {
    if (!this.entity) return []

    const fields = this.store.getAgGridFieldsByType(this.entity._type)

    const rows = fields.reduce((acc, field) => {
      if ("children" in field) {
        const { children, ...rest } = field
        const newFields = this.isNew
          ? children.filter((field) => !field.readOnly)
          : children
        if (!newFields.length) return acc
        return [...acc, { ...rest, isGroup: true }, ...newFields]
      }
      if (this.isNew && field.readOnly) {
        return acc
      }
      return [...acc, field]
    }, [])

    if (this.visibleFields?.length) {
      return rows.filter((field) => this.visibleFields?.includes(field.slug))
    }

    return rows
  }

  get changedFields() {
    return Object.keys(this.changes ?? {})
  }

  get rowData() {
    if (!this.fields.length) return []

    return this.fields.map((field) => {
      const { slug, name, parent } = field
      const value = parent ? this.entity?.[parent]?.[slug] : this.entity?.[slug]
      return {
        ...field,
        name,
        value:
          slug in this.changes
            ? this.changes[slug]
            : value ?? this.referenceValues?.[slug],
      }
    })
  }
}
