import { api } from "./api"
import { sentry, SentryConfig } from "./sentry"
import { entitiesStore } from "./stores"
import type { IInitConfig } from "./types"

export const pikConfig = {
  initSentry: (config: SentryConfig) => sentry.init(config),
  initApi: (config: IInitConfig) => api.init(config),
  setModels: (models: { [key: string]: ModelConstructor<object> }) =>
    entitiesStore.setModel(models),
  terminate: () => entitiesStore.dropStore(),
}
