import { Form as _Form } from "antd"
import styled from "styled-components"

export const FormFooter = styled.footer`
  position: sticky;
  bottom: 0;
  border-top: 1px solid #e9e9e9;
  padding: 16px 0;
  background-color: #fff;
  display: flex;
  margin-top: auto;
  justify-content: flex-end;

  button {
    margin-left: 16px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

export const Form = styled(_Form)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
