import { ICellRendererParams } from "ag-grid-community"

export const BooleanChoiceRenderer = ({ value }: ICellRendererParams) => {
  switch (value) {
    case true:
      return "Да"
    case false:
      return "Нет"
    default:
      return "Не задано"
  }
}
