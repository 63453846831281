import { createBrowserHistory, History } from "history"
import { generatePath, matchPath, To } from "react-router"

import { InventoryStructurePageTabs, RequestPageTabs } from "constants/links"
import { getQueryFromURL, queryToURL } from "pik-react-utils/utils"

class RouterService {
  readonly history: History = createBrowserHistory()

  readonly MAIN_PATH = "/"
  readonly INFO_PATH = "/info/"
  readonly REQUEST_PATH = "/request-system/"
  readonly UIRTA = "/uirta/"
  readonly MANAGE_PATH = "/manage/"
  readonly EMPLOYEE_LIST_PATH = "/employees/"
  readonly FINANCIAL_STRUCTURE_PATH = "/financial-structure/"
  readonly INVENTORY_STRUCTURE_PATH = "/inventory-structure/"
  readonly PIVOT_PATH = "/pivot/:entityType/"
  readonly EXPORT_EXCEL_PATH = "/export-excel/:entityType/"
  readonly INSTRUCTION_PATH = `${this.INFO_PATH}instructions/:instruction?/`

  private navigateTo(path: To, force?: boolean) {
    const method = force ? this.history.replace : this.history.push
    method(typeof path === "string" ? { pathname: path } : path, {
      previousLocation: { ...this.history.location, state: null },
    })
  }

  private match(path: string) {
    return matchPath(path, this.history.location.pathname)
  }

  private get previousLocation(): Location {
    const state = this.history.location.state as {
      ["previousLocation"]: Location
    }
    return state?.["previousLocation"]
  }

  get isUpdatePathname() {
    return this.previousLocation?.pathname !== this.history.location.pathname
  }

  listen(listener: any) {
    return this.history.listen(listener)
  }

  getSearchParams() {
    return getQueryFromURL(location.search)
  }

  getMatchPivotEntityType() {
    const match = this.match(this.PIVOT_PATH)
    return match?.params.entityType
  }

  linkToRequestTab(request: string, query?: Record<string, unknown>) {
    return this.history.createHref({
      pathname: generatePath(`${this.REQUEST_PATH}:request/`, { request }),
      search: query ? queryToURL(query) : undefined,
    })
  }

  get linkToRequests() {
    return this.linkToRequestTab(RequestPageTabs.TARIFF_RECALC)
  }

  linkToInventoryTab(tab: string) {
    return generatePath(`${this.INVENTORY_STRUCTURE_PATH}:tab/`, { tab })
  }

  get linkToInventoryBuildings() {
    return this.linkToInventoryTab(InventoryStructurePageTabs.BUILDINGS)
  }

  linkToPivotEntity(entityType: string) {
    return generatePath(this.PIVOT_PATH, { entityType })
  }

  get linkToPivotBuildings() {
    return this.linkToPivotEntity("inventorybuilding")
  }

  linkToInstruction(instruction?: string) {
    return generatePath(this.INSTRUCTION_PATH, { instruction })
  }

  linkToExcel(entityType: string) {
    return this.history.createHref({
      pathname: generatePath(this.EXPORT_EXCEL_PATH, { entityType }),
      search: this.history.location.search,
    })
  }

  getMatchExcelEntityType() {
    const match = this.match(this.EXPORT_EXCEL_PATH)
    return match?.params.entityType
  }

  navToPivot(entityType: string, query?: Record<string, unknown>): void {
    this.navigateTo({
      pathname: this.linkToPivotEntity(entityType),
      search: query ? queryToURL(query) : undefined,
    })
  }

  updateURLSearchParams(query?: Record<string, unknown>) {
    this.navigateTo(
      {
        pathname: location.pathname,
        search: query ? queryToURL(query) : undefined,
      },
      location.search === ""
    )
  }
}

export const routerService = new RouterService()
