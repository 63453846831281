import React from "react"
import { Button, Checkbox, Table } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { action, computed, makeObservable, observable } from "mobx"
import { observer } from "mobx-react"

import { Modal } from "../modal"
import { entitiesStore } from "stores"
import {
  CheckboxCellParams,
  IColumnModalProps,
  PrivateFields,
  TableRow,
} from "./types"

const getRowClass = ({ key, isGroup }: TableRow) => {
  if (key === "all") return "checkbox-group"
  if (isGroup) return "is-group"
  return "modal-rows"
}

export const ColumnModal = observer(
  class ColumnModal extends React.Component<IColumnModalProps> {
    private isOpen: boolean
    private editableFields: Set<string> = new Set()
    private entitiesStore = entitiesStore

    constructor(props: IColumnModalProps) {
      super(props)
      makeObservable<ColumnModal, PrivateFields>(this, {
        isOpen: observable.struct,
        editableFields: observable,
        tableData: computed,
        allFields: computed.struct,
        currentVisibleFields: computed,
        currentEditableFields: computed,
        initFields: action.bound,
        toggleCurrentEditableFields: action.bound,
        setFieldEditable: action.bound,
        openModal: action.bound,
        closeModal: action.bound,
        onSave: action.bound,
        renderCheckbox: action.bound,
      })
    }

    private get tableData(): TableRow[] {
      const rows = this.allFields.map((field) => {
        const { slug, name } = field
        if ("children" in field) {
          return {
            key: slug,
            name,
            isGroup: true,
          }
        }
        return {
          key: slug,
          name,
          editable: {
            value: this.editableFields.has(slug),
            disabled: field.readOnly,
            onChange: (e: CheckboxChangeEvent) => {
              this.setFieldEditable(slug, e.target.checked)
            },
          },
        }
      })

      const editableFieldCount = this.currentEditableFields.length

      return [
        {
          name: "",
          key: "all",
          isGroup: true,

          editable: {
            disabled: editableFieldCount === 0,
            value:
              editableFieldCount > 0 &&
              this.editableFields.size === editableFieldCount,
            onChange: this.toggleCurrentEditableFields,
          },
        },
        ...rows,
      ]
    }

    private get allFields() {
      const { entityType: type, excludedFields } = this.props
      const fields = this.entitiesStore.getAgGridFieldsByType(
        type,
        excludedFields[type]
      )

      return fields.reduce((acc, field) => {
        if ("children" in field) {
          return [...acc, field, ...field.children]
        }
        return [...acc, field]
      }, [])
    }

    private get currentVisibleFields() {
      return this.allFields.filter(
        (field) => !("children" in field)
      ) as IEntityField[]
    }

    private get currentEditableFields() {
      return this.currentVisibleFields.filter((field) => !field.readOnly)
    }

    private initFields() {
      this.editableFields = new Set(this.props.editableFields)
    }

    private toggleCurrentEditableFields(e: CheckboxChangeEvent) {
      this.currentEditableFields.forEach(({ slug }) => {
        this.setFieldEditable(slug, e.target.checked)
      })
    }

    private setFieldEditable(field: string, checked: boolean) {
      if (checked) {
        this.editableFields.add(field)
      } else {
        this.editableFields.delete(field)
      }
    }

    private openModal() {
      this.initFields()
      this.isOpen = true
    }

    private closeModal() {
      this.isOpen = false
    }

    private onSave() {
      this.props.onSubmit(Array.from(this.editableFields))
      this.closeModal()
    }

    private renderCheckbox(params: CheckboxCellParams) {
      if (!params) return null
      const { value, disabled, onChange } = params

      return (
        <Checkbox checked={value} disabled={disabled} onChange={onChange} />
      )
    }

    render() {
      return (
        <>
          <Button type="primary" onClick={this.openModal}>
            Столбцы
          </Button>
          <Modal
            open={this.isOpen}
            title="Задать столбцы"
            okText="Применить"
            onOk={this.onSave}
            onCancel={this.closeModal}
            width={600}
          >
            <Table
              dataSource={this.tableData}
              pagination={false}
              rowClassName={getRowClass}
              scroll={{ y: 540 }}
            >
              <Table.Column title="Название поля" dataIndex="name" key="name" />
              <Table.Column
                title="Редактировать"
                dataIndex="editable"
                key="editable"
                render={this.renderCheckbox}
                ellipsis
                align="center"
                width={200}
              />
            </Table>
          </Modal>
        </>
      )
    }
  }
)
