import dayjs from "dayjs"
import { makeAutoObservable, observable } from "mobx"

import { entitiesStore } from "stores"
import { FilterType, QueryArgs } from "types"
import { FilterEntity, FilterModalProps, FilterValue } from "./types"
import { fieldFormattedValue } from "./utils"

export class FilterStore {
  private _fields: FilterType[] = []
  private _entityType: string | null = null
  private _count: number | null = null
  private _filters: Record<string, FilterValue> = {}
  private _search = ""
  private store = entitiesStore

  constructor() {
    makeAutoObservable(this)
  }

  get count() {
    return this._count
  }

  get filters(): Record<string, FilterValue> {
    return this._filters
  }

  get formattedFilters() {
    const formattedFilters = {}

    this._fields.map(({ slug, _type: type }) => {
      formattedFilters[slug] = fieldFormattedValue(type, this._filters[slug])
    })

    return formattedFilters
  }

  get hasSelect() {
    return this._fields.some(({ _type }) => _type === "entitylink")
  }

  get canClearable() {
    return this._fields?.length > 1
  }

  destroy() {
    this._fields = []
    this._entityType = null
    this._search = ""
    this._filters = {}
    this._count = null
  }

  initStore(params: Omit<FilterModalProps, "onCancel" | "open" | "onSuccess">) {
    this._entityType = params.entityType ?? null
    this._fields = params.fields
    this.filtersToEditing(params.query)
    this._search = params.query.search as string
  }

  updateFilters(newFilters: Record<string, FilterValue>) {
    this._filters = observable(newFilters)
    this.retrieveCount()
  }

  private async filtersToEditing(query: QueryArgs) {
    const filters: Record<string, FilterValue> = {}
    const promises: Promise<void>[] = []

    this._fields.forEach((field) => {
      const value = query[field.slug]

      if (field._type === "date") {
        filters[field.slug] = value
          ? dayjs(value as string).startOf("date")
          : null
      } else if (field._type === "tags") {
        filters[field.slug] = typeof value === "string" ? value.split(",") : []
      } else if (field._type === "entitylink" && field.entityLinkTo && value) {
        const entityLink = {
          _type: field.entityLinkTo,
          _uid: value as string,
        }
        const promise = this.store
          .getEntity(entityLink)
          .then((entity: FilterEntity) => {
            filters[field.slug] = {
              value: entity,
              label: entity.full_name ?? entity.name ?? "",
            }
          })
        promises.push(promise)
      } else {
        filters[field.slug] = value ?? null
      }
    })
    await Promise.all(promises)
    this._filters = observable(filters)
    this.retrieveCount()
  }

  async retrieveCount() {
    if (!this._entityType) return
    this._count = await this.store.getCount(this._entityType, {
      search: this._search,
      ...this.formattedFilters,
    })
  }

  async resetFilters() {
    const filters = {}
    this._fields.forEach(({ slug, defaultValue }) => {
      filters[slug] = defaultValue
    })

    await this.filtersToEditing(filters)
  }
}
