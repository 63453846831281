import React from "react"
import { Tag } from "antd"
import { ICellRendererParams } from "ag-grid-community"

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from "components/antd"

export const BooleanCellRenderer = ({ value }: ICellRendererParams) => {
  if (value === true) {
    return <CheckCircleOutlined style={{ color: "green" }} />
  }

  if (value === false) {
    return <CloseCircleOutlined style={{ color: "red" }} />
  }

  return (
    <QuestionCircleOutlined
      title="Значение не определено"
      style={{ color: "grey" }}
    />
  )
}

export const TagCellRenderer = ({ value }: ICellRendererParams) =>
  value ? <Tag color="#f50">отсутствует</Tag> : null
