import { primitive } from "serializr"

import { createSerializrSchema } from "pik-react-utils/utils"
import { addFieldsInClass, generateObservableFieldsBySchema } from "utils/model"
import { makeObservable, observable } from "mobx"

const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "НСИ ID" },
      name: { name: "Наименование" },
    },
  },
  group_system_info: {
    name: "Системная информация",
    children: {
      created: { name: "Дата создания", _type: "datetime" },
      updated: { name: "Дата последнего изменения", _type: "datetime" },
      deleted: { name: "Дата удаления", _type: "datetime" },
    },
  },
} as const

export class BaseEntity implements IEntity {
  static readonly schema: ISchema = schema

  readonly _uid: string
  readonly _type: string
  readonly _version: number
  readonly is_deleted: boolean
  readonly name: string
  readonly created: string
  readonly updated: string
  readonly _real_type: string
  deleted: string | null

  constructor(props?: BaseEntity) {
    if (!props) return
    this._uid = props._uid
    this._type = props._type
    this._version = props._version
    this._real_type = props._real_type
    this.name = props.name

    addFieldsInClass.call(this, schema)
    makeObservable(this, {
      ...generateObservableFieldsBySchema(schema),
      _type: observable,
      _real_type: observable,
      _version: observable,
    })
  }

  get isDeleted() {
    return this.is_deleted
  }

  toEntityLink() {
    return { _uid: this._uid, _type: this._type }
  }
}

createSerializrSchema(BaseEntity, schema, {
  _version: primitive(),
  _real_type: primitive(),
  is_deleted: primitive(),
})
