import { list, primitive } from "serializr"

import { Meeting } from "../meeting"

import { createSerializrSchema } from "pik-react-utils/utils"
import { schema } from "./schema"

export class MeetingSnapshot extends Meeting {
  static readonly schema: ISchema = schema

  history_date: string
  readonly document_fields: string[]
}

createSerializrSchema(MeetingSnapshot, schema, {
  document_fields: list(primitive()),
  history_date: primitive(),
})
