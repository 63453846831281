import React, { useState } from "react"
import { observer } from "mobx-react"

import { CHANGE_PWD_LINK } from "constants/links"
import { EditOutlined } from "components/antd"
import { pikConfig } from "pik-react-utils"
import { sessionManager } from "pik-react-utils/auth"

import {
  UserLogin,
  ImgCircleUser,
  UserDropDownContainer,
  DropDownItem,
} from "./components"
import { LogoutIcon } from "./Logout"

export const UserInfo = observer(() => {
  const [{ userInfo }] = useState(() => sessionManager)

  const clickChangePwdHandler = () => (window.location.href = CHANGE_PWD_LINK)

  return (
    <UserLogin>
      <span>{userInfo && (userInfo.email || userInfo.nickname)}</span>
      <ImgCircleUser />
      <UserDropDownContainer>
        <DropDownItem onClick={clickChangePwdHandler}>
          <EditOutlined />
          <span>Сменить пароль</span>
        </DropDownItem>
        <DropDownItem onClick={pikConfig.terminate}>
          <LogoutIcon />
          <span>Выйти</span>
        </DropDownItem>
      </UserDropDownContainer>
    </UserLogin>
  )
})
