import dayjs from "dayjs"
import { AttentionModal } from "modals/AttentionModal"
import React, { useCallback, useState } from "react"
import { entitiesStore } from "stores"
import { DocumentEntity } from "./types"

export const Modal = ({
  entity,
  open,
  onCancel,
  onOk,
}: {
  entity: DocumentEntity
  open: boolean
  onCancel: () => void
  onOk: () => void
}) => {
  const [store] = useState(() => entitiesStore)
  const isDeleted = entity.deleted

  const action = isDeleted ? "восстановить" : "удалить"
  const name = entity.document_name
  const date = dayjs(entity.document_date).format("DD.MM.YYYY")

  const onRecover = useCallback(async () => {
    await store.updateEntity<DocumentEntity>({ ...entity, deleted: null })
  }, [entity, store])

  const onDelete = useCallback(async () => {
    await store.deleteEntity(entity)
  }, [entity, store])

  const update = useCallback(async () => {
    if (isDeleted) {
      await onRecover()
    } else {
      await onDelete()
    }
    onOk()
  }, [isDeleted, onDelete, onRecover, onOk])

  return (
    <AttentionModal open={open} onOk={update} onCancel={onCancel}>
      Вы действительно хотите {action} документ <strong>{name}</strong> от&nbsp;
      {date}?
    </AttentionModal>
  )
}
