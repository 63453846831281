import { BaseEntity } from "pik-react-utils/entities"
import { groupNameFields, GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"
import { RegulationProducer } from "./regulationproducer"

import { createSerializrSchema } from "pik-react-utils/utils"

const schema: ISchema = {
  ...groupNameFields(),
  group_parameters: {
    name: "Характеристики",
    children: {
      begin_date: { name: "Дата начала действия", _type: "date" },
      end_date: { name: "Дата окончания действия", _type: "date" },
      comments: { name: "Комментарий" },
      regulation_producer: {
        name: "Орган самоуправления",
        _type: "entitylink",
        entityLinkTo: RegulationProducer,
      },
    },
  },
  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class Regulation extends BaseEntity {
  static readonly schema: ISchema = schema

  begin_date: string
  end_date: string
  comments: string
  regulation_producer: RegulationProducer
}

createSerializrSchema(Regulation, schema)
