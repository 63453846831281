import React from "react"
import { observer } from "mobx-react"
import { Input, InputRef } from "antd"
import { action, makeObservable, observable } from "mobx"
import { ICellEditorParams } from "ag-grid-community"

import "./editors.css"

interface ITextEditorProps extends ICellEditorParams {
  type: string
}

export const TextEditor = observer(
  class TextEditor extends React.Component<ITextEditorProps> {
    private value: string | number | undefined
    private readonly inputRef: React.RefObject<InputRef> = React.createRef()

    constructor(props: ITextEditorProps) {
      super(props)
      makeObservable<TextEditor, "value" | "inputRef" | "onChange">(this, {
        value: observable.struct,
        inputRef: observable.ref,
        afterGuiAttached: action.bound,
        onChange: action.bound,
      })
    }

    public getValue() {
      return this.value ?? null
    }

    public afterGuiAttached() {
      this.value = this.props.value
      this.inputRef.current?.focus()
    }

    private onChange(event: React.ChangeEvent<HTMLInputElement>) {
      this.value = event.target.value
    }

    render() {
      return (
        <Input
          ref={this.inputRef}
          value={this.value}
          className="pik_ag-grid_text-cell-editor"
          onChange={this.onChange}
          size="small"
          allowClear
        />
      )
    }
  }
)
