import React from "react"
import { MENU_ITEMS } from "components/Header/HeaderMenu/menu"
import { NavCardList } from "components"
import { Content, Hero, MenuWrap } from "./components"

export const Home = () => (
  <Content>
    <Hero>
      <h1>{`Приветствуем\n
        на портале НСИ ПИК-Комфорт!`}</h1>
    </Hero>
    <MenuWrap>
      <NavCardList items={MENU_ITEMS} />
    </MenuWrap>
  </Content>
)
