import { primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

export class Section extends BaseEntity {
  hash: string
}

createSerializrSchema(Section, null, { hash: primitive() })
