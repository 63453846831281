import styled from "styled-components"

export const ColumnContent = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header subheader"
    "list insprctor";
  padding: 12px;
  row-gap: 12px;
  column-gap: 12px;
`
