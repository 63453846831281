import { Row } from "antd"
import styled from "styled-components"

export const Content = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.div`
  flex-grow: 1;
  padding: 0 16px;
  overflow: hidden;
  overflow-y: auto;
`

export const EmptyList = styled(Row).attrs({
  align: "middle",
  justify: "center",
})`
  height: calc(100% - 56px);
`
