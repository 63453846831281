import { AsyncSelect } from "components"
import { observer } from "mobx-react"
import React from "react"
import styled from "styled-components"
import { AsyncSelectOption, QueryArgs } from "types"

const Wrapper = styled.div`
  padding-bottom: 24px;
`

const Label = styled.div`
  padding: 0 0 8px 2px;
  font-weight: bold;
`
type Props = {
  options: Array<{ label: string; type: string }>
  filters: (type: string) => QueryArgs | undefined
  fields: { [key: string]: AsyncSelectOption | null | undefined }
  onChange: (type: string, option?: AsyncSelectOption | null) => void
}

export const SelectList = observer(
  ({ options, filters, fields, onChange }: Props) => (
    <>
      {options.map(({ type, label }) => (
        <Wrapper key={type}>
          <Label>{label}</Label>
          <AsyncSelect
            key={type}
            entityType={type}
            filters={filters(type)}
            isClearable
            placeholder={label}
            value={fields[type]}
            onChange={(option: AsyncSelectOption) => onChange(type, option)}
          />
        </Wrapper>
      ))}
    </>
  )
)
