import React, { useCallback, useRef, useState } from "react"
import { Button } from "antd"
import { observer } from "mobx-react"
import styled from "styled-components"

import { PlusCircleOutlined } from "components/antd"
import { InfiniteScroll, InfiniteScrollRef } from "components"
import { AddOrganizationModal } from "modals"
import { FinancialUnitOrganizationRelationEntity as RelationEntity } from "models"
import { Wrapper } from "pages/FinancialStructure/components"

interface IRelatedOrgsProps {
  financialUnitId: string
}

const Item = styled.p`
  font-size: 12px;
  margin-bottom: 4px;
  &:first-child {
    margin-top: 12px;
  }
`

export const Organizations = observer(
  ({ financialUnitId }: IRelatedOrgsProps) => {
    const [isOpen, setOpenModal] = useState(false)
    const listRef = useRef<InfiniteScrollRef>()

    const closeModal = () => setOpenModal(false)

    const onSave = useCallback(() => {
      closeModal()
      listRef.current?.refresh()
    }, [listRef])

    return (
      <>
        <Wrapper>
          <Button
            onClick={() => setOpenModal(true)}
            icon={<PlusCircleOutlined />}
          >
            Добавить организацию
          </Button>
          <InfiniteScroll
            ref={listRef as React.Ref<InfiniteScrollRef>}
            entityType="financialunitorganizationrelation"
            query={{ financial_unit__uid: financialUnitId }}
            itemEl={({ contractor }: RelationEntity) =>
              contractor ? (
                <Item key={contractor._uid}>
                  {contractor.name} ({contractor.inn})
                </Item>
              ) : null
            }
          />
        </Wrapper>
        <AddOrganizationModal
          open={isOpen}
          onCancel={closeModal}
          onSave={onSave}
          defaultValues={{
            financial_unit: { _uid: financialUnitId, _type: "financialunit" },
          }}
        />
      </>
    )
  }
)
