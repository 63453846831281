import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

import { schema } from "./schema"

export class MeetingRequest extends BaseEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(MeetingRequest, schema)
