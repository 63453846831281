import { action, computed, makeObservable, observable } from "mobx"

import { IS_DELETED_FILTER } from "constants/filters"
import { BaseQueryStore } from "stores"
import { IReferenceType } from "types/references"

export class ReferenceStore extends BaseQueryStore {
  private _referenceType: IReferenceType | null
  private _selectedEntity: IEntity

  constructor() {
    super({
      filterFields: [IS_DELETED_FILTER],
      filters: { ordering: "created" },
    })
    makeObservable<ReferenceStore, "_referenceType" | "_selectedEntity">(this, {
      _referenceType: observable,
      referenceType: computed,
      _selectedEntity: observable,
      selectedEntity: computed,
      referenceName: computed,
      setReferenceType: action.bound,
      setSelectedEntity: action.bound,
      deleteEntity: action.bound,
    })
  }

  get selectedEntity() {
    return this._selectedEntity
  }

  get referenceType() {
    return this._referenceType
  }

  get referenceName() {
    return this.referenceType?.singleLabel ?? this.referenceType?.label ?? ""
  }

  setReferenceType(referenceType: IReferenceType | null) {
    this._referenceType = referenceType
  }

  setSelectedEntity(entity: IEntity) {
    this._selectedEntity = entity
  }

  async deleteEntity() {
    if (!this.selectedEntity) return

    await this.entitiesStore.deleteEntity(this.selectedEntity)
  }
}
