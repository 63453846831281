import { action, makeObservable, observable } from "mobx"

import { api } from "pik-react-utils/api"
import {
  isBaseEntity,
  validateEntity,
  objWithType,
  List, BaseEntity,
} from "pik-react-utils/entities"
import { APIConfig, MethodType, RequestConfig } from "pik-react-utils/types"
import { EntityModelService } from "pik-react-utils/services"
import { queryToURL } from "pik-react-utils/utils"
import { storageKeyByParams } from "./utils"
import { EntitiesCache } from "./cache"

class EntityStore {
  private readonly api = api
  private lists: Map<string, Map<string, NormalizedList>> = new Map()
  private modelsService: EntityModelService
  private entitiesCache: EntitiesCache

  constructor() {
    makeObservable<EntityStore, "lists" | "setListIntoStorage">(
      this,
      {
        lists: observable,
        flushList: action.bound,
        flushStorage: action.bound,
        setListIntoStorage: action.bound,
      },
      { deep: false }
    )
  }

  dropStore() {
    this.entitiesCache.clear()
    this.api.forbid()
    this.flushStorage()
  }

  /**
   * Create entity in global storage
   * Optionally send it to the server
   */
  createEntity<T extends BaseEntity = BaseEntity>(
    entity: (Partial<T> | Record<string, unknown>) & { _type: string },
    config: RequestConfig = {}
  ) {
    if (!objWithType(entity)) {
      return Promise.resolve(undefined)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { created, updated, deleted, ...rest } = entity

    return this.apiCreateEntity<T>(rest, config)
  }

  createDummy<T extends IEntity = IEntity>(type: string) {
    return this.modelsService.createDummy<T>(type)
  }

  getAgGridFieldsByType(type: string, excludedFields?: string[]) {
    const fields = this.modelsService.getAgGridFieldsByType(type)

    if (!excludedFields?.length) {
      return fields
    }

    return fields
      .filter(({ slug }) => !excludedFields.includes(slug))
      .map((field) => {
        if ("children" in field) {
          const children = field.children.filter(
            ({ slug }) => !excludedFields.includes(slug)
          )
          return { ...field, children }
        }

        return field
      })
  }

  /**
   * Update entity in global storage
   * Optionally sync value in storage after update
   */

  updateEntity<T extends IEntity = IEntity>(
    entity: IEntityLink | T,
    config: RequestConfig = {}
  ) {
    if (!isBaseEntity(entity)) {
      return Promise.resolve(undefined)
    }

    return this.apiUpdateEntity<T>(entity, config)
  }

  /**
   * Invalidate lists by type
   */
  flushList(types: string | undefined | string[]): void {
    if (!types) return
    if (types instanceof Array) {
      types.forEach(this.flushList)
    } else {
      this.lists.delete(types)
    }
  }

  /**
   * Delete entity in storage and from server if necessary
   */
  async deleteEntity<T extends IEntity>(
    entity: IEntityLink | T,
    config: RequestConfig = {}
  ) {
    if (!isBaseEntity(entity)) {
      return Promise.resolve(undefined)
    }
    const responseEntity = await this.apiDeleteEntity<T>(entity, config)
    this.entitiesCache.clear(entity)
    return responseEntity
  }

  /**
   * Add new model for the storage
   */
  setModel(classByType: { [key: string]: ModelConstructor<object> }) {
    this.modelsService = new EntityModelService(classByType)
    this.entitiesCache = new EntitiesCache(this.modelsService)
  }

  /**
   * Flush all stored data
   */
  flushStorage(): void {
    this.lists.clear()
    this.entitiesCache.clear()
  }

  /**
   * Get entity from storage
   * or make request to get it
   */
  async getEntity<T extends IEntity = IEntity>(
    entity: IEntityLink | T,
    config: APIConfig & { useSchemaFields?: boolean } = {}
  ) {
    const { fromStorage, ...apiConfig } = config
    const validEntity = validateEntity(entity)
    if (!validEntity) return
    if (fromStorage === false) {
      return await this.apiGetEntity<T>(validEntity, apiConfig)
    }
    const resultFromStorage = this.getEntityFromStorage<T>(
      validEntity,
      config.useSchemaFields
    )

    if (resultFromStorage) {
      return Promise.resolve(resultFromStorage)
    }

    if (fromStorage) return

    return await this.apiGetEntity<T>(validEntity, apiConfig)
  }

  /**
   * Get entity from storage
   */
  private getEntityFromStorage<T extends IEntity>(
    entity: IEntityLink | T,
    useSchemaFields?: boolean
  ) {
    return this.entitiesCache.getEntityFromCache<T>(entity, useSchemaFields)
  }

  /**
   * Get entity from api
   */
  private async apiGetEntity<T extends IEntity>(
    entity: IEntityLink,
    config: APIConfig = {}
  ) {
    const responseEntity = await this.api.getItem(entity, config)
    this.updateCache(responseEntity)
    return this.getEntityFromStorage<T>(responseEntity)
  }

  /**
   * Get list or promise from storage
   * or make a request to get it
   */
  async getList<T extends IEntity = IEntity>(
    type: string,
    queryParams: ListRequestParams & Record<string, unknown> = { empty: true },
    config: RequestConfig = {}
  ) {
    const resultFromStorage = this.getListFromStorage<T>(type, queryParams)
    if (resultFromStorage) {
      return Promise.resolve(resultFromStorage)
    }

    return await this.apiGetList<T>(type, queryParams, config)
  }

  async getEntitiesFromCustomUrl<T extends IEntity>(
    customUrl: { _type: string; suffix: string },
    queryParams?: ListRequestParams,
    config?: RequestConfig
  ): Promise<T[]> {
    const response = await this.api.useCustomUrl<IRawEntity[]>(
      MethodType.GET,
      customUrl,
      null,
      queryParams,
      config
    )

    this.updateCache(response)
    return response.map((item) => this.getEntityFromStorage<T>(item) as T)
  }

  async getCount(
    type: string,
    queryParams?: Record<string, unknown>,
    config?: APIConfig
  ) {
    const response = await this.api.getList(
      type,
      { query: "{_uid}", ...queryParams, page: 1, page_size: 1 },
      {
        handleError: config?.handleError === true,
        apiVer: config?.apiVer,
      }
    )

    return response.count
  }

  /**
   * Send request to delete entity
   */
  private async apiDeleteEntity<T extends IEntity = IEntity>(
    entityLink: IEntityLink | T,
    config: APIConfig = {}
  ) {
    const { handleError, apiVer } = config
    const responseEntity = await this.api.deleteItem(entityLink, {
      handleError,
      apiVer,
    })
    this.flushList(entityLink._type)
    return responseEntity
  }

  /**
   * Send request to update entity
   */
  private async apiUpdateEntity<T extends IEntity = IEntity>(
    params: IEntityLink | T,
    config: APIConfig = {}
  ) {
    const { handleError, apiVer } = config
    const { _type, _uid, ...data } = params

    const responseEntity = await this.api.updateItem(
      { _type, _uid },
      data as Record<string, unknown>,
      { handleError, apiVer }
    )

    this.flushList(_type)
    this.updateCache(responseEntity)
    return this.getEntityFromStorage<T>(responseEntity)
  }

  /**
   * Send request to create entity
   */
  private async apiCreateEntity<T extends IEntity>(
    params: Pick<IEntity, "_type">,
    config: APIConfig
  ) {
    const { _type, ...data } = params
    const { handleError, apiVer } = config

    const responseEntity = await this.api.createItem(
      _type,
      data as Record<string, unknown>,
      { handleError, apiVer }
    )
    this.updateCache(responseEntity)
    this.flushList(_type)
    return this.getEntityFromStorage<T>(responseEntity)
  }

  /**
   * Get list from storage
   */
  private getListFromStorage<T extends IEntity>(
    type: string,
    params: ListRequestParams
  ) {
    const list = this.lists.get(type)?.get(queryToURL(params))
    if (!list) return
    const { meta, results } = list

    return {
      meta,
      entities: results.map((item) => this.getEntityFromStorage<T>(item) as T),
    }
  }

  private setListIntoStorage(
    type: string,
    queryParams: ListRequestParams,
    data: NormalizedList
  ) {
    const storageKey = storageKeyByParams(queryParams)
    if (!this.lists.has(type)) {
      this.lists.set(type, new Map())
    }
    this.lists.get(type)?.set(storageKey, data)
  }

  /**
   * Make api requests to get a list
   */
  private async apiGetList<T extends IEntity>(
    type: string,
    queryParams: ListRequestParams,
    config: APIConfig
  ) {
    const { handleError, apiVer } = config
    const response = await this.api.getList(type, queryParams, {
      handleError,
      apiVer,
    })
    this.updateCache(response.results)
    this.setListIntoStorage(type, queryParams, List.fromServer(response))

    return this.getListFromStorage<T>(type, queryParams)
  }

  updateCache(entities: IRawEntity | IRawEntity[]) {
    this.entitiesCache.updateCache(entities)
  }
}

const store = new EntityStore()

export { store as entitiesStore, EntityStore as IEntitiesStore }
