import { action, computed, makeObservable, observable } from "mobx"

import { ENTITIES_FILTERS } from "constants/filters"
import { CompetitionEntity, MeetingEntity } from "models"
import { BaseQueryStore } from "stores"
import { QueryArgs } from "types"
import { SelectedEntityType } from "./types"

export class UirtaStore extends BaseQueryStore {
  private selectedEntity: SelectedEntityType | undefined

  constructor(params: { filters: QueryArgs; search: string }) {
    super({
      filterFields: ENTITIES_FILTERS.building,
      ...params,
    })

    makeObservable<UirtaStore, "selectedEntity">(this, {
      selectedEntity: observable,
      currentEntity: computed,
      isAbleDocuments: computed.struct,
      meetingFields: computed,
      setEntity: action.bound,
      flushLists: action.bound,
    })
  }

  get currentEntity() {
    return this.selectedEntity
  }

  get isAbleDocuments() {
    return (
      this.selectedEntity instanceof CompetitionEntity ||
      this.selectedEntity instanceof MeetingEntity
    )
  }

  get meetingFields() {
    return this.entitiesStore.getAgGridFieldsByType("meetingchange")
  }

  async setEntity(entity: SelectedEntityType) {
    this.selectedEntity = entity
  }

  flushLists(types: string[] | string) {
    this.entitiesStore.flushList(types)
  }
}
