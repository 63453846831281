/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual } from "lodash"

function normilize(value: any) {
  const EMPTY_VALUES = [undefined, null, "undefined", "null"]

  if (EMPTY_VALUES.includes(value)) {
    return null
  }

  if (typeof value === "object" || value instanceof Number) {
    return value
  }

  const str = String(value).trim()

  if (str === "") {
    return null
  }

  const num = Number(str)
  return Number.isNaN(num) ? str : num
}

export function checkIsSame(oldValue: unknown, newValue: unknown): boolean {
  const normOldValue = normilize(oldValue)
  const normNewValue = normilize(newValue)

  if (
    normOldValue !== null &&
    normNewValue !== null &&
    typeof normOldValue === "object" &&
    typeof normNewValue === "object" &&
    !(normOldValue instanceof Array) &&
    !(normNewValue instanceof Array)
  ) {
    return Boolean(
      normOldValue &&
        normNewValue &&
        "_uid" in normOldValue &&
        "_uid" in normNewValue &&
        normOldValue._uid === normNewValue._uid
    )
  }

  return isEqual(normOldValue, normNewValue)
}
