import { object, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { Division } from "./division"
import { Employment } from "./employment"
import { Entity } from "./entity"
import { ExternalAccount } from "./externalaccount"
import { Organization } from "./organization"
import { Sector } from "./sector"
import { Service } from "./service"

export class StaffUnitRequest extends BaseEntity {}

createSerializrSchema(StaffUnitRequest, null, {
  organization: object(Organization),
  department_unit: object(Entity),
  division_unit: object(Division),
  sector_unit: object(Sector),
  service_unit: object(Service),
  employment: object(Employment),
  external_account: object(ExternalAccount),
  initiator_email: primitive(),
  kind: primitive(),
  is_main: primitive(),
})
