import { list, object, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { Employment } from "./employment"
import { ExternalAccount } from "./externalaccount"
import { Function as FunctionEntity } from "./function"
import { Office } from "./office"
import { Role } from "./role"
import { Unit } from "./unit"

interface IStaffUnit extends IEntity {
  employment: Employment | null
  functions: FunctionEntity[]
  office: Office | null
  rate: number
  role: Role | null
  unit: Unit
  external_account: ExternalAccount | null
  is_main: boolean
}

export class StaffUnit extends BaseEntity implements IStaffUnit {
  functions: FunctionEntity[]
  office: Office | null
  rate: number
  role: Role | null
  unit: Unit
  employment: Employment | null
  is_main: boolean
  external_account: ExternalAccount | null

  get breadcrumbFullName() {
    if (this.external_account) {
      return this.external_account.breadcrumbFullName
    }

    if (this.employment) {
      return this.employment.breadcrumbFullName
    }

    return ""
  }
}

createSerializrSchema(StaffUnit, null, {
  functions: list(object(FunctionEntity)),
  office: object(Office),
  rate: primitive(),
  role: object(Role),
  unit: object(Unit),
  employment: object(Employment),
  is_main: primitive(),
  external_account: object(ExternalAccount),
})
