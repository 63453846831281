import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { DEFAULT_EDITABLE_FIELDS } from "utils/fields"

export class ManufacturingPlant extends BaseEntity {
  static readonly schema: ISchema = DEFAULT_EDITABLE_FIELDS

  get full_name() {
    return this.name
  }
}

createSerializrSchema(ManufacturingPlant)
