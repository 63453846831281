import React from "react"
import { NavCardList } from "components"
import { Content, Heading } from "./components"
import { INFO_LIST } from "./constants"

export const InfoList = () => (
  <Content>
    <Heading>Инструкции для пользователей</Heading>
    <NavCardList items={INFO_LIST} />
  </Content>
)
