import { Button, Dropdown } from "antd"
import React, { useEffect, useMemo, useState } from "react"

import { SIZES } from "constants/media"
import { MenuOutlined } from "components/antd"
import { DropdownWrapper, RouterLink, MenuList } from "./components"
import { MENU_ITEMS } from "./menu"
import { useLocation } from "react-router"
import { routerService } from "services"

export const HeaderMenu = () => {
  const [width, setWidth] = useState(0)
  const [selectedKeys, changeSelectedKeys] = useState<string[]>([])
  const { pathname } = useLocation()

  const menuItems = useMemo(
    () =>
      MENU_ITEMS.map(({ key, link, label, children }) => ({
        key: key ?? link,
        open: true,
        label: <RouterLink to={link}>{label}</RouterLink>,
        children: children?.map(({ key, link, label }) => ({
          key: key ?? (typeof link === "string" ? link : label),
          label:
            typeof link === "string" ? (
              <RouterLink to={link}>{label}</RouterLink>
            ) : (
              link
            ),
        })),
      })),
    []
  )

  const menuKeys: string[] = useMemo(
    () =>
      menuItems.reduce((acc, { key, children }) => {
        acc.push(key)
        if (children) {
          acc.push(...(children.map(({ key }) => key) as string[]))
        }
        return acc
      }, [] as string[]),
    [menuItems]
  )

  useEffect(() => {
    const onUpdateWidth = () => setWidth(window.innerWidth)
    window.addEventListener("resize", onUpdateWidth)
    onUpdateWidth()
    return () => window.removeEventListener("resize", onUpdateWidth)
  }, [])

  useEffect(() => {
    if (pathname.startsWith("/pivot/")) {
      changeSelectedKeys([routerService.PIVOT_PATH])
    } else {
      changeSelectedKeys(menuKeys.includes(pathname) ? [pathname] : [])
    }
  }, [menuKeys, pathname])

  if (width <= SIZES.Desktop_S) {
    return (
      <DropdownWrapper>
        <Dropdown menu={{ items: menuItems, selectable: true, selectedKeys }}>
          <Button type="text" size="large" icon={<MenuOutlined />} />
        </Dropdown>
      </DropdownWrapper>
    )
  }

  return (
    <MenuList mode="horizontal" items={menuItems} selectedKeys={selectedKeys} />
  )
}
