import { Row } from "antd"
import { COLOR_ICON_DEFAULT, COLOR_EXTRA_TEXT } from "constants/ui-colors"
import styled from "styled-components"

export const Wrapper = styled(Row).attrs({
  justify: "space-between",
  align: "middle",
})`
  flex-wrap: nowrap;
  padding: 8px 0 12px;
  & + & {
    border-top: 1px solid ${COLOR_ICON_DEFAULT};
  }
  &:first-child {
    padding-top: 0;
  }
`

export const Role = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${COLOR_EXTRA_TEXT};
`

export const Organization = styled.p`
  margin: 0;
  color: #ac41f4;
  font-size: 12px;
`

export const ButtonGroup = styled.div`
  display: flex;
  gap: 4px;
`
