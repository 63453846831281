import { observable, computed, makeObservable } from "mobx"

import { BaseEntity } from "pik-react-utils/entities"
import { MeasureUnit } from "./measureunit"
import { createSerializrSchema } from "pik-react-utils/utils"
import { object, primitive } from "serializr"

interface ITariffService extends IEntity {
  vat: string
  measure: MeasureUnit
}

export class TariffService extends BaseEntity implements ITariffService {
  vat: string
  measure: MeasureUnit

  constructor(props: BaseEntity) {
    super(props)
    makeObservable(this, {
      vat: observable,
      measure: observable,
      unitName: computed,
    })
  }

  get unitName() {
    return this.measure?.name
  }
}

createSerializrSchema(TariffService, null, {
  vat: primitive(),
  measure: object(MeasureUnit),
})
