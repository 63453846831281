import { computed, makeObservable, observable } from "mobx"
import { object } from "serializr"

import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { ManagementCompany } from "models/managementcompany"
import { AddressComponents } from "models/addresscomponents"
import { BuildingType } from "models/buildingtype"
import { Building } from "../building"
import { BuildingCounter } from "../buildingcounter"
import { schema } from "./schema"

export class InventoryBuilding extends FullNameEntity {
  static readonly schema: ISchema = schema

  juristic_address: string
  management_company: ManagementCompany
  address_components: AddressComponents
  building_type: BuildingType

  // Счетчики
  readonly utility_system_count: BuildingCounter
  readonly building_part_count: BuildingCounter
  readonly premise_count: BuildingCounter

  constructor(props: Building) {
    super(props)
    makeObservable(this, {
      utility_system_count: observable,
      building_part_count: observable,
      premise_count: observable,
      totalGroupCount: computed,
    })
  }

  get totalGroupCount() {
    return (
      this.building_part_count?.amount +
        this.utility_system_count?.amount +
        this.premise_count?.amount || 0
    )
  }

  get certificate_number() {
    return { buiding_uid: this._uid }
  }
}

createSerializrSchema(InventoryBuilding, schema, {
  premise_count: object(BuildingCounter),
  building_part_count: object(BuildingCounter),
  utility_system_count: object(BuildingCounter),
})
