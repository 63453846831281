import { object } from "serializr"

import { COMPETITION_STATUS_CHOICES, KIND_CHOICES } from "constants/choices"
import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { groupDocumentFields, GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { Building } from "./building"
import { Competition } from "./competition"
import { CompetitionChangeAttachment } from "./competitionchangeattachment"
import { CompetitionSnapshot } from "./competitionsnapshot"
import { FinancialEmployment } from "./financialemployment"
import { ManagementCompany } from "./managementcompany"

const schema: ISchema = {
  ...groupDocumentFields({
    attachment: {
      name: "Прикрепленный файл",
      _type: "attachment",
      entityLinkTo: CompetitionChangeAttachment,
      editable: true,
    },
  }),

  group_project_card: {
    name: "Карточка проекта",
    children: {
      name: { name: "Название", editable: true },
      building: { name: "Здание", _type: "entitylink", entityLinkTo: Building },
      curator: {
        name: "Куратор",
        _type: "entitylink",
        entityLinkTo: FinancialEmployment,
      },
      kind: {
        name: "Вид",
        _type: "choices",
        choices: KIND_CHOICES,
        editable: true,
      },
      status: {
        name: "Статус",
        _type: "choices",
        choices: COMPETITION_STATUS_CHOICES,
        editable: true,
      },
      current_management_company: {
        name: "Действующая ук",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
        editable: true,
      },
      target_management_company: {
        name: "Будущая ук",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
        editable: true,
      },
      comment: { name: "Комментарий", editable: true },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class CompetitionChange extends Competition {
  static readonly schema: ISchema = schema

  document_date: string
  document_name: string
  document_link: string
  readonly document_fields: string[]
  document_is_draft: boolean

  attachment: CompetitionChangeAttachment
  readonly snapshot: CompetitionSnapshot
  competition: BaseEntity
}

createSerializrSchema(CompetitionChange, schema, {
  attachment: object(CompetitionChangeAttachment),
  competition: object(BaseEntity),
  snapshot: object(CompetitionSnapshot),
})
