import { list, object, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { Entity } from "./entity"

export class PermissionRequest extends BaseEntity {}

createSerializrSchema(PermissionRequest, null, {
  target_service: object(Entity),
  permission_group: object(Entity),
  initiator_email: primitive(),
  initiator_login: primitive(),
  initiator_full_name: primitive(),
  approvers: list(primitive()),
  user_uid: primitive(),
})
