import { observable, computed, makeObservable } from "mobx"
import { list, object, primitive } from "serializr"

import { BaseEntity, FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"
import { Employee } from "./employee"
import { Employment } from "./employment"

interface IFinancialEmployment extends IEntity {
  employee: Employee | null
  financial_unit: FullNameEntity | null
  employments: Employment[] | null
}

const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "НСИ ID" },
      full_name: { name: "Полное имя" },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
}

export class FinancialEmployment
  extends BaseEntity
  implements IFinancialEmployment
{
  static readonly schema: ISchema = schema
  employee: Employee | null
  financial_unit: FullNameEntity | null
  employments: Employment[] | null
  kind: string

  constructor(props: BaseEntity) {
    super(props)
    makeObservable(this, {
      employee: observable,
      financial_unit: observable,
      employments: observable,
      kind: observable,
      full_name: computed,
    })
  }

  get full_name() {
    const employee_name = this.employee?.full_name ?? ""

    if (this.financial_unit) {
      return `${employee_name} (${this.financial_unit.name})`
    }
    return employee_name
  }
}

createSerializrSchema(FinancialEmployment, null, {
  employee: object(Employee),
  financial_unit: object(FullNameEntity),
  employments: list(object(Employment)),
  kind: primitive(),
})
