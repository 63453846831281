import { object, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { Section } from "./section"

export class SubSection extends BaseEntity {
  hash: string
  section: Section
}

createSerializrSchema(SubSection, null, {
  hash: primitive(),
  section: object(Section),
})
