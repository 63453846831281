import React from "react"
import { Form, Button, FormInstance, message } from "antd"
import { action, makeObservable, observable } from "mobx"
import { observer } from "mobx-react"
import { createSearchParams } from "react-router-dom"

import { AsyncSelect } from "components"
import { FormLabel } from "components/css"
import { sessionManager } from "pik-react-utils/auth"
import { validateEntity } from "pik-react-utils/entities"
import { entitiesStore, IEntitiesStore } from "stores"
import { AsyncSelectOption } from "types"
import { FieldAttrNames } from "./constants"
import { FormFooter } from "../components"
import { RequestContent } from "../content"
import { PrivateFields } from "./types"

type PreparedData = {
  [slug: string]: IEntityLink | string | undefined
}

const createOption = (
  entity: AsyncSelectOption<IEntity>,
  type: "targetservice" | "permissiongroup"
) => ({
  entity_name: entity.label,
  entity_uid: entity.value?._uid,
  entity_type: type,
  _type: "entity",
})

export const PermissionRequest = observer(
  class PermissionRequest extends React.Component {
    private isLoading: boolean
    private readonly formRef: React.RefObject<FormInstance<unknown>> =
      React.createRef()
    private readonly store: IEntitiesStore = entitiesStore

    private targetServiceAttrName = "target_service"
    private permissionGroupAttrName = "permission_group"
    private fieldsAttrList = [
      this.targetServiceAttrName,
      this.permissionGroupAttrName,
    ]

    constructor(props: Record<string, unknown>) {
      super(props)
      makeObservable<PermissionRequest, PrivateFields>(this, {
        isLoading: observable.struct,
        setIsLoading: action.bound,
        onTargetServiceSelect: action.bound,
        onPermissionGroupSelect: action.bound,
        getFilters: action.bound,
        getPreparedData: action.bound,
        onSave: action.bound,
        onSubmit: action.bound,
        setFieldByName: action.bound,
        getFieldByName: action.bound,
        onResetForm: action.bound,
      })
    }

    async componentDidMount() {
      const urlSearchParams = createSearchParams(location.search)

      this.fieldsAttrList.forEach(async (slug) => {
        const _uid = urlSearchParams.get(slug)
        if (!_uid) return
        const _type = slug.replace("_", "")
        const entity = await this.store.getEntity({ _type, _uid })

        const result = entity
          ? { value: entity, label: entity.name ?? "" }
          : null
        this.setFieldByName(slug, result)
      })
    }

    private setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading
    }

    private onTargetServiceSelect(option: AsyncSelectOption<IEntity>) {
      this.setFieldByName(this.targetServiceAttrName, option)
      this.setFieldByName(this.permissionGroupAttrName, null)
    }

    private onPermissionGroupSelect(option: AsyncSelectOption<IEntity>) {
      this.setFieldByName(this.permissionGroupAttrName, option)
    }

    private getPreparedData(rawData: (IEntity | undefined)[]) {
      const result: PreparedData & Pick<IEntity, "_type"> = {
        _type: "permissionrequest",
      }

      const attrsNameArr = Object.keys(FieldAttrNames).filter(
        (key) => !isNaN(Number(FieldAttrNames[key]))
      )

      if (rawData?.length) {
        attrsNameArr.forEach((value, index) => {
          const entity = validateEntity(rawData[index])
          if (entity) {
            result[value] = { _uid: entity._uid, _type: entity._type }
          }
        })
      }

      const permissionGroup = this.getFieldByName(this.permissionGroupAttrName)
      result.approvers = permissionGroup.value.responsibles_login_list
      const { userInfo } = sessionManager
      if (userInfo) {
        result.initiator_email = userInfo.email
        result.initiator_login = userInfo.preferred_username
        result.initiator_full_name = `
      ${userInfo.family_name} ${userInfo.given_name}`
      }

      return result
    }

    private getFilters(type: string): { [key: string]: string } | undefined {
      const targetServiceValue = this.getFieldByName(this.targetServiceAttrName)
      if (type === "permissiongroup" && targetServiceValue?.value) {
        return { target_service__uid: targetServiceValue.value._uid }
      }
      return
    }

    private async onSave() {
      const permissionGroup = createOption(
        this.getFieldByName(this.permissionGroupAttrName),
        "permissiongroup"
      )
      const targetService = createOption(
        this.getFieldByName(this.targetServiceAttrName),
        "targetservice"
      )

      try {
        const promises = await Promise.all(
          [permissionGroup, targetService].map((item) =>
            this.store.createEntity(item)
          )
        )

        const preparedData = this.getPreparedData(promises)
        await this.store.createEntity(preparedData)

        message.success("Заявка успешно отправлена")
        this.onResetForm()
      } finally {
        this.setIsLoading(false)
      }
    }

    private onSubmit(e: React.MouseEvent) {
      e.preventDefault()
      this.formRef.current?.validateFields().then(() => {
        this.setIsLoading(true)
        this.onSave()
      })
    }

    private setFieldByName(
      name: string,
      value: null | string | AsyncSelectOption<IEntity>
    ) {
      this.formRef.current?.setFieldsValue({ [name]: value })
    }

    private getFieldByName(name: string) {
      return this.formRef.current?.getFieldValue(name)
    }

    private onResetForm() {
      this.formRef.current?.resetFields()
    }

    render() {
      return (
        <RequestContent title="Заявка на права доступа">
          <Form ref={this.formRef} layout="vertical">
            <Form.Item
              name={this.targetServiceAttrName}
              label={<FormLabel>Выберите сервис</FormLabel>}
              rules={[
                {
                  required: true,
                  message: "Выберите сервис",
                },
              ]}
            >
              <AsyncSelect
                placeholder="НСИ Сотрудники"
                onChange={this.onTargetServiceSelect}
                entityType="targetservice"
                isClearable
              />
            </Form.Item>

            <Form.Item
              name={this.permissionGroupAttrName}
              label={<FormLabel>Выберите группу прав</FormLabel>}
              rules={[
                {
                  required: true,
                  message: "Выберите группу прав",
                },
              ]}
            >
              <AsyncSelect
                placeholder="Диспетчер"
                onChange={this.onPermissionGroupSelect}
                entityType="permissiongroup"
                filters={this.getFilters("permissiongroup")}
                isClearable
              />
            </Form.Item>
            <FormFooter>
              <Button disabled={this.isLoading} onClick={this.onResetForm}>
                Очистить
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.isLoading}
                onClick={this.onSubmit}
              >
                Отправить
              </Button>
            </FormFooter>
          </Form>
        </RequestContent>
      )
    }
  }
)
