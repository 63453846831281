import React from "react"
import { action, makeObservable } from "mobx"

import {
  AgGridServerSide,
  AgGridServerSideRef,
} from "pik-react-utils/components"
import { Datasource } from "./Datasource"
import { ReferenceStore } from "../InventoryReferenceStore"

interface IReferenceListProps {
  store: ReferenceStore
  onSelectRow: (data: IEntity) => void
}

export class ReferenceList extends React.Component<IReferenceListProps> {
  private readonly agGridRef = React.createRef<AgGridServerSideRef>()

  constructor(props: IReferenceListProps) {
    super(props)
    makeObservable(this, { update: action.bound })
  }

  componentDidMount(): void {
    this.update()
  }

  update() {
    const { query, referenceType } = this.props.store

    this.agGridRef.current?.setServerSideDatasource(
      new Datasource({
        query,
        type: referenceType?.type ?? "",
      })
    )
  }

  render() {
    return (
      <AgGridServerSide
        ref={this.agGridRef}
        columnDefs={[{ headerName: "Название", field: "name" }]}
        onRowClicked={this.props.onSelectRow}
      />
    )
  }
}
