import React from "react"
import styled from "styled-components"
import { uniqueId } from "lodash"

import { ArrowRightOutlined } from "components/antd"
import { EmploymentEntity } from "models"
import { LinkCell } from "./LinkCell"

const Icon = styled(ArrowRightOutlined)`
  padding: "0 6px";
`

export const EmploymentsCell = ({
  employments,
  onClick,
}: {
  employments: EmploymentEntity[]
  onClick: (value: string) => void
}) => (
  <>
    {employments.map(({ juristic_units_list, organization, position_name }) => (
      <p key={[organization, ...juristic_units_list].toString()}>
        {organization ? (
          <>
            <LinkCell
              title={organization.name}
              onClick={() => onClick(organization.name)}
            />
            <Icon />
          </>
        ) : null}
        {juristic_units_list.map((title: string, index: number) => (
          <React.Fragment key={uniqueId("juristic_units_list")}>
            {!!index && <Icon />}
            <LinkCell title={title} onClick={() => onClick(title)} />
          </React.Fragment>
        ))}
        {position_name && (
          <>
            <Icon />
            <LinkCell
              title={position_name}
              onClick={() => onClick(position_name)}
            />
          </>
        )}
      </p>
    ))}
  </>
)
