/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import {
  Button,
  Radio,
  Input,
  Checkbox,
  FormInstance,
  Switch,
  Col,
  Row,
  message,
  DatePicker,
} from "antd"
import { action, makeObservable, observable } from "mobx"
import { observer } from "mobx-react"

import { AsyncSelect } from "components"
import { FormLabel } from "components/css"
import { MeetingRequestEntity } from "models"
import { entitiesStore, IEntitiesStore } from "stores"
import {
  AttrNames,
  COUNTERACTION_OPTIONS,
  MEETING_OPTIONS,
  MEETING_RESULT_OPTIONS,
  REASON_OPTIONS,
} from "./constants"
import { dataFromServer, dataToServer } from "./helpers"
import { Form, FormFooter } from "../components"
import { RequestContent } from "../content"
import { PrivateFields } from "./types"

export const MeetingRequest = observer(
  class MeetingRequest extends React.Component {
    private isLoading: boolean
    private readonly formRef: React.RefObject<FormInstance<any>> =
      React.createRef()
    private readonly store: IEntitiesStore = entitiesStore

    constructor(props: Record<string, unknown>) {
      super(props)
      makeObservable<MeetingRequest, PrivateFields>(this, {
        isLoading: observable.struct,
        setLoading: action.bound,
        setFields: action.bound,
        loadPrevRequestMeeting: action.bound,
        onSave: action.bound,
        onSubmit: action.bound,
        onResetForm: action.bound,
        onValuesChange: action.bound,
      })
    }

    private setLoading(isLoading: boolean) {
      this.isLoading = isLoading
    }

    private setFields(fields: IEntity) {
      if (!fields) return
      const data = dataFromServer(fields)
      this.formRef.current?.setFieldsValue(data)
    }

    private async loadPrevRequestMeeting() {
      const building = this.formRef.current?.getFieldValue(AttrNames.BUILDING)
      const buildingId = building?.value?._uid

      if (!buildingId) return

      const response = await this.store.getList<MeetingRequestEntity>(
        "meetingrequest",
        { page_size: 1, building__uid: buildingId, ordering: "-created" }
      )

      const requestMeeting = response?.entities?.[0]
      if (requestMeeting) this.setFields(requestMeeting)
    }

    private async onSave() {
      const data = dataToServer(this.formRef.current?.getFieldsValue())
      try {
        await this.store.createEntity(data)
        message.success("Заявка успешно отправлена")
        this.onResetForm()
      } finally {
        this.setLoading(false)
      }
    }

    private onSubmit(e: React.MouseEvent) {
      e.preventDefault()
      this.formRef.current?.validateFields().then(() => {
        this.setLoading(true)
        this.onSave()
      })
    }

    private onResetForm() {
      this.formRef.current?.resetFields()
    }

    private onValuesChange(changedValues: { [key in AttrNames]: any }) {
      if (changedValues[AttrNames.BUILDING]) {
        this.loadPrevRequestMeeting()
        this.formRef.current?.validateFields([AttrNames.BUILDING])
      }
    }

    render() {
      return (
        <RequestContent title="Заявка на проведение ОСС">
          <Form
            ref={this.formRef}
            layout="vertical"
            onValuesChange={this.onValuesChange}
          >
            <Form.Item
              label={<FormLabel>Объект учета</FormLabel>}
              name={AttrNames.BUILDING}
              validateTrigger="submit"
              rules={[
                {
                  required: true,
                  message: "Выберите объект учета",
                },
              ]}
            >
              <AsyncSelect
                placeholder="Прим, Нахимовский проспект"
                entityType="building"
              />
            </Form.Item>

            <strong>Собрание против ПИК</strong>

            <br />
            <Form.Item
              name={AttrNames.AGAINST_MEETING_STATUS}
              label={<FormLabel>Текущий статус ОСС против нас</FormLabel>}
            >
              <Radio.Group options={MEETING_OPTIONS} />
            </Form.Item>

            <Form.Item
              name={AttrNames.ATTACKING_SIDE_INFO}
              label={<FormLabel>Атакующая УО</FormLabel>}
            >
              <Input placeholder={`ООО "Железные рукава"`} size="large" />
            </Form.Item>

            <Form.Item
              name={AttrNames.AGAINST_MEETING_RESULT}
              label={<FormLabel>Текущий результат ОСС против нас</FormLabel>}
            >
              <Radio.Group options={MEETING_RESULT_OPTIONS} />
            </Form.Item>

            <Form.Item
              name={AttrNames.PLANNED_COUNTERACTION}
              label={<FormLabel>Планируемая контрмера</FormLabel>}
            >
              <Checkbox.Group
                className="checkbox-group-vertical"
                options={COUNTERACTION_OPTIONS}
              />
            </Form.Item>

            <Form.Item
              name={AttrNames.HAS_INITIATOR}
              valuePropName="checked"
              className="ant-form-item-inline"
            >
              <Switch />
              <FormLabel>Наличие инициатора</FormLabel>
            </Form.Item>

            <Form.Item
              name={AttrNames.OUTFLOW_REASON}
              label={<FormLabel>Причины смены УК</FormLabel>}
              rules={[
                {
                  required: true,
                  message: "Выберите причину",
                },
              ]}
            >
              <Checkbox.Group
                className="checkbox-group-vertical"
                options={REASON_OPTIONS}
              />
            </Form.Item>

            <Row gutter={16}>
              <Col span={10}>
                <Form.Item
                  name={AttrNames.OUR_MEETING}
                  label={<FormLabel>Дата нашего ОСС</FormLabel>}
                  rules={[
                    {
                      required: true,
                      message: "Укажите дату нашего собрания",
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    placeholder={["от", "до"]}
                    format="DD.MM.YYYY"
                  />
                </Form.Item>
              </Col>

              <Col span={10}>
                <Form.Item
                  name={AttrNames.COMPETITOR_MEETING}
                  label={<FormLabel>Дата ОСС конкурента</FormLabel>}
                  rules={[
                    {
                      required: true,
                      message: "Укажите дату собрания конкурента",
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    placeholder={["от", "до"]}
                    format="DD.MM.YYYY"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name={AttrNames.E_OSS}
              valuePropName="checked"
              className="ant-form-item-inline"
            >
              <Switch />
              <FormLabel>Е-ОСС (электронное голосование)</FormLabel>
            </Form.Item>

            <Form.Item name={AttrNames.COMMENT} label="Комментарий">
              <Input.TextArea />
            </Form.Item>

            <FormFooter>
              <Button disabled={this.isLoading} onClick={this.onResetForm}>
                Очистить
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.isLoading}
                onClick={this.onSubmit}
              >
                Отправить
              </Button>
            </FormFooter>
          </Form>
        </RequestContent>
      )
    }
  }
)
