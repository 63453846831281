import { action, computed, makeObservable, observable } from "mobx"

import { TAG_CHOICES } from "constants/choices"
import { ENTITIES_FILTERS } from "constants/filters"
import { BuildingTypeEntity, InventoryBuildingEntity } from "models"
import { APIConfig } from "pik-react-utils/types"
import { BaseQueryStore } from "stores"
import { IInventoryEntity, InventoryQueryProps } from "./types"

const defaultTags = TAG_CHOICES.filter(
  (item) => item.value === "exploitation" || item.value === "management"
).map(({ value }) => value)

class InventoryBuildingStore extends BaseQueryStore<InventoryQueryProps> {
  attribute: { title: string; slug: string } | null
  private _selectedEntity: IInventoryEntity | undefined

  constructor(params: { filters?: InventoryQueryProps; search?: string }) {
    super({
      filters: { contract_tags__overlap: defaultTags.toString() },
      filterFields: ENTITIES_FILTERS.inventorybuilding,
      ...params,
    })
    makeObservable<InventoryBuildingStore, "_selectedEntity">(this, {
      _selectedEntity: observable,
      selectedEntity: computed,
      attribute: observable,
      isAbleDocuments: computed.struct,
      documentQuery: computed,
      initDefaultFilters: action.bound,
      setEntity: action.bound,
      setAttribute: action.bound,
      flushLists: action.bound,
      getEntity: action.bound,
      deleteSelectedEntity: action.bound,
    })
  }

  get selectedEntity() {
    return this._selectedEntity
  }

  get isAbleDocuments() {
    return this.selectedEntity instanceof InventoryBuildingEntity
  }

  get documentQuery() {
    if (!this.isAbleDocuments) return {}
    return {
      building__uid: this.selectedEntity?._uid,
      document_fields__overlap: this.attribute?.slug as string,
    }
  }

  async initDefaultFilters() {
    const response = await this.entitiesStore.getList<BuildingTypeEntity>(
      "buildingtype",
      { is_deleted: false }
    )
    const filtered = response?.entities?.find(
      (entity) => entity.full_name === "МКД"
    )
    this.updateFilters({ building_type__uid: filtered?._uid })
  }

  async setEntity(entity: IInventoryEntity) {
    this._selectedEntity = entity
  }

  setAttribute(attribute: { title: string; slug: string } | null) {
    this.attribute = attribute
  }

  flushLists(types: string[] | string) {
    this.entitiesStore.flushList(types)
  }

  async deleteSelectedEntity() {
    if (!this.selectedEntity) return
    await this.entitiesStore.deleteEntity(this.selectedEntity)
  }

  async getEntity<T extends IEntity>(
    entityLink: IEntityLink,
    config?: APIConfig & { useSchemaFields?: boolean }
  ) {
    return await this.entitiesStore.getEntity<T>(entityLink, config)
  }
}

export { InventoryBuildingStore }
