export const matchPathName = (PATH_NAME: string): boolean =>
  window.location.pathname === PATH_NAME

export const getSearchQueryParam = (name: string): string | null => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(name)
}

export const getHashQueryParam = (name: string): string | null => {
  const uri = window.location.href
  if (!uri) return null
  const searchParams = new URLSearchParams(new URL(uri).hash)
  return searchParams.get(name)
}
