import styled from "styled-components"

export const UnitName = styled.span`
  position: relative;
  top: -16px;
  font-size: 0.875rem;
  font-style: italic;
`

export { ApproverList } from "./ApproverList"
