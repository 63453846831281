import React, { PropsWithChildren } from "react"
import styled from "styled-components"

const Heading = styled.h1`
  margin-top: 0;
  margin-bottom: 36px;
  text-align: center;
  font-size: 1.35rem;
  font-weight: 600;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 600px;
  width: auto;
  max-width: 790px;
  height: 100%;
  padding: 24px 24px 0 24px;
  margin: 0 auto;
`

export const RequestContent = ({
  title,
  children,
}: PropsWithChildren<{ title?: string }>) => {
  return (
    <Wrapper>
      {title && <Heading>{title}</Heading>}
      {children}
    </Wrapper>
  )
}
