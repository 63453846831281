/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import {
  Button,
  Col,
  Row,
  InputNumber,
  Checkbox,
  Input,
  Select,
  FormInstance,
  message,
  DatePicker,
} from "antd"
import { action, makeObservable, observable } from "mobx"
import { observer } from "mobx-react"

import { UploadOutlined } from "components/antd"
import { AsyncSelect } from "components"
import { FormLabel } from "components/css"
import {
  FinancialEmploymentEntity,
  TariffRecalcAttachmentEntity,
  TariffRecalcRequestEntity,
} from "models"
import { PikUpload } from "pik-react-utils/components"
import { entitiesStore } from "stores"
import {
  REQUEST_TYPES,
  APARTMENTS_NUMBERS_REG_EX,
  NON_LIVING_PREMISES_REG_EX,
  AttrNames,
} from "./constants"
import { dataToServer, prepareEntity } from "./helpers"
import { ApproverList, UnitName } from "./components"
import { Form, FormFooter } from "../components"
import { RequestContent } from "../content"
import { PrivateFields } from "./types"

const { TextArea } = Input

export const TariffRecalcRequest = observer(
  class TariffRecalcRequest extends React.Component {
    private filesList: TariffRecalcAttachmentEntity[]
    private approvers: FinancialEmploymentEntity[] | undefined
    private isLoading: boolean
    private isAllApartments: boolean
    private isAllNonLivingPremises: boolean
    private measureUnitName: string
    private formRef: React.RefObject<FormInstance<any>> = React.createRef()
    private store = entitiesStore

    constructor(props: Record<string, unknown>) {
      super(props)
      makeObservable<TariffRecalcRequest, PrivateFields>(this, {
        filesList: observable,
        approvers: observable,
        isLoading: observable.struct,
        isAllApartments: observable.struct,
        isAllNonLivingPremises: observable.struct,
        measureUnitName: observable.struct,
        formRef: observable.ref,
        init: action.bound,
        setLoading: action.bound,
        getFieldByName: action.bound,
        updateApprovers: action.bound,
        prepareData: action.bound,
        onSave: action.bound,
        updateFiles: action.bound,
        onSubmit: action.bound,
        onUpload: action.bound,
        onResetForm: action.bound,
        onValuesChange: action.bound,
      })
    }

    componentDidMount() {
      this.init()
    }

    private init() {
      this.filesList = []
      this.approvers = []
      this.measureUnitName = ""
      this.isAllApartments = true
      this.isAllNonLivingPremises = true
    }

    private setLoading(isLoading: boolean) {
      this.isLoading = isLoading
    }

    private getFieldByName(name: string) {
      return this.formRef.current?.getFieldValue(name)
    }

    private async updateApprovers() {
      const building = this.getFieldByName(AttrNames.BUILDING)
      const buildingId = building?.value?._uid

      if (!buildingId) {
        this.approvers = []
        return
      }

      const finEmployments =
        await this.store.getEntitiesFromCustomUrl<FinancialEmploymentEntity>(
          { _type: "tariffrecalcrequest", suffix: "get-approvers" },
          { building_uid: buildingId }
        )

      const promises = finEmployments?.map((approver) =>
        this.store.getEntity<FinancialEmploymentEntity>(approver, {
          fromStorage: false,
        })
      )

      const approvers = await Promise.all(promises ?? [])
      this.approvers = approvers.filter(
        (item) => item instanceof FinancialEmploymentEntity
      ) as FinancialEmploymentEntity[]
    }

    private async prepareData(data: { [key in AttrNames]: unknown }) {
      const result = dataToServer(data)

      const [createdBuilding, createdService] = await Promise.all(
        [AttrNames.BUILDING, AttrNames.SERVICE].map(async (name) => {
          const entity = prepareEntity(this.getFieldByName(name))
          const createdEntity = await this.store.createEntity(entity)
          return { _uid: createdEntity?._uid, _type: createdEntity?._type }
        })
      )

      result[AttrNames.BUILDING] = createdBuilding
      result[AttrNames.SERVICE] = createdService

      result[AttrNames.MEASURE_UNIT_NAME] = this.measureUnitName
      result[AttrNames.APPROVERS] = this.approvers
      return result
    }

    private async onSave() {
      try {
        const preparedData = await this.prepareData(
          this.formRef.current?.getFieldsValue()
        )
        const response = await this.store.createEntity(preparedData)
        if (response) {
          this.updateFiles({ _uid: response._uid, _type: response._type })
        }
        message.success("Заявка успешно отправлена")
        this.onResetForm()
      } finally {
        this.setLoading(false)
      }
    }

    private async updateFiles(request: IEntityLink) {
      await Promise.all(
        this.filesList.map(
          ({ _uid, _type, file_name }: TariffRecalcAttachmentEntity) =>
            this.store.updateEntity<TariffRecalcAttachmentEntity>({
              _uid,
              _type,
              file_name,
              tariff_recalc_request: request,
            })
        )
      )
      this.store.updateEntity<TariffRecalcRequestEntity>({
        ...request,
        is_ready: true,
      })
    }

    private onSubmit(e: React.MouseEvent) {
      e.preventDefault()
      this.formRef.current?.validateFields().then(() => {
        this.setLoading(true)
        this.onSave()
      })
    }

    private onUpload(attachment: TariffRecalcAttachmentEntity) {
      this.filesList.push(attachment)
    }

    private onResetForm() {
      this.formRef.current?.resetFields()
      this.init()
    }

    private onValuesChange(changedValues: { [key in AttrNames]: any }) {
      if (AttrNames.BUILDING in changedValues) {
        this.updateApprovers()
        if (changedValues[AttrNames.BUILDING]) {
          this.formRef.current?.validateFields([AttrNames.BUILDING])
        }
      }

      if (AttrNames.SERVICE in changedValues) {
        this.measureUnitName =
          changedValues[AttrNames.SERVICE]?.value?.unitName ?? ""
      }

      if (AttrNames.IS_ALL_APARTMENTS in changedValues) {
        this.isAllApartments = changedValues[AttrNames.IS_ALL_APARTMENTS]
        if (this.isAllApartments) {
          this.formRef.current?.resetFields([AttrNames.APARTMENT_NUMBERS])
        }
      }

      if (AttrNames.IS_ALL_NON_LIVING_PREMISES in changedValues) {
        this.isAllNonLivingPremises =
          changedValues[AttrNames.IS_ALL_NON_LIVING_PREMISES]
        if (this.isAllNonLivingPremises) {
          this.formRef.current?.resetFields([AttrNames.NON_LIVING_PREMISE])
        }
      }
    }

    render() {
      return (
        <RequestContent title="Заявка на перерасчет">
          <Form
            ref={this.formRef}
            layout="vertical"
            onValuesChange={this.onValuesChange}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name={AttrNames.BUILDING}
                  label={<FormLabel>Объект учета</FormLabel>}
                  validateTrigger="submit"
                  rules={[
                    {
                      required: true,
                      message: "Выберите объект учета",
                    },
                  ]}
                >
                  <AsyncSelect
                    placeholder="Прим, Нахимовский проспект"
                    entityType="building"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={AttrNames.SERVICE}
                  label={<FormLabel>Услуга</FormLabel>}
                  rules={[
                    {
                      required: true,
                      message: "Выберите услугу",
                    },
                  ]}
                >
                  <AsyncSelect
                    entityType="tariffservice"
                    placeholder="Прим. Уборка территории"
                    field={{ label: ["name", "unitName"] }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col span={12}>
                <Form.Item
                  name={AttrNames.RECALC_PERIOD}
                  label={<FormLabel>Период перерасчета</FormLabel>}
                  rules={[
                    {
                      required: true,
                      message: "Период перерасчета",
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    placeholder={["от", "до"]}
                    format="DD.MM.YYYY"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={AttrNames.TYPE}
                  label={<FormLabel>Тип заявки</FormLabel>}
                  rules={[
                    {
                      required: true,
                      message: "Выберите тип заявки",
                    },
                  ]}
                >
                  <Select>
                    {REQUEST_TYPES.map((type) => (
                      <Select.Option
                        key={JSON.stringify(type.value)}
                        value={type.value}
                      >
                        {type.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col span={16}>
                <Form.Item
                  name={AttrNames.APARTMENT_NUMBERS}
                  label={<FormLabel>Список квартир</FormLabel>}
                  initialValue=""
                  rules={[
                    {
                      required: false,
                      pattern: APARTMENTS_NUMBERS_REG_EX,
                      message:
                        "Укажите список квартир и/или диапазонов квартир",
                    },
                  ]}
                >
                  <Input
                    placeholder="Прим. 10, 13, 22-25"
                    disabled={this.isAllApartments}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={AttrNames.IS_ALL_APARTMENTS}
                  valuePropName="checked"
                  initialValue
                >
                  <Checkbox>Все квартиры</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col span={16}>
                <Form.Item
                  name={AttrNames.NON_LIVING_PREMISE}
                  label={<FormLabel>Список нежилых помещений</FormLabel>}
                  initialValue=""
                  rules={[
                    {
                      required: !this.isAllNonLivingPremises,
                      pattern: NON_LIVING_PREMISES_REG_EX,
                      message: "Укажите список НП",
                    },
                  ]}
                >
                  <Input
                    placeholder="Прим. НП1, НП2, НП3"
                    disabled={this.isAllNonLivingPremises}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={AttrNames.IS_ALL_NON_LIVING_PREMISES}
                  valuePropName="checked"
                  initialValue
                >
                  <Checkbox>Все НП</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name={AttrNames.RECALC_TARIFF}
                  label={<FormLabel>Тариф к перерасчету</FormLabel>}
                  initialValue={0}
                  rules={[
                    {
                      required: true,
                      message: "Тариф к перерасчету",
                    },
                  ]}
                >
                  <InputNumber decimalSeparator="," />
                </Form.Item>
                {this.measureUnitName && (
                  <UnitName>*{this.measureUnitName}</UnitName>
                )}
              </Col>
              <Col span={12}>
                <Form.Item
                  name={AttrNames.COMMENT}
                  label={<FormLabel>Комментарий</FormLabel>}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="attachment"
                  label={<FormLabel>Основания</FormLabel>}
                >
                  <PikUpload<TariffRecalcAttachmentEntity>
                    name="attachment"
                    entityType="tariffrecalcattachment"
                    onChange={this.onUpload}
                    multiple
                  >
                    <Button icon={<UploadOutlined />}>
                      <span>Добавить</span>
                    </Button>
                  </PikUpload>
                </Form.Item>
              </Col>
            </Row>
            <ApproverList approvers={this.approvers} />
            <FormFooter>
              <Button disabled={this.isLoading} onClick={this.onResetForm}>
                Очистить
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.isLoading}
                onClick={this.onSubmit}
              >
                Отправить
              </Button>
            </FormFooter>
          </Form>
        </RequestContent>
      )
    }
  }
)
