import React, { useCallback, useState } from "react"
import { observer } from "mobx-react"
import AsyncPaginate from "react-select-async-paginate"

import { EmploymentEntity, ExternalAccountEntity } from "models"
import { entitiesStore } from "stores"
import { AsyncSelectOption } from "types"
import { employmentOptions } from "./utils"

export const EmploymentSelect = observer(
  ({
    value,
    onChange,
  }: {
    value?:
      | AsyncSelectOption<EmploymentEntity | ExternalAccountEntity>
      | undefined
    onChange?: (
      entity: AsyncSelectOption<EmploymentEntity | ExternalAccountEntity>
    ) => void
  }) => {
    const [store] = useState(() => entitiesStore)

    const loadEntities = useCallback(
      async <T extends IEntity>(
        entity: string,
        params: { [key: string]: string | number | boolean }
      ) => {
        return await store.getList<T>(entity, {
          ...params,
          is_actual: true,
          is_deleted: false,
        })
      },
      [store]
    )

    const loadEmployments = async (search: string) => {
      const [externalaccounts, empoyments] = await Promise.all([
        loadEntities<ExternalAccountEntity>("externalaccount", {
          search,
          ordering: "last_name",
        }),
        loadEntities<EmploymentEntity>("employment", {
          search,
          ordering: "employee__last_name",
        }),
      ])

      const options = [
        ...employmentOptions(externalaccounts?.entities),
        ...employmentOptions(empoyments?.entities),
      ]

      return {
        options,
        hasMore: Boolean(
          externalaccounts?.meta?.page_next || empoyments?.meta?.page_next
        ),
        additional: {
          page: externalaccounts?.meta.page_next || empoyments?.meta?.page_next,
        },
      }
    }

    return (
      <AsyncPaginate
        loadOptions={loadEmployments}
        debounceTimeout={300}
        value={value}
        onChange={onChange}
        isClearable
      />
    )
  }
)
