import React from "react"
import { Collapse } from "antd"
import { useParams } from "react-router-dom"

import { Wrapper, Heading, Footer } from "./component"
import { instructionBlocks } from "./constants"

export const Instructions = () => {
  const { instruction } = useParams<{ instruction: string }>()

  return (
    <Wrapper>
      {instructionBlocks.map(({ title, items }) => (
        <React.Fragment key={title}>
          <Heading>{title}</Heading>
          <Collapse activeKey={instruction}>
            {items.map(({ key, header, component }) => (
              <Collapse.Panel key={key} header={header}>
                {component}
              </Collapse.Panel>
            ))}
          </Collapse>
        </React.Fragment>
      ))}
      <Footer>
        <p>
          В случае возникновения проблем на любом из этапов обращаться в
          техническую поддержку по телефону
          8&nbsp;800&nbsp;505&#8209;89&#8209;89 (доб.7777), либо отправив письмо
          на&nbsp;
          <a href="mailto:7777@pik-comfort.ru">7777@pik&#8209;comfort.ru</a>
        </p>
      </Footer>
    </Wrapper>
  )
}
