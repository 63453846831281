import { Input } from "antd"
import React, { useState } from "react"

import { InfiniteScroll } from "components"
import { BuildingEntity } from "models"
import { BuildingInfo, Header, Type, Content, Wrapper } from "./components"

export const Territory = ({ query }: { query: Record<string, unknown> }) => {
  const [search, setSearch] = useState<string>("")

  return (
    <Wrapper>
      <Header>
        <Input.Search
          placeholder="Поиск"
          enterButton="Найти"
          onSearch={(value: string) => setSearch(value)}
          allowClear
        />
      </Header>
      <Content>
        <InfiniteScroll
          entityType="building"
          query={{ ...query, search }}
          itemEl={(building: BuildingEntity) => (
            <BuildingInfo>
              <span>{building.address}</span>
              <Type>{building.building_type?.name}</Type>
            </BuildingInfo>
          )}
        />
      </Content>
    </Wrapper>
  )
}
