import { BaseEntity } from "pik-react-utils/entities"
import { Contractor } from "./contractor"
import { FinancialUnit } from "./financialunit"

import { createSerializrSchema } from "pik-react-utils/utils"

const schema: ISchema = {
  financial_unit: {
    name: "Подразделение финансовой структуры",
    _type: "entitylink",
    entityLinkTo: FinancialUnit,
  },
  contractor: {
    name: "Контрагенты",
    _type: "entitylink",
    entityLinkTo: Contractor,
  },
} as const

export class FinancialUnitOrganizationRelation extends BaseEntity {
  static readonly schema: ISchema = schema

  contractor: Contractor
}

createSerializrSchema(FinancialUnitOrganizationRelation, schema)
