import React from "react"
import {
  ColDef,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
  MenuItemDef,
  RowGroupOpenedEvent,
  ValueFormatterParams,
} from "ag-grid-community"
import { observer } from "mobx-react"
import {
  action,
  IReactionDisposer,
  makeObservable,
  observable,
  when,
} from "mobx"

import { PAGE_SIZE_DEFAULT } from "constants/requests"
import { COLOR_ACCENT } from "constants/ui-colors"
import { processCellForClipboard } from "pik-react-utils/utils/clipboard"
import { PrivateFields } from "./types"
import { AgGridComponent } from "../GridComponent"

enum KeyboardKey {
  ARROW_LEFT = "ArrowLeft",
  ARROW_DOWN = "ArrowDown",
  ARROW_UP = "ArrowUp",
  ARROW_RIGHT = "ArrowRight",
  ENTER = "Enter",
}

type IAgGridServerSideProps = {
  columnDefs: ColDef[]
  onGridReady?: () => void
  autoGroupColumnDef?: ColDef
  onRowClicked?: (data: unknown) => void
  getContextMenuItems?: (data: string[] | undefined) => (string | MenuItemDef)[]
}

// eslint-disable-next-line max-len
export class AgGridServerSideRef extends React.Component<IAgGridServerSideProps> {
  private gridApi: GridApi
  private purgedArray: string[] = []
  private neededSelectFirstRow = true
  private readonly tableRef = React.createRef<HTMLDivElement>()
  private reactionDisposer: IReactionDisposer

  constructor(props: IAgGridServerSideProps) {
    super(props)
    makeObservable<AgGridServerSideRef, PrivateFields>(this, {
      gridApi: observable,
      purgedArray: observable,
      neededSelectFirstRow: observable.struct,
      setNode: action.bound,
      onGridReady: action.bound,
      onResize: action.bound,
      onKeyDown: action.bound,
      onArrowLeft: action.bound,
      onArrowRight: action.bound,
      onArrowDown: action.bound,
      onArrowUp: action.bound,
      onRowClicked: action.bound,
      onRowGroupOpened: action.bound,
      getContextMenuItems: action.bound,
      getRowStyle: action.bound,
      onModelUpdated: action.bound,
      refresh: action.bound,
      setServerSideDatasource: action.bound,
      focus: action.bound,
    })
  }

  private currentSelectedNode = () => this.gridApi?.getSelectedNodes()?.[0]
  private currentSelectedRow = () => this.gridApi?.getSelectedRows()?.[0]

  componentWillUnmount(): void {
    this.reactionDisposer?.()
  }

  private setNode(index: number) {
    if (!this.gridApi) return
    const isNumber = typeof index === "number" && !isNaN(index)
    const checkedIndex = isNumber && index >= 0 ? index : 0
    const node = this.gridApi?.getDisplayedRowAtIndex(checkedIndex)
    node?.setSelected(true, true)
  }

  private onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api
    this.props.onGridReady?.()
    this.onResize()
    this.focus()
  }

  private onResize() {
    this.gridApi?.sizeColumnsToFit()
  }

  private onArrowLeft() {
    const rowNode = this.currentSelectedNode()

    if (!rowNode?.parent) return
    if (rowNode.parent.level >= 0) {
      this.setNode(rowNode.parent.rowIndex)
    }

    rowNode.parent.setExpanded(false)
  }

  private onArrowRight() {
    const rowNode = this.currentSelectedNode()
    if (rowNode?.isExpandable() && !rowNode.expanded) {
      rowNode.setExpanded(true)
    }
  }

  private onArrowDown() {
    const index = this.currentSelectedNode()?.rowIndex + 1
    this.setNode(index)
  }

  private onArrowUp() {
    const index = this.currentSelectedNode()?.rowIndex - 1
    this.setNode(index)
  }

  private onRowClicked(rowIndex: number) {
    this.setNode(rowIndex)
    this.props.onRowClicked?.(this.currentSelectedRow())
  }

  private onRowGroupOpened(event: RowGroupOpenedEvent) {
    if (event.data) {
      this.purgedArray.push(event.data.name)
    }
  }

  private getContextMenuItems(params: GetContextMenuItemsParams) {
    return this.props.getContextMenuItems?.(params.defaultItems) ?? []
  }

  private getRowStyle(params: ValueFormatterParams) {
    if (!params.data?.isDeleted) return
    return {
      color: COLOR_ACCENT,
      fontWeight: "600",
    }
  }

  private onModelUpdated() {
    if (!this.gridApi || !this.neededSelectFirstRow) return
    const rowIndex = 0
    const node = this.gridApi?.getDisplayedRowAtIndex(rowIndex)
    if (node?.data) {
      this.onRowClicked(rowIndex)
      this.neededSelectFirstRow = false
    }
  }

  private onKeyDown(event: React.KeyboardEvent) {
    event.preventDefault()
    switch (event.key) {
      case KeyboardKey.ARROW_LEFT:
        return this.onArrowLeft()
      case KeyboardKey.ARROW_UP:
        return this.onArrowUp()
      case KeyboardKey.ARROW_DOWN:
        return this.onArrowDown()
      case KeyboardKey.ARROW_RIGHT:
        return this.onArrowRight()
      case KeyboardKey.ENTER:
        return this.onRowClicked?.(this.currentSelectedRow())
      default:
        return
    }
  }

  refresh() {
    this.purgedArray = []
    this.gridApi?.purgeServerSideCache(this.purgedArray)
    this.gridApi?.refreshCells()
  }

  setServerSideDatasource(datasource: IServerSideDatasource) {
    if (this.gridApi) {
      this.gridApi?.setServerSideDatasource(datasource)
      this.neededSelectFirstRow = true
    } else {
      this.reactionDisposer = when(
        () => !!this.gridApi,
        () => this.setServerSideDatasource(datasource)
      )
    }
  }

  focus() {
    this.tableRef.current?.focus()
  }

  render() {
    const { columnDefs, autoGroupColumnDef } = this.props

    return (
      <div
        tabIndex={0}
        ref={this.tableRef}
        onKeyDown={this.onKeyDown}
        style={{ height: "100%" }}
      >
        <AgGridComponent
          rowModelType="serverSide"
          columnDefs={columnDefs}
          autoGroupColumnDef={autoGroupColumnDef}
          onGridReady={this.onGridReady}
          cacheBlockSize={PAGE_SIZE_DEFAULT}
          isServerSideGroup={(data) => !!data.isGroup}
          getServerSideGroupKey={(data) => data.groupKey}
          onRowClicked={(event) => this.onRowClicked(event.rowIndex)}
          onRowDoubleClicked={this.onRowGroupOpened}
          onRowGroupOpened={this.onRowGroupOpened}
          defaultColDef={{ resizable: true }}
          treeData={!!autoGroupColumnDef}
          getRowStyle={this.getRowStyle}
          getContextMenuItems={this.getContextMenuItems}
          maxConcurrentDatasourceRequests={8}
          cacheOverflowSize={50}
          infiniteInitialRowCount={1000}
          groupSelectsFiltered
          onModelUpdated={this.onModelUpdated}
          onCellContextMenu={(event) => this.onRowClicked?.(event.rowIndex)}
          processCellForClipboard={processCellForClipboard}
        />
      </div>
    )
  }
}

export const AgGridServerSide = React.forwardRef(function (
  props: IAgGridServerSideProps,
  ref: React.LegacyRef<AgGridServerSideRef>
) {
  const AgGridServerSide = observer(AgGridServerSideRef)
  return <AgGridServerSide {...props} ref={ref} />
})
