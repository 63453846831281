import { Dayjs } from "dayjs"
import { AsyncSelectOption } from "types"
import { FilterValue } from "./types"

export const fieldFormattedValue = (
  type: string | null | undefined,
  value: FilterValue
) => {
  if (!type) return null
  if (type === "entitylink") {
    return (value as AsyncSelectOption)?.value?._uid
  }
  if (type === "tags") {
    const tags = value as string[] | undefined
    return tags?.length ? tags.toString() : null
  }
  if (type === "date") {
    return (value as Dayjs)?.toISOString()
  }
  return value
}
