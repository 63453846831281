export const MEETING_OPTIONS = [
  { label: "Прошло", value: "passed" },
  { label: "Идет", value: "happens" },
  { label: "Планируется", value: "planned" },
]

export const MEETING_RESULT_OPTIONS = [
  { label: "В пользу ПИК", value: "in_favor" },
  { label: "Не в пользу ПИК", value: "against" },
  { label: "Неизвестно", value: "NULL" },
]

export const COUNTERACTION_OPTIONS = [
  { label: "КонтрОСС", value: "counter_meeting" },
  { label: "Конкурс", value: "competition" },
  { label: "Суд", value: "court" },
  { label: "КЧС", value: "emergency_commission" },
]

export const REASON_OPTIONS = [
  { label: "Эксплуатация", value: "exploitation" },
  { label: "Уборка", value: "cleaning" },
  { label: "Благоустройство", value: "landscaping" },
  { label: "Начисления", value: "billing" },
  { label: "Заявки", value: "complaints" },
  { label: "Доступность офиса", value: "office_accessibility" },
  { label: "Лифты", value: "elevators" },
  { label: "Тариф", value: "tariff" },
]

export enum AttrNames {
  BUILDING = "building",
  AGAINST_MEETING_STATUS = "against_meeting_status",
  ATTACKING_SIDE_INFO = "attacking_side_info",
  AGAINST_MEETING_RESULT = "against_meeting_result",
  PLANNED_COUNTERACTION = "planned_counteraction",
  HAS_INITIATOR = "has_initiator",
  OUTFLOW_REASON = "outflow_reason",
  OUR_MEETING = "our_meeting",
  COMPETITOR_MEETING = "competitor_meeting",
  E_OSS = "e_oss",
  COMMENT = "comment",
  TYPE = "_type",
  START_PERIOD_DATE = "start_period_date",
}

export const NULLABLE_FIELDS = [AttrNames.AGAINST_MEETING_RESULT]

export const NON_MODIFIED_FIELDS = [
  AttrNames.AGAINST_MEETING_STATUS,
  AttrNames.AGAINST_MEETING_RESULT,
  AttrNames.HAS_INITIATOR,
  AttrNames.E_OSS,
  AttrNames.COMMENT,
]

export const RANGE_DATA_FIELDS = [
  AttrNames.COMPETITOR_MEETING,
  AttrNames.OUR_MEETING,
]

export const MULTIPLE_VALUES = {
  [AttrNames.PLANNED_COUNTERACTION]: COUNTERACTION_OPTIONS,
  [AttrNames.OUTFLOW_REASON]: REASON_OPTIONS,
}
