import React from "react"
import { Button, Select } from "antd"
import { observer } from "mobx-react"
import { action, computed, makeObservable, observable } from "mobx"
import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community"

import { PIVOT_ENTITIES, EXCLUDED_PIVOT_FIELDS } from "constants/pivot"
import { AccessPortal, QueryPanel } from "components"
import { AttentionModal, ColumnModal, CreateEntityModal } from "modals"
import { AgGridViewport, AgGridViewportRef } from "pik-react-utils/components"
import { routerService, storageService } from "services"
import { PIVOT_ENTITIES_DESCRIPTION } from "./constants"
import { Content, ExcelBtn, Header } from "./components"
import { PivotStore } from "./PivotStore"
import { PrivateFields } from "./types"

enum NewEntityModalType {
  NEW = "new",
  NEW_AS_COPY = "newAsCopy",
}

export const Pivot = observer(
  class Pivot extends React.Component {
    private newEntityModal: NewEntityModalType | null
    private excelModalIsOpen: boolean
    private isShowingToolPanel: boolean
    private localstorage = storageService
    private readonly viewportRef = React.createRef<AgGridViewportRef>()
    private router = routerService
    private pivotStore: PivotStore

    constructor(props: Record<string, unknown>) {
      super(props)
      makeObservable<Pivot, PrivateFields>(this, {
        newEntityModal: observable,
        excelModalIsOpen: observable.struct,
        isShowingToolPanel: observable.struct,
        defaultValueForNew: computed,
        currentEntityType: computed,
        selectedEntity: computed,
        getContextMenuItems: action.bound,
        onExportClick: action.bound,
        openCreateNewEntityModal: action.bound,
        onCloseCreateNewEntityModal: action.bound,
        onCreateNewEntity: action.bound,
        closeExcelModal: action.bound,
        onSelect: action.bound,
        onShowToolPanel: action.bound,
      })
      this.pivotStore = new PivotStore({
        type: this.router.getMatchPivotEntityType() ?? PIVOT_ENTITIES[0].value,
        ...this.router.getSearchParams(),
      })

      const { getItem, keys } = this.localstorage
      const isShowing = getItem(keys.pivotToolPanel)
      this.isShowingToolPanel =
        isShowing === null ? true : JSON.parse(isShowing)
    }

    private get defaultValueForNew() {
      if (this.newEntityModal === NewEntityModalType.NEW_AS_COPY) {
        return this.selectedEntity
      }
      return
    }

    private get currentEntityType() {
      return this.pivotStore.currentEntityType
    }

    get selectedEntity() {
      return this.pivotStore.selectedEntity ?? {}
    }

    private getContextMenuItems(params: GetContextMenuItemsParams) {
      const items: (MenuItemDef | string)[] | undefined =
        params.defaultItems ?? []

      if (!items || !this.pivotStore.canCreateEntity) {
        return items
      }

      items.push({
        name: "Создать копию",
        action: () =>
          this.openCreateNewEntityModal(NewEntityModalType.NEW_AS_COPY),
      })

      return items
    }

    private onExportClick(e: React.MouseEvent) {
      if (!this.pivotStore.visibleAllFields) return
      e.preventDefault()
      this.excelModalIsOpen = true
    }

    private openCreateNewEntityModal(type: NewEntityModalType) {
      this.newEntityModal = type
    }

    private onCloseCreateNewEntityModal(isCreated?: boolean) {
      if (isCreated) {
        this.onCreateNewEntity()
      } else {
        this.newEntityModal = null
      }
    }

    private onCreateNewEntity() {
      this.newEntityModal = null
      this.pivotStore.flushList()
      this.viewportRef.current?.refresh()
    }

    private closeExcelModal() {
      this.excelModalIsOpen = false
    }

    private onSelect(type: string) {
      this.pivotStore.update(type)
      this.router.navToPivot(
        this.currentEntityType,
        this.pivotStore.searchParamsForURL
      )
    }

    private onShowToolPanel(isShowing: boolean) {
      this.isShowingToolPanel = isShowing
      const { keys, setItem } = this.localstorage
      setItem(keys.pivotToolPanel, String(this.isShowingToolPanel))
    }

    render() {
      const downloadBtnProps = {
        href: this.router.linkToExcel(this.currentEntityType),
        target: "_blank",
      }

      return (
        <>
          <Content>
            <Header>
              <Select
                onChange={this.onSelect}
                value={this.currentEntityType}
                options={PIVOT_ENTITIES}
              />
              <QueryPanel
                entityType={this.currentEntityType}
                store={this.pivotStore}
              />
              <ColumnModal
                entityType={this.currentEntityType}
                onSubmit={this.pivotStore.setEditableFields}
                editableFields={this.pivotStore.editableFields}
                excludedFields={EXCLUDED_PIVOT_FIELDS}
              />
              <ExcelBtn
                title="Экспорт всех ОУ в файл .xlsx"
                onClick={this.onExportClick}
                {...downloadBtnProps}
              >
                Экспорт в Excel
              </ExcelBtn>
              {this.pivotStore.canCreateEntity && (
                <Button
                  onClick={() =>
                    this.openCreateNewEntityModal(NewEntityModalType.NEW)
                  }
                >
                  Создать
                </Button>
              )}
            </Header>
            <AccessPortal
              entityType={this.currentEntityType}
              title={PIVOT_ENTITIES_DESCRIPTION[this.currentEntityType].title}
              description={
                PIVOT_ENTITIES_DESCRIPTION[this.currentEntityType].description
              }
            >
              <AgGridViewport
                ref={this.viewportRef}
                entityType={this.currentEntityType}
                query={this.pivotStore.query}
                onCellClicked={this.pivotStore.setSelect}
                visibleFields={this.pivotStore.visibleFields}
                editableFields={this.pivotStore.editableFields}
                getContextMenuItems={this.getContextMenuItems}
                onChangeVisibleFields={this.pivotStore.setVisibleFields}
                excludedFields={EXCLUDED_PIVOT_FIELDS}
                columnOpenGroupState={this.pivotStore.columnOpenGroupState}
                onChangeColumnGroupState={this.pivotStore.setColumnGroupState}
                autoColumnGroups
                sideBarProps={{
                  isDefaultShowing: this.isShowingToolPanel,
                  onShowToolPanel: this.onShowToolPanel,
                }}
              />
            </AccessPortal>
          </Content>
          <CreateEntityModal
            entityType={this.currentEntityType}
            title="Создать новый объект"
            open={!!this.newEntityModal}
            onClose={this.onCloseCreateNewEntityModal}
            onSuccess={this.onCreateNewEntity}
            defaultValues={this.defaultValueForNew}
          />
          <AttentionModal
            open={this.excelModalIsOpen}
            okText="Продолжить скачивание"
            onCancel={this.closeExcelModal}
            okButtonProps={downloadBtnProps}
          >
            Вы хотите скачать таблицу со всеми свойствами выбранного объекта.
            Это может занять продолжительное время, рекомендуем ограничить набор
            полей.
          </AttentionModal>
        </>
      )
    }
  }
)
