import React from "react"
import reactYandexMetrika, { YMInitializer } from "react-yandex-metrika"

import { routerService } from "services"

export class YMListener extends React.Component {
  private router = routerService
  private unListen: () => void

  componentDidMount() {
    this.unListen = this.router.listen(
      ({ location }: { location: Location }) => {
        // Exclude oauth tokens from yandex metrika
        if (location.pathname?.includes("oauth_redirect")) {
          reactYandexMetrika("hit", location.pathname.split("#")[0])
          return
        }
        reactYandexMetrika("hit", location.pathname)
      }
    )
  }

  componentWillUnmount() {
    this.unListen()
  }

  render() {
    return <YMInitializer accounts={[46284543]} />
  }
}
