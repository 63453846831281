import {
  ACCESSION_STATUS_CHOICES,
  COMFORT_CLASS_CHOICES,
  OUTFLOW_STATUS_CHOICES,
  TARIFFICATION_CLASS_CHOICES,
} from "constants/choices"
import { groupDocumentFields, GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { Building } from "../building"
import { BuildingChangeAttachment } from "../buildingchangeattachment"
import { BuildingsGroup } from "../buildingsgroup"
import { BuildingType } from "../buildingtype"
import { Contractor } from "../contractor"
import { Exploitation } from "../exploitation"
import { ManagementCompany } from "../managementcompany"
import { ManagementService } from "../managementservice"
import { MDSLivingComplex } from "../mdslivingcomplex"

export const schema: ISchema = {
  ...groupDocumentFields({
    attachment: {
      name: "Прикрепленный файл",
      _type: "attachment",
      entityLinkTo: BuildingChangeAttachment,
      editable: true,
    },
  }),

  group_identification: {
    name: "Идентификация",
    children: {
      juristic_address: { name: "Наименование", editable: true },
      building: {
        name: "Здание",
        _type: "entitylink",
        entityLinkTo: Building,
        _isNull: true,
        editable: true,
      },
      house_fias_id: { name: "Код ФИАС", editable: true },
      oidp_codes: { name: "Коды ОИДП", _type: "string[]", editable: true },
      cadastral_id: { name: "Кадастровый номер здания", editable: true },
      land_cadastral_id: {
        name: "Кадастровый номер замельного участка",
        editable: true,
      },
      building_type: {
        name: "Тип",
        _type: "entitylink",
        entityLinkTo: BuildingType,
        editable: true,
      },
      comfort_class: {
        _type: "choices",
        choices: COMFORT_CLASS_CHOICES,
        name: "Класс фонда",
        editable: true,
      },
      empowered_developer: {
        name: "Застройщик",
        _type: "entitylink",
        entityLinkTo: Contractor,
        editable: true,
      },
      building_address: { name: "Строительный адрес", editable: true },
      living_complex: {
        name: "Жилищный комплекс",
        _type: "entitylink",
        entityLinkTo: MDSLivingComplex,
        editable: true,
      },
      special_fund: {
        name: "Спецфонд",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      product_fund: {
        name: "Фонд продукт",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      renovation_fund: {
        name: "Реновация",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      tariffication_class: {
        name: "Тарификация",
        _type: "choices",
        choices: TARIFFICATION_CLASS_CHOICES,
        editable: true,
        _isNull: true,
      },
    },
  },

  group_contracts: {
    name: "Договоры",
    children: {
      has_management_tag: {
        name: "Управление (УК)",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      has_exploitation_tag: {
        name: "Эксплуатация (УК)",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      has_receipt_tag: {
        name: "Приемка",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      has_funds_collecting_tag: {
        name: "Сбор средств через ЛК Клиента",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      has_customer_service_tag: {
        name: "Услуга обращения в ЛК",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      has_contact_center_service_tag: {
        name: "Контактный центр",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      has_dvz_tag: {
        name: "ДВЗ",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
    },
  },

  group_management_structure: {
    name: "Структура управления",
    children: {
      management_company: {
        name: "Управляющая компания",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
        editable: true,
      },
      management_service: {
        name: "Территориальное отделение управления",
        _type: "entitylink",
        entityLinkTo: ManagementService,
        editable: true,
      },
    },
  },

  group_exploitation_structure: {
    name: "Структура эксплуатации (ГП №1)",
    children: {
      exploitation: {
        name: "Служба эксплуатации",
        _type: "entitylink",
        entityLinkTo: Exploitation,
        editable: true,
      },
      buildings_group: {
        name: "Группа зданий",
        _type: "entitylink",
        entityLinkTo: BuildingsGroup,
        editable: true,
      },
    },
  },

  group_billing: {
    name: "Биллинг",
    children: {
      residential_premise_billing: {
        name: "Билинг жилых помещений",
        _type: "entitylink",
        entityLinkTo: Contractor,
        editable: true,
      },
      non_living_premise_billing: {
        name: "Билинг нежилых помещений",
        _type: "entitylink",
        entityLinkTo: Contractor,
        editable: true,
      },
    },
  },

  premise_type: {
    name: "Тип помещений",
    children: {
      residential_premise_space: {
        name: "Квартиры, м2",
        _type: "decimal",
        editable: true,
      },
      residential_premise_count: {
        name: "Квартиры, шт.",
        _type: "integer",
        editable: true,
      },
      apartment_space: {
        name: "Апартаменты, м2",
        _type: "decimal",
        editable: true,
      },
      apartment_count: {
        name: "Апартаменты, шт.",
        _type: "integer",
        editable: true,
      },
      parking_place_space: {
        name: "Машиноместа, м2",
        _type: "decimal",
        editable: true,
      },
      parking_place_count: {
        name: "Машиноместа, шт.",
        _type: "integer",
        editable: true,
      },
      storage_room_space: {
        name: "Кладовые, м2",
        _type: "decimal",
        editable: true,
      },
      storage_room_count: {
        name: "Кладовые, шт.",
        _type: "integer",
        editable: true,
      },
      non_living_premise_space: {
        name: "Нежилые помещения (БКФН), м2",
        _type: "number",
        editable: true,
      },
      non_living_premise_count: {
        name: "Нежилые помещения (БКФН), шт.",
        _type: "integer",
        editable: true,
      },
    },
  },

  group_exploitation_parameters: {
    name: "Эксплуатационные характеристики",
    children: {
      max_floor_count: {
        name: "Этажность (1 этаж и выше), max",
        _type: "integer",
        editable: true,
      },
      min_floor_count: {
        name: "Этажность (1 этаж и выше), min",
        _type: "integer",
        editable: true,
      },
      common_areas_space_first_floor: {
        name: "МОП (1 этаж), м2",
        _type: "decimal",
        editable: true,
      },
      common_areas_space_other_floors: {
        name: "МОП (2 этаж и выше), м2",
        _type: "decimal",
        editable: true,
      },
      common_areas_space_garage: {
        name: "МОП (гараж), м2",
        _type: "decimal",
        editable: true,
      },
      garbage_room_space: { name: "МК, м2", _type: "decimal", editable: true },
      adjacent_territory_space: {
        name: "ПТ (убираемая УК), м2",
        _type: "decimal",
        editable: true,
      },
      typical_floors_count: {
        name: "Типовые этажи, шт.",
        _type: "integer",
        editable: true,
      },
      concierge_count: {
        name: "Консьержи (по ОСС), шт.",
        _type: "integer",
        editable: true,
      },
    },
  },

  life_cycle: {
    name: "Жизненный цикл",
    children: {
      accession_status: {
        _type: "choices",
        choices: ACCESSION_STATUS_CHOICES,
        name: "Основание прихода в периметр",
        editable: true,
      },
      outflow_status: {
        _type: "choices",
        choices: OUTFLOW_STATUS_CHOICES,
        name: "Основание выхода из периметра",
        editable: true,
      },
      outflow_company: {
        name: "Выбытие в управляющую компанию",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
        editable: true,
      },
      built_year: { name: "Год постройки", editable: true },
      permission_to_enter_date: { name: "РВОЭ", _type: "date", editable: true },
      occupancy_start_date: {
        name: "Заселение (начало)",
        _type: "date",
        editable: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const
