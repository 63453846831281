import { object } from "serializr"

import { createSerializrSchema } from "pik-react-utils/utils"
import { ExtraAttr } from "./extraattr"
import { PremiseType } from "./premisetype"

export class PremiseExtraAttr extends ExtraAttr {
  premise_type: PremiseType
}

createSerializrSchema(PremiseExtraAttr, null, {
  premise_type: object(PremiseType),
})
