import { AnnotationsMap, computed, observable } from "mobx"

export const generateObservableFieldsBySchema = function <T>(
  this: T,
  schema: ISchema
) {
  return Object.entries(schema).reduce(
    (acc, [key, value]): AnnotationsMap<string, PropertyKey> => {
      if ("children" in value) {
        return {
          ...acc,
          ...generateObservableFieldsBySchema.call(this, value.children),
        }
      }
      if ("computed" in value) {
        return {
          ...acc,
          [key]: computed,
          ...value.computed?.reduce(
            (acc, field) => ({ ...acc, [field]: observable }),
            {}
          ),
        }
      }

      return { ...acc, [key]: observable }
    },
    {} as AnnotationsMap<string, PropertyKey>
  )
}

export const addFieldsInClass = function <T>(this: T, schema: ISchema) {
  Object.entries(schema).map(([key, value]) => {
    if ("computed" in value) return

    if ("children" in value) {
      addFieldsInClass.call(this, value.children)
    } else {
      this[key] = null
    }
  })
}
