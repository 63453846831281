import React, { useCallback, useState } from "react"
import { observer } from "mobx-react"
import styled from "styled-components"

import { AgGridInspector } from "pik-react-utils/components"
import { Modal } from "../modal"
import { CreateEntityModalProps as Props } from "./types"

const Container = styled.div`
  height: calc(100vh - 300px);
  min-height: 400px;
`

export const CreateEntityModal = observer(
  ({ title, open, onSuccess, onClose, entityType, ...props }: Props) => {
    const [hasCreatedEntity, setCreatingEntity] = useState(false)
    const [newEntity, setNewEntity] = useState<IEntity | undefined>()

    const closeModal = useCallback(
      (hasCreated = hasCreatedEntity) => {
        if (hasCreated) onSuccess?.(newEntity)
        onClose()
        setCreatingEntity(false)
        setNewEntity(undefined)
      },
      [hasCreatedEntity, newEntity, onClose, onSuccess]
    )

    const onSaveEntity = useCallback(
      (isClosing: boolean, entity?: IEntity) => {
        setCreatingEntity(true)
        setNewEntity(entity)
        if (isClosing) closeModal(true)
      },
      [closeModal]
    )

    if (!open || !entityType) return null

    return (
      <Modal
        title={title ?? "Новый документ"}
        open={open}
        footer={null}
        width="820px"
        onCancel={() => closeModal()}
      >
        <Container>
          <AgGridInspector
            {...props}
            entityLink={{ _type: entityType, _uid: "" }}
            onCancel={closeModal}
            onSave={onSaveEntity}
            canCreateMore
          />
        </Container>
      </Modal>
    )
  }
)
