import { parse, stringify } from "qs"

export const getQueryFromURL = (search: string) =>
  parse(search, {
    ignoreQueryPrefix: true,
    strictNullHandling: true,
  }) || {}

export const queryToURL = (query: unknown) =>
  stringify(query, {
    skipNulls: true,
    encode: false,
    arrayFormat: "comma",
  })
