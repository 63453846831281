import { list, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"

export class JuristicUnit extends BaseEntity {
  parent_names: string[] | undefined
}

createSerializrSchema(JuristicUnit, null, {
  parent_names: list(primitive()),
})
