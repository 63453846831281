import React from "react"
import { Button } from "antd"
import { action, computed, makeObservable, observable } from "mobx"
import { observer } from "mobx-react"
import dayjs from "dayjs"

import { QueryPanel, Widget } from "components"
import { ColumnHeader, ColumnWithHeader, TwoColumnLayout } from "components/css"
import { CreateEntityModal } from "modals"
import { BuildingEntity } from "models"
import { getLink } from "pik-react-utils/entities"
import {
  AgGridServerSide,
  AgGridServerSideRef,
} from "pik-react-utils/components"
import { getChoiceLabel } from "pik-react-utils/utils/agGrid"
import { routerService } from "services"
import { COLUMNS } from "./constants"
import { Datasource } from "./Datasource"
import { UirtaStore } from "./UirtaStore"
import { UirtaInspector } from "./Inspector"
import { PrivateFields } from "./types"

export const UirtaBuildingTree = observer(
  class UirtaBuildingTree extends React.Component {
    private isOpenModal = false
    private readonly agGridRef = React.createRef<AgGridServerSideRef>()
    private readonly router = routerService
    private uirtaStore

    constructor(props: Record<string, unknown>) {
      super(props)
      this.uirtaStore = new UirtaStore(this.router.getSearchParams())
      makeObservable<UirtaBuildingTree, PrivateFields>(this, {
        isOpenModal: observable.struct,
        query: computed,
        updateDataSource: action.bound,
        openModal: action.bound,
        closeModal: action.bound,
        meetingRequestFormatter: action.bound,
        createEntityComponent: computed,
      })
    }

    componentDidMount(): void {
      this.updateDataSource()
    }

    private get query() {
      return this.uirtaStore?.query
    }

    private updateDataSource() {
      const datasource = new Datasource({ query: this.query })
      this.agGridRef.current?.setServerSideDatasource(datasource)
    }

    private openModal() {
      this.isOpenModal = true
    }

    private closeModal() {
      this.isOpenModal = false
    }

    private meetingRequestFormatter(changes: { [key: string]: unknown }) {
      let kind = ""
      let date = ""
      if (changes.kind) {
        const fields = this.uirtaStore.meetingFields
        const field = fields.find((item) => item.slug === "kind")
        if (field && "choices" in field) {
          kind = getChoiceLabel(field.choices, changes.kind as FieldChoiceValue)
        }
      }

      if (changes.document_date) {
        date = `(${dayjs(changes.document_date as string).format("MMMM YYYY")})`
      }

      return { ...changes, name: `${kind} ${date}` }
    }

    private get createEntityComponent() {
      const entity = this.uirtaStore.currentEntity
      if (!entity) return
      const building =
        entity instanceof BuildingEntity ? getLink(entity) : entity.building

      return (
        <CreateEntityModal
          isDocument
          entityType="meetingchange"
          title="Новое собрание"
          open={this.isOpenModal}
          onClose={this.closeModal}
          defaultValues={{
            building,
            status: "accepted",
            document_date: dayjs().toISOString(),
            document_is_draft: false,
            name: "Поле будет заполнено автоматически после сохранения документа",
          }}
          onSuccess={() => this.uirtaStore.flushLists("meeting")}
          requestFormatter={this.meetingRequestFormatter}
        />
      )
    }

    render() {
      return (
        <>
          <TwoColumnLayout>
            <ColumnWithHeader>
              <ColumnHeader>
                <QueryPanel
                  entityType="building"
                  store={this.uirtaStore}
                  onUpdate={this.updateDataSource}
                />
                <Button onClick={this.openModal}>Создать</Button>
              </ColumnHeader>
              <AgGridServerSide
                ref={this.agGridRef}
                columnDefs={COLUMNS}
                autoGroupColumnDef={{ headerName: "Название", field: "name" }}
                onRowClicked={this.uirtaStore.setEntity}
              />
            </ColumnWithHeader>

            <UirtaInspector store={this.uirtaStore} />
          </TwoColumnLayout>
          <Widget entityType="meeting" title="собраний" />
          {this.createEntityComponent}
        </>
      )
    }
  }
)
