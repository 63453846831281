import React from "react"
import { Tabs } from "antd"

import { FinancialUnitEntity } from "models"
import { RuleList } from "./RuleList"
import { BuildingsByRules } from "./BuildingsByRules"

import { observer } from "mobx-react"

export const Rules = observer(
  ({ financialUnit }: { financialUnit: FinancialUnitEntity }) => {
    const { _uid, follower_count } = financialUnit

    if (!_uid) return null

    return (
      <Tabs
        size="small"
        defaultActiveKey="buildings"
        destroyInactiveTabPane
        style={{ height: "100%" }}
        items={[
          {
            key: "buildings",
            label: "Объекты учёта",
            children: <BuildingsByRules financialUnitId={_uid} />,
          },
          {
            key: "rules",
            label: "Правила получения ОУ",
            disabled: follower_count !== 0,
            children: <RuleList financialUnitId={_uid} />,
          },
        ]}
      />
    )
  }
)
