import React from "react"
import { ICellRendererParams } from "ag-grid-community"
import { Button } from "antd"

import { DownloadOutlined } from "components/antd"
import { AttachmentEntity } from "models"
import { FileService } from "pik-react-utils/services"
import { CellContainer, NameEllipsis } from "../components"

export const AttachmentRender = ({
  valueFormatted,
  value,
}: ICellRendererParams) => {
  const downloadFile = () => {
    const attachment = value as AttachmentEntity
    if (!attachment?.file) return
    FileService.download(attachment.file, valueFormatted)
  }

  if (!valueFormatted) return null

  return (
    <CellContainer>
      <NameEllipsis>{valueFormatted}</NameEllipsis>
      <Button
        type="link"
        target="_blank"
        onClick={downloadFile}
        icon={<DownloadOutlined />}
      />
    </CellContainer>
  )
}
