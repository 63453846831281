import React from "react"
import { AccessPortal } from "components"
import { UirtaBuildingTree } from "./UirtaBuilding"

export const Uirta = () => (
  <AccessPortal
    entityType="meeting"
    title='У вас недостаточно прав для использования сервиса "Собрания"'
    description="Для предоставления доступа отправьте заявку на sobranie@pik-comfort.ru"
  >
    <UirtaBuildingTree />
  </AccessPortal>
)
