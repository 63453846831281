import styled from "styled-components"

export const CellContainer = styled.div`
  display: flex;
  overflow: hidden;
  align-items: baseline;
`

export const NameEllipsis = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`
