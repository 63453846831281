import React from "react"

export const Personal = () => (
  <ol>
    <li>
      Необходимо перейти по ссылке
      <a href="https://drive.google.com/drive/u/0/my-drive">
        https://drive.google.com/drive/u/0/my&#8209;drive
      </a>
      и авторизоваться под под вашим логином (вашлогин@pik&#8209;comfort.ru) и
      постоянным паролем.
    </li>
    <li>
      Для работы с корпоративными ресурсами компании необходимо использовать
      программу Cisco AnyConnect, загрузить которую можно по ссылке:
      <br />
      <a href="https://drive.google.com/file/d/1E0h28-xYCK8mXUCTjaZQlcLt4hEZDJNU/view?usp=sharing">
        https://drive.google.com/file/d/1E0h28-xYCK8mXUCTjaZQlcLt4hEZDJNU/view?usp=sharing
      </a>
      &nbsp;- для windows
      <br />
      <a href="https://drive.google.com/file/d/1tYHSiJ_ewoPmbKSZPqmYSmtYj-YoTxcU/view?usp=sharing">
        https://drive.google.com/file/d/1tYHSiJ_ewoPmbKSZPqmYSmtYj-YoTxcU/view?usp=sharing
      </a>
      &nbsp;- для macos После загрузки, установите приложение.
    </li>
    <li>
      После окончания остановки, запустите Cisco AnyConnect. В открывшемся окне
      будет предложено ввести имя сервера. Если Вы являетесь сотрудником
      Контакт-центра, нужно ввести следующий адрес: vpncc.pik&#8209;comfort.ru
      Если Вы сотрудник Москвы и МО, нужно ввести следующий адрес:
      vpn1.pik&#8209;comfort.ru В любом другом случае, необходимо использовать
      следующий адрес: vpn.pik-comfort.ru
      <br />
      <img src={require("assets/img/cisco.png")} alt="" />
      <br />
      После ввода адреса нужно нажать кнопку Connect, на что программа предложит
      ввести Ваш логин и пароль от учетной записи:
      <br />
      <img src={require("assets/img/login.png")} alt="" />
      <br />
      Нажимаем ОК. Если соединение установлено, выйдет сообщение об успешном
      подключении:
      <br />
      <img src={require("assets/img/connected.png")} alt="" />
      <br />
      Если у Вас выходит ошибка или соединение не устанавливается, проверьте ваш
      логин и пароль, если ошибка не пропала, сделайте заявку на адрес&nbsp;
      <a href="mailto:7777@pik-comfort.ru">7777@pikt&#8209;comfort.ru</a> со
      своего корпоративного ящика или оставьте обращение на портале по
      ссылке:&nbsp;
      <a href="https://home.pik-comfort.ru/request-system/">
        https://home.pik&#8209;comfort.ru/request&#8209;system/
      </a>
      .
    </li>
    <li>
      Для работы с корпоративными ресурсами необходимо использовать Терминальный
      сервер:
      <br />
      <br />
      Ярлык для подключения к терминалу “Терминальный сервер.rdp” можно скачать
      по ссылке:
      <br />
      <a href="https://drive.google.com/file/d/1L4J7jwVaieKwD2N-RZkj_tHa6-oiTE-b/view?usp=sharing">
        https://drive.google.com/file/d/1L4J7jwVaieKwD2N-RZkj_tHa6&#8209;oiTE-b/view?usp=sharing
      </a>
      <br />
      <br />
      Если вы сотрудник, относящийся к КЦ (Деп. клиентского сервиса), то вам
      необходимо скачать ярлык для подключения к терминалам КЦ по ссылке:
      <br />
      <a href="https://drive.google.com/file/d/1cFw1l3D-Cj-nMRF_dKXCyCfnYJnoRQnl/view?usp=sharing">
        https://drive.google.com/file/d/1cFw1l3D&#8209;Cjt&#8209;nMRF_dKXCyCfnYJnoRQnl/view?usp=sharing
      </a>
      <br />
      <br />
      После загрузки ярлыка, размещаем его на рабочем столе и используем при
      необходимости работы с корпоративными ресурсами. Необходимость работы на
      терминале уточняйте у руководителя. При запуске ярлыка, поставьте галочку
      «Больше не выводить запрос о подключениях к этому компьютеру» и нажмите
      «Подключить».
      <br />
      <img src={require("assets/img/start.png")} alt="" />
      <br />
      В открывшемся окошке введите логин в формате comf\логин и пароль.
      Поставьте галочку на «запомнить меня» и нажмите «ОК»
      <br />
      <img src={require("assets/img/pass.png")} alt="" />
      <br />
    </li>
    <li>
      При запущеном и подключенном Cisco AnyConnect и успешном входе на
      Терминал, сотрудник готов к удаленной работе с личного ПК. Если необходимо
      запросить дополнительные доступы, сделать это можно здесь же на Портале по
      ссылке:&nbsp;
      <a href="https://home.pik-comfort.ru/request-system/">
        https://home.pik&#8209;comfort.ru/requestt&#8209;system/
      </a>
      &nbsp;или обратиться с корпоративного ящика по адресу&nbsp;
      <a href="mailto:7777@pik-comfort.ru">7777@pikt&#8209;comfort.ru</a>
    </li>
  </ol>
)
