import { object } from "serializr"
import { makeObservable, observable } from "mobx"

import { createSerializrSchema } from "pik-react-utils/utils"
import { ExtraAttr } from "./extraattr"
import { UtilitySystemType } from "./utilitysystemtype"

export class UtilitySystemExtraAttr extends ExtraAttr {
  utility_system_type: UtilitySystemType

  constructor(props: ExtraAttr) {
    super(props)
    makeObservable(this, { utility_system_type: observable })
  }
}

createSerializrSchema(UtilitySystemExtraAttr, null, {
  utility_system_type: object(UtilitySystemType),
})
