import { object } from "serializr"

import { FullNameEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS, groupEditableNameFields } from "utils/fields"

import { ExploitationSector } from "./exploitationsector"
import { Sector } from "./sector"

const schema: ISchema = {
  ...groupEditableNameFields(),

  group_exploitation_structure: {
    name: "Структура эксплуатации (ГП №1)",
    children: {
      exploitation_sector: {
        name: "Участок эксплуатации",
        _type: "entitylink",
        entityLinkTo: ExploitationSector,
        editable: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class Exploitation extends FullNameEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(Exploitation, schema, {
  sector: object(Sector),
})
