import React from "react"

export const OfficeWork = () => (
  <ul>
    <li>
      После официального трудоустройства логин и временный пароль для нового
      сотрудника приходит на почту <b>непосредственному руководителю.</b>
      <br />*
      <i>
        Если руководитель не получил логин/пароль, то необходимо связаться со
        службой технической поддержки
      </i>
      &nbsp;-&nbsp;(7777@pik&#8209;comfort.ru).
    </li>
    <li>
      <b>Руководитель</b> должен определить какие ещё доступы требуются новому
      сотруднику для работы и сделать заявки на их предоставление.
    </li>
    <li>
      Новому сотруднику требуется сообщить где будет размещаться его рабочее
      место. Новый сотрудник должен зайти в компьютер под своим логином и
      паролем. Система предложит изменить его при первом входе в систему на
      постоянный. <br />
      <i>
        *Требования к новому паролю: минимальная длина пароля 10 символов,
        необходимо использовать большие и маленькие буквы и цифры.
      </i>
    </li>
    <li>
      Новый сотрудник должен зайти в свою корпоративную почту&nbsp;
      <b>mail.google.com</b> под своим адресом (вашлогин@pik&#8209;comfort.ru) и
      постоянным паролем.
    </li>
  </ul>
)
