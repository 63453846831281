const parentMask = /^[a-z]+(_?)[a-z]+$/

export function fieldsToQuery(fieldList: IEntityField[]) {
  const prepareFields: Set<string> = fieldList.reduce((acc, val) => {
    if (val.parent && !parentMask.test(val.parent)) {
      throw new Error("Parent isn't suitable for mask")
    }
    acc.add(val.parent ?? val.slug)

    return acc
  }, new Set<string>())

  if (!prepareFields.size) return ""

  return queryFormat(prepareFields)
}

export function queryFormat(list: Set<string>) {
  list.add("_uid").add("_type").add("_version")

  return `{${Array.from(list).toString()}}`
}
