import { object, list, primitive } from "serializr"

import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { Building } from "./building"
import { Service } from "./service"

export class TariffRecalcRequest extends BaseEntity {
  is_ready: boolean
}

createSerializrSchema(TariffRecalcRequest, null, {
  building: object(Building),
  service: object(Service),
  approvers: list(object(BaseEntity)),
  measure_unit_name: primitive(),
  initiator_email: primitive(),
  initiator_full_name: primitive(),
  initiator_position: primitive(),
  comment: primitive(),
  recalc_period_start: primitive(),
  recalc_period_end: primitive(),
  apartments_numbers: primitive(),
  non_living_premise_list: primitive(),
  type: primitive(),
  all_apartments: primitive(),
  is_all_non_living_premises: primitive(),
  status: primitive(),
  recalc_tariff: primitive(),
  is_ready: primitive(),
})
