import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from "ag-grid-community"
import { agGridPageQuery } from "pik-react-utils/utils/agGrid"

import { entitiesStore } from "stores"

export class Datasource<T> implements IServerSideDatasource {
  private store = entitiesStore
  private query: T | undefined
  private type

  constructor(params: { query?: T; type: string }) {
    this.type = params.type
    this.query = params.query
  }

  async getRows(params: IServerSideGetRowsParams) {
    const { successCallback } = params

    if (!this.type) {
      return successCallback([], 0)
    }

    const list = await this.store.getList(this.type, {
      ...this.query,
      ...agGridPageQuery(params.request.endRow),
    })

    if (list?.entities) {
      successCallback(list?.entities, list?.meta.count)
    }
  }
}
