import React, { PropsWithChildren } from "react"
import { message, Upload } from "antd"
import { action, makeObservable } from "mobx"
import { UploadChangeParam } from "antd/lib/upload"
import { RcFile, UploadFile } from "antd/lib/upload/interface"

import { api } from "pik-react-utils/api"
import { sessionManager } from "pik-react-utils/auth"
import { getLink } from "pik-react-utils/entities"
import { entitiesStore } from "stores"

const uploadProps = {
  accept: ".pdf,.doc,.docx,.png,.jpeg,.jpg,.xls,.xlsx",
  headers: {
    authorization: sessionManager.currentToken,
  },
}

type IPikUploadTypes<T extends IEntity> = PropsWithChildren<{
  name: string
  entityType: string
  onChange: (param: T | undefined) => void
  multiple?: boolean
}>

export class PikUpload<T extends IEntity> extends React.PureComponent<
  IPikUploadTypes<T>
> {
  private store = entitiesStore

  constructor(props: IPikUploadTypes<T>) {
    super(props)
    makeObservable<PikUpload<T>, "beforeUpload" | "onUpload">(this, {
      beforeUpload: action.bound,
      onUpload: action.bound,
    })
  }

  private beforeUpload(file: RcFile) {
    const maxSize = 20 * 1024 * 1024
    if (file.size > maxSize) {
      message.error("Максимальный размер файла 20Мб!")
    }
  }

  private async onUpload({ file }: UploadChangeParam<UploadFile<IRawEntity>>) {
    if (file.status !== "done") return
    if (!file.response) return
    this.store.updateCache(file.response)
    const entity = await this.store.getEntity<T>(getLink(file.response), {
      fromStorage: true,
    })

    if (entity?.hasOwnProperty("file_name")) {
      Object.assign(entity, { file_name: file.name })
    }
    this.props.onChange(entity)
  }

  render() {
    const { name, entityType, children, multiple } = this.props
    return (
      <Upload
        {...uploadProps}
        name={name}
        action={api.getApiUrl(entityType)}
        onChange={this.onUpload}
        multiple={multiple}
        beforeUpload={this.beforeUpload}
      >
        {children}
      </Upload>
    )
  }
}
