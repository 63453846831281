/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from "mobx-react"
import React from "react"
import { ICellEditorParams } from "ag-grid-community"
import { action, makeObservable, observable } from "mobx"
import dayjs, { Dayjs } from "dayjs"
import { Button, DatePicker } from "antd"

export const DateEditor = observer(
  class DateEditor extends React.Component<ICellEditorParams> {
    private isOpen = false
    private value: Dayjs | null
    private readonly selectRef: React.RefObject<any> = React.createRef()

    constructor(props: ICellEditorParams) {
      super(props)
      makeObservable<DateEditor, "isOpen" | "value" | "onChange" | "onClear">(
        this,
        {
          isOpen: observable.struct,
          value: observable,
          afterGuiAttached: action.bound,
          onChange: action.bound,
          onClear: action.bound,
        }
      )
    }

    public afterGuiAttached() {
      const value = this.props.value
      this.value = value ? dayjs.utc(value).local() : dayjs()
      this.isOpen = true
      // @rulapin Dirty Hack to allow close Datepicker by Escape
      setTimeout(() => this.selectRef.current?.focus(), 300)
    }

    public getValue() {
      return this.value?.format("YYYY-MM-DD") ?? null
    }

    private onChange(date: Dayjs | null) {
      this.value = date
      this.isOpen = false
      // Workaround for missing DatePicker animation finish call back
      setTimeout(() => this.props.stopEditing(), 300)
    }

    private onClear() {
      this.onChange(null)
    }

    render() {
      return (
        <DatePicker
          ref={this.selectRef}
          allowClear
          size="small"
          value={this.value}
          format="DD.MM.YYYY"
          open={this.isOpen}
          onChange={this.onChange}
          renderExtraFooter={() => (
            <Button type="primary" size="small" onClick={this.onClear}>
              Очистить
            </Button>
          )}
        />
      )
    }
  }
)
