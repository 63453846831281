import React from "react"
import { observer } from "mobx-react"
import { message } from "antd"
import { action, computed, makeObservable, observable } from "mobx"
import { MenuItemDef } from "ag-grid-community"

import {
  AgGridServerSide,
  AgGridServerSideRef,
} from "pik-react-utils/components"
import { CreateEntityModal, AttentionModal } from "modals"
import {
  ElevatorEntity,
  InventoryBuildingEntity,
  InventoryUtilitySystemEntity,
  LivingEntranceEntity,
} from "models"
import { isBaseEntity } from "pik-react-utils/entities"
import { createRequestTemplate } from "./utils"
import {
  CREATE_MODAL_TITLES,
  COLUMNS,
  GROUP_COLUMN,
  TypeForCreating,
  TypeForFlush,
} from "./constants"
import { Datasource } from "../Datasource"
import { InventoryBuildingStore } from "../InventoryBuildingStore"
import { IInventoryEntity } from "../types"
import { PrivateFields } from "./types"

type BuildingTreeProps = {
  store: InventoryBuildingStore
}

export class BuildingTreeRef extends React.Component<BuildingTreeProps> {
  private attentionModalIsOpen: boolean
  private addModalEntityType: TypeForCreating | null
  private loading = false
  private readonly ref = React.createRef<AgGridServerSideRef>()

  constructor(props: BuildingTreeProps) {
    super(props)
    makeObservable<BuildingTreeRef, PrivateFields>(this, {
      attentionModalIsOpen: observable.struct,
      addModalEntityType: observable,
      loading: observable.struct,
      inventoryStore: computed,
      selectedEntity: computed,
      defaultModalValues: computed,
      entityName: computed,
      setEntity: action.bound,
      openAddModal: action.bound,
      closeAddModal: action.bound,
      closeAttentionModal: action.bound,
      onDelete: action.bound,
      deleteEntity: action.bound,
      getContextMenuItems: action.bound,
      updateSource: action.bound,
      refresh: action.bound,
    })
  }

  private get inventoryStore() {
    return this.props.store
  }

  private get selectedEntity() {
    return this.inventoryStore.selectedEntity
  }

  private get defaultModalValues() {
    if (!this.selectedEntity) return

    if (this.selectedEntity._type === "inventorybuilding") {
      return {
        building: { ...this.selectedEntity, _type: "building" },
      }
    }

    if (this.selectedEntity._type === "inventorybuildingpart") {
      const { building, ...rest } = this.selectedEntity as LivingEntranceEntity
      return {
        building: { ...building, _type: "building" },
        building_part: { ...rest, _type: "buildingpart" },
      }
    }

    return
  }

  get entityName() {
    if (!this.selectedEntity) return ""
    return this.selectedEntity.full_name ?? this.selectedEntity.name ?? ""
  }

  private setEntity(data: IInventoryEntity) {
    this.inventoryStore.setEntity(data)
  }

  private openAddModal(type: TypeForCreating) {
    this.addModalEntityType = type
  }

  private closeAddModal(isCreated?: boolean) {
    if (isCreated) this.refresh()
    this.addModalEntityType = null
  }

  private closeAttentionModal() {
    this.attentionModalIsOpen = false
  }

  private onDelete() {
    this.attentionModalIsOpen = true
  }

  private async deleteEntity() {
    if (
      !this.selectedEntity ||
      this.selectedEntity instanceof InventoryBuildingEntity
    ) {
      return
    }

    const entityName = this.entityName
    try {
      this.loading = true
      await this.inventoryStore.deleteSelectedEntity()
      const { _real_type, _type, building } = this.selectedEntity
      this.inventoryStore.flushLists(_real_type ?? _type)
      if (building) {
        await this.inventoryStore.getEntity(building, { fromStorage: false })
      }
      this.ref.current?.refresh()
      message.success(`Объект "${entityName}" удалён`)
    } finally {
      this.closeAttentionModal()
      this.loading = false
    }
  }

  private async createRequestToTechSupervision() {
    if (!isBaseEntity(this.selectedEntity)) return

    const {
      _uid,
      _real_type,
      building: buildingLink,
    } = this.selectedEntity as InventoryUtilitySystemEntity
    const elevator = await this.inventoryStore.getEntity<ElevatorEntity>(
      { _uid, _type: _real_type },
      { useSchemaFields: true }
    )

    if (!elevator) {
      message.error({ message: "При создании документа возникла ошибка" })
      return
    }

    const building =
      await this.inventoryStore.getEntity<InventoryBuildingEntity>(
        buildingLink,
        { useSchemaFields: true }
      )
    createRequestTemplate(elevator, building ?? elevator.building)
  }

  private getContextMenuItems(defaultItems: string[] = []) {
    const items: (MenuItemDef | string)[] = defaultItems.slice(0)

    if (!this.selectedEntity) return items

    const type = this.selectedEntity._real_type ?? this.selectedEntity._type

    if (type === "elevator") {
      return [
        ...items,
        {
          name: "Заявка в РосТехНадзор",
          action: () => this.createRequestToTechSupervision(),
        },
        {
          name: "Удалить",
          action: () => this.onDelete(),
        },
      ]
    }

    if (type === "livingentrance") {
      return [
        ...items,
        {
          name: `Добавить "Лифт"`,
          action: () => this.openAddModal(TypeForCreating.ELEVATOR),
        },
        {
          name: "Удалить",
          action: () => this.onDelete(),
        },
      ]
    }

    if (type === "inventorybuilding") {
      return [
        ...items,
        {
          name: `Добавить "Жилой подъезд"`,
          action: () => this.openAddModal(TypeForCreating.LIVING_ENTRANCE),
        },
        {
          name: `Добавить "Лифт"`,
          action: () => this.openAddModal(TypeForCreating.ELEVATOR),
        },
      ]
    }

    return items
  }

  updateSource(datasource: Datasource) {
    this.ref.current?.setServerSideDatasource(datasource)
  }

  async refresh() {
    if (this.addModalEntityType) {
      this.inventoryStore.flushLists(TypeForFlush[this.addModalEntityType])
    }
    if (this.selectedEntity) {
      await this.inventoryStore.getEntity(this.selectedEntity.toEntityLink(), {
        fromStorage: false,
      })
    }

    this.ref.current?.refresh()
  }

  render() {
    return (
      <>
        <AgGridServerSide
          ref={this.ref}
          columnDefs={COLUMNS}
          autoGroupColumnDef={GROUP_COLUMN}
          onRowClicked={this.setEntity}
          getContextMenuItems={this.getContextMenuItems}
        />
        <CreateEntityModal
          entityType={this.addModalEntityType}
          title={
            this.addModalEntityType
              ? CREATE_MODAL_TITLES[this.addModalEntityType]
              : ""
          }
          open={!!this.addModalEntityType}
          onClose={this.closeAddModal}
          onSuccess={this.refresh}
          defaultValues={this.defaultModalValues}
        />
        <AttentionModal
          open={this.attentionModalIsOpen}
          onOk={this.deleteEntity}
          onCancel={this.closeAttentionModal}
          confirmLoading={this.loading}
        >
          Вы действительно хотите удалить {this.entityName}?
        </AttentionModal>
      </>
    )
  }
}

export const BuildingTree = React.forwardRef(function (
  props: BuildingTreeProps,
  ref: React.LegacyRef<BuildingTreeRef>
) {
  const BuildingTree = observer(BuildingTreeRef)
  return <BuildingTree {...props} ref={ref} />
})
