import styled from "styled-components"

export const Wrapper = styled.section`
  padding: 0 24px;

  .ant-table-pagination {
    position: sticky;
    bottom: 0;
    padding: 16px 0;
    background-color: #fff;
    margin: 0;
  }
`

export const ContainerSearch = styled.div`
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 2fr 0.75fr;
  align-items: center;
  gap: 24px;
  padding: 24px 0;
  background-color: #fff;
  z-index: 5;
`
