import { object } from "serializr"

import { createSerializrSchema } from "pik-react-utils/utils"
import { BuildingPartType } from "./buildingparttype"
import { ExtraAttr } from "./extraattr"

export class BuildingPartExtraAttr extends ExtraAttr {
  building_part_type: BuildingPartType
}

createSerializrSchema(BuildingPartExtraAttr, null, {
  building_part_type: object(BuildingPartType),
})
