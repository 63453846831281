export const COLOR_BASIC = "#4285F4"
// noinspection JSUnusedGlobalSymbols
export const COLOR_BASIC_DARK = "#2370ef"
export const COLOR_ACCENT = "#ef4e23"
// noinspection JSUnusedGlobalSymbols
export const COLOR_PAGE_BG = "#f4f4f5"
export const COLOR_BUTTON_HOVER = "#fafafa"
export const COLOR_BLACK = "#000000"
export const COLOR_WHITE = "#fff"
export const COLOR_WHITE_TOOTLTIP_TEXT = "rgba(255, 255, 255, 0.8);"

export const COLOR_MAIN_TEXT = "#333"
export const COLOR_EXTRA_TEXT = "#757575"
export const COLOR_SUB_TEXT = "rgba(51, 51, 51, 0.8)"

export const COLOR_DARK_LINES = "#d6d6d6"
export const COLOR_AGGRID_BG_LIGHT = "#f5f7f7"
export const COLOR_AGGRID_BG = "#F2F2F2"
export const COLOR_AGGRID_GROUP_BG = "#c0c0c0"
export const COLOR_AGGRID_BG_CHANGED_FIELD = "#ffe699"
export const COLOR_AGGRID_BG_CHANGING_ROW = "rgba(144, 238, 144, .5)"
export const COLOR_AGGRID_ERROR_BOXSHADOW = "inset 0px 0px 0px 2px red"
// noinspection JSUnusedGlobalSymbols
export const COLOR_ICON_DEFAULT = "rgba(0, 0, 0, 0.08)"
export const COLOR_LINK_HOVER = "#1890ff"
export const COLOR_ICON_ACTIVE = "#4285f4"
export const COLOR_ICON_BORDER_HOVER = "rgba(66, 133, 244, 0.32)"
export const COLOR_TOOLTIP_BACKGROUND = "#152934"

export const COLOR_MENU_BACKGROUND =
  "linear-gradient(90deg, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0) 100%)"

export const STATIC_SHADOW = "0 2px 8px 0 rgba(51, 51, 51, 0.1)"
