import { action, computed, makeObservable, observable } from "mobx"
import { FinancialUnitEntity } from "models"
import { BaseQueryStore } from "stores"
import { FILTER_LIST } from "./constants"

export class FinStructureStore extends BaseQueryStore {
  private _financialUnit: FinancialUnitEntity | undefined
  readonly entityType = "financialunit"

  constructor() {
    super({ filterFields: FILTER_LIST })
    makeObservable<FinStructureStore, "_financialUnit">(this, {
      _financialUnit: observable,
      financialUnit: computed,
      setFinancialUnit: action.bound,
    })
  }

  get financialUnit() {
    return this._financialUnit
  }

  async setFinancialUnit(unit: FinancialUnitEntity) {
    this._financialUnit =
      await this.entitiesStore.getEntity<FinancialUnitEntity>(unit, {
        fromStorage: true,
      })
  }
}
