import { ValueFormatterParams } from "ag-grid-community"

export const generateEntityLinkFormatter =
  (field: IEntityField) =>
  ({ value }: ValueFormatterParams) => {
    if (!value) return ""

    if ("_entityvisiblename" in field && field._entityvisiblename) {
      return value[field._entityvisiblename]
    }

    return value.full_name ?? value?.name ?? ""
  }
