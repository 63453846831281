import React, { useCallback, useState } from "react"
import { observer } from "mobx-react"

import { CREATING_RULE_FIELDS } from "constants/fields"
import { SelectList } from "components"
import { Modal } from "modals"
import { FinancialUnitEntity } from "models"
import { entitiesStore } from "stores"
import { AsyncSelectOption } from "types"
import { ModalProps } from "antd"

interface IRulesModalProps extends ModalProps {
  defaultValues: { [slug: string]: IEntityLink }
  open: boolean
  onCancel: () => void
  onSave: () => void
}

const defaultFields = CREATING_RULE_FIELDS.reduce(
  (acc, { type }) => ({ ...acc, [type]: null }),
  {}
)

export const RulesModal = observer(
  ({ open, defaultValues, ...props }: IRulesModalProps) => {
    const [store] = useState(() => entitiesStore)
    const [fields, setFields] = useState({ ...defaultFields })

    const onChange = useCallback(
      (type: string, option?: AsyncSelectOption) => {
        setFields({ ...fields, [type]: option })
      },
      [fields]
    )

    const onSave = useCallback(async () => {
      const dummy = store.createDummy("buildinglocationrule")
      if (!dummy) return

      const changes = { ...defaultValues }

      CREATING_RULE_FIELDS.forEach(({ type, slug }) => {
        const filterId = fields[type]?.value?._uid

        if (filterId) {
          changes[slug] = { _uid: filterId, _type: type }
        }
      })

      await entitiesStore.createEntity<FinancialUnitEntity>({
        ...dummy,
        ...changes,
      })

      setFields({ ...defaultFields })
      props.onSave()
    }, [fields, defaultValues, props, store])

    const onCancel = useCallback(() => {
      setFields({ ...defaultFields })
      props.onCancel()
    }, [props])

    return (
      <Modal
        open={open}
        title="Создать правило"
        okText="Создать"
        onOk={onSave}
        onCancel={onCancel}
      >
        <SelectList
          options={CREATING_RULE_FIELDS}
          fields={fields}
          onChange={onChange}
          filters={() => ({ is_deleted: false })}
        />
      </Modal>
    )
  }
)
