import React, { Suspense, useState } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { Spin } from "antd"
import locale from "antd/lib/locale/ru_RU"
import { observer } from "mobx-react"

import { isProduction } from "constants/env"
import { InventoryStructurePageTabs, RequestPageTabs } from "constants/links"
import { PIVOT_ENTITIES } from "constants/pivot"
import { Header } from "components"
import { Employees } from "pages/Employees"
import { ExportExcel } from "pages/ExportExcel"
import {
  InventoryBuildings,
  InventoryReferences,
  InventoryMDSObjects,
} from "pages/InventoryStructure"
import { FinancialStructure } from "pages/FinancialStructure"
import { Home } from "pages/Home"
import { InfoList } from "pages/InfoList"
import { Instructions } from "pages/Instructions"
import { ManagementStructure } from "pages/ManagementStructure"
import { Pivot } from "pages/Pivot"
import {
  MeetingRequest,
  OtherRequest,
  PermissionRequest,
  TariffRecalcRequest,
} from "pages/Requests"
import { Uirta } from "pages/Uirta"
import { sessionManager } from "pik-react-utils/auth"
import { OAUTH_AUTHORIZE_REDIRECT_PATHNAME } from "pik-react-utils/constants/auth"
import { PikContainer, YMListener } from "pik-react-utils/components"
import { routerService } from "services"
import { Content, Main } from "./components"

export const AppRouter = observer(() => {
  const [router] = useState(() => routerService)
  const [session] = useState(() => sessionManager)

  if (!session.isAuthenticated) return null

  return (
    <BrowserRouter>
      {isProduction && <YMListener />}
      <Main>
        <Header />
        <Content>
          <PikContainer locale={locale}>
            <Suspense fallback={<Spin size="large" />}>
              <Routes>
                <Route index path={router.MAIN_PATH} element={<Home />} />
                <Route path={router.INVENTORY_STRUCTURE_PATH}>
                  <Route
                    index
                    path={InventoryStructurePageTabs.BUILDINGS}
                    element={<InventoryBuildings />}
                  />
                  <Route
                    path={InventoryStructurePageTabs.REFERENCES}
                    element={<InventoryReferences />}
                  />
                  <Route
                    path={InventoryStructurePageTabs.MDS_OBJECTS}
                    element={<InventoryMDSObjects />}
                  />
                </Route>
                <Route path={router.UIRTA}>
                  <Route path="buildings" element={<Uirta />} />
                </Route>
                <Route path={router.PIVOT_PATH} element={<Pivot />} />
                <Route
                  path={router.EXPORT_EXCEL_PATH}
                  element={<ExportExcel />}
                />

                <Route
                  path={router.FINANCIAL_STRUCTURE_PATH}
                  element={<FinancialStructure />}
                />
                <Route
                  path={router.EMPLOYEE_LIST_PATH}
                  element={<Employees />}
                />

                <Route path={router.INFO_PATH} element={<InfoList />} />
                <Route
                  path={router.INSTRUCTION_PATH}
                  element={<Instructions />}
                />

                <Route path={router.REQUEST_PATH}>
                  <Route
                    index
                    path={RequestPageTabs.TARIFF_RECALC}
                    element={<TariffRecalcRequest />}
                  />
                  <Route
                    path={RequestPageTabs.MEETING}
                    element={<MeetingRequest />}
                  />
                  <Route
                    path={RequestPageTabs.PERMISSION}
                    element={<PermissionRequest />}
                  />
                  <Route
                    path={RequestPageTabs.OTHER}
                    element={<OtherRequest />}
                  />
                </Route>

                <Route
                  path={router.MANAGE_PATH}
                  element={<ManagementStructure />}
                />
              </Routes>
              <Routes>
                <Route
                  path={router.INVENTORY_STRUCTURE_PATH}
                  element={
                    <Navigate to={router.linkToInventoryBuildings} replace />
                  }
                />
                <Route
                  path={router.UIRTA}
                  element={<Navigate to={`${router.UIRTA}buildings`} replace />}
                />
                <Route
                  path={router.REQUEST_PATH}
                  element={
                    <Navigate
                      to={router.linkToRequestTab(
                        RequestPageTabs.TARIFF_RECALC
                      )}
                      replace
                    />
                  }
                />

                {/* pivot trables */}
                {PIVOT_ENTITIES.map(({ value }) => (
                  <Route
                    key={value}
                    path={`/pivot-${value}`}
                    element={<Navigate to={`/pivot/${value}`} replace />}
                  />
                ))}
                <Route
                  path={OAUTH_AUTHORIZE_REDIRECT_PATHNAME}
                  element={<Navigate to="/" replace />}
                />
              </Routes>
            </Suspense>
          </PikContainer>
        </Content>
      </Main>
    </BrowserRouter>
  )
})
