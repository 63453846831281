import React from "react"
import { Button, Input } from "antd"
import { action, computed, makeObservable, observable, reaction } from "mobx"
import { observer } from "mobx-react"
import { isEqual } from "lodash"

import { FilterModal } from "modals/FilterModal"
import { routerService } from "services"
import { BaseQueryStore } from "stores"
import { QueryArgs } from "types"
import { PrivateFields } from "./types"

type IQueryPanelProps = {
  store: BaseQueryStore
  entityType: string
  onUpdate?: () => void
  input?: {
    hidden?: boolean
    placeholder?: string
  }
}

export const QueryPanel = observer(
  class QueryPanel extends React.Component<IQueryPanelProps> {
    private searchValue = ""
    private isOpen = false
    private router = routerService
    private unListen: () => void

    private reactionDisposer = reaction(
      () => this.props.store.searchParamsForURL,
      (query) => {
        this.router.updateURLSearchParams(query)
        this.searchValue = this.query.search
        this.props.onUpdate?.()
      }
    )
    constructor(props: IQueryPanelProps) {
      super(props)
      makeObservable<QueryPanel, PrivateFields>(this, {
        searchValue: observable.struct,
        isOpen: observable.struct,
        query: computed,
        activeFiltersCount: computed.struct,
        setModalOpen: action.bound,
        setSearchValue: action.bound,
        onSave: action.bound,
        updateSearch: action.bound,
      })
    }

    componentDidMount(): void {
      this.searchValue = this.query.search
      this.router.updateURLSearchParams(this.props.store.searchParamsForURL)

      this.unListen = this.router.listen(() => {
        if (this.router.isUpdatePathname) return
        const isEqualParams = isEqual(
          this.props.store.searchParamsForURL,
          this.router.getSearchParams()
        )
        if (isEqualParams) return
        this.props.store.updateQuery(this.router.getSearchParams())
      })
    }

    componentWillUnmount(): void {
      this.reactionDisposer?.()
      this.unListen?.()
    }

    private get query() {
      return this.props.store.query
    }

    private get activeFiltersCount() {
      return this.props.store.activeFiltersCount
    }

    private setModalOpen(isOpen: boolean) {
      this.isOpen = isOpen
    }

    private setSearchValue(value: string) {
      this.searchValue = value
    }

    private onSave(filters: QueryArgs) {
      this.props.store.updateFilters(filters)
      this.setModalOpen(false)
    }

    private updateSearch(value: string) {
      this.searchValue = value
      this.props.store.updateSearch(this.searchValue)
    }

    render() {
      const { entityType, store, input } = this.props

      return (
        <>
          {input?.hidden ? null : (
            <Input.Search
              placeholder={input?.placeholder ?? "Поиск объекта"}
              enterButton="Найти"
              value={this.searchValue}
              onChange={(e) => this.setSearchValue(e.currentTarget.value)}
              onSearch={this.updateSearch}
              allowClear
            />
          )}
          {store.hasFilterEditingFields && (
            <>
              <Button
                type="primary"
                danger={this.activeFiltersCount > 0}
                onClick={() => this.setModalOpen(true)}
              >
                Фильтры
                {!!this.activeFiltersCount && `: ${this.activeFiltersCount}`}
              </Button>
              <FilterModal
                fields={store.filterEditingFields}
                open={this.isOpen}
                entityType={entityType}
                onCancel={() => this.setModalOpen(false)}
                onSuccess={this.onSave}
                query={this.query}
              />
            </>
          )}
        </>
      )
    }
  }
)
