import React from "react"
import { List as ListComponent } from "antd"
import { observer } from "mobx-react"
import { FinancialEmploymentEntity } from "models"

type ApproverListProps = {
  approvers: FinancialEmploymentEntity[] | undefined
}

const renderItem = (approver: FinancialEmploymentEntity, index: number) => {
  const { employee, employments, financial_unit } = approver

  const employment = employments?.filter((i) => !i.is_deleted)?.[0]

  const position = employment?.position_name
    ? ` – ${employment.position_name}`
    : ""

  return (
    <ListComponent.Item>
      {index + 1}. {employee?.full_name}
      {position} ({financial_unit?.name})
    </ListComponent.Item>
  )
}

export const ApproverList = observer(({ approvers }: ApproverListProps) =>
  approvers?.length ? (
    <ListComponent
      size="small"
      header={<div>Список согласующих</div>}
      locale={{ emptyText: "Согласующие не найдены" }}
      footer={null}
      bordered
      dataSource={approvers}
      style={{ marginTop: "12px" }}
      renderItem={renderItem}
    />
  ) : null
)
