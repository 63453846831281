import {
  MEETING_ALL_ATTRS_GROUP,
  MEETING_SERVICE_UID,
} from "constants/subscribe-groups"

export const SUBSCRIBABLE_ENTITY_TO_DATA = {
  meeting: {
    target_service: MEETING_SERVICE_UID,
    permission_group: MEETING_ALL_ATTRS_GROUP,
  },
}
