import axios from "axios"

import {
  sessionManager,
  PikAuthService,
  IAuthService,
  ISessionManager,
} from "pik-react-utils/auth"
import { processError } from "pik-react-utils/utils"
import {
  IInitConfig,
  MethodType,
  RequestConfig,
  ResponseError,
} from "pik-react-utils/types"
import { getEndpoint, makeRequestHash, sendToSentry } from "./utils"

class Api {
  private request = axios.create()
  private authService: undefined | IAuthService
  private currentSession: ISessionManager
  private requestCache = new Map()

  forbid() {
    this.authService?.forbid()
  }

  getApiUrl(_type: string, config: { apiVer?: string; suffix?: string } = {}) {
    const baseURL = this.request.defaults.baseURL
    return `${baseURL}${getEndpoint({ _type }, { apiVer: config.apiVer })}`
  }

  init({ authConfig, apiConfig }: IInitConfig) {
    for (const [key, value] of Object.entries(apiConfig)) {
      this.request.defaults[key] = value
    }
    this.authService = new PikAuthService(authConfig)
    this.currentSession = sessionManager
  }

  private setRequestInCache(
    requestHash: string,
    promise: Promise<unknown>
  ): void {
    this.requestCache.set(requestHash, promise)
  }

  private getRequestFromCache(requestHash: string) {
    return this.requestCache.get(requestHash)
  }

  private dropRequestFromCache(requestHash: string): void {
    this.requestCache.delete(requestHash)
  }

  useCustomUrl<T extends IListResponse<IRawEntity> | IRawEntity[] | IRawEntity>(
    method: MethodType,
    { _type, suffix }: { _type: string; suffix: string },
    data?: Record<string, unknown> | null,
    params?: ListRequestParams,
    config?: RequestConfig
  ) {
    const customUrl = getEndpoint({ _type }, { suffix, apiVer: config?.apiVer })
    return this.makeRequest<T>(method, customUrl, data, { params }, config)
  }

  getItem({ _type, _uid }: IEntityLink, config: RequestConfig = {}) {
    const endpoint = getEndpoint({ _type, _uid })
    return this.makeRequest<IRawEntity>(
      MethodType.GET,
      endpoint,
      null,
      {},
      config
    )
  }

  getList(
    _type: string,
    params: ListRequestParams,
    config: RequestConfig = {}
  ) {
    const endpoint = getEndpoint({ _type }, config)
    return this.makeRequest<IListResponse<IRawEntity>>(
      MethodType.GET,
      endpoint,
      null,
      { params },
      config
    )
  }

  createItem(
    _type: string,
    data: Record<string, unknown>,
    config: RequestConfig = {}
  ) {
    const endpoint = getEndpoint({ _type }, config)
    return this.makeRequest<IRawEntity>(
      MethodType.POST,
      endpoint,
      data,
      {},
      config
    )
  }

  updateItem(
    { _type, _uid }: IEntityLink,
    data: Record<string, unknown> | null,
    config: RequestConfig = {}
  ) {
    const endpoint = getEndpoint({ _type, _uid }, { apiVer: config?.apiVer })
    return this.makeRequest<IRawEntity>(
      MethodType.PATCH,
      endpoint,
      data,
      {},
      config
    )
  }

  deleteItem({ _type, _uid }: IEntityLink, config: RequestConfig = {}) {
    const endpoint = getEndpoint({ _type, _uid }, { apiVer: config?.apiVer })
    return this.makeRequest<IRawEntity>(
      MethodType.DELETE,
      endpoint,
      null,
      {},
      config
    )
  }

  private makeRequest<T>(
    method: MethodType,
    endpoint: string,
    data: Record<string, unknown> | null = null,
    queryParams: { params?: ListRequestParams } = {},
    requestConfig: RequestConfig = {}
  ): Promise<T> {
    const requestHash = makeRequestHash(method, endpoint, {
      ...data,
      ...queryParams,
    })

    const activeRequest = this.getRequestFromCache(requestHash)
    if (activeRequest) return activeRequest

    this.request.defaults.headers.authorization =
      this.currentSession?.currentToken

    const promise = this.request[method](endpoint, {
      ...queryParams,
      ...data,
    })
      .then((response: { data: T }) => response.data)
      .catch((e: ResponseError) => {
        if (this.authService) {
          // from Dmitry Danew TODO:
          // should save some request data if some data were send,
          // and client was redirected to the auth service and try to send it,
          // while token were refreshed
          this.authService.handleAuthError(e)
        }

        sendToSentry(e.response)
        if (requestConfig?.handleError !== false) processError(e)
        return Promise.reject(e)
      })
      .finally(() => this.dropRequestFromCache(requestHash))

    this.setRequestInCache(requestHash, promise)
    return promise
  }
}

export const api = new Api()
