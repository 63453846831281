import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from "ag-grid-community"

import { entitiesStore } from "stores"
import { INVENTORY_REFERENCE_LIST } from "./constants"

export class Datasource<T> implements IServerSideDatasource {
  private store = entitiesStore
  private query: T | undefined

  constructor(params?: { query: T }) {
    this.query = params?.query
  }

  async getRows(params: IServerSideGetRowsParams) {
    const { successCallback } = params
    const rowsData: {
      label: string
      groupKey: string
      type: string | null
      count: number | undefined
      singleLabel?: string | undefined
    }[] = INVENTORY_REFERENCE_LIST.map((item) => ({
      ...item,
      groupKey: item.label,
      count: undefined,
    }))

    const promises: Promise<void>[] = []

    rowsData.forEach((item) => {
      if (!item.type) return
      const promise = this.store
        .getCount(item.type, this.query ?? {})
        .then((count) => {
          item.count = count
        })
      promises.push(promise)
    })
    await Promise.all(promises)

    successCallback(rowsData, INVENTORY_REFERENCE_LIST.length)
  }
}
