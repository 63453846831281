import React from "react"
import { Button } from "antd"
import { observer } from "mobx-react"
import { action, makeObservable, observable } from "mobx"

import { QueryPanel } from "components"
import { ColumnHeader, ColumnWithHeader, TwoColumnLayout } from "components/css"
import { CreateEntityModal } from "modals"
import {
  AgGridServerSide,
  AgGridServerSideRef,
} from "pik-react-utils/components"
import { COLUMNS } from "./constants"
import { Datasource } from "./Datasource"
import { FinUnitInspector } from "./FinUnitInspector"
import { FinStructureStore } from "./FinStructureStore"
import { Title } from "./components"
import { PrivateFields } from "./types"

export const FinancialStructure = observer(
  class FinancialStructure extends React.Component {
    private isOpen = false
    private readonly agGridRef = React.createRef<AgGridServerSideRef>()
    private readonly finStore = new FinStructureStore()

    constructor(props: Record<string, unknown>) {
      super(props)
      makeObservable<FinancialStructure, PrivateFields>(this, {
        isOpen: observable.struct,
        updateDataSource: action.bound,
        openModal: action.bound,
        onCloseModal: action.bound,
        updateSource: action.bound,
        refresh: action.bound,
      })
    }

    componentDidMount() {
      const datasource = new Datasource()
      this.updateDataSource(datasource)
    }

    private updateDataSource(datasource: Datasource) {
      this.agGridRef.current?.setServerSideDatasource(datasource)
    }

    private openModal() {
      this.isOpen = true
    }

    private onCloseModal() {
      this.isOpen = false
    }

    private updateSource() {
      const datasource = new Datasource({ query: this.finStore.query })
      this.updateDataSource(datasource)
    }

    private refresh() {
      this.agGridRef.current?.refresh()
    }

    render() {
      const financialUnit = this.finStore.financialUnit

      return (
        <>
          <TwoColumnLayout>
            <ColumnWithHeader>
              <ColumnHeader>
                <Title>Финансовая структура</Title>
                <QueryPanel
                  store={this.finStore}
                  entityType={this.finStore.entityType}
                  onUpdate={this.updateSource}
                  input={{ hidden: true }}
                />
                <Button onClick={this.openModal}>Создать</Button>
              </ColumnHeader>
              <AgGridServerSide
                ref={this.agGridRef}
                columnDefs={COLUMNS}
                onRowClicked={this.finStore.setFinancialUnit}
                autoGroupColumnDef={{
                  headerName: "Название",
                  field: "name",
                }}
              />
            </ColumnWithHeader>
            <FinUnitInspector
              financialUnit={financialUnit}
              refresh={this.refresh}
              query={this.finStore.query}
            />
          </TwoColumnLayout>
          <CreateEntityModal
            entityType={this.finStore.entityType}
            title="Новый узел финансовой структуры"
            open={this.isOpen}
            onClose={this.onCloseModal}
            onSuccess={this.refresh}
            defaultValues={{
              leader: financialUnit,
              is_cost_center: true,
            }}
          />
        </>
      )
    }
  }
)
