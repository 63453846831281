import { object } from "serializr"

import {
  ASSESSMENT_CHOICES,
  COMFORT_CLASS_CHOICES,
  MONTH_CHOICES,
} from "constants/choices"
import {
  BaseEntity,
  FullNameEntity,
  PersonEntity,
} from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { FinancialUnit } from "./financialunit"
import { Employee } from "./employee"

const schema: ISchema = {
  group_parameters: {
    name: "Характеристики",
    children: {
      financial_unit: {
        name: "Подразделение",
        _type: "entitylink",
        entityLinkTo: FinancialUnit,
        editable: true,
      },
      owner: {
        name: "Владелец",
        _type: "entitylink",
        entityLinkTo: Employee,
        editable: true,
      },
      is_owner_exists: {
        name: "Имеется владелец бюджета",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      fund: {
        name: "Фонд",
        _type: "choices",
        choices: COMFORT_CLASS_CHOICES,
        editable: true,
      },
      assessment: {
        name: "Оценка",
        _type: "choices",
        choices: ASSESSMENT_CHOICES,
        editable: true,
      },
      year: { name: "Год", _type: "number", editable: true },
      month: {
        name: "Месяц",
        _type: "choices",
        choices: MONTH_CHOICES,
        editable: true,
      },
    },
  },
  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class FinancialUnitAssessment extends BaseEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(FinancialUnitAssessment, schema, {
  financial_unit: object(FullNameEntity),
  owner: object(PersonEntity),
})
