import { COMPETITION_STATUS_CHOICES, KIND_CHOICES } from "constants/choices"
import { BaseEntity } from "pik-react-utils/entities"
import { createSerializrSchema } from "pik-react-utils/utils"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { Building } from "./building"
import { FinancialEmployment } from "./financialemployment"
import { ManagementCompany } from "./managementcompany"

const schema: ISchema = {
  group_project: {
    name: "Карточка проекта",
    children: {
      name: { name: "Название" },
      building: { name: "Здание", _type: "entitylink", entityLinkTo: Building },
      curator: {
        name: "Куратор",
        _type: "entitylink",
        entityLinkTo: FinancialEmployment,
      },
      kind: { name: "Вид", _type: "choices", choices: KIND_CHOICES },
      status: {
        name: "Статус",
        _type: "choices",
        choices: COMPETITION_STATUS_CHOICES,
      },
      current_management_company: {
        name: "Действующая ук",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
      },
      target_management_company: {
        name: "Будущая ук",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
      },
      comment: { name: "Комментарий" },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class Competition extends BaseEntity {
  static readonly schema: ISchema = schema

  building: Building
  status: string
}

createSerializrSchema(Competition, schema)
