import React, { useMemo } from "react"
import { Tabs } from "antd"
import { observer } from "mobx-react"

import { UnitEntity } from "models"
import { Territory } from "./Territory"
import { Workplaces } from "./Workplaces"

export const UnitTabs = observer(({ unit }: { unit: UnitEntity }) => {
  const items = useMemo(() => {
    const { buildings_group = [], territories = [], _uid } = unit

    const query = {
      is_deleted: false,
      query: "{_uid,_type,address,building_type}",
    }

    return [
      {
        key: "workplace",
        label: "Доступы",
        children: <Workplaces unitUid={_uid} />,
      },
      ...buildings_group.map(({ _uid: key, name }) => ({
        key,
        label: name,
        children: (
          <Territory query={{ buildings_group__name: name, ...query }} />
        ),
      })),
      ...territories.map(({ _uid: key, name }) => ({
        key,
        label: name,
        children: <Territory query={{ territories__name: name, ...query }} />,
      })),
    ]
  }, [unit])

  return <Tabs items={items} />
})
