import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Button, Checkbox, Col, Input, message, Radio, Row } from "antd"

import { CloseCircleOutlined } from "components/antd"
import { AsyncSelect } from "components"
import { FormLabel } from "components/css"
import { UnitEntity, UnitTypeEntity } from "models"
import { entitiesStore, IEntitiesStore } from "stores"
import { AsyncSelectOption } from "types"
import { FormContent, Header } from "./components"
import { DivisionEditorProps } from "./types"
import { DivisionState } from "../constants"
import { Content, Footer, Form } from "../components"

type UnitCreateTypes = {
  name: string
  leader: AsyncSelectOption<UnitEntity>
  unit_type: string
  isCreateMore: boolean
}

const init = (unit: UnitEntity | undefined, state: DivisionState) => {
  const data = state === DivisionState.EDITING ? unit : { leader: unit }
  return {
    ...UnitEntity.initialState(data),
    isCreateMore: state === DivisionState.CREATING,
  }
}

export const DivisionEditor = observer(
  ({ onClose, unit, state }: DivisionEditorProps) => {
    const [initialValues] = useState(() => init(unit, state))
    const [store] = useState<IEntitiesStore>(() => entitiesStore)
    const [form] = Form.useForm<UnitCreateTypes>()
    const [unitTypeOptions, setUnitTypeOptions] =
      useState<{ label: string; value: string }[]>()

    const getOptions = useCallback(async () => {
      const response = await store.getList<UnitTypeEntity>("unittype", {
        is_deleted: false,
      })
      const options = response?.entities?.map(({ name, _uid }) => ({
        label: name,
        value: _uid,
      }))

      setUnitTypeOptions(() => options ?? [])
    }, [store])

    useEffect(() => {
      if (!unitTypeOptions) getOptions()
    }, [unitTypeOptions, getOptions])

    const onSave = async () => {
      await form?.validateFields()
      const formFields = form.getFieldsValue()
      const isCreateMore = formFields.isCreateMore
      const data = UnitEntity.toUpdate(formFields)

      state === DivisionState.CREATING
        ? await store.createEntity<UnitEntity>(data)
        : unit &&
          (await store.updateEntity<UnitEntity>({ _uid: unit._uid, ...data }))
      onClose({
        refresh: true,
        nextState:
          state === DivisionState.CREATING && isCreateMore
            ? DivisionState.CREATING
            : DivisionState.VIEWING,
      })
      message.success(
        `Подразделение успешно ${
          state === DivisionState.EDITING ? "обновлено" : "создано"
        }`
      )
      if (isCreateMore) {
        form.resetFields(["name", "unit_type"])
      }
    }

    return (
      <Content>
        <Col span={24}>
          <Form layout="vertical" form={form} initialValues={initialValues}>
            <Header>
              <h2>
                {state === DivisionState.EDITING
                  ? "Редактирование"
                  : "Создание"}
                &nbsp;подразделения
              </h2>
              <Button
                type="text"
                size="large"
                onClick={() => onClose()}
                icon={<CloseCircleOutlined />}
              />
            </Header>
            <FormContent>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Введите название" }]}
                label={<FormLabel>Подразделение</FormLabel>}
              >
                <Input placeholder="Название" size="large" />
              </Form.Item>
              <Form.Item
                name="leader"
                label={<FormLabel>Родительское подразделение</FormLabel>}
              >
                <AsyncSelect
                  placeholder="Выбрать"
                  entityType="unit"
                  field={{ label: "name", search: "name__contains" }}
                  filters={{ is_deleted: false }}
                  defaultValue={initialValues.leader}
                />
              </Form.Item>
              <Form.Item
                name="unit_type"
                rules={[
                  { required: true, message: "Выберите тип подразделения" },
                ]}
                label={<FormLabel>Тип подразделения</FormLabel>}
              >
                <Radio.Group
                  className="checkbox-vertical"
                  options={unitTypeOptions}
                />
              </Form.Item>
            </FormContent>
            <Footer>
              <Row>
                {state === DivisionState.CREATING && (
                  <Form.Item
                    name="isCreateMore"
                    valuePropName="checked"
                    initialValue
                  >
                    <Checkbox>Создать ещё</Checkbox>
                  </Form.Item>
                )}
              </Row>
              <Row gutter={12}>
                <Col>
                  <Button type="primary" onClick={onSave}>
                    {state === DivisionState.CREATING ? "Создать" : "Сохранить"}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => onClose()}>Отмена</Button>
                </Col>
              </Row>
            </Footer>
          </Form>
        </Col>
      </Content>
    )
  }
)
