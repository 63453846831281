import { ValueFormatterParams } from "ag-grid-community"

import { isBaseEntity } from "pik-react-utils/entities"
import { AttachmentEntity } from "models"

export const fileLinkFormatter = ({ value }: ValueFormatterParams) => {
  if (!isBaseEntity(value)) return ""

  const path = decodeURI((value as AttachmentEntity).file ?? "").split("/")

  return path.at(-1) ?? ""
}
