import {
  AGAINST_MEETING_RESULT_CHOICES,
  AGAINST_MEETING_STATUS_CHOICES,
} from "constants/choices"
import { Building } from "../building"
import { Employee } from "../employee"
import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

export const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "НСИ ID" },
      building: {
        name: "Здание",
        _type: "entitylink",
        entityLinkTo: Building,
        editable: true,
      },
      responsible: {
        name: "Ответственный",
        _type: "entitylink",
        entityLinkTo: Employee,
        editable: true,
      },
      initiator: {
        name: "Заполняющий",
        _type: "entitylink",
        entityLinkTo: Employee,
        editable: true,
      },
      start_period_date: { name: "Дата отчёта", _type: "date", editable: true },
      attacking_side_info: {
        name: "Атакующая Управляющая Организация",
        editable: true,
      },
      against_meeting_status: {
        name: "Текущий статус ОСС против нас",
        _type: "choices",
        choices: AGAINST_MEETING_STATUS_CHOICES,
        editable: true,
      },
      against_meeting_result: {
        name: "Текущий результат ОСС против нас",
        _type: "choices",
        choices: AGAINST_MEETING_RESULT_CHOICES,
        editable: true,
      },
      has_initiator: {
        name: "Есть инициатор защиты",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      competitor_meeting_start: {
        name: "Начало ОСС конкурента",
        _type: "date",
        editable: true,
      },
      competitor_meeting_finish: {
        name: "Завершение ОСС конкурента",
        _type: "date",
        editable: true,
      },
      our_meeting_start: {
        name: "Начало нашего ОСС",
        _type: "date",
        editable: true,
      },
      our_meeting_finish: {
        name: "Завершение нашего ОСС",
        _type: "date",
        editable: true,
      },
      e_oss: {
        name: "Электронное ОСС",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      assembled: {
        name: "Собрание собрано",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      comment: { name: "Комментарий", editable: true },
    },
  },

  group_plans: {
    name: "Планируемые контрмеры",
    children: {
      is_planned_counteraction_counter_meeting: {
        name: "Контрсобрание",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      is_planned_counteraction_competition: {
        name: "Конкурс",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      is_planned_counteraction_court: {
        name: "Суд",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      is_planned_counteraction_emergency_commission: {
        name: "КЧС",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
    },
  },

  group_reasons: {
    name: "Причины смены УК",
    children: {
      is_outflow_reason_exploitation: {
        name: "Из-за эксплуатации",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      is_outflow_reason_cleaning: {
        name: "Из-за уборки",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      is_outflow_reason_landscaping: {
        name: "Из-за благоустройства",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      is_outflow_reason_billing: {
        name: "Из-за начислений",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      is_outflow_reason_complaints: {
        name: "Из-за заявок",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      is_outflow_reason_office_accessibility: {
        name: "Из-за доступности офиса",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      is_outflow_reason_elevators: {
        name: "Из-за лифтов",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      is_outflow_reason_tariff: {
        name: "Из-за тарифов",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const
