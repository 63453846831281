/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from "dayjs"
import {
  AttrNames,
  RANGE_DATA_FIELDS,
  NON_MODIFIED_FIELDS,
  MULTIPLE_VALUES,
  NULLABLE_FIELDS,
} from "./constants"

export const dataToServer = (fields: { [key in AttrNames]: any }) => {
  const data: Record<string, unknown> & Pick<IEntity, "_type"> = {
    _type: "meetingrequest",
  }

  RANGE_DATA_FIELDS.forEach((key) => {
    data[`${key}_start`] = fields[key][0]?.format("YYYY-MM-DD")
    data[`${key}_finish`] = fields[key][1]?.format("YYYY-MM-DD")
  })

  Object.entries(MULTIPLE_VALUES).forEach(([name, options]) => {
    if (!Array.isArray(fields[name])) return

    options.forEach(({ value }) => {
      data[`is_${name}_${value}`] = fields[name].some(
        (item: string) => item === value
      )
    })
  })

  NULLABLE_FIELDS.forEach((key) => {
    // antd radio value can't be null or undefined
    data[key] = fields[key] === "NULL" ? null : fields[key]
  })

  data.start_period_date = dayjs().format("YYYY-MM-DD")
  data.building = fields.building.value

  return data
}

export const dataFromServer = (rawData: Record<string, any>) => {
  const data: { [key in AttrNames]?: any } = {}

  NON_MODIFIED_FIELDS.forEach((name) => {
    data[name] = rawData[name]
  })

  RANGE_DATA_FIELDS.forEach((name) => {
    data[name] = ["_start", "_finish"].map((postfix) =>
      rawData[`${name}${postfix}`]
        ? dayjs(rawData[`${name}${postfix}`], "YYYY-MM-DD")
        : null
    )
  })

  Object.entries(MULTIPLE_VALUES).forEach(([name, options]) => {
    if (!data[name]) data[name] = []

    options.forEach(({ value }) => {
      const key = `is_${name}_${value}`
      if (rawData[key]) {
        data[name].push(value)
      }
    })
  })

  NULLABLE_FIELDS.forEach((key) => {
    // antd radio value can't be null or undefined
    data[key] = rawData[key] === null ? "NULL" : rawData[key]
  })

  return data
}
