import React, { StrictMode } from "react"
import ReactDOM from "react-dom"
import dayjs from "dayjs"
import "dayjs/locale/ru"
import utc from "dayjs/plugin/utc"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"

import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-balham.css"
import "antd/dist/reset.css"
import "./app.css"

import { AG_GRID_LICENSE_KEY } from "constants/env"
import { API_CONFIG, AUTH_CONFIG, SENTRY_CONFIG } from "constants/config"
import { classByModelName } from "models"
import { AppRouter } from "routes/AppRouter"

import { pikConfig } from "pik-react-utils"
import { GlobalStyle } from "styles/injectGlobalStyles"

dayjs.locale("ru")
dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(relativeTime)

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY ?? "")

pikConfig.initApi({ apiConfig: API_CONFIG, authConfig: AUTH_CONFIG })
pikConfig.setModels(classByModelName)

if (SENTRY_CONFIG) {
  pikConfig.initSentry(SENTRY_CONFIG)
}

const root = document.getElementById("root")

const App = () => (
  <StrictMode>
    <GlobalStyle />
    <AppRouter />
  </StrictMode>
)

ReactDOM.render(<App />, root)
