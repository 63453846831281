import { GROUP_SYSTEM_DATE_FIELDS } from "utils/fields"

import { Building } from "../building"
import { BuildingPart } from "../buildingpart"
import { ElevatorModel } from "../elevatormodel"
import { ManufacturingPlant } from "../manufacturingplant"
import { PassportStorage } from "../passportstorage"

export const schema: ISchema = {
  group_identification: {
    name: "Идентификация",
    children: {
      _uid: { name: "НСИ ID" },
      name: { name: "Заводской номер", editable: true },
      visible_name: { name: "Сокращенное наименование" },
      full_name: { name: "Полное наименование" },
      building: {
        name: "Здание",
        _type: "entitylink",
        entityLinkTo: Building,
        editable: true,
      },
      management_company: {
        _type: "parent",
        parentEntityLinkTo: Building,
        aliasParentField: "building",
        fields: ["management_company"],
      },
      building_part: {
        name: "Подъезд",
        _type: "entitylink",
        entityLinkTo: BuildingPart,
        entityVisibleField: "visible_name",
        editable: true,
        dependencies: ["building"],
      },
      number_in_living_entrance: { name: "Номер в подъезде", editable: true },
      control_station_id: { name: "ID Станции управления", editable: true },
      passport_location: {
        name: "Местонахождение паспорта лифта",
        _type: "entitylink",
        entityLinkTo: PassportStorage,
        editable: true,
      },
      manufacturing_plant: {
        name: "Завод изготовитель",
        _type: "entitylink",
        entityLinkTo: ManufacturingPlant,
        editable: true,
      },
      elevator_model: {
        name: "Модель лифта",
        _type: "entitylink",
        entityLinkTo: ElevatorModel,
        editable: true,
      },
      stop_count: { name: "Кол-во остановок", editable: true },
      speed: { name: "Скорость (м/с)", editable: true },
      lifting_capacity: { name: "Грузоподъемность (кг)", editable: true },
      commissioning_date: {
        name: "Дата ввода в эксплуатацию",
        _type: "date",
        editable: true,
      },
      planned_inspection_month: {
        name: "Месяц планового освидетельствования",
        _type: "month",
        editable: true,
      },
      declaration_number: { name: "Номер декларации", editable: true },
      declaration_date: {
        name: "Дата декларации",
        _type: "date",
        editable: true,
      },
      registration_number: { name: "Регистрационный номер", editable: true },
      manufacture_year: { name: "Год выпуска", editable: true },
      has_frequency_converter: {
        name: "Наличие преобразователя частоты",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      has_machine_room: {
        name: "Наличие машинного помещения",
        _type: "boolean",
        editable: true,
        _isNull: true,
      },
      declaration_applicant_info: {
        name: "Информация о заявителе декларации",
        editable: true,
      },
      declaration_applicant_ogrn: {
        name: "ОГРН заявителя декларации",
        editable: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const
