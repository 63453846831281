import React, { useCallback, useState } from "react"
import { ICellRendererParams } from "ag-grid-community"
import { observer } from "mobx-react"
import { Button } from "antd"

import { Drawer } from "components"
import { FormOutlined } from "components/antd"
import { AgGridInspector } from "pik-react-utils/components"
import { entitiesStore } from "stores"
import styled from "styled-components"

export const CellContainer = styled.div`
  display: inline-flex;
  overflow: hidden;
  align-items: baseline;
`

export const EntityLinkRenderer = observer(
  ({ value, valueFormatted }: ICellRendererParams) => {
    const [store] = useState(() => entitiesStore)
    const [isOpen, setOpen] = useState(false)
    const [drawerEntity, setDrawerEntity] = useState<IEntityLink | null>()

    const openDrawer = useCallback(() => {
      setOpen(true)
      const { _type, _uid } = value
      const entityLink = {
        _type: _type === "building" ? "inventorybuilding" : _type,
        _uid,
      }

      setDrawerEntity(entityLink)
    }, [value])

    const onClose = () => {
      setOpen(false)
      setDrawerEntity(null)
    }

    const onSave = useCallback(() => {
      if (!value._type) return
      store.flushList(value._type)
    }, [value?._type, store])

    if (!value?._uid) return null

    return (
      <CellContainer key={value._uid}>
        <Button
          type="link"
          size="small"
          icon={<FormOutlined />}
          onClick={openDrawer}
        />
        <span>{valueFormatted}</span>

        <Drawer
          title="Просмотр карточки объекта"
          open={isOpen}
          onClose={onClose}
        >
          {drawerEntity && (
            <AgGridInspector entityLink={drawerEntity} onSave={onSave} />
          )}
        </Drawer>
      </CellContainer>
    )
  }
)
