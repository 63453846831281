import React from "react"
import { ICellRendererParams } from "ag-grid-community"
import { Button } from "antd"

import { ROSREESTR_PATH } from "constants/links"

export const RosReestrViewportRenderer = (params: ICellRendererParams) => {
  if (!params.data?.statement_uid) return null

  return (
    <Button
      type="link"
      size="small"
      target="_blank"
      href={`${ROSREESTR_PATH}/${params.data.statement_uid}/`}
    >
      {params.valueFormatted}
    </Button>
  )
}
