import {
  IReactionDisposer,
  action,
  makeAutoObservable,
  reaction,
  remove,
} from "mobx"
import { ValueSetterParams, ICellRendererParams } from "ag-grid-community"

import { entitiesStore } from "pik-react-utils/stores"
import { checkIsSame, getFieldsErrors } from "pik-react-utils/utils"
import { FieldErrorByRow, FieldChangesByRow } from "./types"

export interface IViewportStore {
  entityType: string
  fields: (IEntityField | IEntityGroup)[]
  changes: FieldChangesByRow
  errors: FieldErrorByRow
  updateEntity: (data: IEntity) => Promise<IEntity | undefined>
  setPropertyValue: (params: ValueSetterParams) => void
  clearChanges: (params?: ICellRendererParams) => void
  dependenciesByField: { [field: string]: string[] }
  reactionDisposer: IReactionDisposer
}

export class ViewportStore implements IViewportStore {
  private store = entitiesStore
  private _entityType: string
  private _changes: FieldChangesByRow = {}
  private _errors: FieldErrorByRow = {}
  private _excludedFields: Record<string, string[]> | undefined
  dependenciesByField: { [field: string]: string[] } = {}

  reactionDisposer = reaction(
    () => this.fields,
    (fields) => {
      fields.forEach((field) => {
        if ("children" in field) {
          field.children.map(this.setDependency)
        } else {
          this.setDependency(field)
        }
      })
    }
  )

  constructor(props: {
    entityType: string
    excludedFields?: Record<string, string[]>
  }) {
    this.setEntityType(props.entityType)
    this._excludedFields = props.excludedFields
    makeAutoObservable<ViewportStore, "clearChangesById">(this, {
      clearChangesById: action.bound,
    })
  }

  private setDependency(field: IEntityField) {
    if (!field.dependencies?.length) return
    field.dependencies.map((key) => {
      this.dependenciesByField[key] = [
        ...new Set([...(this.dependenciesByField[key] ?? []), field.slug]),
      ]
    })
  }

  get entityType() {
    return this._entityType
  }

  get changes() {
    return this._changes
  }

  get errors() {
    return this._errors
  }

  setEntityType(entityType: string) {
    this._entityType = entityType
  }

  get fields() {
    if (!this.entityType) return []
    return this.store.getAgGridFieldsByType(
      this.entityType,
      this._excludedFields?.[this.entityType]
    )
  }

  setPropertyValue(params: ValueSetterParams) {
    const {
      colDef: { field: slug = "" },
      data: { _uid },
      oldValue,
      newValue,
    } = params

    if (checkIsSame(oldValue, newValue)) {
      if (this.changes[_uid]?.[slug]) {
        delete this._changes[_uid][slug]
      }
    } else {
      this._changes[_uid] = { ...this.changes[_uid], [slug]: newValue }
    }

    if (this.dependenciesByField[slug]) {
      this._changes[_uid] = {
        ...this.changes[_uid],
        ...this.dependenciesByField[slug].reduce(
          (acc, key) => ({ ...acc, [key]: null }),
          {}
        ),
      }
    }

    this.clearEntityFieldError(_uid, slug)
  }

  async updateEntity(data: IEntity) {
    const { _uid, _type } = data
    try {
      const response = await this.store.updateEntity({
        _type,
        _uid,
        ...this.changes[_uid],
      })

      if (response?._uid) this.clearChangesById(response._uid)
      return response
    } catch (error) {
      const errors = getFieldsErrors(error)
      this._errors[_uid] = errors
      return
    }
  }

  private clearChangesById(uid: string) {
    remove(this._changes, uid)
  }

  private clearErrorsById(uid: string) {
    remove(this._errors, uid)
  }

  clearEntityFieldError(uid: string, field: string) {
    if (this.errors[uid]) {
      remove(this._errors[uid], field)
    }
  }

  clearChanges(params?: ICellRendererParams) {
    if (params) {
      const { _uid: id } = params.data
      this.clearChangesById(id)
      this.clearErrorsById(id)
    } else {
      this._changes = {}
      this._errors = {}
    }
  }
}
