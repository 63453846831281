import React from "react"
import { observer } from "mobx-react"
import {
  CellClickedEvent,
  CellDoubleClickedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community"
import { AgGridReactProps } from "ag-grid-react"
import { action, computed, makeObservable, observable } from "mobx"
import { debounce, isEqual } from "lodash"

import {
  COLOR_AGGRID_BG_CHANGED_FIELD,
  COLOR_AGGRID_BG_CHANGING_ROW,
  COLOR_AGGRID_BG_LIGHT,
  COLOR_AGGRID_ERROR_BOXSHADOW,
  COLOR_AGGRID_GROUP_BG,
  COLOR_BLACK,
} from "constants/ui-colors"
import { APIConfig } from "pik-react-utils/types"
import { Renderers } from "pik-react-utils/utils/agGrid"
import { Wrapper } from "./components"
import { ConfirmationPanel } from "./ConfirmationPanel"
import { InspectorStore } from "./InspectorStore"
import {
  cellRendererSelector,
  getNameStyle,
  getTooltipValue,
  inspectorCellEditor,
  inspectorFieldFormatter,
} from "./utils"
import { AgGridComponent } from "../GridComponent"
import { PrivateFields } from "./types"

const SubscribableRenderer = Renderers.SubscribableRenderer

interface IAgGridInspectorProps extends AgGridReactProps {
  entityLink: IEntityLink | undefined
  onCellDoubleClicked?: (event: CellDoubleClickedEvent) => void
  onCellClicked?: (event: CellClickedEvent) => void
  subscribableData?: Record<string, unknown>
  canCreateMore?: boolean
  isDocument?: boolean
  onCancel?: () => void
  onSave?: (isClosing?: boolean, entity?: IEntity) => void
  defaultValues?: Partial<IEntity>
  referenceLink?: IEntity | undefined
  requestFormatter?: (
    changes: Record<string, unknown>
  ) => Record<string, unknown>
}

export class AgGridInspectorRef extends React.Component<IAgGridInspectorProps> {
  private store = new InspectorStore()
  private gridApi: GridApi

  constructor(props: IAgGridInspectorProps) {
    super(props)
    makeObservable<AgGridInspectorRef, PrivateFields>(this, {
      store: observable,
      isShowConfirm: computed,
      columnDefs: computed,
      refresh: action.bound,
      redrawRows: action.bound,
      updateStore: action.bound,
      onGridReady: action.bound,
      getValueStyle: action.bound,
      onCancel: action.bound,
      onSave: action.bound,
    })
  }

  componentDidUpdate(prevProps: Readonly<IAgGridInspectorProps>): void {
    if (!isEqual(prevProps.entityLink, this.props.entityLink)) {
      this.updateStore()
    }
  }

  componentWillUnmount(): void {
    this.redrawRows.cancel
  }

  private get isShowConfirm() {
    if (!this.props.entityLink) return false
    return this.store.changedFields.length > 0 || !!this.store.isNew
  }

  private get columnDefs() {
    const { subscribableData, defaultValues } = this.props
    const regularDefs: ColDef[] = [
      {
        field: "name",
        headerName: "Параметр",
        cellStyle: getNameStyle,
      },
      {
        headerName: "Значение",
        field: "value",
        autoHeight: true,
        editable: ({ data }: { data: Record<string, unknown> }) =>
          data.isGroup ? false : !data.readOnly,
        cellEditorSelector: inspectorCellEditor,
        tooltipValueGetter: getTooltipValue,
        cellStyle: this.getValueStyle,
        valueFormatter: inspectorFieldFormatter,
        cellRendererSelector,
        valueSetter: (params) =>
          this.store.setPropertyValue(params, defaultValues),
        cellEditorParams: ({ data }: ICellEditorParams) => {
          if (!data.dependencies?.length) return {}
          return data.dependencies.reduce(
            (acc: Record<string, unknown>, key: string) => {
              const data = this.store.rowData.find(({ slug }) => slug === key)
              return { ...acc, [key]: data?.value }
            },
            {}
          )
        },
      },
    ]
    const subscribableColOptions = {
      editable: false,
      width: 12,
      cellRendererFramework: ({ data }: ICellRendererParams) =>
        data ? (
          <SubscribableRenderer
            data={data.subscribeData}
            title={`Подписаться на рассылку: ${data.name}`}
          />
        ) : null,
      cellStyle: this.getValueStyle,
      headerComponentFramework: ({ data }: ICellRendererParams) => (
        <SubscribableRenderer
          data={data}
          title="Подписаться на изменение всех атрибутов"
        />
      ),
      headerComponentParams: { data: subscribableData },
    }
    if (subscribableData) {
      regularDefs.push(subscribableColOptions)
    }
    return regularDefs
  }

  private redrawRows = debounce(() => {
    this.gridApi?.redrawRows()
    this.gridApi?.refreshCells()
  }, 200)

  private async updateStore(config?: APIConfig) {
    const { referenceLink, defaultValues, entityLink } = this.props
    try {
      this.gridApi?.showLoadingOverlay()
      await this.store.refresh(
        { referenceLink, defaultValues, entityLink },
        config
      )
    } finally {
      this.gridApi?.hideOverlay()
    }
  }

  private onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api
    this.updateStore()
  }

  private getValueStyle(params: ValueFormatterParams) {
    if (!params.data) {
      return {}
    }

    const styles: IStyles = {
      backgroundColor: "inherit",
      boxShadow: "inherit",
    }

    const errors = this.store.errors || {}
    const { _type, slug, readOnly } = params.data
    if (_type === "text") {
      styles.height = "100%"
      styles.whiteSpace = "normal"
    }

    if (readOnly) {
      styles.backgroundColor = COLOR_AGGRID_BG_LIGHT
    }

    if (errors?.[slug]) {
      styles.boxShadow = COLOR_AGGRID_ERROR_BOXSHADOW
    }

    if (this.store.documentFields?.includes(params.data.slug)) {
      styles.backgroundColor = COLOR_AGGRID_BG_CHANGED_FIELD
    }

    const isFieldChanged = this.store.changedFields.includes(slug)

    if (isFieldChanged) {
      styles.backgroundColor = COLOR_AGGRID_BG_CHANGING_ROW
    }
    if (params.data.isGroup) {
      styles.backgroundColor = COLOR_AGGRID_GROUP_BG
      styles.color = COLOR_BLACK
      styles.fontWeight = "bold"
    }

    return styles
  }

  private onCancel() {
    this.store.clear()
    this.props?.onCancel?.()
  }

  private async onSave() {
    const { isDocument, requestFormatter, onSave } = this.props

    try {
      this.gridApi?.stopEditing()
      this.gridApi?.showLoadingOverlay()
      await this.store.saveChanges(isDocument, requestFormatter)
      onSave?.(!this.store.isCreateMore, this.store.entity)
    } finally {
      this.gridApi?.hideOverlay()
      this.gridApi?.redrawRows()
    }
  }

  refresh() {
    this.updateStore({ fromStorage: false })
  }

  render() {
    return (
      <Wrapper>
        <AgGridComponent
          columnDefs={this.columnDefs}
          onGridReady={this.onGridReady}
          rowData={this.store.rowData}
          onCellDoubleClicked={this.props.onCellDoubleClicked}
          onCellClicked={this.props.onCellClicked}
          suppressScrollOnNewData
        />
        <ConfirmationPanel
          canCreateMore={this.props.canCreateMore}
          isCreateMore={this.store.isCreateMore}
          onCreateMoreChange={this.store.onCreateMoreChange}
          isVisible={this.isShowConfirm}
          loading={this.store.loading}
          onCancel={this.onCancel}
          onSave={this.onSave}
          isNew={this.store.isNew}
        />
      </Wrapper>
    )
  }
}

export const AgGridInspector = React.forwardRef(function (
  props: IAgGridInspectorProps,
  ref: React.LegacyRef<AgGridInspectorRef>
) {
  const AgGridInspector = observer(AgGridInspectorRef)
  return <AgGridInspector {...props} ref={ref} />
})
