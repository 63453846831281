export enum AccessPortalStatus {
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
  ERROR = "error",
  LOADING = "loading",
  SUCCESS = "success",
}

export const ERROR_NUMBER_CODES = [
  AccessPortalStatus.FORBIDDEN,
  AccessPortalStatus.NOT_FOUND,
  AccessPortalStatus.SERVER_ERROR,
]

export const ERROR_DESCRIPTIONS: {
  [key in AccessPortalStatus]?: { title: string; description?: string }
} = {
  403: {
    title: "Недостаточно прав для доступа к данному разделу!",
    description: "Обратитесь в техническую поддержку.",
  },
  404: {
    title: "Данный запрос не нашли!",
    description: "Попрбуйте повторить запрос.",
  },
  500: {
    title: "Произошла внутренняя ошибка сервера!",
    description: "Разработчики оповещены и уже работают над ее устранением.",
  },
  error: {
    title: "Произошла ошибка!",
    description: "Попрбуйте повторить запрос.",
  },
}
