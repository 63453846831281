import { ColDef } from "ag-grid-community"
import {
  BuildingPartEntity,
  InventoryBuildingEntity,
  InventoryBuildingPartEntity,
  PremiseEntity,
  UtilitySystemEntity,
} from "models"

export enum TypeForCreating {
  ELEVATOR = "elevator",
  LIVING_ENTRANCE = "livingentrance",
}

export const TypeForFlush = {
  elevator: ["inventorybuildingpart", "inventoryutilitysystem"],
  livingentrance: "inventorybuildingpart",
}

export const COLUMNS: ColDef[] = [
  { field: "_uid", hide: true },
  {
    field: "type",
    headerName: "Тип",
    width: 120,
    cellStyle: { fontWeight: "300", opacity: "0.85" },
    valueGetter: ({ data }) => {
      if (!data) return ""

      if (["inventorybuilding", "building"].includes(data._type)) {
        return (data as InventoryBuildingEntity).building_type?.name
      }

      if (["inventorybuildingpart", "buildingpart"].includes(data._type)) {
        return (data as BuildingPartEntity).building_part_type?.name
      }

      if (["inventoryutilitysystem", "utilitysystem"].includes(data._type)) {
        return (data as UtilitySystemEntity).utility_system_type?.name
      }

      if (["inventorypremise", "premise"].includes(data._type)) {
        return (data as PremiseEntity).premise_type?.name
      }

      return data.type ?? ""
    },
  },
  {
    field: "count",
    headerName: "Кол-во",
    width: 65,
    valueGetter: ({ data }) => {
      if (!data) return ""

      if (["inventorybuilding"].includes(data._type)) {
        return (data as InventoryBuildingEntity).totalGroupCount
      }

      if (["inventorybuildingpart", "buildingpart"].includes(data._type)) {
        return (data as InventoryBuildingPartEntity).totalGroupCount
      }

      return data.count ?? ""
    },
  },
]

export const GROUP_COLUMN: ColDef = {
  headerName: "Объект эксплуатации",
  field: "label",
  tooltipField: "label",
  width: 400,
  valueGetter: ({ data }) => {
    if (!data) return ""

    if (["building", "inventorybuilding"].includes(data._type)) {
      return (data as InventoryBuildingEntity).juristic_address
    }

    return data.name
  },
}

export const CREATE_MODAL_TITLES: Record<TypeForCreating, string> = {
  elevator: "Создать лифт",
  livingentrance: "Создать жилой подъезд",
}
