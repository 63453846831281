// HOUSING

export const NSI_SERVICE_UID = "1f7de7d5-0b0a-4c0b-b36b-4c7f3fd18ffc"
export const MEETING_SERVICE_UID = "014226c5-ced1-4905-a3de-cd1187b941cb"

export const MDS_OBJECT_ALL_ATTRS_GROUP = "87a3fdfc-ad97-42c0-aed7-3d198a5efb2a"
export const MDS_OBJECT_RVOE = "d3968fc5-d921-477f-a9d7-a746727598cd"

export const ELEVATOR_ALL_ATTRS_GROUP_UID =
  "fc7dc200-e053-4d13-8ac8-25b7a958d0da"

export const IDENTIFICATION_GROUP_UID = "3ae17ea5-17cb-4be7-8316-68f6a1f26c36"
export const CONTRACT_GROUP_UID = "735a8a60-3067-4b61-aadb-2d17d65ab169"
export const LIFECICLE_GROUP_UID = "41ce64e3-c881-4c98-ac07-faed449ac8b3"
export const EXPLOITATION_DATA_GROUP_UID =
  "91c63814-cb16-4bc1-9d33-2695828c707e"
export const PREMISE_TYPE_GROUP_UID = "1b0b8f13-d193-49d5-ba2a-51a2b4538965"
export const EXPLOITATION_GROUP_UID = "8816cc1e-f266-492c-aa89-fec38fb4443d"
export const BILLING_GROUP_UID = "5e01d55e-53d2-4d53-8ea2-03e382665dab"
export const MANAGEMENT_STRUCTURE_GROUP_UID =
  "07239a30-2736-4ba8-8c41-c8879468cf4f"
export const BUILDING_ALL_ATTRS_GROUP_UID =
  "e0da1610-dab8-40ef-bfa7-2776d66db2bd"

export const MEETING_DYNAMIC_GROUP = "477a5b9d-1bdb-4682-b458-076716d2b3e5"
export const MEETING_PROJECT_CARD_GROUP = "d6560494-999e-46e0-806a-61e6bf09a5ee"
export const MEETING_ALL_ATTRS_GROUP = "ef0a0ce3-0f60-445f-a6a3-3b4c4ef4791b"
