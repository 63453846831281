import { makeObservable, observable } from "mobx"

import { BaseEntity, FullNameEntity } from "pik-react-utils/entities"
import { Building } from "./building"
import { BuildingPart } from "./buildingpart"
import { UtilitySystemType } from "./utilitysystemtype"

import { createSerializrSchema } from "pik-react-utils/utils"
import { object, raw } from "serializr"
import { UtilitySystemExtraAttr } from "./utilitysystemextraattr"

export class UtilitySystem extends FullNameEntity {
  building: Building
  building_part: BuildingPart
  utility_system_type: UtilitySystemType
  extra_attrs: UtilitySystemExtraAttr

  constructor(props: BaseEntity) {
    super(props)
    makeObservable(this, {
      building: observable,
      building_part: observable,
      utility_system_type: observable,
      extra_attrs: observable,
    })
  }
}

createSerializrSchema(UtilitySystem, null, {
  building: object(Building),
  building_part: object(BuildingPart),
  utility_system_type: object(UtilitySystemType),
  extra_attrs: raw(),
})
