import {
  KIND_CHOICES,
  MEETING_RESULT_CHOICES,
  MEETING_STATUS_CHOICES,
} from "constants/choices"
import { Building } from "../building"
import { FinancialEmployment } from "../financialemployment"
import { ManagementCompany } from "../managementcompany"

export const schema: ISchema = {
  group_parameters: {
    name: "Свойства собрания",
    children: {
      name: { name: "Название" },
      building: {
        name: "Здание",
        _type: "entitylink",
        entityLinkTo: Building,
      },
      curator: {
        name: "Куратор",
        _type: "entitylink",
        entityLinkTo: FinancialEmployment,
      },
      leading_specialist: {
        name: "Ведущий специалист",
        _type: "entitylink",
        entityLinkTo: FinancialEmployment,
      },
      quorum: { name: "Кворум (в %)", _type: "number" },
      kind: { name: "Вид", _type: "choices", choices: KIND_CHOICES },
      status: {
        name: "Статус",
        _type: "choices",
        choices: MEETING_STATUS_CHOICES,
      },
      result: {
        name: "Итог ОСС",
        _type: "choices",
        choices: MEETING_RESULT_CHOICES,
      },
      current_management_company: {
        name: "Действующая ук",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
      },
      target_management_company: {
        name: "Будущая ук",
        _type: "entitylink",
        entityLinkTo: ManagementCompany,
      },
      service_desk_request_url: {
        name: "Заявка на проведение собрания",
        _type: "request_url",
      },
      comment: { name: "Комментарий" },
    },
  },
} as const
