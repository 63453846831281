import { makeAutoObservable } from "mobx"

import {
  URL_TOKEN_NAME,
  OAUTH_FORBID_STATE_NAME,
  ACCESS_TOKEN_NAME,
  ID_TOKEN_NAME,
} from "pik-react-utils/constants"
import { sentry } from "pik-react-utils/sentry"

import { tokenManager } from "./tokenManager"
import { storageService } from "./storageService"
import { ISessionManager, IUserInfo, SessionData } from "./types"

class SessionManager implements ISessionManager {
  userInfo: IUserInfo
  private tokenManager = tokenManager
  private storageService = storageService

  constructor() {
    makeAutoObservable(this)
  }

  get isAuthenticated() {
    const { idToken, accessToken } = this.sessionData
    return !!idToken && !!accessToken
  }

  get sessionData(): SessionData {
    const { accessToken, idToken } = this.tokenManager
    return { accessToken, idToken }
  }

  get currentToken(): string {
    return `Bearer pik ${this.sessionData.accessToken}`
  }

  setUserInfo(info: IUserInfo) {
    this.userInfo = info
    sentry.setUser({ nickname: info.nickname })
  }

  setSession(sessionData: SessionData): void {
    const { accessToken, idToken } = sessionData

    if (accessToken !== undefined) {
      this.tokenManager.setAccessToken(accessToken, ACCESS_TOKEN_NAME)
    }

    if (idToken !== undefined) {
      this.tokenManager.setIdToken(idToken, ID_TOKEN_NAME)
    }
  }

  removeSession(): void {
    sentry.clearSentry()
    tokenManager.removeTokens()
    this.removeForbidState()
  }

  saveSessionUri(): void {
    const currUri = window.location.href
    this.storageService.setItem(URL_TOKEN_NAME, JSON.stringify(currUri))
  }

  getPrevSessionUri(): string {
    const prevSessionUrl: string | null =
      this.storageService.getItem(URL_TOKEN_NAME)
    return prevSessionUrl !== null ? JSON.parse(prevSessionUrl) : "/"
  }

  removePrevSessionUri(): void {
    this.storageService.removeItem(URL_TOKEN_NAME)
  }

  saveForbidState(): void {
    const state = Math.floor(Math.random() * 1000000)
    this.storageService.setItem(OAUTH_FORBID_STATE_NAME, JSON.stringify(state))
  }

  removeForbidState(): void {
    this.storageService.removeItem(OAUTH_FORBID_STATE_NAME)
  }

  getForbidState(): number | undefined {
    const s = this.storageService.getItem(OAUTH_FORBID_STATE_NAME)
    return s !== null ? Number(s) : undefined
  }
}

export const sessionManager = new SessionManager()
