import React, { useCallback, useMemo } from "react"
import { observer } from "mobx-react"

import { InfiniteScroll } from "components"
import { FinancialEmploymentEntity } from "models"
import { Employee, EmployeeList, Organization, Role } from "./components"

interface IEmploymentListProps {
  financialUnitId: string
  search: string
  organization: string | undefined
  settings: {
    isLoadChildren: boolean
    isShowFinancialEmployment: boolean
    isShowJuristicEmployment: boolean
  }
}

export const EmploymentList = observer(
  ({
    organization,
    search = "",
    financialUnitId,
    settings,
  }: IEmploymentListProps) => {
    const query = useMemo(() => {
      const field = settings.isLoadChildren
        ? "financial_unit__unit_and_unit_followers__uid"
        : "financial_unit__uid"
      const ordering =
        "employee__last_name,employee__first_name,employee__middle_name"

      return {
        organization__uid: organization,
        ordering,
        is_actual: true,
        search,
        [field]: financialUnitId,
      }
    }, [financialUnitId, settings, organization, search])

    // If FinancialEmployment(FE) has only one JuristicEmployment(JE) - use it
    // If FE has 2 or more JE - use "main" JE
    // If FE has 2 or more JE and none of them is "main" - don't show position
    const itemEl = useCallback(
      (employment: FinancialEmploymentEntity) => {
        const { _uid, employee, employments, financial_unit } = employment
        const breadcrumbs: string[] = []
        const mainEmployment =
          employments?.length === 1
            ? employments[0]
            : employments?.filter((i) => i.kind === "main")[0]

        if (settings.isShowFinancialEmployment && financial_unit?.full_name) {
          breadcrumbs.push(financial_unit.full_name)
        }

        if (settings.isShowJuristicEmployment) {
          employments?.map((i) => {
            if (i.is_deleted) return
            if (i.juristic_unit?.parent_names) {
              breadcrumbs.push(...i.juristic_unit.parent_names)
            }
            if (i.position_name) {
              breadcrumbs.push(i.position_name)
            }
          })
        }

        return (
          <Employee key={_uid + financialUnitId + employee?._uid}>
            <strong>{employee?.full_name}</strong>
            <Role>{mainEmployment?.position_name}</Role>
            <Organization>{breadcrumbs.join(" -> ")}</Organization>
          </Employee>
        )
      },
      [financialUnitId, settings]
    )

    return (
      <EmployeeList>
        <InfiniteScroll
          entityType="financialemployment"
          query={query}
          itemEl={itemEl}
        />
      </EmployeeList>
    )
  }
)
