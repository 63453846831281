import React, { useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react"
import { Button, Progress } from "antd"

import { routerService } from "services"
import { ExportStatus } from "./constants"
import { PageContainer, WidgetContainer } from "./components"
import { ExcelStore } from "./ExcelStore"
import { getAttention, progressStatus } from "./utils"

export const ExportExcel = observer(() => {
  const [router] = useState(() => routerService)
  const [
    {
      currentCount,
      entityName,
      progressValue,
      status,
      totalCount,
      waitingTime,
      downloadTime,
    },
  ] = useState(
    () =>
      new ExcelStore({
        type: router.getMatchExcelEntityType(),
        searchParams: router.getSearchParams(),
      })
  )

  const pageTitle = useMemo(() => {
    switch (status) {
      case ExportStatus.INIT:
        return "Подготовка объектов к экспорту..."
      case ExportStatus.LOADING:
        return `${progressValue}% загружено
        (${currentCount}/${totalCount})`
      case ExportStatus.DONE:
        return `Загрузка завершена!`
      case ExportStatus.ERROR:
        return "Во время экспорта произошла ошибка!"
      default:
        return `Экспорт в Excel: ${entityName}`
    }
  }, [currentCount, entityName, progressValue, status, totalCount])

  const description = useMemo(() => {
    switch (status) {
      case ExportStatus.INIT:
        return "Подготовка объектов к экспорту..."
      case ExportStatus.LOADING:
        return `
          Загружено ${currentCount} из ${totalCount} объектов.
          ${waitingTime ? `Осталось ${waitingTime.humanize()}` : ""}
        `
      case ExportStatus.DONE:
        // eslint-disable-next-line max-len
        return `Загружено ${currentCount} объектов за ${downloadTime?.humanize()}.`
      case ExportStatus.ERROR:
        return "Во время экспорта произошла ошибка!"
      default:
        return `Экспорт в Excel: ${entityName}`
    }
  }, [currentCount, downloadTime, entityName, status, totalCount, waitingTime])

  useEffect(() => {
    document.title = pageTitle
    if (status === ExportStatus.DONE) {
      getAttention()
    }
  }, [pageTitle, status])

  return (
    <PageContainer>
      <WidgetContainer>
        <Progress
          type="circle"
          status={progressStatus(status)}
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
          percent={progressValue}
        />
        <br />
        <span>{description}</span>
        <Button
          hidden={status !== ExportStatus.DONE}
          type="primary"
          onClick={window.close}
          style={{ marginTop: "24px" }}
        >
          Закрыть окно
        </Button>
      </WidgetContainer>
    </PageContainer>
  )
})
