import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Tooltip } from "antd"

import { RequestPageTabs } from "constants/links"
import { BellOutlined } from "components/antd"
import { routerService } from "services"

type SubscribableRendererProps = {
  data: Record<string, unknown>
  title?: string
}

export const SubscribableRenderer = ({
  data,
  title,
}: SubscribableRendererProps) => {
  const [router] = useState(() => routerService)

  if (!data) return null

  return (
    <Tooltip placement="left" title={title}>
      <Link
        target="_blank"
        to={router.linkToRequestTab(RequestPageTabs.PERMISSION, data)}
      >
        <BellOutlined />
      </Link>
    </Tooltip>
  )
}
