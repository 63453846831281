import { makeAutoObservable } from "mobx"
import { serializeAll } from "serializr"

@serializeAll
export class BuildingCounter {
  protected readonly _type: string

  constructor() {
    makeAutoObservable(this)
  }

  get ids() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _type, ...uids } = this
    return Object.keys(uids)
  }

  get amount() {
    return this.ids.length
  }
}
