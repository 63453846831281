import { ICellEditorParams } from "ag-grid-community"
import { BooleanChoiceRenderer } from "./renderer"

const editorSelector = (params: ICellEditorParams, field: IEntityField) => {
  if (field._type === "choices") {
    return {
      component: "agRichSelectCellEditor",
      params: {
        values: [...field.choices.map(({ value }) => value), null],
      },
    }
  }

  if (field._type === "month") {
    return {
      component: "agRichSelectCellEditor",
      params: { values: Array.from(Array(12).keys()).map((i) => i + 1) },
    }
  }
  if (field._type === "boolean") {
    return {
      component: "agRichSelectCellEditor",
      params: {
        values: [true, false, null],
        cellRenderer: BooleanChoiceRenderer,
      },
    }
  }

  if (field._type === "datetime") {
    return { component: "DateTimeEditor" }
  }

  if (field._type === "date") {
    return { component: "DateEditor" }
  }

  if (
    field._type === "number" ||
    field._type === "integer" ||
    field._type === "decimal"
  ) {
    return {
      component: "NumberEditor",
      params: { type: field._type },
    }
  }

  if (field._type === "entitylink") {
    return {
      component: "EntityLinkEditor",
      params: {
        entityType: field._entitylinkto,
        isNull: field._isNull ?? false,
        dependencies: params.colDef.cellEditorParams?.(params),
      },
    }
  }

  if (field._type === "string[]") {
    return {
      component: "StringArrayEditor",
    }
  }

  if (field._type === "attachment") {
    return { component: "AttachmentEditor" }
  }

  return { component: "TextEditor", params: {} }
}

export const cellEditorSelector =
  (field: IEntityField) => (params: ICellEditorParams) =>
    editorSelector(params, field)
