import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from "ag-grid-community"

import { DEFAULT_QUERY, PAGE_SIZE_DEFAULT } from "constants/requests"
import { BuildingEntity, CompetitionEntity, MeetingEntity } from "models"
import { entitiesStore } from "stores"
import { QueryArgs } from "types"

export class Datasource implements IServerSideDatasource {
  private query: QueryArgs = {}
  private store = entitiesStore

  constructor(props: { query: QueryArgs }) {
    this.query = props.query
  }

  private async buildingGroupList(results: IEntityLink[] = []) {
    const uidString = results.map(({ _uid }) => _uid).toString()
    const getList = <T extends IEntity>(type: string) =>
      this.store.getList<T>(type, { page: 1, building__uid__in: uidString })

    const [competitionList, meetingList] = await Promise.all([
      getList<MeetingEntity>("meeting"),
      getList<CompetitionEntity>("competition"),
    ])

    const uids = [
      ...(competitionList?.entities ?? []),
      ...(meetingList?.entities ?? []),
    ].map(({ building }) => building._uid)

    return uids
  }

  private mapBuildings(
    entities: BuildingEntity[] = [],
    groupList: string[] = []
  ) {
    return entities.map((entity: BuildingEntity) => {
      const { _uid } = entity

      return Object.assign(entity, {
        type: entity.building_type?.name,
        groupKey: _uid,
        isGroup: groupList.includes(_uid),
      })
    })
  }

  private async retrieveBuildingList(params: IServerSideGetRowsParams) {
    const { successCallback, request } = params
    const query = {
      ...DEFAULT_QUERY,
      ...this.query,
      page: Math.floor(request.endRow / PAGE_SIZE_DEFAULT),
    }

    const response = await this.store.getList<BuildingEntity>("building", query)
    const buildingList = await this.buildingGroupList(response?.entities)
    successCallback(
      this.mapBuildings(
        response?.entities.map((entity: BuildingEntity) => {
          const { _uid } = entity

          return Object.assign(entity, {
            type: entity.building_type?.name,
            groupKey: _uid,
            isGroup: buildingList.includes(_uid),
          })
        }),
        buildingList
      ),
      response?.meta?.count ?? 0
    )
  }

  private mapEvents(
    entities: (CompetitionEntity | MeetingEntity)[] = [],
    type: string
  ) {
    return entities.map((entity) =>
      Object.assign(entity, {
        groupKey: entity._uid,
        isGroup: false,
        type,
      })
    )
  }

  private async retrieveGroupList(params: IServerSideGetRowsParams) {
    const { parentNode, request, successCallback } = params

    const getList = <T extends IEntity>(type: string) =>
      this.store.getList<T>(type, {
        ...DEFAULT_QUERY,
        page: Math.floor(request.endRow / PAGE_SIZE_DEFAULT),
        building__uid__in: parentNode.data._uid,
      })

    const [competitions, meetings] = await Promise.all([
      getList<CompetitionEntity>("competition"),
      getList<MeetingEntity>("meeting"),
    ])

    const count = (competitions?.meta.count ?? 0) + (meetings?.meta?.count ?? 0)
    const list = [
      ...this.mapEvents(competitions?.entities ?? [], "Конкурс"),
      ...this.mapEvents(meetings?.entities ?? [], "Собрание"),
    ]
    successCallback(list, count)
  }

  async getRows(params: IServerSideGetRowsParams) {
    const { request } = params

    if (request.groupKeys.length > 0) {
      this.retrieveGroupList(params)
    } else {
      this.retrieveBuildingList(params)
    }
  }
}
