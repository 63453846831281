import { PAGE_SIZE_DEFAULT } from "pik-react-utils/constants"

export function getChoiceLabel(
  choices: FieldChoice[],
  value: FieldChoiceValue
) {
  const choice = choices?.find((item) => item.value === value)
  return choice?.label ?? ""
}

export const agGridPageQuery = (
  endRow: number | undefined,
  pageSize = PAGE_SIZE_DEFAULT
) => ({
  page: endRow ? Math.floor(endRow / pageSize) : 1,
  page_size: pageSize,
})
