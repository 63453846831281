import React, { PropsWithChildren } from "react"
import { Drawer as DefaultDrawer, DrawerProps } from "antd"
import { CloseCircleOutlined } from "components/antd"

export const Drawer = (props: PropsWithChildren<DrawerProps>) => (
  <DefaultDrawer
    closable
    destroyOnClose
    closeIcon={<CloseCircleOutlined />}
    width={600}
    {...props}
  />
)
