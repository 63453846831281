import { Button } from "antd"
import React from "react"
import styled from "styled-components"
import { ArrowRightOutlined } from "components/antd"

const Link = styled(Button)`
  padding: 0;
  height: auto;
`

export const ExternalLink = ({ to, label }: { to: string; label: string }) => (
  <Link type="link" href={to} target="_blank" rel="noopener noreferrer">
    {label} <ArrowRightOutlined type="link" rotate={-35} />
  </Link>
)
