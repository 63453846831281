import React from "react"
import { observer } from "mobx-react"

import { routerService as router } from "services"
import { HeaderMenu } from "./HeaderMenu"
import { UserInfo } from "./UserInfo"
import { Wrapper, Logo, LogoIcon, Title } from "./components"

export const Header = observer(() => (
  <Wrapper>
    <Logo to={router.MAIN_PATH}>
      <LogoIcon />
      <Title>НСИ</Title>
    </Logo>
    <HeaderMenu />
    <UserInfo />
  </Wrapper>
))
