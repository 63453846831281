import { ceil } from "lodash"
import {
  IViewportDatasourceParams,
  IViewportDatasource,
  RowNode,
} from "ag-grid-community"

import { PAGE_SIZE_DEFAULT } from "pik-react-utils/constants"
import { entitiesStore } from "pik-react-utils/stores"
import { QueryArgs } from "pik-react-utils/types"

export interface IAgGridViewportDatasource extends IViewportDatasource {
  getRow: (index: number) => RowNode
  update: (props: { entityType: string; query?: QueryArgs }) => void
  updateRow: (rowIndex: number, newEntity: IEntity) => void
  destroy: () => void
}

export class ViewportDatasource implements IAgGridViewportDatasource {
  private gridApi: IViewportDatasourceParams
  private store = entitiesStore
  private query: QueryArgs
  private entityType: string
  private rowCount = 0
  private rows = {}

  constructor(props: { entityType: string; query?: QueryArgs }) {
    this.entityType = props.entityType
    this.query = props.query ?? {}
    this.updateRowsCount()
  }

  update(props: { entityType: string; query?: QueryArgs }) {
    this.entityType = props.entityType
    this.query = props.query ?? {}
    this.destroy()
    this.updateRowsCount()
  }

  private get maxPage() {
    return ceil(this.rowCount / PAGE_SIZE_DEFAULT)
  }

  init(params: IViewportDatasourceParams): void {
    this.gridApi = params
  }

  setViewportRange(firstRow: number, lastRow: number) {
    this.updateRows(firstRow, lastRow)
  }

  getRow(index: number) {
    return this.gridApi?.getRow(index)
  }

  updateRow(rowIndex: number, newEntity: IEntity) {
    Object.assign(this.rows[rowIndex], newEntity)
  }

  async updateRows(firstRow: number, lastRow: number) {
    const startPage = Math.floor(firstRow / PAGE_SIZE_DEFAULT) + 1
    const lastPage = Math.floor(lastRow / PAGE_SIZE_DEFAULT) + 1

    for (let page = startPage; page <= lastPage; page++) {
      if (page > this.maxPage) {
        break
      }

      const response = await this.store.getList(
        this.entityType,
        { ...this.query, page, page_size: PAGE_SIZE_DEFAULT },
        { handleError: false }
      )

      if (response?.entities) {
        response?.entities.forEach((entity: IEntity, index: number) => {
          const rowNumber = (page - 1) * PAGE_SIZE_DEFAULT + index
          this.rows[rowNumber] = entity
        })
      }
      this.gridApi?.setRowData(this.rows)
    }
  }

  async updateRowsCount() {
    this.rowCount = await this.store.getCount(this.entityType, this.query, {
      handleError: false,
    })
    this.gridApi?.setRowCount(this.rowCount, true)
  }

  destroy() {
    this.rows = {}
    this.rowCount = 0
    this.gridApi?.setRowCount(this.rowCount, false)
    this.gridApi?.setRowData(this.rows)
  }
}
