import React, { useCallback, useState } from "react"
import { observer } from "mobx-react"
import { Button } from "antd"

import { CREATING_RULE_FIELDS } from "constants/fields"
import { DeleteOutlined } from "components/antd"
import { BuildingLocationRuleEntity } from "models"
import { AttentionModal } from "modals"
import { entitiesStore } from "stores"
import { Text, RuleItem, Wrapper } from "./components"

interface IRuleProps {
  rule: BuildingLocationRuleEntity
  onDelete: () => void
}

export const Rule = observer(({ rule, onDelete }: IRuleProps) => {
  const [store] = useState(() => entitiesStore)
  const [isOpen, setOpenModal] = useState(false)

  const onDeleteRule = useCallback(async () => {
    await store.deleteEntity({
      _type: "buildinglocationrule",
      _uid: rule._uid,
    })
    setOpenModal(false)
    onDelete()
  }, [rule._uid, store, onDelete])

  const Description = () => (
    <div>
      {CREATING_RULE_FIELDS.map((field, i) => {
        const entity = rule[field.slug]
        if (!entity) return null

        return (
          <Text key={i}>
            <strong>{field.label}</strong>: {entity.name}
          </Text>
        )
      })}
    </div>
  )

  return (
    <RuleItem>
      <Wrapper>
        <Description />
        <Button
          type="link"
          danger
          icon={<DeleteOutlined />}
          onClick={() => setOpenModal(true)}
        >
          Удалить
        </Button>
        <AttentionModal
          open={isOpen}
          onOk={onDeleteRule}
          onCancel={() => setOpenModal(false)}
        >
          Вы действительно хотите удалить это правило?
          <Description />
        </AttentionModal>
      </Wrapper>
    </RuleItem>
  )
})
