import React from "react"

export const Telephony = () => (
  <div>
    Если Вы собираетесь использовать внутреннюю телефонию, Вам необходимо
    запустить и настроить программу 3CX Phone. Для работы с телефонией
    обязательно наличие гарнитуры с микрофоном c разъемом USB или двумя
    разъемами mini-Jack 3.5, либо встроенный микрофон (в ноутбук или веб камеру)
    и колонки. Гарнитура от мобильного телефона для работы с корпоративной
    телефонией не подойдет.
    <br />
    <br />
    Если Вы сотрудник Контактного центра, программа 3CX Phone уже установлена на
    вашем терминале. Настройки 3CX Phone сработают автоматически.
    <br />
    <br />
    Если Вы клиентский менеджер, программа 3CX Phone уже установлена на вашем
    терминале. Перейдите к пункту 6.5 данной инструкции.
    <br />
    <br />
    Если Вы не являетесь клиентским менеджером или сотрудником Контактного
    центра, проверьте установлена ли у Вас программа 3CX Phone. Для этого
    нажмите на кнопку “Пуск” и поищите ее в списке программ:
    <br />
    <img src={require("assets/img/3cx.png")} alt="" />
    <br />
    <ol>
      <li>Если программа установлена, перейдите к пункту 4</li>
      <li>
        Если программа не установлена и Вы используете корпоративный
        компьютер/ноутбук для работы из дома - сделайте заявку на&nbsp;
        <a href="mailto:7777@pik-comfort.ru">7777@pikt&#8209;comfort.ru</a> или
        оставьте обращение на портале по ссылке:&nbsp;
        <a href="https://home.pik-comfort.ru/request-system/">
          https://home.pikt&#8209;comfort.ru/request&#8209;system/
        </a>
      </li>
      <li>
        Если программа не установлена и Вы используете личный компьютер/ноутбук
        для работы из дома. Скачайте программу 3CXPhone6.msi по ссылке&nbsp;
        <a href="https://drive.google.com/drive/u/0/folders/1C4EMDXMgODefyeKYbyJNMMD_Vx_Q3SW5">
          https://drive.google.com/drive/u/0/folders/1C4EMDXMgODefyeKYbyJNMMD_Vx_Q3SW5
        </a>
        <br />
        <br />
        Установка программы:
        <ol>
          <li>Запустите установщик 3CX Phone</li>
          <li>
            Нажмите “Next”
            <br />
            <img src={require("assets/img/setup_start.png")} alt="" />
            <br />
          </li>
          <li>
            Снова нажмите “Next”
            <br />
            <img src={require("assets/img/setup_folder.png")} alt="" />
            <br />
          </li>
          <li>
            В следующем диалоговом окне нажмите “Install”
            <br />
            <img src={require("assets/img/setup_install.png")} alt="" />
            <br />
          </li>
          <li>
            Дождитесь окончания установки и нажмите “Finish”
            <br />
            <img src={require("assets/img/setup_finish.png")} alt="" />
            <br />
          </li>
        </ol>
      </li>
      <li>
        После установки программы телефонии Вам необходимо один раз настроить
        ее:
        <br />
        Нажмите кнопку Create Profile: <br />
        <img src={require("assets/img/create_profile.png")} alt="" />
        <br />
        Появится окно с пустым списком аккаунтов, необходимо добавить новый
        профиль нажав кнопку NEW: <br />
        <img src={require("assets/img/new_profile.png")} alt="" />
        <br />
        Появится окно где необходимо заполнить данные для подключения.
        <span style={{ color: "red" }}>
          Эти данные можно запросить у группы телефонии отправив заявку на&nbsp;
          <a href="mailto:7777@pik-comfort.ru">7777@pik-comfort.ru</a> или на
          портале по ссылке:&nbsp;
          <a href="https://home.pik-comfort.ru/request-system/">
            https://home.pik&#8209;comfort.ru/request&#8209;system/
          </a>
        </span>
        <ol>
          <li>
            Сюда необходимо вписать&nbsp;
            <span style={{ color: "red" }}>номер телефона или логин от ПК</span>
          </li>
          <li>
            Сюда необходимо вписать&nbsp;
            <span style={{ color: "red" }}>номер телефона или логин от ПК</span>
          </li>
          <li>
            Сюда необходимо вписать&nbsp;
            <span style={{ color: "red" }}>номер телефона</span>
          </li>
          <li>
            Сюда необходимо вписать&nbsp;
            <span style={{ color: "red" }}>номер телефона</span>
          </li>
          <li>
            Сюда необходимо вписать&nbsp;
            <span style={{ color: "red" }}>пароль которые вам предоставят</span>
          </li>
          <li>
            Сюда необходимо вписать&nbsp;
            <span style={{ color: "red" }}>
              имя сервера которые вам тоже предоставят в заявке
            </span>
          </li>
        </ol>
        <img src={require("assets/img/profile_data.png")} alt="" />
        <br />
        <br />
        Если все заполнено верно, после нажатия ОК в этом окне и ОК в окне со
        списком профилей, в программе появятся надписи On Hook и Available.
        После этого Вы сможете использовать программу 3CX Phone для звонков.
        Набор номера и звонок производится также как на стационарном телефоне в
        офисе.
      </li>
    </ol>
  </div>
)
