/* eslint-disable @typescript-eslint/no-explicit-any */

import { sessionManager } from "pik-react-utils/auth"
import { AsyncSelectOption } from "types"
import { AttrNames, NON_MODIFIED_FIELDS, RANGE_DATA_FIELDS } from "./constants"

export const dataToServer = (fields: { [key in AttrNames]: any }) => {
  const data: Record<string, unknown> & Pick<IEntity, "_type"> = {
    _type: "tariffrecalcrequest",
  }

  RANGE_DATA_FIELDS.forEach((key) => {
    data[`${key}_start`] = fields[key][0]?.format("YYYY-MM-DD")
    data[`${key}_end`] = fields[key][1]?.format("YYYY-MM-DD")
  })

  NON_MODIFIED_FIELDS.forEach((name) => {
    data[name] = fields[name]
  })

  const { userInfo } = sessionManager
  if (userInfo) {
    data.initiator_email = userInfo.email
    data.initiator_full_name = `${userInfo.family_name} ${userInfo.given_name}`
  }

  return data
}

export const prepareEntity = (option: AsyncSelectOption) => {
  const { value, label } = option

  const entity = {
    entity_name: label,
    entity_uid: value?._uid,
    entity_type: value?._type,
    _type: "entity",
  }

  return entity
}
