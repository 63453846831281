import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"

import { InfiniteScroll } from "components"
import { BuildingLocationEntity } from "models"
import { Wrapper } from "pages/FinancialStructure/components"

const Item = styled.p`
  font-size: 13px;
  margin-bottom: 4px;
`

export const BuildingsByRules = observer(
  ({ financialUnitId }: { financialUnitId: string }) => (
    <Wrapper>
      <InfiniteScroll
        entityType="buildinglocation"
        query={{ financial_territory__financial_unit__uid: financialUnitId }}
        itemEl={(el: BuildingLocationEntity) => (
          <Item>{el.building?.name}</Item>
        )}
      />
    </Wrapper>
  )
)
