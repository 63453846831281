import React from "react"
import { ICellRendererParams } from "ag-grid-community"
import { Button } from "antd"
import { makeObservable, observable } from "mobx"
import { observer } from "mobx-react"

import { ROSREESTR_PATH } from "constants/links"
import { RosReestrStatementEntity } from "models"
import { entitiesStore } from "stores"

export const RosReestrInspectorRenderer = observer(
  // eslint-disable-next-line max-len
  class RosReestrInspectorRenderer extends React.Component<ICellRendererParams> {
    private statement: RosReestrStatementEntity | null | undefined
    private store = entitiesStore

    constructor(props: ICellRendererParams) {
      super(props)
      makeObservable<RosReestrInspectorRenderer, "statement">(this, {
        statement: observable,
      })
    }

    componentDidMount() {
      this.retrieveStatement()
    }

    private async retrieveStatement() {
      const { buiding_uid } = this.props.value ?? {}

      if (!buiding_uid) return

      const response = await this.store.getList<RosReestrStatementEntity>(
        "rosreestrstatement",
        {
          ordering: "-created",
          type: "building",
          building__uid: buiding_uid,
          status: "parsed",
        },
        { handleError: false }
      )

      this.statement = response?.entities?.[0]
    }

    render() {
      if (!this.statement?._uid) return null

      const { certificate_number, _uid } = this.statement

      return (
        <Button
          type="link"
          size="small"
          href={`${ROSREESTR_PATH}/${_uid}`}
          target="_blank"
        >
          {certificate_number ?? _uid}
        </Button>
      )
    }
  }
)
