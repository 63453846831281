import React from "react"
import { ICellRendererParams } from "ag-grid-community"
import { Button } from "antd"

import { YANDEX_MAP_LINK } from "constants/links"

export const PointRenderer = ({
  value,
  valueFormatted,
}: ICellRendererParams) => {
  if (!value?.coordinates) return null

  const href = `${YANDEX_MAP_LINK}/?ll=${value.coordinates.toString()}&z=18`

  return (
    <Button type="link" size="small" target="_blank" href={href}>
      {valueFormatted}
    </Button>
  )
}
