import { deserialize } from "serializr"

export class EntityModelService {
  private entityClassByType: { [key: string]: ModelConstructor<object> }
  private typeByClass: { [key: string]: string }

  constructor(classByType: { [key: string]: ModelConstructor<object> } = {}) {
    this.entityClassByType = classByType
    this.typeByClass = Object.fromEntries(
      Object.entries(this.entityClassByType).map(([typeName, ClassByType]) => [
        ClassByType.name,
        typeName,
      ])
    )
  }

  getClassByType<T>(_type: string) {
    const EntityClass = this.entityClassByType[_type]
    if (!EntityClass) return
    return EntityClass as ModelConstructor<T>
  }

  getTypeByClassName(className: string) {
    return this.typeByClass[className]
  }

  createDummy<T extends IEntity = IEntity>(_type: string) {
    const EntityClass = this.getClassByType<T>(_type)
    if (!EntityClass) return
    return deserialize<T>(EntityClass, { _type })
  }

  private schemaForParent(slug: string, schema: IParentSchema): IEntityField[] {
    const {
      parentEntityLinkTo,
      fields: visibleFields,
      editable,
      aliasParentField: parent = slug,
    } = schema

    const parentSchemaFields = Object.entries(parentEntityLinkTo.schema).reduce(
      (acc, [key, field]) => {
        if ("children" in field) {
          return { ...acc, [key]: field, ...field.children }
        }
        return { ...acc, [key]: field }
      },
      {}
    )

    const newFields = visibleFields.reduce((acc, fieldName) => {
      const foundItem = parentSchemaFields[fieldName]
      if (!foundItem) return acc

      const newField = this.convertSchemaToField(fieldName, foundItem)
      if (!newField) return acc

      return acc.concat({ ...newField, parent, readOnly: !editable })
    }, [] as IEntityField[])

    return newFields
  }

  private schemaForEntityLink(slug: string, schema: IEntityLinkSchema) {
    const {
      entityLinkTo,
      editable,
      entityVisibleField,
      aliasEntityType,
      ...rest
    } = schema

    const getClassType = this.getTypeByClassName(entityLinkTo.name)

    const fieldParams = {
      slug,
      _entitylinkto: aliasEntityType || getClassType,
      ...rest,
      readOnly: !editable,
    } as IEntityLinkField

    if (entityVisibleField) {
      fieldParams._entityvisiblename = entityVisibleField
    }

    return fieldParams as IEntityField
  }

  private convertSchemaToField(
    slug: string,
    schema: IChildSchema
  ): IEntityField | IEntityField[] | undefined {
    if (schema._type === "parent") {
      return this.schemaForParent(slug, schema)
    }

    if (schema._type === "attachment" || schema._type === "entitylink") {
      return this.schemaForEntityLink(slug, schema)
    }

    if (schema._type === "rosreestrstatement" || schema._type === "point") {
      return { slug, ...schema, readOnly: true } as IEntityField
    }

    const { _type = "string", editable, ...rest } = schema
    return { slug, _type, ...rest, readOnly: !editable } as IEntityField
  }

  getAgGridFieldsByType(_type: string) {
    const EntityClass = this.getClassByType(_type)
    if (!EntityClass) return []
    const currentSchema: ISchema = EntityClass.schema

    let fields: (IEntityGroup | IEntityField)[] = []

    Object.entries(currentSchema).map(([slug, schema]) => {
      if ("children" in schema) {
        const { children, ...rest } = schema

        fields.push({
          slug,
          ...rest,
          children: Object.entries(children).reduce(
            (acc, [slug, subSchema]) => {
              const field = this.convertSchemaToField(slug, subSchema)
              if (!field) return acc
              return acc.concat(field)
            },
            [] as IEntityField[]
          ),
        })
      } else {
        const field = this.convertSchemaToField(slug, schema)
        if (field) {
          fields = fields.concat(field)
        }
      }
    })

    return fields
  }
}
