import { WorkplaceFormField } from "../types"

export const dataToServer = (
  unitUid: string,
  formField: WorkplaceFormField | undefined
) => {
  const isExternal = formField?.employment?.value?._type === "externalaccount"

  const data = {
    _type: "staffunit",
    office: formField?.office.value?.toEntityLink(),
    unit: { _type: "unit", _uid: unitUid },
    is_main: false,
    role: formField?.role.value?.toEntityLink(),
    ...(isExternal
      ? { external_account: formField?.employment?.value?.toEntityLink() }
      : {
          employment: formField?.employment?.value?.toEntityLink(),
        }),
  }
  return data
}
