import Decimal from "decimal.js"
import {
  createModelSchema,
  custom,
  identifier,
  list,
  object,
  primitive,
  Props,
  PropSchema,
  raw,
  SKIP,
} from "serializr"

export const decimal = (): PropSchema =>
  custom(
    (val) => val || SKIP,
    (val) => {
      if (val == null) return null
      return new Decimal(val)
    }
  )

export const getPreparedSchema = (
  schema: ISchema
): { [key: string]: PropSchema } => {
  return Object.entries(schema).reduce((acc, [slug, model]) => {
    if ("children" in model) {
      return { ...acc, ...getPreparedSchema(model.children) }
    }

    if ("computed" in model) {
      model.computed?.map((slug) => {
        if (typeof slug === "string" && !(slug in acc)) {
          acc[slug] = primitive()
        }
      })
      return acc
    }

    const { _type: type } = model

    if (type === "rosreestrstatement") {
      return acc
    }

    if (type === "decimal") {
      return { ...acc, [slug]: decimal() }
    }

    if (type === "parent") {
      const { aliasParentField: parentSlug = slug } = model
      return { ...acc, [parentSlug]: object(model.parentEntityLinkTo) }
    }

    if (type === "attachment" || type === "entitylink") {
      return { ...acc, [slug]: object(model.entityLinkTo) }
    }

    if (type === "string[]") {
      return { ...acc, [slug]: list(primitive()) }
    }

    if (type === "point") {
      return { ...acc, [slug]: raw() }
    }

    if (type === "date" || type === "month" || type === "datetime") {
      // TODO: analyze how use this types
      return { ...acc, [slug]: primitive() }
    }

    return { ...acc, [slug]: primitive() }
  }, {})
}

export const createSerializrSchema = (
  EntityClass: ModelConstructor<object>,
  schema: ISchema | null = {},
  additionSchema: Props = {}
) => {
  // _type: required parameters in object
  const defaultSchema = {
    ...additionSchema,
    _type: primitive(),
    _version: primitive(),
  }

  if (!schema) {
    return createModelSchema(EntityClass, defaultSchema)
  }

  const modelSchema = getPreparedSchema(schema)
  if (modelSchema._uid) {
    modelSchema._uid = identifier()
  }
  return createModelSchema(EntityClass, { ...modelSchema, ...defaultSchema })
}
