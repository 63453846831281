import { GROUP_SYSTEM_DATE_FIELDS, groupEditableNameFields } from "utils/fields"
import { FullNameEntity } from "pik-react-utils/entities"

import { Exploitation } from "./exploitation"
import { createSerializrSchema } from "pik-react-utils/utils"

const schema: ISchema = {
  ...groupEditableNameFields(),

  group_exploitation_structure: {
    name: "Структура эксплуатации (ГП №1)",
    children: {
      exploitation: {
        name: "Служба эксплуатации",
        _type: "entitylink",
        entityLinkTo: Exploitation,
        editable: true,
      },
    },
  },

  ...GROUP_SYSTEM_DATE_FIELDS,
} as const

export class BuildingsGroup extends FullNameEntity {
  static readonly schema: ISchema = schema
}

createSerializrSchema(BuildingsGroup, schema)
