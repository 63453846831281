import React from "react"
import { Button } from "antd"
import { ICellRendererParams } from "ag-grid-community"

export const RequestURLRender = ({
  value,
  valueFormatted,
}: ICellRendererParams) => {
  if (!value) return null

  return (
    <Button type="link" size="small" target="_blank" href={value}>
      {valueFormatted}
    </Button>
  )
}
